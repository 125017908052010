import { Middleware } from 'redux'
import { ActionTypes as RouterActionsTypes } from 'src/state/router/enums'
import { ActionTypes as FeedbackActionsTypes } from 'src/api/feedback/enums'
import { ActionTypes as QuizActionsTypes } from 'src/api/quiz/main/enums'
import { ActionTypes as QuizBettingActionTypes } from 'src/api/quiz/betting/enums'
import { ActionTypes as QuizWidgetActionsTypes } from 'src/api/quiz/widget/enums'
import { ActionTypes as PowerupsActionTypes } from 'src/api/powerups/enums'
import { ActionTypes as SummaryActionTypes } from 'src/api/summary/enums'

import {
  trackPageView,
  trackUserFeedbackClosed,
  trackUserFeedbackShown,
  trackUserFeedbackStarClick,
  trackUserFeedbackSubmit,
  trackQuizAnswer,
  trackApplyPowerup,
  trackChangePowerup,
  trackEndOfRoundModalOpen,
  trackQuizWidgetClick,
  trackQuizWidgetAnswerOnetClick,
} from 'src/tracking/gtm/actions'
import { AddPowerupsStatus } from 'src/enums/powerups'
import { getShouldShowFeedback } from 'src/utils/feedback'
import { addBreadcrumb } from '@sentry/browser'
import { WIDGET_PATHS } from 'src/router/constants'
import { isFromPaths } from 'src/utils/router/isFromPaths'
import { TLocationChangeAction } from 'src/state/router/types'
import { Tenants } from 'src/enums/tenants'

export const trackingMiddleware: Middleware = (store) => (next) => (action) => {
  if (action) {
    {
      // add sentry breadCrumb
      const error = action.payload?.error
      addBreadcrumb({ category: 'react.redux', level: 'debug', message: action.type, data: error && { error } })
    }

    switch (action.type) {
      case RouterActionsTypes.LOCATION_CHANGE: {
        const routerAction = action as TLocationChangeAction
        const isWidgetPath = isFromPaths(WIDGET_PATHS, routerAction.payload.currentLocation.pathname)
        if (!isWidgetPath && !routerAction.payload.currentLocation.hashChange) {
          trackPageView()
        }

        break
      }
      case FeedbackActionsTypes.FETCH_FEEDBACK_SUCCESS: {
        const shouldShowFeedback = getShouldShowFeedback(
          action.payload.shouldShow,
          action.isSeasonEnded,
          action.isBettingFeedback,
          action.isUserInAGroup,
        )
        if (shouldShowFeedback) {
          trackUserFeedbackShown()
        }

        break
      }

      case FeedbackActionsTypes.POST_FEEDBACK_SUCCESS:
        trackUserFeedbackSubmit()

        break
      case FeedbackActionsTypes.CLOSE_FEEDBACK:
        trackUserFeedbackClosed()

        break
      case FeedbackActionsTypes.FEEDBACK_STAR_CLICK:
        trackUserFeedbackStarClick()

        break

      case QuizActionsTypes.ANSWER_QUESTION:
      case QuizBettingActionTypes.ANSWER_BETTING_QUESTION: {
        trackQuizAnswer(action?.payload?.questionNumber)

        break
      }

      case QuizWidgetActionsTypes.ANSWER_WIDGET_QUESTION: {
        switch (action?.payload?.tenant) {
          case Tenants.ONET: {
            trackQuizWidgetAnswerOnetClick(action?.payload?.questionNumber)
            break
          }
          default: {
            trackQuizWidgetClick(action?.payload?.questionNumber, undefined, undefined)
          }
        }
        break
      }

      case PowerupsActionTypes.APPLY_POWERUPS: {
        const { addPowerupsStatus, powerupName } = action.payload
        const isEnableAction = addPowerupsStatus !== AddPowerupsStatus.Disable

        if (addPowerupsStatus === AddPowerupsStatus.Change) {
          trackChangePowerup(powerupName, isEnableAction)
        } else {
          trackApplyPowerup(powerupName, isEnableAction)
        }

        break
      }

      case SummaryActionTypes.OPEN_END_OF_ROUND_MODAL: {
        trackEndOfRoundModalOpen()
        break
      }
    }

    return next(action)
  }
}
