import { TModalStyle } from 'src/theme/themes/default/components/modal'
import { theme as defaultTheme } from 'src/theme/themes/default'

import { defaultsDeep } from 'lodash-es'

const modal: TModalStyle = defaultsDeep({}, defaultTheme.components.Modal)

modal.borderRadius = '0'
modal.baseFontWeight = 300

export const libertateaModal = modal
