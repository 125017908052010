import { createContext, useState, useCallback } from 'react'
import { TransitionGroup } from 'react-transition-group'

import { TDrawerContext, TDrawerProvider, TDrawer } from './types'
import { Drawer } from './Drawer'

export const DrawerContext = createContext<TDrawerContext>({
  openDrawer: (drawer) => {
    return drawer
  },
  closeDrawer: (id) => {
    return id
  },
  closeDrawers: (ids) => {
    return ids
  },
  closeAllDrawers: () => {
    return
  },
})

export const DrawerProvider = ({ children }: TDrawerProvider) => {
  const [drawers, setDrawers] = useState<TDrawer[]>([])

  const openDrawer = useCallback(
    (drawer: TDrawer) => {
      const isDrawerExists = !!drawers.find((stateDrawers) => stateDrawers.id === drawer.id)
      if (!isDrawerExists) {
        setDrawers((drawers) => {
          return [...drawers, drawer]
        })
      }

      //Turn off scrolling while drawer is open
      if (!drawer.settings?.dontDisableScroll) {
        document.body.style.overflow = 'hidden'
      }
    },
    [drawers],
  )

  const closeDrawer = useCallback(
    (id: string) => {
      const isDrawerExist = drawers.find((stateDrawer) => stateDrawer.id === id)
      if (!isDrawerExist) {
        return
      }

      const newDrawers = drawers.filter((stateDrawer) => stateDrawer.id !== id)
      if (drawers.length !== 0) {
        setDrawers(newDrawers)
      }

      //Turn on scrolling when all the drawers is closed
      if (newDrawers.length === 0) {
        document.body.style.overflow = 'initial'
      }
    },
    [drawers],
  )

  const closeDrawers = useCallback(
    (ids: string[]) => {
      const isAnyDrawerExist = drawers.some((stateDrawer) => !!ids.find((id) => stateDrawer.id === id))
      if (!isAnyDrawerExist) {
        return
      }

      const newDrawers = drawers.filter((stateDrawer) => !ids.find((id) => stateDrawer.id === id))
      setDrawers(newDrawers)

      //Turn on scrolling when all the drawers is closed
      if (newDrawers.length === 0) {
        document.body.style.overflow = 'initial'
      }
    },
    [drawers],
  )

  const closeAllDrawers = useCallback(() => {
    setDrawers([])
    //Turn on scrolling
    document.body.style.overflow = 'initial'
  }, [])

  return (
    <DrawerContext.Provider value={{ openDrawer, closeDrawer, closeDrawers, closeAllDrawers }}>
      <TransitionGroup>
        {drawers.map((drawer) => {
          return (
            <Drawer key={drawer.id} {...drawer}>
              {drawer.content}
            </Drawer>
          )
        })}
      </TransitionGroup>
      {children}
    </DrawerContext.Provider>
  )
}
