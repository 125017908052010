import { Reducer } from 'redux'

import { TActions, TChampionsState } from './types'
import { ActionTypes } from './enums'

import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'

export const initialState: TChampionsState = {
  data: {
    eventId: 0,
    betEnd: null,
    bet: null,
    config: {
      championsCorrectReward: 0,
      badBoyCorrectReward: 0,
      consolationReward: 0,
      goalieCorrectReward: 0,
      topScorerCorrectReward: 0,
    },
    outcome: null,
    reward: null,
    teams: [],
    players: [],
  },
  loading: {
    fetchChampions: false,
    placeChampionsBet: [],
  },
  error: {
    fetchChampions: null,
    placeChampionsBet: null,
  },
  resolved: {
    fetchChampions: false,
    placeChampionsBet: [],
  },
}

export const reducer: Reducer<TChampionsState, TActions> = (state = initialState, action): TChampionsState => {
  switch (action.type) {
    case ActionTypes.FETCH_CHAMPIONS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchChampions'),
      }
    }
    case ActionTypes.FETCH_CHAMPIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ...getReduxSuccessStates(state, 'fetchChampions'),
      }
    }
    case ActionTypes.FETCH_CHAMPIONS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchChampions', action.payload),
      }
    }

    case ActionTypes.PLACE_CHAMPIONS_BET: {
      const newPlace1ChampionsBetResolved = state.resolved.placeChampionsBet.filter(
        (resolvedAnswer) => !Object.keys(action.payload).find((requestAnswer) => requestAnswer === resolvedAnswer),
      )
      return {
        ...state,
        loading: {
          ...state.loading,
          placeChampionsBet: [...state.loading.placeChampionsBet, ...Object.keys(action.payload)],
        },
        error: {
          ...state.error,
          placeChampionsBet: null,
        },
        resolved: {
          ...state.resolved,
          placeChampionsBet: newPlace1ChampionsBetResolved,
        },
      }
    }
    case ActionTypes.PLACE_CHAMPIONS_BET_SUCCESS: {
      const newPlaceChampionsBetLoading = state.loading.placeChampionsBet.filter(
        (loadingAnswer) => !Object.keys(action.payload).find((requestAnswer) => requestAnswer === loadingAnswer),
      )
      return {
        ...state,
        data: {
          ...state.data,
          bet: {
            ...state.data?.bet,
            ...action.payload,
          },
        },
        loading: {
          ...state.loading,
          placeChampionsBet: newPlaceChampionsBetLoading,
        },
        resolved: {
          ...state.resolved,
          placeChampionsBet: [...state.resolved.placeChampionsBet, ...Object.keys(action.payload)],
        },
      }
    }
    case ActionTypes.PLACE_CHAMPIONS_BET_FAILURE: {
      const newPlaceChampionsBetLoading = state.loading.placeChampionsBet.filter(
        (loadingAnswer) => !Object.keys(action.payload).find((requestAnswer) => requestAnswer === loadingAnswer),
      )
      return {
        ...state,
        loading: {
          ...state.loading,
          placeChampionsBet: newPlaceChampionsBetLoading,
        },
        error: {
          ...state.error,
          placeChampionsBet: action.errorPayload,
        },
      }
    }
    default: {
      return state
    }
  }
}
