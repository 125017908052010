import { THeroStyle } from 'src/theme/themes/default/components/hero'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'

const hero: THeroStyle = defaultsDeep({}, defaultTheme.components.Hero)

hero.slideDesktopHeight = '300px'
hero.slideMobileHeight = '300px'
hero.slideText.color = defaultTheme.colors.shades.white
hero.slideText.fontFamily = ''
hero.slideText.textShadow = ''
hero.slideText.fontSize = '30px'
hero.slideText.fontWeight = '700'
hero.slideText.textAlign = 'center'
hero.slideText.lineHeight = '120%'
hero.slideText.width = '323px'
hero.slideText.margin = 'auto'
hero.slideText.top = '34px'
hero.slideText.bottom = '0'
hero.slideText.left = '0'
hero.slideText.right = '0'

hero.slideText.desktop.fontSize = '40px'
hero.slideText.desktop.lineHeight = '120%'
hero.slideText.desktop.width = '548px'
hero.slideText.desktop.top = '40px'
hero.slideText.desktop.bottom = '0'
hero.slideText.desktop.left = '0'
hero.slideText.desktop.right = '0'

export const onetHero = hero
