import { colors } from 'src/theme/colors'

export type TUserDashBoardStyle = typeof defaultUserDashBoard

export const defaultUserDashBoard = {
  sectionPadding: '32px 16px',
  sectionWidePadding: '24px 16px',
  sectionPaddingMobile: '24px 16px',
  sectionBorderColor: colors.blick.grey20,
  sectionTitleText: `
    font-size: 22px;
    line-height: 25px;
    font-weight: 600;
  `,
  sectionRowIndent: '10px',
  sectionValueText: `
    font-weight: 400;
  `,
  headerPadding: '32px 32px 32px',
  headerPaddingMobile: '16px 16px 8px',
  actionButtonTextStyle: `
    font-size: 18px;
    line-height: 23px;
  `,
}
