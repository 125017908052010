import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TInfoSectionStyle } from 'src/theme/themes/default/components/infoSection'

const infoSection: TInfoSectionStyle = defaultsDeep({}, defaultTheme.components.InfoSection)

infoSection.padding = '40px 25px 40px 25px'
infoSection.paddingMobile = '32px 16px 32px 16px'
infoSection.titleTextStyle = `
    font-size: 42px;
    line-height: 50px;
`
infoSection.titleTextStyleMobile = `
    font-family: ${defaultTheme.fonts.goodOffcPro};
    font-style: normal;
    font-weight: 950;
    font-size: 28px;
    line-height: 34px;
    color: ${defaultTheme.colors.blick.grey100};
`
infoSection.contentTextStyleMobile = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-weight: 400;
    letter-spacing: 0.01em;
    font-size: 16px;
    line-height: 20px;
`

export const greenfieldInfoSection = infoSection
