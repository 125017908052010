export enum ActionTypes {
  FETCH_SEASON_SUMMARY = '@SUMMARY/FETCH_SEASON_SUMMARY',
  FETCH_SEASON_SUMMARY_SUCCESS = '@SUMMARY/FETCH_SEASON_SUMMARY_SUCCESS',
  FETCH_SEASON_SUMMARY_FAILURE = '@SUMMARY/FETCH_SEASON_SUMMARY_FAILURE',

  FETCH_ROUND_SUMMARY = '@SUMMARY/FETCH_ROUND_SUMMARY',
  FETCH_ROUND_SUMMARY_SUCCESS = '@SUMMARY/FETCH_ROUND_SUMMARY_SUCCESS',
  FETCH_ROUND_SUMMARY_FAILURE = '@SUMMARY/FETCH_ROUND_SUMMARY_FAILURE',

  FETCH_ROUND_GROUP_SUMMARY = '@SUMMARY/FETCH_ROUND_GROUP_SUMMARY',
  FETCH_ROUND_GROUP_SUMMARY_SUCCESS = '@SUMMARY/FETCH_ROUND_GROUP_SUMMARY_SUCCESS',
  FETCH_ROUND_GROUP_SUMMARY_FAILURE = '@SUMMARY/FETCH_ROUND_GROUP_SUMMARY_FAILURE',

  FETCH_SEASON_GROUP_SUMMARY = '@SUMMARY/FETCH_SEASON_GROUP_SUMMARY',
  FETCH_SEASON_GROUP_SUMMARY_SUCCESS = '@SUMMARY/FETCH_SEASON_GROUP_SUMMARY_SUCCESS',
  FETCH_SEASON_GROUP_SUMMARY_FAILURE = '@SUMMARY/FETCH_SEASON_GROUP_SUMMARY_FAILURE',

  FETCH_GROUP_SUMMARY = '@SUMMARY/FETCH_GROUP_SUMMARY',
  FETCH_GROUP_SUMMARY_SUCCESS = '@SUMMARY/FETCH_GROUP_SUMMARY_SUCCESS',
  FETCH_GROUP_SUMMARY_FAILURE = '@SUMMARY/FETCH_GROUP_SUMMARY_FAILURE',

  CLEAR_SEASON_SUMMARY = '@SUMMARY/CLEAR_SEASON_SUMMARY',
  CLEAR_ROUND_SUMMARY = '@SUMMARY/CLEAR_ROUND_SUMMARY',
  CLEAR_ROUND_GROUP_SUMMARY = '@SUMMARY/CLEAR_ROUND_GROUP_SUMMARY',
  CLEAR_SEASON_GROUP_SUMMARY = '@SUMMARY/CLEAR_SEASON_GROUP_SUMMARY',

  OPEN_END_OF_ROUND_MODAL = '@SUMMARY/OPEN_END_OF_ROUND_MODAL',
  CLOSE_END_OF_ROUND_MODAL = '@SUMMARY/CLOSE_END_OF_ROUND_MODAL',

  OPEN_USER_STATS_MODAL = '@SUMMARY/OPEN_USER_STATS_MODAL',
  CLOSE_USER_STATS_MODAL = '@SUMMARY/CLOSE_USER_STATS_MODAL',

  OPEN_GROUP_STATS_MODAL = '@SUMMARY/OPEN_GROUP_STATS_MODAL',
  CLOSE_GROUP_STATS_MODAL = '@SUMMARY/CLOSE_GROUP_STATS_MODAL',
}
