export enum ActionTypes {
  ANSWER_BETTING_QUESTION = '@QUIZ/ANSWER_BETTING_QUESTION',
  ANSWER_BETTING_QUESTION_SUCCESS = '@QUIZ/ANSWER_BETTING_QUESTION_SUCCESS',
  ANSWER_BETTING_QUESTION_FAILURE = '@QUIZ/ANSWER_BETTING_QUESTION_FAILURE',

  GET_NEXT_BETTING_QUESTION = '@QUIZ/GET_NEXT_BETTING_QUESTION',
  GET_NEXT_BETTING_QUESTION_SUCCESS = '@QUIZ/GET_NEXT_BETTING_QUESTION_SUCCESS',
  GET_NEXT_BETTING_QUESTION_FAILURE = '@QUIZ/GET_NEXT_BETTING_QUESTION_FAILURE',

  GET_BETTING_STATUS = '@QUIZ/GET_BETTING_STATUS',
  GET_BETTING_STATUS_SUCCESS = '@QUIZ/GET_BETTING_STATUS_SUCCESS',
  GET_BETTING_STATUS_FAILURE = '@QUIZ/GET_BETTING_STATUS_FAILURE',

  RESET_BETTING_QUIZ = '@QUIZ/RESET_BETTING_QUIZ',
  STORE_BETTING_STEP = '@QUIZ/STORE_BETTING_STEP',
  RESTORE_BETTING_STEP = '@QUIZ/RESTORE_BETTING_STEP',
  SET_SELECTED_BETTING_ANSWER = '@QUIZ/SET_SELECTED_BETTING_ANSWER',

  SET_BETTING_ALREADY_ANSWERED_NOTIFICATION = '@QUIZ/SET_BETTING_ALREADY_ANSWERED_NOTIFICATION',
  SET_BETTING_ALL_ANSWERED_NOTIFICATION = '@QUIZ/SET_BETTING_ALL_ANSWERED_NOTIFICATION',
  SET_BETTING_NO_MORE_QUESTIONS_NOTIFICATION = '@QUIZ/SET_BETTING_NO_MORE_QUESTIONS_NOTIFICATION',
  SET_BETTING_ERROR_NOTIFICATION = '@QUIZ/SET_BETTING_ERROR_NOTIFICATION',
}

export enum Errors {
  NO_MORE_QUESTIONS = 'no_more_questions',
}
