import { InternalLink } from 'src/components/links/InternalLink'
import { ExternalLink } from 'src/components/links/ExternalLink'

export type TMedalCountTranslations = typeof medalCountTranslations

export const medalCountTranslations = {
  title: 'WM-Special: Schweizer Medaillen tippen',
  description: (
    <>
      <b key="bold-1">Vom 6. bis zum 19. Februar</b> findet in Frankreich die 47. Alpine Ski-WM statt.{' '}
      <b key="bold-2">Tippe vor dem ersten Rennen, wieviele Medaillen die Schweiz insgesamt gewinnt</b> und hole dir
      wertvolle <b key="bold-3">Zusatzpunkte</b>.{' '}
      <InternalLink to="#spielregeln" key="link-1">
        Mehr Infos
      </InternalLink>
    </>
  ),
  medalCountTitle: 'Wieviele Medaillen gewinnt die Schweiz während der Ski-WM?',
  medalCountSubtitle: (
    <>
      Es finden <b key="bold-1">voraussichtlich 13 Rennen</b> statt. Weitere Infos im{' '}
      <ExternalLink href="https://www.blick.ch/sport/kalender/?path=/ski/" target="_blank" key="link-1">
        Ski-Kalender
      </ExternalLink>
      .
    </>
  ),
}
