import { Bold } from 'src/components/Bold'
import { ExternalLink } from 'src/components/links/ExternalLink'
import { InternalLink } from 'src/components/links/InternalLink'

import { TQuizTranslations } from 'src/translations/de/default/quiz'

export const quizTranslations: TQuizTranslations = {
  title: 'Întrebarea Zilei',
  description: <>Răspunde la Întrebarea Zilei și ai șansa de a câștiga unul dintre premiile puse în joc. Mult noroc!</>,
  seasonEndCardTitle: 'Mulțumim pentru că ai jucat Întrebarea Zilei!',
  seasonEndCardText: (
    <>
      Concursul s-a încheiat. Găsești câștigătorii în <InternalLink to={'#rangliste'}>secțiunea</InternalLink> de mai
      jos.
    </>
  ),
  seasonEndCardButtonText: null, // Not used
  indicatiorText: (maxQuestionCount?: number) => `Fragen beantwortet ${maxQuestionCount}/${maxQuestionCount}`, // Not used
  resultTopText: 'Ergebnis des TagesQuiz', // Not used
  resultTitle: {
    all: 'Felicitări',
    some: 'Felicitări',
    none: 'Oh no!',
  },
  resultSummaryText: (
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number,
    gainedPoints: number,
  ) => (
    <>
      {correctAnswerCount ? (
        <>
          Cu cât joci mai mult, cu atât mai mare este șansa ta de a câștiga! <br /> <br /> Revino mâine pentru a
          răspunde la o altă întrebare.
        </>
      ) : (
        <>
          Revino mâine și încearcă din nou!
          <br />
          Link-ul din hint te poate ajuta să afli răspunsul corect.
          <br />
          <br />
          Cu cât joci mai mult, cu atât mai mare este șansa ta de a câștiga!
        </>
      )}
    </>
  ), // Not used
  resultSummaryTextGroup: (
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number,
    gainedPoints: number,
  ) => {
    const gainedPointsRatio =
      gainedPoints > 0 && maxQuestionCount && pointsPerCorrectQuestion
        ? `${gainedPoints}/${maxQuestionCount * pointsPerCorrectQuestion}`
        : 0

    return (
      <>
        Du hast{' '}
        <Bold>
          {correctAnswerCount}/{maxQuestionCount} Quizfragen
        </Bold>{' '}
        richtig beantwortet und <Bold>{gainedPointsRatio} Punkte für dich sowie für dein Team</Bold> gesammelt.
      </>
    )
  }, // Not used
  resultSummaryEndText: 'Morgen kannst du dein Wissen aufs Neue testen!',
  resultSummaryEndTextGroup: (_: number, __: number) => quizTranslations.resultSummaryEndText,
  resultTable: {
    rank: 'Rang',
    nickname: 'Poreclă',
    group: 'Team',
    total: 'Total',
    gained: 'Heute',
  }, // Not used
  leaderboardButtonText: 'Zur Rangliste', // Not used
  blickButtonText: 'Blick Ski Nachrichten', // Not used
  cardErrorTitle: 'Ooops...',
  noMoreQuestionMessage: (
    <>
      Întrebarea nu a putut fi încărcată. Te rugăm să revii mai târziu. Dacă această eroare se repetă, te rugăm să
      contactezi <ExternalLink href="mailto:intrebareazilei@ringier.ro">intrebareazilei@ringier.ro</ExternalLink>.
    </>
  ),
  unknownErrorMessage: (
    <>
      Există o eroare necunoscută. Te rugăm să reîncarci pagina. Dacă această eroare se repetă, te rugăm să contactezi{' '}
      <ExternalLink href="mailto:intrebareazilei@ringier.ro">intrebareazilei@ringier.ro</ExternalLink>.
    </>
  ),
  collectedPoints: (collectedPoints: number) => (
    <>
      {collectedPoints === 0 ? '' : '+'} {collectedPoints} Punkte
    </>
  ), // Not used
  questionName: 'Întrebarea Zilei',
  nextResultsHint: 'Heutige Resultate folgen', // Not used
  nextQuestionHint: 'Nächste Frage folgt', // Not used
  continue: 'Continuă',
  nextQuestion: 'Continuă',
  teaser: <>Hint: iată articolul unde poți regăsi răspunsul:</>,
  simpleTeaser: (url: string, onClick?: () => void) => (
    <>
      Hint: iată articolul{' '}
      <ExternalLink onClick={onClick} target="_blank" href={url}>
        unde poți regăsi răspunsul
      </ExternalLink>
      .
    </>
  ),
  answersName: 'Răspunsuri',
  allAnsweredCard: {
    title: 'Ai răspuns deja la Întrebarea Zilei',
    description:
      'Mulțumim pentru autentificare! Ai răspuns deja la această întrebare. Apasă click pe butonul de mai jos pentru a vedea cum ai jucat.',
    button: 'Vezi rezultatul concursului',
  },
  alreadyAnsweredCard: {
    title: 'Ai răspuns deja la Întrebarea Zilei',
    description:
      'Mulțumim pentru autentificare! Ai răspuns deja la această întrebare. Apasă click pe butonul de mai jos pentru a vedea cum ai jucat.',
    button: 'Vezi rezultatul concursului',
  },

  singleQuestionSuccessTitle: 'Felicitări',
  singleQuestionSuccessDescription: (
    <>
      Cu cât joci mai mult, cu atât mai mare este șansa ta de a câștiga! <br /> <br /> Revino mâine pentru a răspunde la
      o altă întrebare.
    </>
  ),
  singleQuestionFailureTitle: 'Oh no!',
  singleQuestionFailureDescription: (
    <>
      Revino mâine și încearcă din nou!
      <br />
      Link-ul din hint te poate ajuta să afli răspunsul corect.
      <br />
      <br />
      Cu cât joci mai mult, cu atât mai mare este șansa ta de a câștiga!
    </>
  ),
  singleQuestionResultButton: 'Fii la curent cu concursurile Libertatea',
}
