import { TNavigationLinksTranslations } from 'src/translations/de/default/navigationLinks'

export const navigationLinksTranslations: TNavigationLinksTranslations = {
  hero: 'Start',
  infoBlock: 'Start',
  rules: 'Rules',
  prizes: 'Prizes',
  leaderboard: 'Ranking',
  leaderboardrounded: 'Ranking',
  leaderboardgroup: 'Team-Ranking',
  champions: 'Champions',
  medalcount: 'Medalcount',
  top3participants: 'Tippspiel',
  matchbet: 'Bet',
  quiz: 'Quiz',
  quizbetting: 'Quiz',
  scorebet: 'Tippspiel',
  backButton: 'Back',
}
