import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TUserDashBoardStyle } from 'src/theme/themes/default/components/userDashBoard'
import { onetColors } from 'src/theme/themes/onet/colors'

export const onetUserDashBoard: TUserDashBoardStyle = defaultsDeep({}, defaultTheme.components.UserDashBoard)

onetUserDashBoard.sectionPadding = '32px 32px'
onetUserDashBoard.sectionWidePadding = '32px 32px'
onetUserDashBoard.sectionPaddingMobile = '32px 16px'
onetUserDashBoard.sectionBorderColor = onetColors.grey
onetUserDashBoard.sectionTitleText = `
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
`
onetUserDashBoard.sectionRowIndent = '16px'
onetUserDashBoard.sectionValueText = `
  font-weight:400;
`
onetUserDashBoard.headerPadding = '24px 32px 32px'
onetUserDashBoard.headerPaddingMobile = '24px 16px 32px'
onetUserDashBoard.actionButtonTextStyle = `
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  text-decoration-line: underline;
`
