import { defaultsDeep } from 'lodash-es'

import { prizesTranslations, TPrizesTranslations } from 'src/translations/de/default/prizes'

export const skitrophyPrizesTranslations: TPrizesTranslations = defaultsDeep({}, prizesTranslations)

skitrophyPrizesTranslations.title = 'Jeden Monat coole Preise gewinnen'
skitrophyPrizesTranslations.description = () => (
  <>
    Jeden Monat gibt es einen <b>Preis für den besten Spieler</b> und zwei <b>Tombolapreise</b>, die unter allen
    Spielern verlost werden.
  </>
)
