export const pushSATIEvents = async (isLoggedIn: boolean) => {
  try {
    // @ts-ignore
    // eslint-disable-next-line
    const cX = window.cX || { options: { consent: true } }
    cX.callQueue = cX.callQueue || []
    cX.callQueue.push(['requireConsent'])
    cX.callQueue.push([
      'invoke',
      function () {
        // @ts-ignore
        setConsentforSATI()
      },
    ])
    cX.callQueue.push(['setSiteId', '1136227972865927410'])
    cX.callQueue.push(['setCustomParameters', { category: 'concurs' }])
    if (isLoggedIn) {
      cX.callQueue.push(['setCustomParameters', { autentificat: 'da' }])
    }
    cX.callQueue.push(['sync', 'adform'])
    cX.callQueue.push(['sync', 'ddp'])
    cX.callQueue.push(['sendPageViewEvent'])
    // @ts-ignore
  } catch {
    // do nothing
  }
}
