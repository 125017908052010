import { TCommonStyle } from 'src/theme/themes/default/components/common'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { bluefrostColors } from 'src/theme/themes/bluefrost/colors'

const common: TCommonStyle = defaultsDeep({}, defaultTheme.components.common)

common.background = bluefrostColors.blueLight1
common.borderRadius = '8px'
common.boxShadow = 'none'

export const bluefrostCommon = common
