export enum ActionTypes {
  DETAIL_SELECT_PARTICIPANT = '@BETTING/DETAIL_SELECT_PARTICIPANT',
  DETAIL_DESELECT_PARTICIPANT = '@BETTING/DETAIL_DESELECT_PARTICIPANT',
  DETAIL_RESET_SELECTION = '@BETTING/DETAIL_RESET_SELECTION',

  // Place a bet
  PLACE_BET = '@BETTING/PLACE_BET',
  PLACE_BET_SUCCESS = '@BETTING/PLACE_BET_SUCCESS',
  PLACE_BET_FAILURE = '@BETTING/PLACE_BET_FAILURE',

  // Fetch
  FETCH_RACES = '@BETTING/FETCH_RACES',
  FETCH_RACES_SUCCESS = '@BETTING/FETCH_RACES_SUCCESS',
  FETCH_RACES_FAILURE = '@BETTING/FETCH_RACES_FAILURE',

  FETCH_RACE = '@BETTING/FETCH_RACE',
  FETCH_RACE_SUCCESS = '@BETTING/FETCH_RACE_SUCCESS',
  FETCH_RACE_FAILURE = '@BETTING/FETCH_RACE_FAILURE',

  // Reset
  RESET_BETS_FETCH_STATES = '@BETTING/RESET_BETS_FETCH_STATES',
}
