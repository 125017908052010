import { defaultsDeep } from 'lodash-es'

import { TTranslations } from 'src/translations/types'
import { translations as defaultTranslations } from 'src/translations/de/default'
import { frauenwmQuizTranslations } from './quiz'
import { frauenwmEndOfRoundModalTranslations } from './endofroundmodal'
import { frauenwmSummaryTranslations } from './summary'
import { frauenwmLeaderboardTranslations } from './leaderboard'
import { frauenwmPowerupsTranslations } from './powerups'
import { frauenwmScoreBetTranslations } from './scorebet'
import { frauenwmQuizWidgetTranslations } from './quizwidget'

export const translations: TTranslations = defaultsDeep({}, defaultTranslations)

translations.quiz = frauenwmQuizTranslations
translations.quizwidget = frauenwmQuizWidgetTranslations
translations.endofroundmodal = frauenwmEndOfRoundModalTranslations
translations.summary = frauenwmSummaryTranslations
translations.leaderboard = frauenwmLeaderboardTranslations
translations.powerups = frauenwmPowerupsTranslations
translations.scorebet = frauenwmScoreBetTranslations
