import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { greenfieldColors } from 'src/theme/themes/greenfield/colors'
import { TQuizStyle } from 'src/theme/themes/default/components/quiz'

const quiz: TQuizStyle = defaultsDeep({}, defaultTheme.components.Quiz)

quiz.background = greenfieldColors.green5
quiz.padding = '32px 0px 0px 0px'
quiz.wrapperMargin = '0'
quiz.innerWrapperPadding = '12px 16px 16px 16px'
quiz.innerWrapperPaddingMobile = '8px'
quiz.indicatorPadding = '0'
quiz.indicatorPaddingMobile = '0'
quiz.indicatorTextStyle = `
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
`
quiz.indicatorTextStyleMobile = quiz.indicatorTextStyle
quiz.titleTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    font-family: ${defaultTheme.fonts.sourceSansPro};
`
quiz.titleTextStyleMobile = quiz.titleTextStyle
quiz.titleTextAlign = 'left'
quiz.titleMargin = '16px 0 24px 0'
quiz.titleMarginMobile = '16px 0 24px 0'
quiz.answersWrapperPadding = '0'
quiz.questionsWrapperPadding = '0 8px 16px 8px'
quiz.questionsWrapperPaddingMobile = '8px'
quiz.footerMargin = '16px 0px 0px 0px'
quiz.answerMargin = '0 0 16px 0'
quiz.answerDefaultBorder = '0'
quiz.answersWrapperPaddingMobile = quiz.answersWrapperPadding
quiz.answerJustifyContent = 'flex-start'
quiz.answerBoxShadow = '0px 1px 4px 1px rgba(0, 0, 0, 0.25)'
quiz.answerMinHeight = '52px'
quiz.answerMinHeightMobile = '52px'
quiz.answerPadding = '0 50px 0 24px'
quiz.answerPaddingMobile = '0 50px 0 16px'
quiz.answerTextStyle = `
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`
quiz.subTitleTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
`
quiz.resultTableBackground = defaultTheme.colors.shades.white
quiz.resultWrapperPadding = '8px'
quiz.resultWrapperPaddingMobile = '8px'
quiz.resultTableMargin = '24px 0'
quiz.resultTableMarginMobile = '24px 0 16px 0'
quiz.resultTableHeadRowHeight = '34px'
quiz.resultTableHeadCellTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-size: 14px;
    line-height: 32px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.resultTableRowHeight = '39px'
quiz.resultWrapperAlign = 'left'
quiz.resultsTitleMargin = quiz.resultsTitleMarginMobile
quiz.resultTableCellTextStyle = `
    font-family: 'Source Sans Pro';
    font-size: 18px;
    line-height: 23px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.resultTableCellPadding = '0 8px'
quiz.resultTableCellPaddingMobile = '0 8px'
quiz.resultTableCellFirstChildPadding = '0 0 0 16px'
quiz.resultTableCellLastChildPadding = '0 16px 0 0'
quiz.progressBarWidth = '210px'
quiz.progressBarPosition = 'absolute'
quiz.progressBarTextStyle = `
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${greenfieldColors.intenseGreen};
`
quiz.progressBarBackground = defaultTheme.colors.shades.white
quiz.progressBarBorder = `1px solid ${greenfieldColors.intenseGreen}`
quiz.progressBarBorderRadius = '8px'
quiz.progressBarInnerBarBackground = greenfieldColors.green
quiz.collectedPointsBackground = greenfieldColors.intenseGreen
quiz.teaserBoxPaddingLeft = '64px'
quiz.teaserTitleTextStyle = `
    font-size: 18px;
    line-height: 23px;
    color: ${defaultTheme.colors.blick.grey100};
`

export const greenfieldQuiz = quiz
