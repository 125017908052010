import styled from 'styled-components/macro'

type Props = {
  size?: number
  horizontal?: boolean
  vertical?: boolean
  h?: boolean
  v?: boolean
}

export const Spacer = styled.div<Props>`
  ${({ vertical, v, size }) => (vertical || v ? `height: ${size}px;` : null)}
  ${({ horizontal, h, size }) => (horizontal || h ? `width: ${size}px;` : null)}
`
