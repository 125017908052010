import * as Sentry from '@sentry/browser'
import { AxiosError } from 'axios'

import { config } from 'src/config'
import { isBackendError } from 'src/utils/errors'
import { SentryInfo, TSentryErrorData } from './types'

export const trackError = (err: string | Error | AxiosError | unknown, info?: SentryInfo) => {
  if (isBackendError(err) && err.response?.status === 401) {
    return
  }

  if (config.tracking.isEnabled) {
    const fingerprint = info && info.fingerprint ? info.fingerprint : ['{{ default }}']
    const extraData: TSentryErrorData = {
      env: process.env.REACT_APP_ENV,
      ...(isBackendError(err) &&
        err?.response?.headers['x-trace-id'] && { traceId: err.response.headers['x-trace-id'] }),
    }

    Sentry.withScope((scope) => {
      scope.setFingerprint(fingerprint)

      if (typeof err === 'string') {
        Sentry.captureMessage(err, {
          extra: extraData,
        })
      } else {
        Sentry.captureException(err, { extra: extraData })
      }
    })
  }
}
