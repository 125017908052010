import { Bold } from 'src/components/Bold'
import { InternalLink } from 'src/components/links/InternalLink'
import { TEndOfSeasonModalTranslations } from 'src/translations/de/default/endofseasonmodal'

export const endOfSeasonModalTranslations: TEndOfSeasonModalTranslations = {
  subtitle: '',
  getSummarytitle: (userRank, userName) => <>Thanks, {userName} for playing “question of the day”!</>,
  getGroupStepTitle: (seasonRank: number) => `Dein Team und du seid auf Rang ${seasonRank} gelandet`,
  statisticsSubtitle: '',
  feedbackSubscriptionText: 'Thanks for signing up!',
  feedbackTitle: 'Last question for today! How happy were you with this game?',
  feedbackPlaceholder: (
    <>
      <Bold $fontWeight={500}>Your feedback</Bold> (Optional)
    </>
  ),
  subscriptionText: 'Would you like to get an email if there is a new game coming up?',
  thanksTitle: 'Thanks for playing “Question of the day”!',
  thanksText: (link: string) => (
    <>
      If you have not done it yet, you can still <InternalLink to={link}>subscribe to the Newsletter.</InternalLink>
    </>
  ),
  socialTitle: 'Share your result',
  showLaterButton: 'Show later',
  nextButton: 'Next',
  backButton: 'Back',
  closeButton: 'Close',
  approveButton: 'Yes, please',
  rejectButton: 'No, thanks',
  sharingText: 'Hi! Check out how I have played the Libertatea Quiz Game "Question of the day"!',
  sharingSubject: 'Results of Question of the day',
  copyText: 'Sharing link has been copied',
}
