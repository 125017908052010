import { Bold } from 'src/components/Bold'
import { InternalLink } from 'src/components/links/InternalLink'
import { TEndOfSeasonModalTranslations } from 'src/translations/de/default/endofseasonmodal'

export const endOfSeasonModalTranslations: TEndOfSeasonModalTranslations = {
  subtitle: '',
  getSummarytitle: (userRank, userName) => <>Dzięki, {userName} za grę!</>,
  statisticsSubtitle: '',
  feedbackSubscriptionText: 'Dzięki za zapisanie się!',
  feedbackTitle: 'Ostatnie pytanie na dziś! Jakie są Twoje wrażenia z tej gry?',
  feedbackPlaceholder: (
    <>
      <Bold $fontWeight={500}>Twoja opinia</Bold> (Opcjonalnie)
    </>
  ),
  subscriptionText: 'Czy chcesz otrzymać e-mail, jeśli pojawi się nowa gra?',
  thanksTitle: 'Dzięki za grę!',
  thanksText: (link: string) => (
    <>
      Jeśli jeszcze tego nie zrobiłeś, możesz jeszcze <InternalLink to={link}>zapisać się do Newslettera.</InternalLink>
    </>
  ),
  socialTitle: 'Podziel się swoim wynikiem',
  showLaterButton: 'Pokaż później',
  nextButton: 'Następny',
  backButton: 'Wstecz',
  closeButton: 'Zamknij',
  approveButton: 'Tak, proszę',
  rejectButton: 'Nie, dziękuję',
  sharingText: 'Cześć! Zobacz jak poszło mi w quizie!',
  sharingSubject: 'Mój wynik z codziennego quizu',
  copyText: 'Link do udostępniania został skopiowany',
  // not used
  getGroupStepTitle: () => '',
}
