import { TSummaryTranslations } from 'src/translations/de/default/summary'

export const summaryTranslations: TSummaryTranslations = {
  pageTitle: 'Câștigă cu Întrebarea Zilei',
  subtitle: (username: string | undefined) => <>so hat {username} abgeschnitten</>, // Not used
  title: (userRank, userName) => {
    return <>Iată rezultatele lui {userName} pentru concursul “Întrebarea Zilei”!</>
  },
  team: {
    title: (rank?: number, name?: string) => (
      <>
        Team {name} ist auf Rang {rank} gelandet
      </>
    ),
    subtitle: <>Endergebnis Teams</>,
  }, // Not used
  subscriptionTitle: '', // Not used
  subscriptionText: `Câștigă cu Întrebarea Zilei s-a încheiat. Veți primi un email în momentul în care vom lansa următorul concurs!`,
  subscriptionSuccessfulText: 'Mulțumim! De îndată ce va apărea un joc nou, vei primi un e-mail.',
  invalidEmailError: 'Introdu o adresă de e-mail validă',
  requiredFieldError: 'Te rugăm să completezi acest câmp',
  inputLabel: 'Introdu adresa de e-mail',
  buttonText: 'Înscrie-te pentru notificări',
  round: {
    subtitle: (dinamicText?: string) => <>Ski TROPHY im {dinamicText}</>,
    titleMobile: (rank?: number, name?: string) => (
      <>
        {name} hat sich auf Platz {rank} der Rangliste gekämpft
      </>
    ),
    titleDesktop: (rank?: number, name?: string) => (
      <>
        {name} hat sich auf Platz {rank} der Rangliste gekämpft
      </>
    ),
    bannerTitle: 'Spiel mit bei der Ski Trophy und gewinne jeden Monat coole Preise!',
    team: {
      title: (rank?: number, name?: string) => (
        <>
          Team {name} ist auf Rang {rank} gelandet
        </>
      ),
      subtitle: (dinamicText?: string) => <>{dinamicText} TEAMS</>,
    },
    buttonTextLoggedIn: 'jetzt mitspielen',
    buttonTextLoggedOut: 'anmelden',
  }, // Not used
}
