import { colors } from 'src/theme/colors'

export type TTextInputStyle = typeof defaultTextInput

export const defaultTextInput = {
  labelStyle: `
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 16px;
  `,
  borderRadius: '8px',
  height: '56px',
  backgroundColor: colors.shades.white,
  errorColor: colors.feedback.negative,
  errorLabelColor: colors.feedback.negative,
  defaultColor: colors.blick.brand100,
  borderColor: colors.blick.brand100,
  floatWrapperPadding: '0 16px',
  errorMessageStyle: `
    display: inline-block;
    margin-right: 8px;
    font-size: 12px;
    line-height: 18px;
    color: ${colors.blick.brand70};
  `,
}
