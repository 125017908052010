import { defaultsDeep } from 'lodash-es'
import { TEndOfRoundModalTranslations, endOfRoundModalTranslations } from 'src/translations/de/default/endofroundmodal'

export const frauenwmEndOfRoundModalTranslations: TEndOfRoundModalTranslations = defaultsDeep(
  {},
  endOfRoundModalTranslations,
)

frauenwmEndOfRoundModalTranslations.getSubtitle = (round: string) => `WM TROPHY - ${round}`
frauenwmEndOfRoundModalTranslations.finalStepText = (
  <>Weiter geht’s mit der K.o.-Phase. Viel Spass und Glück beim Tippen und Quizzen!</>
)
