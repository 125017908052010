import { defaultsDeep } from 'lodash-es'

import {
  endOfSeasonModalTranslations,
  TEndOfSeasonModalTranslations,
} from 'src/translations/de/default/endofseasonmodal'

export const uefafrauenEndOfSeasonModalTranslations: TEndOfSeasonModalTranslations = defaultsDeep(
  {},
  endOfSeasonModalTranslations,
)

uefafrauenEndOfSeasonModalTranslations.statisticsSubtitle = 'SO LIEF DEINE EM TROPHY'
uefafrauenEndOfSeasonModalTranslations.feedbackTitle = 'Wie zufrieden warst du mit der Fussball EM Trophy ?'
uefafrauenEndOfSeasonModalTranslations.thanksTitle = 'Danke, dass du bei der EM Trophy mitgemacht hast!'
