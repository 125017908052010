import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { isArray } from 'lodash-es'
import { saveUtmParams } from 'src/utils/auth'
import { eraseCookie, getCookie } from 'src/utils/cookie'
import { trackError } from 'src/tracking/sentry'
import { returnedFromLogin } from 'src/api/auth/actions'
import { useDispatch } from 'react-redux'

export const useUrlParams = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const parsedSearch = queryString.parse(location.search)
  // Remove the state QS parameter
  const qs = queryString.parse(location.search)
  if (qs['state']) {
    delete qs['state']
    const qsStr = queryString.stringify(qs)
    if (qsStr) {
      window.history.replaceState({}, document.title, `${location.pathname}?${qsStr}${location.hash}`)
    } else {
      window.history.replaceState({}, document.title, `${location.pathname}${location.hash}`)
    }
  }

  /**
   * Try to extract the state-cookie. If it is found, that means that the user is coming back from
   * a login flow (for example from OneLog). If that is the case we send this to Redux so GTM/GA4
   * tracking can pick this up. This cookie is set in app/src/api/auth/actions.ts -> login
   */
  useEffect(() => {
    let loginMethod = null
    const state = parsedSearch.state
    if (state && typeof state === 'string') {
      const cookie = getCookie(`loginstate_${state}`)
      eraseCookie(`loginstate_${state}`)
      if (cookie) {
        try {
          const parsed = JSON.parse(cookie)
          loginMethod = parsed.method
        } catch (e) {
          trackError(e)
        }
        dispatch(returnedFromLogin({ didReturnFromLogin: true, loginMethod }))
      }
    }
  }, [dispatch, parsedSearch.state])

  useEffect(() => {
    if (parsedSearch.utm) {
      saveUtmParams(isArray(parsedSearch.utm) ? JSON.stringify(parsedSearch.utm) : parsedSearch.utm)
    }
  }, [parsedSearch])
}
