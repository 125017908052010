import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TTableStyle } from 'src/theme/themes/default/components/table'

const table: TTableStyle = defaultsDeep({}, defaultTheme.components.Table)

table.tableHead.color = defaultTheme.colors.blick.grey90
table.tableCell.fontWeightBold = 600

export const bluefrostTable = table
