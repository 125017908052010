import { Theme } from 'src/theme/styled'
import { defaultsDeep } from 'lodash-es'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { ThemeNames } from 'src/theme/enums'

import { onetQuiz } from './components/quiz'
import { onetQuizWidget } from './components/quizwidget'
import { onetMeasures } from './measures'
import { onetHeader } from './components/header'
import { onetCommon } from './components/common'
import { onetInfoSection } from './components/infoSection'
import { onetButton } from './components/button'
import { onetCollapsible } from './components/collapsible'
import { onetRules } from './components/rules'
import { onetLeaderboard } from './components/leaderboard'
import { onetTable } from './components/table'
import { onetHero } from './components/hero'
import { onetUserDashBoard } from './components/userDashBoard'
import { onetDrawer } from './components/drawer'
import { onetTextInput } from './components/textInput'
import { onetModal } from './components/modal'
import { onetPrizeAlt } from './components/prizeAlt'
import { onetEndOfSeasonModal } from './components/endOfSeasonModal'
import { onetSummary } from './components/summary'

const theme: Theme = defaultsDeep({}, defaultTheme)

theme.name = ThemeNames.ONET
theme.measures = onetMeasures
theme.components.common = onetCommon
theme.components.Header = onetHeader
theme.components.InfoSection = onetInfoSection
theme.components.Hero = onetHero
theme.components.Quiz = onetQuiz
theme.components.QuizWidget = onetQuizWidget
theme.components.Button = onetButton
theme.components.Collapsible = onetCollapsible
theme.components.Rules = onetRules
theme.components.Leaderboard = onetLeaderboard
theme.components.Table = onetTable
theme.components.UserDashBoard = onetUserDashBoard
theme.components.Drawer = onetDrawer
theme.components.TextInput = onetTextInput
theme.components.Modal = onetModal
theme.components.PrizeAlt = onetPrizeAlt
theme.components.EndOfSeasonModal = onetEndOfSeasonModal
theme.components.Summary = onetSummary

export const onet = theme
