import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { Actions, TSummaryState } from './types'

export const initialState: TSummaryState = {
  data: {
    season: null,
    round: {},
    roundGroup: {},
    seasonGroup: {},
    modals: {
      endOfRound: null,
      userStats: null,
      groupStats: null,
      groupStatsGroupSummary: null,
    },
  },
  loading: {
    fetchSeasonSummary: false,
    fetchRoundSummary: [],
    fetchRoundGroupSummary: [],
    fetchSeasonGroupSummary: [],
  },
  error: {
    fetchSeasonSummary: null,
    fetchRoundSummary: null,
    fetchRoundGroupSummary: null,
    fetchSeasonGroupSummary: null,
  },
  resolved: {
    fetchSeasonSummary: false,
    fetchRoundSummary: [],
    fetchRoundGroupSummary: [],
    fetchSeasonGroupSummary: [],
  },
}

export const reducer: Reducer<TSummaryState, Actions> = (state = initialState, action): TSummaryState => {
  switch (action.type) {
    case ActionTypes.FETCH_SEASON_SUMMARY: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchSeasonSummary'),
      }
    }
    case ActionTypes.FETCH_SEASON_SUMMARY_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          season: action.payload,
        },
        ...getReduxSuccessStates(state, 'fetchSeasonSummary'),
      }
    }
    case ActionTypes.FETCH_SEASON_SUMMARY_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchSeasonSummary', action.payload),
      }
    }
    case ActionTypes.CLEAR_SEASON_SUMMARY: {
      return {
        ...state,
        data: {
          ...state.data,
          season: initialState.data.season,
        },
      }
    }

    case ActionTypes.FETCH_ROUND_SUMMARY: {
      const { roundId } = action.payload

      const newFetchRoundSummaryLoading = [...state.loading.fetchRoundSummary, roundId]
      const newFetchRoundSummaryResolved = state.resolved.fetchRoundSummary.filter((item) => item !== roundId)

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchRoundSummary: newFetchRoundSummaryLoading,
        },
        resolved: {
          ...state.resolved,
          fetchRoundSummary: newFetchRoundSummaryResolved,
        },
      }
    }

    case ActionTypes.FETCH_ROUND_SUMMARY_SUCCESS: {
      const {
        requestPayload: { roundId },
        summary,
      } = action.payload

      const newFetchRoundSummaryLoading = state.loading.fetchRoundSummary.filter((item) => item !== roundId)
      const newFetchRoundSummaryResolved = [...state.resolved.fetchRoundSummary, roundId]

      return {
        ...state,
        data: {
          ...state.data,
          round: {
            ...state.data.round,
            [roundId]: summary,
          },
        },
        loading: {
          ...state.loading,
          fetchRoundSummary: newFetchRoundSummaryLoading,
        },
        resolved: {
          ...state.resolved,
          fetchRoundSummary: newFetchRoundSummaryResolved,
        },
      }
    }
    case ActionTypes.FETCH_ROUND_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          fetchRoundSummary: [],
        },
        error: {
          ...state.error,
          fetchRoundSummary: action.payload,
        },
      }
    }
    case ActionTypes.CLEAR_ROUND_SUMMARY: {
      const { roundId } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          round: {
            ...state.data.round,
            ...(roundId && { [roundId]: undefined }),
          },
        },
      }
    }

    case ActionTypes.FETCH_ROUND_GROUP_SUMMARY: {
      const { roundId } = action.payload

      const newFetchRoundGroupSummaryLoading = [...state.loading.fetchRoundGroupSummary, roundId]
      const newFetchRoundSummaryResolved = state.resolved.fetchRoundGroupSummary.filter((item) => item !== roundId)

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchRoundGroupSummary: newFetchRoundGroupSummaryLoading,
        },
        resolved: {
          ...state.resolved,
          fetchRoundGroupSummary: newFetchRoundSummaryResolved,
        },
      }
    }
    case ActionTypes.FETCH_ROUND_GROUP_SUMMARY_SUCCESS: {
      const {
        requestPayload: { roundId },
        summary,
      } = action.payload

      const newFetchRoundGroupSummaryLoading = state.loading.fetchRoundGroupSummary.filter((item) => item !== roundId)
      const newFetchRoundGroupSummaryResolved = [...state.resolved.fetchRoundGroupSummary, roundId]

      return {
        ...state,
        data: {
          ...state.data,
          roundGroup: {
            ...state.data.roundGroup,
            [roundId]: summary,
          },
        },
        loading: {
          ...state.loading,
          fetchRoundGroupSummary: newFetchRoundGroupSummaryLoading,
        },
        resolved: {
          ...state.resolved,
          fetchRoundGroupSummary: newFetchRoundGroupSummaryResolved,
        },
      }
    }
    case ActionTypes.FETCH_ROUND_GROUP_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          fetchRoundGroupSummary: [],
        },
        error: {
          ...state.error,
          fetchRoundGroupSummary: action.payload,
        },
      }
    }
    case ActionTypes.CLEAR_ROUND_GROUP_SUMMARY: {
      const { roundId } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          roundGroup: {
            ...state.data.roundGroup,
            ...(roundId && { [roundId]: undefined }),
          },
        },
      }
    }
    case ActionTypes.FETCH_SEASON_GROUP_SUMMARY: {
      const { seasonId } = action.payload

      const newFetchSeasonGroupSummaryLoading = [...state.loading.fetchSeasonGroupSummary, seasonId]
      const newFetchSeasonSummaryResolved = state.resolved.fetchSeasonGroupSummary.filter((item) => item !== seasonId)

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchSeasonGroupSummary: newFetchSeasonGroupSummaryLoading,
        },
        resolved: {
          ...state.resolved,
          fetchSeasonGroupSummary: newFetchSeasonSummaryResolved,
        },
      }
    }
    case ActionTypes.FETCH_SEASON_GROUP_SUMMARY_SUCCESS: {
      const {
        requestPayload: { seasonId },
        summary,
      } = action.payload

      const newFetchSeasonGroupSummaryLoading = state.loading.fetchSeasonGroupSummary.filter(
        (item) => item !== seasonId,
      )
      const newFetchSeasonGroupSummaryResolved = [...state.resolved.fetchSeasonGroupSummary, seasonId]

      return {
        ...state,
        data: {
          ...state.data,
          seasonGroup: {
            ...state.data.seasonGroup,
            [seasonId]: summary,
          },
        },
        loading: {
          ...state.loading,
          fetchSeasonGroupSummary: newFetchSeasonGroupSummaryLoading,
        },
        resolved: {
          ...state.resolved,
          fetchSeasonGroupSummary: newFetchSeasonGroupSummaryResolved,
        },
      }
    }
    case ActionTypes.FETCH_SEASON_GROUP_SUMMARY_FAILURE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          fetchSeasonGroupSummary: [],
        },
        error: {
          ...state.error,
          fetchSeasonGroupSummary: action.payload,
        },
      }
    }
    case ActionTypes.FETCH_GROUP_SUMMARY: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchGroupSummary'),
      }
    }
    case ActionTypes.FETCH_GROUP_SUMMARY_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          modals: { ...state.data.modals, groupStatsGroupSummary: action.payload },
        },
        ...getReduxSuccessStates(state, 'fetchGroupSummary'),
      }
    }
    case ActionTypes.FETCH_GROUP_SUMMARY_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchGroupSummary', action.payload),
      }
    }
    case ActionTypes.CLEAR_SEASON_GROUP_SUMMARY: {
      const { seasonId } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          seasonGroup: {
            ...state.data.seasonGroup,
            ...(seasonId && { [seasonId]: undefined }),
          },
        },
      }
    }

    case ActionTypes.OPEN_END_OF_ROUND_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          modals: {
            ...state.data.modals,
            endOfRound: action.payload,
          },
        },
      }
    }
    case ActionTypes.CLOSE_END_OF_ROUND_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          modals: {
            ...state.data.modals,
            endOfRound: null,
          },
        },
      }
    }
    case ActionTypes.OPEN_USER_STATS_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          modals: {
            ...state.data.modals,
            userStats: action.payload,
          },
        },
      }
    }
    case ActionTypes.CLOSE_USER_STATS_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          modals: {
            ...state.data.modals,
            userStats: null,
          },
        },
      }
    }
    case ActionTypes.OPEN_GROUP_STATS_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          modals: {
            ...state.data.modals,
            groupStats: action.payload,
          },
        },
      }
    }
    case ActionTypes.CLOSE_GROUP_STATS_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          modals: {
            ...state.data.modals,
            groupStats: null,
          },
        },
      }
    }

    default:
      return state
  }
}
