import { defaultsDeep } from 'lodash-es'

import { quizTranslations, TQuizTranslations } from 'src/translations/de/default/quiz'

export const wmtrophyQuizTranslations: TQuizTranslations = defaultsDeep({}, quizTranslations)

wmtrophyQuizTranslations.seasonEndCardTitle =
  'Die Fussball WM Trophy ist beendet - weiter geht’s mit der Blick Ski Trophy!'
wmtrophyQuizTranslations.seasonEndCardText = 'Mach mit bei der Ski Trophy und gewinne jeden Monat coole Preise!'
wmtrophyQuizTranslations.seasonEndCardButtonText = 'Zur SKI Trophy'
wmtrophyQuizTranslations.blickButtonText = 'Blick-WM-News'
