import { notificationsTranslations } from './notifications'
import { leaderboardTranslations } from './leaderboard'
import { scoreBetTranslations } from './scorebet'
import { quizBettingTranslations } from './quizbetting'
import { endOfSeasonModalTranslations } from './endofseasonmodal'
import { endOfRoundModalTranslations } from './endofroundmodal'
import { summaryTranslations } from './summary'
import { quizTranslations, TQuizTranslations } from './quiz'
import { quizWidgetTranslations, TQuizWidgetTranslations } from './quizwidget'
import { teamTranslations } from './team'
import { powerupsTranslations } from './powerups'
import { messagesTranslations } from './messages'
import { rulesTranslations } from './rules'
import { winnersTranslations } from './winners'
import { prizesTranslations } from './prizes'
import { userDashBoardTranslations } from './userDashBoard'
import { navigationLinksTranslations } from './navigationLinks'
import { medalCountTranslations } from './medalcount'
import { matchBetTranslations } from './matchbet'
import { championsTranslations } from './champions'

export const translations = {
  messages: messagesTranslations,
  notifications: notificationsTranslations,
  leaderboard: leaderboardTranslations,
  scorebet: scoreBetTranslations,
  quiz: quizTranslations as TQuizTranslations,
  quizwidget: quizWidgetTranslations as TQuizWidgetTranslations,
  quizbetting: quizBettingTranslations,
  endofseasonmodal: endOfSeasonModalTranslations,
  endofroundmodal: endOfRoundModalTranslations,
  summary: summaryTranslations,
  team: teamTranslations,
  powerups: powerupsTranslations,
  rules: rulesTranslations,
  winners: winnersTranslations,
  prizes: prizesTranslations,
  userDashBoard: userDashBoardTranslations,
  navigationLinks: navigationLinksTranslations,
  medalCount: medalCountTranslations,
  matchbet: matchBetTranslations,
  champions: championsTranslations,
}
