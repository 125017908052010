export enum ActionTypes {
  FETCH_MATCHES = '@SCORE_BET/FETCH_MATCHES',
  FETCH_MATCHES_SUCCESS = '@SCORE_BET/FETCH_MATCHES_SUCCESS',
  FETCH_MATCHES_FAILURE = '@SCORE_BET/FETCH_MATCHES_FAILURE',

  PLACE_BET = '@SCORE_BET/PLACE_BET',
  PLACE_BET_SUCCESS = '@SCORE_BET/PLACE_BET_SUCCESS',
  PLACE_BET_FAILURE = '@SCORE_BET/PLACE_BET_FAILURE',
  CLEAR_PLACE_BET_ERROR = '@SCORE_BET/CLEAR_PLACE_BET_ERROR',

  FETCH_BETS_WITH_SCORE = '@SCORE_BET/FETCH_BETS_WITH_SCORE',
  FETCH_BETS_WITH_SCORE_SUCCESS = '@SCORE_BET/FETCH_BETS_WITH_SCORE_SUCCESS',
  FETCH_BETS_WITH_SCORE_FAILURE = '@SCORE_BET/FETCH_BETS_WITH_SCORE_FAILURE',
}
