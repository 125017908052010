import { TMatchBetReward } from 'src/api/matchbetting/types'
import { BoldContent } from 'src/components/Bold'

export type TMatchBetTranslations = typeof matchBetTranslations

export const matchBetTranslations = {
  title: 'Wer gewinnt das Spiel?',
  description: (
    <>
      Gib deinen Tipp ab und entscheide, welches Team als Sieger vom Eis geht. Für jeden richtigen Tipp erhältst du{' '}
      <b key="bold-1">5 Punkte.</b> Liegst du daneben, gibts <b key="bold-2">einen Trostpunkt.</b> Um deine Punktzahl
      zusätzlich zu steigern, stehen dir <b key="bold-3">8 Booster</b> zur Verfügung.
    </>
  ),
  countDownText: (time: string) => `Start in ${time} Std`,
  result: {
    pointsLabel: {
      correct: 'Pkt',
      incorrect: (points?: number | null) => (points === 1 ? 'Trostpunkt' : 'Punkte'),
    },
    buttonText: {
      correct: 'Richtig',
      incorrect: 'Falsch',
      winner: 'Sieger',
      selected: 'Dein Tipp',
    },
    popup: {
      title: (correct?: boolean | undefined) => {
        switch (correct) {
          case true:
            return 'Volltreffer!'
          case false:
            return 'Leider falsch getippt...'
          default:
            return 'Kein Tipp abgegeben'
        }
      },
      points: (hasWon: boolean | undefined, reward: TMatchBetReward | null, powerup: string | null) => {
        return (
          <>
            {reward?.total ? (
              <>
                {hasWon ? (
                  <>
                    <BoldContent>+5</BoldContent> Sieger richtig getippt
                    <br />
                  </>
                ) : (
                  <>
                    <BoldContent>+1 </BoldContent> Trostpunkt fürs Mitspielen
                    <br />
                  </>
                )}
                {powerup ? (
                  <>
                    <BoldContent>+{reward.powerupReward}</BoldContent> für Booster: {powerup}
                  </>
                ) : (
                  <>Booster: Nicht aktiviert</>
                )}
              </>
            ) : (
              <>0 Punkte</>
            )}
          </>
        )
      },
    },
  },
}
