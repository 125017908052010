import { defaultsDeep } from 'lodash-es'
import { summaryTranslations, TSummaryTranslations } from 'src/translations/de/default/summary'

export const frauenwmSummaryTranslations: TSummaryTranslations = defaultsDeep({}, summaryTranslations)

frauenwmSummaryTranslations.round.subtitle = (dynamicText) => <>So hat {dynamicText} gespielt</>
frauenwmSummaryTranslations.round.titleMobile = (rank) => <>Du hast dich auf Platz {rank} gekämpft</>
frauenwmSummaryTranslations.round.titleDesktop = (rank) => <>Du hast dich auf Platz {rank} der Rangliste gekämpft</>
frauenwmSummaryTranslations.round.team.title = (rank, name) => (
  <>
    {name} ist auf Rang {rank} gelandet
  </>
)
frauenwmSummaryTranslations.round.bannerTitle = 'Spiel mit bei der WM Trophy und gewinne coole Preise!'
