import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'

export type TCollapsibleStyle = typeof defaultCollapsible

export const defaultCollapsible = {
  background: colors.blick.grey15,
  contentBackground: colors.blick.grey15,
  borderRadius: '8px',
  padding: '16px 24px 16px 16px',
  paddingMobile: '12px 16px',
  headerTextStyle: `
        font-family: ${fonts.sourceSansPro};
        font-size: 18px;
        line-height: 23px;
        font-weight: 600;
    `,
  headerTextStyleSmall: `
        font-family: ${fonts.sourceSansPro};
        font-size: 18px;
        line-height: 23px;
        font-weight: 600;
    `,
  boxShadow: 'none',
  arrowColor: colors.blick.grey90,
  arrowWidth: '16px',
  arrowHeight: '12px',
  collapsedColor: '',
}
