import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { TArticlesState, TActions, TArticle } from './types'

const initialState: TArticlesState = {
  data: null,
  loading: {
    fetchArticle: false,
  },
  error: {
    fetchArticle: null,
  },
  resolved: {
    fetchArticle: false,
  },
}

export const reducer: Reducer<TArticlesState, TActions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ARTICLE: {
      return {
        ...state,
        ...getReduxSuccessStates(state, 'fetchArticle'),
      }
    }
    case ActionTypes.FETCH_ARTICLE_SUCCESS: {
      let id
      const article: TArticle = {}

      if (action.payload.metadata) {
        article.blick = action.payload.metadata
        id = article.blick.id
      } else {
        article.libertatea = {
          id: action.payload.id || '',
          title: action.payload.title || '',
          teaserImageUrl: action.payload.teaserImageUrl || '',
          link: action.payload.link,
        }
        id = article.libertatea.id
      }

      return {
        ...state,
        data: {
          ...state.data,
          [id]: article,
        },
        ...getReduxSuccessStates(state, 'fetchArticle'),
      }
    }
    case ActionTypes.FETCH_ARTICLE_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchArticle', action.payload),
      }
    }
    default:
      return state
  }
}
