export const onetColors = {
  yellow: '#FFD200',
  darkYellow: '#e9c000',
  grey: '#E4E4E4',
  grey2: '#DEDEDE',
  grey3: '#A9A7A8',
  darkGrey: '#1E1E1E',
  lightGrey: '#EBEBEB',
  lightGrey2: '#F1F1F1',
  veryLightGrey: '#F5F5F7',
  ultraLightGrey: '#F8F8F8',
  red: '#CC181E',
  errorRed: '#CC181E',
  errorRedMedium: '#FB8E92',
  errorRedLight: '#FFF3F2',
  successGreen: '#3A8D26',
  successGreenMedium: '#95D485',
  successGreenLight: '#DEE9DB',
}
