import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { THeroStyle } from 'src/theme/themes/default/components/hero'

const hero: THeroStyle = defaultsDeep({}, defaultTheme.components.Hero)

hero.slideMobileHeight = '362px'
hero.imagePosition = 'top right'
hero.slideText.fontFamily = defaultTheme.fonts.goodOffcPro
hero.slideText.fontSize = '28px'
hero.slideText.width = 'calc(100% - 16px)'
hero.slideText.left = '16px'
hero.slideText.bottom = '6px'
hero.slideText.fontWeight = '950'
hero.slideText.lineHeight = '36px'
hero.slideText.letterSpacing = '0.56px'
hero.slideText.desktop.width = '510px'
hero.slideText.desktop.left = '24px'
hero.slideText.desktop.bottom = '22px'
hero.slideText.desktop.fontWeight = '950'
hero.slideText.desktop.fontSize = '36px'
hero.slideText.desktop.lineHeight = '44px'
hero.slideText.desktop.letterSpacing = '0.72px'

export const bluefrostHero = hero
