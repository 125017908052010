import { InternalLink } from 'src/components/links/InternalLink'

export type TEndOfSeasonModalTranslations = typeof endOfSeasonModalTranslations

export const endOfSeasonModalTranslations = {
  subtitle: 'SO LIEF DEINE TROPHY',
  getSummarytitle: (userRank?: number, userName?: string) => {
    return <>Du hast dich auf Platz {userRank} der Rangliste gekämpft!</>
  },
  getGroupStepTitle: (seasonRank: number) => `Dein Team und du seid auf Rang ${seasonRank} gelandet`,
  statisticsSubtitle: 'so hast du abgeschnitten',
  feedbackSubscriptionText: 'Danke, fürs Anmelden!',
  feedbackTitle: 'Wie zufrieden warst du mit dieser Blick Trophy?',
  feedbackPlaceholder: <>Dein Feedback (Optional)</>,
  subscriptionText: 'Möchtest du eine E-Mail erhalten, wenn es ein neues Spiel gibt?',
  thanksTitle: 'Danke, dass du mitgemacht hast! ',
  thanksText: (link: string) => (
    <>
      Auf <InternalLink to={link}>deiner Übersichtsseite</InternalLink> kannst du deine Resultate sehen oder dich für
      den Newsletter anmelden.
    </>
  ),
  socialTitle: 'RESULTAT TEILEN',
  showLaterButton: 'SPÄTER ANSEHEN',
  nextButton: 'WEITER',
  backButton: 'ZURÜCK',
  closeButton: 'SCHLIESSEN',
  approveButton: 'JA, Gerne',
  rejectButton: 'Nein, danke',
  sharingText: 'Hallo! Schau dir an, wie ich bei der Blick Trophy abgeschnitten habe!',
  sharingSubject: 'Mein Blick Trophy Ergebnis',
  copyText: 'Zu teilende URL wurde kopiert!',
}
