import { libertateaColors } from 'src/theme/themes/libertatea/colors'

export type TPrizeAltStyle = typeof defaultPrizeAlt

export const defaultPrizeAlt = {
  background: 'transparent',
  boxShadow: '0px 0px 8px 2px rgba(0, 0, 0, 0.12)',
  border: 'none',
  borderRadius: '0',
  titleTextStyle: `
    font-weight: 300;
    font-size: 24px;
    line-height: 140%;
  `,
  descriptionTextStyle: `
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
  `,
  imageWrapperBorder: `1px solid ${libertateaColors.greyLight}`,
}
