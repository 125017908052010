import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { Actions, TProductState } from './types'

export const initialState: TProductState = {
  data: null,
  loading: {
    fetchProduct: false,
  },
  error: {
    fetchProduct: null,
  },
  resolved: {
    fetchProduct: false,
  },
}

export const reducer: Reducer<TProductState, Actions> = (state = initialState, action): TProductState => {
  switch (action.type) {
    case ActionTypes.FETCH_PRODUCT: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchProduct'),
      }
    }
    case ActionTypes.FETCH_PRODUCT_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ...getReduxSuccessStates(state, 'fetchProduct'),
      }
    }
    case ActionTypes.FETCH_PRODUCT_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchProduct', action.payload),
      }
    }
    default:
      return state
  }
}
