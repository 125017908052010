import FlagPart from 'src/assets/images/rectangle_flag_part.png'
import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'

export type TSummaryStyle = typeof defaultSummary

export const defaultSummary = {
  bannerBackground: colors.blick.grey10,
  bannerBackgroundPosition: 'initial',
  textPaddingLeftDesktop: '176px',
  textPaddingLeftMobile: '126px',
  topInfoPaddingTopDesktop: '24px',
  topInfoPaddingBottomDesktop: '24px',
  topInfoPaddingTopMobile: '16px',
  topInfoPaddingBottomMobile: '11px',
  topInfoMobileMinHeight: '150px',
  subtitleDisplay: 'inline-block',
  subtitleTextStyle: `
    color: ${colors.shades.white};
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  `,
  subtitleBackgrounnd: colors.blick.brand55,
  titleTextStyle: `
    font-family: ${fonts.goodOffcPro};
    font-weight: 700;
    font-size: 33.75px;
    line-height: 120%;
    letter-spacing: 0.4px;
    max-width: 512px;
    width: 415px;
  `,
  titleTextStyleMobile: `
    font-family: ${fonts.goodOffcPro};
    width: 230px;
    font-weight: 950;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-shadow: 0px 0px 8px #1F1F1F;
  `,
  infoBlocksCentered: false,
  infoBlockBackground: colors.shades.white,
  infoBlockShadow: '0px 3px 0px rgba(0, 0, 0, 0.3)',
  infoBlockPadding: '8px 16px',
  infoBlockMargin: 'auto auto 16px 0',
  infoBlockValueTextStyle: `
    font-size: 18px;
    line-height: 23px;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: ${colors.blick.grey100};
  `,
  infoBlockDescriptionTextStyle: `
    font-size: 14px;
    font-wight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: ${colors.blick.grey100};
  `,
  infoBlockAfter: `

    position: absolute;
    top: 0;
    right: -12px;
    background-image: ${`url("${FlagPart}")`};
    height: 43px;
    width: 12px;
    background-size: 12px 43px;
    content: '';
`,
  infoBlockBorderRadius: '',
  bottomInfoPaddingBottomDesktop: '24px',
  bottomInfoPaddingBottomMobile: '16px',
  isSubscriptionCentered: false,
  subscriptionMaxWidth: '487px',
  subscriptionPaddingDesktop: '32px 32px 16px 32px',
  subscriptionPaddingMobile: '16px',
  subscriptionTitleTextStyle: `
    font-family: ${fonts.goodOffcPro};
    font-style: normal;
    font-weight: 950;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 0.02em;
  `,
  subscriptionTitleTextStyleMobile: `
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
  `,
  subscriptionFormTitleTextStyleDesktop: `
    margin-bottom: 16px;
    font-family: ${fonts.goodOffcPro};
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.4px;
  `,
  subscriptionFormTitleTextStyleMobile: `
      margin-bottom: 16px;
      font-family: ${fonts.goodOffcPro};
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.4px;
  `,
  subscriptionBackground: colors.blick.blueLight1,
  subscriptionShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.25)',
  subscriptionFormSuccessBackground: colors.feedback.positive,
  subscriptionBorderRadius: '8px',
  subscriptionInputMaxWidth: 'initial',
  isSubscriptionButtonFullWidth: false,
}
