import styled, { css } from 'styled-components/macro'

export const buttonTheme = {
  primary: css`
    color: ${({ theme }) => theme.components.Button.componentTheme.primary.color};
    background-color: ${({ theme }) => theme.components.Button.componentTheme.primary.background};

    &:hover {
      background-color: ${({ theme }) => theme.components.Button.componentTheme.primary.hover.background};
    }
    &:disabled {
      opacity: 1;
      color: ${({ theme }) => theme.components.Button.componentTheme.primary.disabled.color};
      background: ${({ theme }) => theme.components.Button.componentTheme.primary.disabled.background};

      svg path {
        fill: ${({ theme }) => theme.components.Button.componentTheme.primary.disabled.color};
      }
    }
  `,
  secondary: css`
    color: ${({ theme }) => theme.components.Button.componentTheme.secondary.color};
    background-color: ${({ theme }) => theme.components.Button.componentTheme.secondary.background};
    border: 1px solid ${({ theme }) => theme.components.Button.componentTheme.secondary.color};
    svg path {
      fill: ${({ theme }) => theme.components.Button.componentTheme.secondary.color};
    }

    &:hover {
      color: ${({ theme }) => theme.components.Button.componentTheme.secondary.hover.color};
      border: 1px solid ${({ theme }) => theme.components.Button.componentTheme.secondary.hover.color};

      svg path {
        fill: ${({ theme }) => theme.components.Button.componentTheme.secondary.hover.color};
      }
    }

    &:disabled {
      color: ${({ theme }) => theme.components.Button.componentTheme.secondary.disabled.color};
      border: 1px solid ${({ theme }) => theme.components.Button.componentTheme.secondary.disabled.color};

      svg path {
        fill: ${({ theme }) => theme.components.Button.componentTheme.secondary.disabled.color};
      }
    }
  `,
  ghost: css`
    background-color: ${({ theme }) => theme.components.Button.componentTheme.ghost.background};
    color: ${({ theme }) => theme.components.Button.componentTheme.ghost.color};
    padding: ${({ theme }) => theme.components.Button.componentTheme.ghost.padding};

    &:hover {
      background-color: ${({ theme }) => theme.components.Button.componentTheme.ghost.hover.background};
      color: ${({ theme }) => theme.components.Button.componentTheme.ghost.hover.color};
    }
  `,
  underline: css`
    color: ${({ theme }) => theme.components.Button.componentTheme.underline.color};
    text-decoration: underline;
    background-color: transparent;
    padding: 0;
    font-family: ${({ theme }) => theme.components.Button.componentTheme.underline.fontFamily};
    font-size: ${({ theme }) => theme.components.Button.componentTheme.underline.fontSize};
    font-weight: ${({ theme }) => theme.components.Button.componentTheme.underline.fontWeight};
    text-transform: ${({ theme }) => theme.components.Button.componentTheme.underline.textTransform};
    &:hover {
      color: ${({ theme }) => theme.components.Button.componentTheme.underline.color};
      background-color: transparent;
    }
  `,
  link: css`
    padding: ${({ theme }) => theme.components.Button.componentTheme.link.padding};
    background-color: ${({ theme }) => theme.components.Button.componentTheme.link.background};
    font-size: ${({ theme }) => theme.components.Button.componentTheme.link.fontSize};
    color: ${({ theme }) => theme.components.Button.componentTheme.link.color};
    height: ${({ theme }) => theme.components.Button.componentTheme.link.height};
    text-transform: ${({ theme }) => theme.components.Button.componentTheme.link.textTransform};
    text-decoration: ${({ theme }) => theme.components.Button.componentTheme.link.textDecoration};
    font-weight: ${({ theme }) => theme.components.Button.componentTheme.link.fontWeight};

    svg path {
      fill: ${({ theme }) => theme.components.Button.componentTheme.link.color};
    }

    &:hover {
      color: ${({ theme }) => theme.components.Button.componentTheme.link.hover.color};
      background-color: ${({ theme }) => theme.components.Button.componentTheme.link.hover.background};

      svg path {
        fill: ${({ theme }) => theme.components.Button.componentTheme.link.hover.color};
      }
    }
  `,
  highlighted: css`
    background-color: ${({ theme }) => theme.components.Button.componentTheme.highlighted.background};
    color: ${({ theme }) => theme.components.Button.componentTheme.highlighted.color};
  `,
}

export const buttonStyles = css<Props>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 30px;
  padding: 0 23px;
  height: ${({ theme, height }) => (height ? height : theme.components.Button.height)};
  font-family: ${({ theme }) => theme.components.Button.fontFamily};
  font-size: ${({ theme }) => theme.components.Button.fontSize};
  font-style: normal;
  font-weight: ${({ theme }) => theme.components.Button.fontWeight};
  line-height: 20px;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  text-decoration: none;

  border: none;
  border-radius: ${({ theme }) => theme.components.Button.borderRadius};
  box-sizing: border-box;
  // spacing between multiple elements, e.g. Text + Icon
  > *:not(:first-child) {
    margin-left: 10px;
  }

  svg {
    flex-shrink: 0;
    fill: currentColor !important;
  }

  @media (max-width: 350px) {
    font-size: 12px;
  }
`

export type TButtonTheme = keyof typeof buttonTheme

type Props = {
  componentTheme?: TButtonTheme
  fullWidth?: boolean
  disabled?: boolean
  height?: string
  $centered?: boolean
}

export const Button = styled.button<Props>`
  ${buttonStyles}
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ componentTheme }) => buttonTheme[componentTheme || 'primary']}



  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  ${({ $centered }) =>
    $centered &&
    css`
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    `}
`
