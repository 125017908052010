import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TBetStyle } from 'src/theme/themes/default/components/bet'
import { bluefrostColors } from 'src/theme/themes/bluefrost/colors'

const bet: TBetStyle = defaultsDeep({}, defaultTheme.components.Bet)
bet.selectionHeaderBackground = bluefrostColors.blueLight2
bet.selectedParticipantBackground = bluefrostColors.blueLight1
bet.selectedParticipantBackgroundHover = bluefrostColors.blueLight2
bet.infoBoxColor = defaultTheme.colors.blick.grey100
bet.infoBoxBackgroundColor = bluefrostColors.blueLight2

export const bluefrostBet = bet
