import { ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

import blickLogo from 'src/assets/images/logo/blick_logo.svg'
import { Spacer } from 'src/components/Spacer'
import { Image } from './Image'

const ANIMATION_TIME_SECONDS = 3

type Props = {
  onClickLogo?: () => void
  message?: string
  children?: ReactNode
  isFullScreen?: boolean
}

export const SplashScreen = ({ message, children, onClickLogo = () => null, isFullScreen }: Props) => (
  <Wrapper $isFullScreen={!!isFullScreen}>
    <SpinContainer $isFullScreen={!!isFullScreen}>
      <SpinningLogo onClick={onClickLogo}>
        <Logo src={blickLogo} />
      </SpinningLogo>
      <Shadow />
    </SpinContainer>
    <Spacer v size={isFullScreen ? 80 : 40} />
    <SplashScreenText>{message ? message : 'Anmeldung läuft ...'}</SplashScreenText>
    {children}
  </Wrapper>
)

export const spinKeyframe = keyframes`
  20% { transform:rotate(0); }
  40% { transform:rotate(-30deg); }
  100% { transform:rotate(360deg); }
`

const Wrapper = styled.div<{ $isFullScreen: boolean }>`
  ${({ $isFullScreen }) =>
    $isFullScreen &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    `}
  z-index: ${({ theme }) => theme.zIndex.aboveAll};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.shades.white};
`

const SpinContainer = styled.div<{ $isFullScreen: boolean }>`
  position: relative;
  width: ${({ $isFullScreen }) => ($isFullScreen ? '320px' : '160px')};
  height: ${({ $isFullScreen }) => ($isFullScreen ? '320px' : '160px')};

  ${({ theme }) => theme.media.isMobile} {
    width: ${({ $isFullScreen }) => ($isFullScreen ? '200' : '100px')};
    height: ${({ $isFullScreen }) => ($isFullScreen ? '200px' : '100px')};
  }
`

const SpinningLogo = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.blick.brand};
  border-top: 5px solid #ff2222;
  border-bottom: 5px solid #bb0000;
  border-radius: 40px;

  ${({ theme }) => theme.media.isMobile} {
    border-radius: 25px;
  }

  animation: ${spinKeyframe} ${ANIMATION_TIME_SECONDS}s cubic-bezier(0.14, 0.55, 0.39, 0.99) infinite;
`

const Shadow = styled.div`
  position: absolute;
  top: 15%;
  left: 5%;

  width: 90%;
  height: 90%;
  filter: blur(20px);
  background-color: black;

  animation: ${spinKeyframe} ${ANIMATION_TIME_SECONDS}s cubic-bezier(0.14, 0.55, 0.39, 0.99) infinite;
`

export const SplashScreenText = styled.h2`
  color: #545454;
  text-align: center;
  padding: 20px 0;
  ${({ theme }) => theme.media.isDesktop} {
    margin: 0;
    background-color: #fdfdfd;
    border-radius: 40px;
  }
`

const Logo = styled(Image)`
  height: auto;
  max-width: 100%;
`
