import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { onetColors } from 'src/theme/themes/onet/colors'
import { TQuizWidgetStyle } from 'src/theme/themes/default/components/quizWidget'

const quizwidget: TQuizWidgetStyle = defaultsDeep({}, defaultTheme.components.QuizWidget)

quizwidget.border = `1.2px solid ${onetColors.grey}`
quizwidget.borderRadius = '6px'
quizwidget.widgetTitlePadding = '24px 32px 0px'
quizwidget.widgetTitlePaddingMobile = '16px 16px 0px'
quizwidget.widgetTitleTextStyle = `
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
`
quizwidget.wigetTitleTextStyleMobile = `
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
`
quizwidget.indicatorPadding = '24px 0 8px 0'
quizwidget.indicatorPaddingMobile = quizwidget.indicatorPadding
quizwidget.indicatorTextStyle = `
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
`
quizwidget.indicatorTextStyleMobile = quizwidget.indicatorTextStyle
quizwidget.titleTextStyle = `
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
`
quizwidget.titleTextStyleMobile = quizwidget.titleTextStyle
quizwidget.titleTextAlign = 'center'
quizwidget.titleMargin = '0px 0 8px 0'
quizwidget.titleMarginMobile = '0px 0 8px 0'
quizwidget.simpleTeaserMargin = '-8px 0 16px 0'
quizwidget.simpleTeaserMarginMobile = '0px 0 16px 0'
quizwidget.simpleTeaserTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
`
quizwidget.simpleTeaserTextStyleMobile = quizwidget.simpleTeaserTextStyle

quizwidget.questionsWrapperPadding = '0px 32px 32px 32px'
quizwidget.questionsWrapperPaddingMobile = '0px 16px 16px 16px'
quizwidget.answerBorderRadius = '6px'
quizwidget.answerDefaultBackground = onetColors.ultraLightGrey
// quizwidget.answerDefaultBackgroundHover = defaultTheme.colors.shades.white
quizwidget.answerDefaultBoxShadow = 'none'
quizwidget.answerDefaultBorder = `1px solid ${onetColors.grey2}`
quizwidget.answerDefaultInnerBackground = onetColors.lightGrey2
quizwidget.answerDefaultPercentageBackground = defaultTheme.colors.shades.white
quizwidget.answerDefaultPercentageBorder = `2px solid ${onetColors.grey3}`
quizwidget.answerMargin = '0 0 16px 0'

quizwidget.answerLoadingBackground = defaultTheme.colors.shades.white
quizwidget.answerLoadingBorder = `1px solid ${onetColors.grey2}`

quizwidget.answerSuccessBackground = onetColors.successGreenLight
quizwidget.answerSuccessBorder = `2px solid ${onetColors.successGreen}`
quizwidget.answerSuccessInnerBackground = onetColors.successGreenMedium

quizwidget.answerFailureBackground = onetColors.errorRedLight
quizwidget.answerFailureBorder = `2px solid ${onetColors.errorRed}`
quizwidget.answerFailureInnerBackground = onetColors.errorRedMedium

quizwidget.answerTextStyle = `
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
`
quizwidget.answerTextStyleMobile = quizwidget.answerTextStyle

quizwidget.answerMinHeight = '57px'
quizwidget.answerMinHeightMobile = quizwidget.answerMinHeight
quizwidget.footerMargin = '16px 0px 0px 0px'

quizwidget.bottomWrapperTextAlignLoggedin = 'center'
quizwidget.bottomWrapperPaddingDesktop = '32px'
quizwidget.bottomWrapperBackground = onetColors.lightGrey
quizwidget.bottomWrapperMargin = '0 -32px -32px -32px'
quizwidget.bottomWrapperMarginMobile = '0 -16px -16px -16px'
quizwidget.resultsTitleTextStyle = `
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    text-align: center; 
`
quizwidget.resultsTitleMargin = '16px 0 32px 0'
quizwidget.subTitleTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
`
quizwidget.subTitleTextStyleMobile = quizwidget.subTitleTextStyle

export const onetQuizWidget = quizwidget
