import { defaultsDeep } from 'lodash-es'
import { InternalLink } from 'src/components/links/InternalLink'

import {
  endOfSeasonModalTranslations,
  TEndOfSeasonModalTranslations,
} from 'src/translations/de/default/endofseasonmodal'

export const wmtrophyEndOfSeasonModalTranslations: TEndOfSeasonModalTranslations = defaultsDeep(
  {},
  endOfSeasonModalTranslations,
)

wmtrophyEndOfSeasonModalTranslations.subtitle = 'so lief deine wm-trophy'
wmtrophyEndOfSeasonModalTranslations.statisticsSubtitle = 'WM TROPHY - Vorrunden'
wmtrophyEndOfSeasonModalTranslations.feedbackTitle = 'Wie zufrieden warst du mit dieser Blick Trophy?'
wmtrophyEndOfSeasonModalTranslations.subscriptionText =
  'Möchtest du eine E-Mail erhalten, wenn es ein neues Spiel gibt?'
wmtrophyEndOfSeasonModalTranslations.thanksTitle = 'Danke, dass du mitgemacht hast!'
wmtrophyEndOfSeasonModalTranslations.thanksText = (link: string) => (
  <>
    Auf deiner Übersichtseite kannst du dich auch später für den &nbsp;
    <InternalLink to={link}>Newsletter anmelden</InternalLink>
  </>
)
