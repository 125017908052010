import { Reducer } from 'redux'

import { ActionTypes } from './enums'
import { ActionTypes as PowerupsActionTypes } from 'src/api/powerups/enums'
import { TMatchBetState, TActions } from './types'
import { GameId } from 'src/enums/other'

const initialState: TMatchBetState = {
  matches: [],
  teams: [],
  matchesIsLoading: false,
  matchesIsLoaded: false,
  placingBets: [],
}

export const reducer: Reducer<TMatchBetState, TActions> = (state = initialState, action): TMatchBetState => {
  switch (action.type) {
    case ActionTypes.FETCH_MATCHES: {
      return {
        ...state,
        matchesIsLoading: true,
      }
    }

    case ActionTypes.FETCH_MATCHES_SUCCESS: {
      return {
        ...state,
        matchesIsLoading: false,
        matchesIsLoaded: true,
        matches: action.payload.matches,
        teams: action.payload.teams,
      }
    }

    case ActionTypes.FETCH_MATCHES_FAILURE: {
      return {
        ...state,
        matchesIsLoading: false,
        matchesIsLoaded: true,
      }
    }

    case ActionTypes.PLACE_MATCH_BET: {
      const matches =
        action.payload.component === 'matchbetwidget'
          ? state.matches
          : state.matches?.map((match) =>
              match.id !== action.payload.matchId ? match : { ...match, bet: action.payload.bet },
            )

      return {
        ...state,
        matches,
        placingBets: [...state.placingBets, action.payload.matchId],
      }
    }

    case ActionTypes.PLACE_MATCH_BET_SUCCESS: {
      return {
        ...state,
        placingBets: state.placingBets.filter((matchId) => matchId !== action.payload.matchId),
      }
    }

    case ActionTypes.PLACE_MATCH_BET_FAILURE: {
      return {
        ...state,
        placingBets: state.placingBets.filter((matchId) => matchId !== action.payload.matchId),
      }
    }

    case PowerupsActionTypes.APPLY_POWERUPS_SUCCESS: {
      const { eventId, powerupId, powerupType, gameId } = action.payload

      if (gameId !== GameId.MatchBet) {
        return state
      }

      const eventToUpdate = state.matches.find((match) => match.id === eventId)
      const eventToUpdateIndex = state.matches.findIndex((match) => match.id === eventId)
      let updatedMatches = state.matches

      //Assign new powerups values after updates
      if (eventToUpdateIndex >= 0 && eventToUpdate) {
        updatedMatches = [
          ...updatedMatches.slice(0, eventToUpdateIndex),
          { ...eventToUpdate, powerupId, powerupType },
          ...updatedMatches.slice(eventToUpdateIndex + 1),
        ]
      }

      return {
        ...state,
        matches: updatedMatches,
      }
    }

    default: {
      return state
    }
  }
}
