export type TSummaryTranslations = typeof summaryTranslations

export const summaryTranslations = {
  pageTitle: 'Blick Trophy',
  subtitle: (username: string | undefined) => <>so hat {username} gespielt</>,
  title: (userRank?: number, userName?: string) => {
    return <>Du hast dich auf Platz {userRank} der Rangliste gekämpft</>
  },
  team: {
    title: (rank?: number, name?: string) => (
      <>
        Team {name} ist auf Rang {rank} gelandet
      </>
    ),
    subtitle: <>Endergebnis Teams</>,
  },
  subscriptionTitle: 'Die Blick Trophy ist leider vorbei. Schon bald aber gibt es neue Spiele!', //hidden if empty
  subscriptionText: `Erhalte eine E-Mail, sobald es neue Spiele gibt!`,
  subscriptionSuccessfulText:
    'Vielen Dank! Sobald neue Tippspiele in den Startlöchern stehen, wirst du benachrichtigt.',
  inputLabel: 'Deine E-Mail-Adresse',
  invalidEmailError: 'Geben Sie eine gültige E-Mail-Adresse',
  requiredFieldError: 'Feld darf nicht leer sein',
  buttonText: 'SENDEN',
  round: {
    subtitle: (dynamicText?: string) => <>Ski TROPHY im {dynamicText}</>,
    titleMobile: (rank?: number, name?: string) => (
      <>
        {name} hat sich auf Platz {rank} der Rangliste gekämpft
      </>
    ),
    titleDesktop: (rank?: number, name?: string) => (
      <>
        {name} hat sich auf Platz {rank} der Rangliste gekämpft
      </>
    ),
    bannerTitle: 'Spiel mit bei der Ski Trophy und gewinne jeden Monat coole Preise!',
    team: {
      title: (rank?: number, name?: string) => (
        <>
          Team {name} ist auf Rang {rank} gelandet
        </>
      ),
      subtitle: (dinamicText?: string) => <>{dinamicText} TIPPGRUPPE</>,
    },
    buttonTextLoggedIn: 'jetzt mitspielen',
    buttonTextLoggedOut: 'anmelden',
  },
}
