import { Bold } from 'src/components/Bold'
import { TQuizWidgetTranslations } from 'src/translations/de/default/quizwidget'

export const quizWidgetTranslations: TQuizWidgetTranslations = {
  indicatiorText: (maxQuestionCount?: number) => `Fragen beantwortet ${maxQuestionCount}/${maxQuestionCount}`, // Not used
  resultTitle: {
    all: 'Volltreffer',
    some: 'Super',
    none: 'Knapp daneben ist auch vorbei!',
  }, // Not used
  resultSummaryText: (
    points: number,
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number | undefined,
  ) =>
    points ? (
      <>
        Du hast{' '}
        <Bold>
          {correctAnswerCount}/{maxQuestionCount} Quizfragen richtig
        </Bold>{' '}
        beantwortet und{' '}
        <Bold>
          {pointsPerCorrectQuestion && maxQuestionCount
            ? `${points}/${pointsPerCorrectQuestion * maxQuestionCount}`
            : '0'}{' '}
          Punkte
        </Bold>{' '}
        erhalten.
      </>
    ) : (
      <>
        Du hast <b>keine Frage richtig</b> beantwortet. Das gibt leider keinen Punkt für dich.
      </>
    ), // Not used
  resultSummaryEndText: 'Teste dein Wissen morgen erneut!', // Not used
  resultTable: {
    rank: 'Rang',
    nickname: 'Benutzername',
    total: 'Total',
    gained: 'Heute',
  }, // Not used
  leaderboardButtonText: 'Zur Rangliste', // Not used
  blickButtonText: 'Blick Ski Nachrichten', // Not used

  quizTitle: <>Răspunde la "Întrebarea Zilei" şi adaugă cât mai multe şanse de câştig.</>,
  resultButtonUserWithBets: 'Rennen warten auf tipps', // Not used
  resultButtonUser: 'Continuă către Întrebarea Zilei',
  resultButtonLogin: 'Conectează-te pentru a putea câștiga',
  resultButtonLoginAlt: 'Conectează-te pentru a putea câștiga',
  resultButtonLoginNotValidUser: 'Conectează-te pentru a putea câștiga',
  resultBottomTitle: 'Vezi premiile puse în joc!',
  resultBottomTextWithBets: (availableBets: number) => <>Du hast noch ungetippte Ski-Rennen.</>, // Not used
  resultBottomText:
    'Câștigă un premiu la fiecare 2 săptămâni și găsește premii surpriză de la partenerii noștri în timpul jocului.',
  guestBottomResultTitle: 'Înscrie-te sau conectează-te pentru a câștiga premii!',
  guestBottomResultText1: 'Sutele de premii puse în joc',
  guestBottomResultText2: 'Extragerea o dată la două săptămâni',
  guestBottomResultText3: 'Cu cât joci mai mult, cu atât ai șanse mai mari de a câștiga',
  singleQuestionSuccessTitle: 'Felicitări',
  singleQuestionSuccessDescription: (
    <>
      Cu cât joci mai mult, cu atât mai mare este șansa ta de a câștiga! <br /> <br /> Revino mâine pentru a răspunde la
      o altă întrebare.
    </>
  ),
  singleQuestionFailureTitle: 'Oh no!',
  singleQuestionFailureDescription: (
    <>
      Revino mâine și încearcă din nou!
      <br />
      Link-ul din hint te poate ajuta să afli răspunsul corect.
      <br />
      <br />
      Cu cât joci mai mult, cu atât mai mare este șansa ta de a câștiga!
    </>
  ),
  continue: 'Vezi toate premiile și câștigătorii',
}
