import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'
import { defaultCommon } from './common'

export type TQuizStyle = typeof defaultQuiz

export const defaultQuiz = {
  background: defaultCommon.background,
  border: ``,
  borderRadius: defaultCommon.borderRadius,
  padding: '40px 16px 48px 16px',
  wrapperMargin: '0 auto',
  innerWrapperPadding: '24px',
  innerWrapperNoTeaserPadding: '24px',
  innerWrapperPaddingMobile: '16px',
  indicatorPadding: '0',
  indicatorPaddingMobile: '0',
  indicatorTextStyle: `
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
    `,
  indicatorTextStyleMobile: `
      font-size: 16px;
      font-weight: 900;
      line-height: 20px;
    `,
  titleTextStyle: `
      font-size: 20px;
      line-height: 24px;
      font-weight: 400;
      font-family: ${fonts.goodOffcPro};
    `,
  titleTextStyleMobile: `
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
      font-family: ${fonts.goodOffcPro};
    `,
  titleTextAlign: 'center',
  titleMargin: '16px 0 32px 0',
  titleMarginMobile: '16px 0 24px 0',
  simpleTeaserMargin: '-16px 0 32px 0',
  simpleTeaserMarginMobile: '-16px 0 32px 0',
  simpleTeaserTextStyle: `
    font-size: 16px;
  `,
  simpleTeaserTextStyleMobile: `
    font-size: 16px;
  `,
  questionsWrapperPadding: '0',
  questionsWrapperPaddingMobile: '0',
  answersWrapperPadding: '0 66px',
  answersWrapperPaddingMobile: '0 2px',
  answerJustifyContent: 'center',
  answerBorderRadius: defaultCommon.borderRadius,
  answerBoxShadow: '0px 2px 1px rgba(0, 0, 0, 0.15)',
  answerMinHeight: '68px',
  answerMinHeightMobile: '68px',
  answerPadding: '0px 15px',
  answerPaddingMobile: '10px 15px',
  answerMargin: '0 0 24px 0',
  answerTextStyle: `
      font-size: 18px;
      line-height: 23px;
      font-weight: 400;
    `,
  answerTextStyleMobile: `
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
    `,

  answerDefaultBackground: colors.shades.white,
  answerDefaultBackgroundHover: colors.blick.grey10,
  answerDefaultBoxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.25)',
  answerDefaultBorder: `2px solid ${colors.shades.white}`,
  answerDefaultInnerBackground: colors.blick.grey15,
  answerDefaultPercentageBackground: colors.shades.white,
  answerDefaultPercentageBorder: `2px solid ${colors.blick.grey30}`,
  answerDefaultPercentageColor: colors.blick.grey100,
  answerDefaultColor: '',

  answerLoadingBackground: colors.blick.grey10,
  answerLoadingBorder: `2px solid ${colors.blick.grey20}`,
  answerLoadingColor: '',

  answerSuccessBackground: colors.blick.sport5,
  answerSuccessBorder: `2px solid ${colors.blick.sport55}`,
  answerSuccessColor: '',
  answerSuccessInnerBackground: colors.blick.sport40,
  answerSuccessPointTextColor: colors.blick.sport55,

  answerFailureBackground: colors.blick.brand5,
  answerFailureBorder: `2px solid ${colors.feedback.negative}`,
  answerFailureColor: '',
  answerFailureInnerBackground: colors.blick.brand20,
  answerFailurePointTextColor: colors.feedback.negative,

  answerPercentageTextStyle: ``,
  footerMargin: '16px 0 0 0',
  subTitleTextStyle: `
      font-family: ${fonts.sourceSansPro};
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
  `,
  subTitleTextStyleMobile: `
    font-family: ${fonts.sourceSansPro};
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  `,
  resultWrapperPadding: '0',
  resultWrapperPaddingMobile: '0',
  resultsQuestionsIndicatorTextStyle: '',
  resultWrapperAlign: 'center',
  resultsTitleTextStyle: `
    font-family: ${fonts.goodOffcPro};
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
  `,
  resultsTitleTextStyleMobile: `
    font-family: ${fonts.goodOffcPro};
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.4px;
  `,
  resultsTitleMargin: '32px 0 16px 0',
  resultsTitleMarginMobile: '24px 0 16px 0',
  resultTableBackground: 'transparent',
  resultTableBorder: 'none',
  resultTableMargin: '0',
  resultTableMarginMobile: '0',
  resultTableHeadRowHeight: '',
  resultTableHeadCellTextStyle: '',
  resultTableRowHeight: '',
  resultTableCellTextStyle: `
    &:first-child {
      font-weight: 700
    }
  `,
  resultTableCellPadding: '',
  resultTableCellFirstChildPadding: '',
  resultTableCellLastChildPadding: '',
  resultTableCellPaddingMobile: '',
  progressBarWidth: '100%',
  progressBarPosition: 'relative',
  progressBarPositionTop: '0',
  progressBarTextStyle: `
    font-size: 14px;
    line-height: 22px;
    color: ${colors.shades.white};
    text-transform: uppercase;
  `,
  progressBarBackground: colors.blick.blueGrey,
  progressBarBorder: 'none',
  progressBarBorderRadius: '4px',
  progressBarBoxShadow: 'none',
  progressBarInnerBarBackground: colors.blick.blueGreyAlt,
  bettingBannerBackground: '',
  bettingBannerTitleTextStyle: '',
  bettingBannerTextStyle: '',
  bettingButtonBackground: '',
  bettingButtonColor: '',
  bettingButtonBackgroundDisabled: '',
  bettingButtonColorDisabled: '',
  collectedPointsBackground: colors.blick.grey100,
  collectedPointsColor: colors.shades.white,
  teaserTitlePadding: '6px 8px;',
  teaserBoxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.25)',
  teaserBoxPaddingLeft: '40px',
  teaserTitleTextStyle: `
    font-size: 16px;
    line-height: 150%;
    font-family: ${fonts.sourceSansPro};
    color: ${colors.blick.grey90};
  `,
  teaserArticleTitleTextStyle: `
    font-family: ${fonts.goodOffcPro};
    font-weight: 950;
    font-size: 28px;
    line-height: 30px;
    color: ${colors.blick.grey100};
  `,
  teaserArticleTitleTextStyleMobile: `
    font-family: ${fonts.goodOffcPro};
    font-weight: 950;
    font-size: 20px;
    line-height: 22px;
    color: ${colors.blick.grey100};
  `,
  teaserArticleSubTitleTextStyle: `
    font-family: ${fonts.goodOffcPro};
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    color: ${colors.blick.grey75};
  `,
  teaserArticleSubTitleTextStyleMobile: `
    font-family: ${fonts.goodOffcPro};
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: ${colors.blick.grey75};
  `,
  cardPadding: '0 8px',
  cardPaddingMobile: '8px',
  cardTitleTextStyle: `
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
  `,
  cardTextStyle: `
    font-size: 18px;
    line-height: 23px;
    margin-top: 0;
    margin-bottom: 0px;
  `,
  cardButtonsJustifyContent: 'flex-end',
}
