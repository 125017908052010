import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TTableStyle } from 'src/theme/themes/default/components/table'

const table: TTableStyle = defaultsDeep({}, defaultTheme.components.Table)

table.tableHead.lineHeight = '140%'
table.tableCell.fontSize = '18px'

export const onetTable = table
