import { sanitizeUrl } from 'src/utils/other'
import { getTenant, getTenantConfig } from 'src/utils/tenant'

const urlify = (envVar: string | undefined, fallback?: string) => sanitizeUrl(envVar, fallback)

const version = process.env.REACT_APP_VERSION || 'No commit hash found'
const env = process.env.REACT_APP_ENV || process.env.NODE_ENV
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
const isDev = env !== 'prod' && env !== 'production'
const isProd = env === 'prod' || env === 'production'

if (typeof window !== 'undefined') {
  // @ts-expect-error
  window.VERSION = version
}

const currentTenant = getTenant()
const tenantConfig = getTenantConfig(currentTenant)

export const config = {
  version,
  release: process.env.REACT_APP_SENTRY_RELEASE_NAME || 'localhost',
  isLocalhost,
  isProd,
  isDev,
  selfUrl: tenantConfig?.frontend_url,
  app: {
    rootElementId: 'root',
    env,
  },
  // Service APIs
  api: {
    articles: {
      url: urlify(process.env.REACT_APP_ARTICLE_URL, 'https://api.blick.ch/1.7.6'),
    },
    onelog: {
      profileUrl: urlify(tenantConfig?.profile_url, 'https://profile.s.onelog.ch/'),
      metadataUrl: urlify(process.env.REACT_APP_ONELOG_METADATA_URL) || 'https://auth.stg.ws.blick.ch/user',
      loginCase: tenantConfig?.login_case || 'SoccerEM_game_2024',
      source: tenantConfig?.source || 'GAME_gameplatform',
    },
    backend: {
      url: urlify(tenantConfig?.backend_url, 'https://api.d.trophy.ws.blick.ch/api/v1'),
    },
    images: {
      url: urlify(process.env.REACT_APP_IMAGE_URL, 'https://img.blick.ch'),
    },
  },
  blickUtilsScriptUrl: 'https://www.blick.ch/assets/iframeHeightAdjustment.js',
  game: {
    maxSkiersOnBet: 3,
    points: {
      correctAnswer: 3,
      correctBet: 3,
      betParticipation: 3,
    },
    quiz: {
      numberOfQuestionsPerDay: 3,
      libertateaNumberOfQuestionsPerDay: 1,
    },
    quizbetting: {
      externalLinks: {
        schwingen: 'https://www.blick.ch/sport/schwingen/?utm_source=trophy&utm_medium=quiz&utm_campaign=schwingen',
        unihockey: 'https://www.blick.ch/sport/',
        wmtrophy: 'https://www.blick.ch/sport/fussball/wm/',
        skitrophy: 'https://www.blick.ch/sport/ski/',
        onet: 'https://przegladsportowy.onet.pl/',
        frauenwm: 'https://www.blick.ch/sport/fussball/frauen-fussball/frauen-wm/',
      },
    },
  },
  scoreBetGame: {
    points: {
      correctScore: 12,
      correctWinner: 6,
      betParticipation: 1,
    },
  },
  feedback: {
    points: 5,
    bettingFeedbackTitle: 'bettinggroup-feedback',
  },
  tracking: {
    isEnabled: !isLocalhost,
  },
  externalLinks: {
    blickUrl: 'https://www.blick.ch',
    libertatea: 'https://www.libertatea.ro',
    onet: 'https://przegladsportowy.onet.pl/',
    onetTOS: 'https://ocdn.eu/przegladsportowy/zagraj/regulamin-serwisu-zagraj-onet.pdf',
    blickPrivacyUrl:
      'https://www.blick.ch/services/datenschutz-das-wichtigste-zum-datenschutz-in-kuerze-id8334786.html',
    ringierUrl: 'https://www.ringier.ch',
    fisURL:
      'https://www.fis-ski.com/DB/alpine-skiing/cup-standings.html?sectorcode=AL&seasoncode=2024&cupcode=WC&disciplinecode=ALL&gendercode=W&nationcode=',
  },
}
