import { Theme } from 'src/theme/styled'
import { defaultsDeep } from 'lodash-es'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { ThemeNames } from 'src/theme/enums'
import { bluefrostCommon } from './components/common'
import { bluefrostTable } from './components/table'
import { bluefrostLeaderboard } from './components/leaderboard'
import { bluefrostQuiz } from './components/quiz'
import { bluefrostButton } from './components/button'
import { bluefrostFeedback } from './components/feedback'
import { bluefrostBet } from './components/bet'
import { blueFrostEndOfSeasonModal } from './components/endOfSeasonModal'
import { bluefrostSummary } from './components/summary'
import { bluefrostHero } from './components/hero'
import { bluefrostInfoSection } from './components/infoSection'
import { bluefrostQuizWidget } from './components/quizwidget'
import { bluefrostMedalCount } from './components/medalCount'
import { bluefrostCollapsible } from './components/collapsible'
import { bluefrostToast } from './components/toast'

const theme: Theme = defaultsDeep({}, defaultTheme)

theme.name = ThemeNames.BLUEFROST
theme.components.common = bluefrostCommon
theme.components.Table = bluefrostTable
theme.components.Leaderboard = bluefrostLeaderboard
theme.components.Quiz = bluefrostQuiz
theme.components.QuizWidget = bluefrostQuizWidget
theme.components.Button = bluefrostButton
theme.components.Feedback = bluefrostFeedback
theme.components.Bet = bluefrostBet
theme.components.EndOfSeasonModal = blueFrostEndOfSeasonModal
theme.components.Summary = bluefrostSummary
theme.components.Hero = bluefrostHero
theme.components.InfoSection = bluefrostInfoSection
theme.components.MedalCount = bluefrostMedalCount
theme.components.Collapsible = bluefrostCollapsible
theme.components.Toast = bluefrostToast

export const bluefrost = theme
