import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TSummaryStyle } from 'src/theme/themes/default/components/summary'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { libertateaFonts } from 'src/theme/themes/libertatea/fonts'

const summary: TSummaryStyle = defaultsDeep({}, defaultTheme.components.Summary)

summary.textPaddingLeftDesktop = '0'
summary.textPaddingLeftMobile = '0'
summary.topInfoPaddingTopDesktop = '40px'
summary.topInfoPaddingBottomDesktop = '40px'
summary.topInfoPaddingTopMobile = '40px'
summary.topInfoPaddingBottomMobile = '40px'
summary.topInfoMobileMinHeight = 'auto'
summary.subtitleDisplay = 'none'
summary.titleTextStyle = `
  margin:0 auto;
  text-align:center;  
  font-family: ${libertateaFonts.signika};
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  max-width: 643px;
`
summary.titleTextStyleMobile = `
  padding:0 16px;
  text-align:center;
  font-family: ${libertateaFonts.signika};
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
`
summary.infoBlocksCentered = true
summary.infoBlockBackground = '#fff'
summary.infoBlockAfter = `  
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: calc(25.2px + 13px + 13px) 32px 0 0;
  border-color: #fff transparent transparent
    transparent;
  `
summary.infoBlockPadding = '13px 16px'
summary.infoBlockShadow = ''
summary.infoBlockValueTextStyle = `
  font-size: 18px;
  line-height: 140%;
  font-weight: 700;
  color: ${libertateaColors.greyDark};
`
summary.infoBlockDescriptionTextStyle = `
  font-size: 16px;
  font-weight: 300;
  line-height: 140%;
  color: ${libertateaColors.greyDark};
`
summary.infoBlockMargin = 'auto auto 8px 0'
summary.bottomInfoPaddingBottomDesktop = '40px'
summary.isSubscriptionCentered = true
summary.subscriptionMaxWidth = '649px'
summary.subscriptionPaddingDesktop = '32px 32px 32px 32px'
summary.subscriptionPaddingMobile = '32px 32px 32px 32px'
summary.subscriptionShadow = 'none'
summary.subscriptionFormTitleTextStyleDesktop = `
  margin-bottom: 40px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 120%;
`
summary.subscriptionFormTitleTextStyleMobile = `
  margin-bottom: 16px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 140%;
`
summary.subscriptionBackground = libertateaColors.greyVeryLight
summary.subscriptionBorderRadius = '0'
summary.subscriptionInputMaxWidth = '314px'
summary.isSubscriptionButtonFullWidth = true

export const libertateaSummary = summary
