import { TButtonStyle } from 'src/theme/themes/default/components/button'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { libertateaFonts } from 'src/theme/themes/libertatea/fonts'

const button: TButtonStyle = defaultsDeep({}, defaultTheme.components.Button)
button.height = '50px'
button.fontFamily = libertateaFonts.signika
button.fontWeight = 700
button.borderRadius = '0'

//Primary
button.componentTheme.primary.background = libertateaColors.red
button.componentTheme.primary.hover.background = libertateaColors.redDark

//Secondary
button.componentTheme.secondary.color = libertateaColors.red
button.componentTheme.secondary.hover.color = libertateaColors.redDark

//Ghost
button.componentTheme.ghost.color = libertateaColors.red
button.componentTheme.ghost.padding = '0 20px'
button.componentTheme.ghost.hover.color = libertateaColors.redDark
button.componentTheme.ghost.hover.background = 'transparent'

//Link
button.componentTheme.link.color = libertateaColors.red
button.componentTheme.link.hover.color = libertateaColors.redDark
button.componentTheme.link.textDecoration = 'none'
button.componentTheme.link.fontWeight = '300'

export const libertateaButton = button
