import { colors } from 'src/theme/colors'

export type TScoreBetStyle = typeof defaultScoreBet

export const defaultScoreBet = {
  matchCardBackgroundColor: colors.blick.sport5,

  successColor: colors.blick.sport65,
  successBorderColor: colors.blick.sport55,
  successBackgroundColor: colors.blick.sport5,

  failedColor: colors.feedback.negative,
  failedBorderColor: colors.feedback.negative,
  failedBackgroundColor: colors.blick.brand5,

  disabledColor: colors.blick.grey75,
  disabledBorderColor: colors.blick.grey75,
  disabledBackgroundColor: colors.blick.grey15,

  powerupButtonBg: colors.blick.sport55,

  pointsButtonBg: colors.blick.sport55,
  pointsButtonBgShadow: colors.shades.green,
  pointsButtonBgWrongBet: colors.blick.brand,
  pointsButtonBgWrongBetShadow: colors.shades.red,
}
