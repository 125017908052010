import { AxiosError } from 'axios'
import {
  TClickData,
  TTrackingPayload,
  TTrackingPayloadLogin,
  TTrackingPowerupsPayload,
  TTrackingScoreBetStagePayload,
  TScoreBetStageChangePosition,
  TEndOfSeasonTrackingPayload,
  TUserFeedbackTrackingPayload,
  TTop3ParticipantsTrackingPayload,
  TUserGroupClickTrackingPayload,
  TIframeName,
  TUserIconClickTrackingPayload,
  TBettingGemeClickTrackingPayload,
} from './types'
import { isBackendError } from 'src/utils/errors'
import { GameId } from 'src/enums/other'
import { ShareType } from 'src/components/buttons/share/enums'
import { getTenant } from 'src/utils/tenant'
import { store } from 'src/redux/store'
import { getIsLoggedIn, getIsValidUser, getShouldCacheGtmEvents, getUserSub } from 'src/api/auth/selectors'
import { config } from 'src/config'
import { Tenants } from 'src/enums/tenants'

const pushToDataLayer = (payload: AnyObject) => {
  if (getShouldCacheGtmEvents()) {
    if (!window.dataLayerCache) {
      window.dataLayerCache = []
    }
    window.dataLayerCache?.push(payload)
  } else {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
    window.dataLayer.push(payload)
  }
  !config.isProd && console.log(payload)
}

const pushToBlickDataLayer = (payload: AnyObject) => {
  !config.isProd && console.log(payload)
  window.blickDataLayer?.push(payload)
}

const addAllCachedEvents = () => {
  if (getShouldCacheGtmEvents()) {
    return
  }

  if (!window.dataLayerCache) {
    return
  }

  window.dataLayerCache.forEach((event) => pushToDataLayer(event))

  delete window.dataLayerCache
}

const preparePayload = (payload?: AnyObject) => {
  const state = store.getState()
  const isLoggedIn = getIsLoggedIn(state)
  const isValidUser = getIsValidUser(state)
  const userSub = getUserSub(state)
  const tenant = getTenant()

  return {
    ...payload,
    requestSource: 'web',
    page: `${window.location.origin}${window.location.pathname}`,
    is_logged_in: isLoggedIn,
    is_valid_user: isValidUser,
    user_id: userSub,
    ...(tenant === Tenants.BLICK && { blickId: userSub }),
  }
}

const prepareBlickPayload = (payload?: AnyObject) => {
  const state = store.getState()
  const isLoggedIn = getIsLoggedIn(state)
  const userSub = getUserSub(state)

  return {
    ...payload,
    page: `${window.location.origin}${window.location.pathname}`,
    is_logged_in: isLoggedIn,
    blickId: userSub,
  }
}

const trackUserAction = (event: string, payload?: AnyObject) => {
  const modifiedPayload = preparePayload(payload)
  pushToDataLayer({ ...modifiedPayload, event })
  pushToDataLayer({ ...modifiedPayload, event: 'gaEvent', eventName: event })
}

const trackBlickUserAction = (event: string, payload: AnyObject) => {
  const modifiedPayload = prepareBlickPayload(payload)
  pushToBlickDataLayer({ event, ...modifiedPayload })
}

export const waitForConsentChange = () => {
  // wait for OneTrust to appear in window object
  let counter = 0
  const maxCheck = 100
  const interval = setInterval(() => {
    if (window.OneTrust) {
      window.OneTrust?.OnConsentChanged(addAllCachedEvents)
      clearInterval(interval)
    }

    if (counter >= maxCheck) {
      clearInterval(interval)
    }

    counter++
  }, 500)
}

export const trackAppInitialization = () => {
  const tenant = getTenant()
  const langAttr = document.documentElement.lang
  const lang = langAttr.split('-')[0]
  const countryCode = langAttr.split('-')[1]
  pushToDataLayer({
    brand_name: tenant,
    page_language: lang,
    page_country_code: countryCode,
    content_accessibility: 'free',
  })
}

// Redux trackers
export const trackPageView = () => trackUserAction('pageview')

export const trackUserFeedbackShown = () => {
  const payload: TUserFeedbackTrackingPayload = {
    eventCategory: 'userFeedback',
    eventAction: 'show',
    non_interactive: true,
  }
  trackUserAction('userFeedbackShown', payload)
}

export const trackUserFeedbackImpression = () => trackUserAction('userFeedbackImpression')

export const trackUserFeedbackSubmit = () => {
  const payload: TUserFeedbackTrackingPayload = {
    eventCategory: 'userFeedback',
    eventAction: 'submit',
  }
  trackUserAction('userFeedbackSubmit', payload)
}

export const trackUserFeedbackStarClick = () => {
  const payload: TUserFeedbackTrackingPayload = {
    eventCategory: 'userFeedback',
    eventAction: 'star click',
  }
  trackUserAction('userFeedbackStarClick', payload)
}

export const trackUserFeedbackClosed = () => {
  const payload: TUserFeedbackTrackingPayload = {
    eventCategory: 'userFeedback',
    eventAction: 'close',
  }
  trackUserAction('userFeedbackClosed', payload)
}

//Quiz tracking
export const trackQuizImpression = (hasLinkedArticle: boolean) => {
  trackUserAction('quiz_impression', { has_linked_article: hasLinkedArticle })
}

export const trackQuizWidgetImpression = (hasLinkedArticle: boolean) => {
  trackUserAction('quizWidget_impression', { has_linked_article: hasLinkedArticle })
}

export const trackQuizAnswer = (quizNumber: number) => {
  const payload: TTrackingPayload = {
    eventCategory: 'play game',
    eventAction: 'quiz_answer_click',
    eventLabel: String(quizNumber),
  }

  trackUserAction('quiz_answer_click', payload)
}

export const trackQuizTeaserClick = () => {
  trackUserAction('quiz_teaser_click')
}

export const trackQuizWidgetShow = (actionLabel: 'start' | 'end') => {
  const payload: TTrackingPayload = {
    eventCategory: 'play game',
    eventAction: 'quizWidget_show',
    eventLabel: actionLabel,
  }

  trackUserAction('quizWidget_show', payload)
}

export const trackQuizWidgetClick = (
  quizNumber: number | null,
  actionLabel: string | undefined,
  link: string | undefined,
) => {
  const payload: TTrackingPayload = {
    eventCategory: 'play game',
    eventAction: 'quizWidget_click',
    eventLabel: actionLabel ? actionLabel : 'quizWidget_click',
    position: quizNumber ? String(quizNumber) : '',
  }
  trackUserAction('quizWidget_click', payload)
  trackWidgetClick('quizWidget', quizNumber, actionLabel, link)
}

export const trackQuizWidgetOnetLogin = () => {
  window.dlApi?.uaEvent('log_in_and_play_clicked', 'quizes')
}

export const trackQuizWidgetAnswerOnetClick = (questionNumber: number) => {
  window.dlApi?.uaEvent('answer_clicked', 'quizes', { question_number: questionNumber })
}

export const trackQuizBettingInfoClick = (questionId: number) => {
  const payload: TTrackingPayload = {
    eventCategory: 'play game',
    eventAction: 'quiz_betting_info_click',
    eventLabel: String(questionId),
  }

  trackUserAction('quiz_betting_info_click', payload)
}

export const trackiQuizBettingRiskChoose = (actionLabel: string) => {
  const payload: TTrackingPayload = {
    eventCategory: 'play game',
    eventAction: 'risk_choose',
    eventLabel: actionLabel,
  }

  trackUserAction('risk_choose', payload)
}

export const trackBetWidgetShow = () => {
  const payload: TTrackingPayload = {
    eventCategory: 'play game',
    eventAction: 'bet_widget_show',
    eventLabel: '',
  }

  trackUserAction('bet_widget_show', payload)
}

export const trackBetWidgetClick = (actionLabel: string | undefined) => {
  const payload: TTrackingPayload = {
    eventCategory: 'play game',
    eventAction: 'bet_widget_click',
    eventLabel: 'bet_widget_click',
    position: '',
    button_label: actionLabel ? actionLabel : 'bet_widget_click',
  }

  trackUserAction('bet_widget_click', payload)
}

export const trackApplyPowerup = (powerupName: string, isActive: boolean) => {
  const payload: TTrackingPowerupsPayload = {
    eventCategory: 'booster',
    eventAction: 'booster_place',
    eventLabel: powerupName,
    isActive,
  }

  trackUserAction('booster_place', payload)
}

export const trackChangePowerup = (powerupName: string, isActive: boolean) => {
  const payload: TTrackingPowerupsPayload = {
    eventCategory: 'booster',
    eventAction: 'booster_change',
    eventLabel: powerupName,
    isActive,
  }

  trackUserAction('booster_change', payload)
}

// Manual trackers //
export const trackUserAuthStateUpdated = () => {
  // Not GA4 event, this sets the GTM variables to be used in all GA4 events
  const state = store.getState()
  const isLoggedIn = getIsLoggedIn(state)
  const isValidUser = getIsValidUser(state)
  const userSub = getUserSub(state)
  const tenant = getTenant()

  const payload = {
    event: 'auth_state_updated',
    page: `${window.location.origin}${window.location.pathname}`,
    is_logged_in: isLoggedIn,
    is_valid_user: isValidUser,
    user_id: userSub,
    ...(tenant === Tenants.BLICK && { blickId: userSub }),
  }

  pushToDataLayer(payload)
}

export const trackUserStartLogin = (loginMethod: string | null) => {
  const payload: TTrackingPayloadLogin = {
    eventCategory: 'user',
    eventAction: 'login',
    position: 'start',
    loginMethod: loginMethod || 'unknown',
  }

  trackUserAction('user_login', payload)
}

export const trackUserFinishedLogin = (loginMethod: string | null) => {
  const payload: TTrackingPayloadLogin = {
    eventCategory: 'user',
    eventAction: 'login',
    position: 'finish',
    loginMethod: loginMethod || 'unknown',
  }

  trackUserAction('user_login', payload)
  trackUserAction('user_login_success', { loginMethod })
}

export const trackOnClick = ({ componentName, targetName, userSub }: TClickData) => {
  const payload: TTrackingPayload = {
    eventCategory: 'click',
    eventAction: componentName,
    eventLabel: targetName,
  }
  trackUserAction('click', payload)
}

export const trackSpielregelClick = ({ targetName, userSub }: TClickData) => {
  const payload: TTrackingPayload = {
    eventCategory: 'spielregel',
    eventAction: targetName,
  }
  trackUserAction('spielregel', payload)
}

export const trackNavigationClick = (title: string, hash: string, userSub?: string) => {
  const page = `${window.location.origin}${window.location.pathname}`
  const eventLabel = `${page}${hash}`

  const payload: TTrackingPayload = {
    eventCategory: 'navigation',
    eventAction: title,
    eventLabel: eventLabel,
  }

  trackUserAction('navigation_click', payload)
}

//Leaderboard tracking
export const trackUserLeaderboardImpression = () => trackUserAction('userLeaderboardImpression')
export const trackGroupLeaderboardImpression = () => trackUserAction('groupLeaderboardImpression')

export const trackLeaderBoardClick = (userSub?: string) => {
  const eventAction = 'ranglist'

  const payload: TTrackingPayload = {
    eventCategory: 'ranglist',
    eventAction,
  }

  trackUserAction('ranglist', payload)
}

export const trackLeaderBoardSearch = () => {
  const payload: TTrackingPayload = {
    eventCategory: 'ranglist',
    eventAction: 'ranglist_search',
  }

  trackUserAction('ranglist_search', payload)
}

//Error tracking
export const trackErrorEvent = (error: Error | AxiosError) => {
  if (isBackendError(error) && error.response?.status === 401) {
    return
  }

  const payload: TTrackingPayload = {
    eventCategory: 'error message',
    eventAction: error?.name || 'unknown',
    eventLabel: error?.message,
  }

  trackUserAction('error_message', payload)
}

//Powerups tracking
export const trackAddPowerupDrawerOpen = (gameId: GameId, isActive: boolean) => {
  const payload: TTrackingPowerupsPayload = {
    eventCategory: 'booster',
    eventAction: 'booster_activate',
    eventLabel: gameId,
    isActive,
  }

  trackUserAction('booster_activate', payload)
}

export const trackPowerupInfoIconClick = (isActive: boolean) => {
  const payload: TTrackingPowerupsPayload = {
    eventCategory: 'booster',
    eventAction: 'booster_infoicon_click',
    eventLabel: 'info icon click',
    isActive,
  }

  trackUserAction('booster_infoicon_click', payload)
}

export const trackPowerupDiscardModalOpen = (isActive: boolean) => {
  const payload: TTrackingPowerupsPayload = {
    eventCategory: 'booster',
    eventAction: 'booster_message_show',
    eventLabel: isActive ? 'enable' : 'disable',
    isActive,
  }

  trackUserAction('booster_message_show', payload)
}

export const trackPowerupDiscardModalResponse = (buttonLabel: string, isActive: boolean) => {
  const payload: TTrackingPowerupsPayload = {
    eventCategory: 'booster',
    eventAction: 'booster_message_response',
    eventLabel: buttonLabel,
    isActive,
  }

  trackUserAction('booster_message_response', payload)
}

//Betting tracking

export const trackTop3ParticiantsGameImpression = () => trackUserAction('top3participantsImpression')

export const trackTop3ParticiantsGameClick = (buttonLabel: string | null, element: 'button' | 'tipp-section') => {
  const payload: TBettingGemeClickTrackingPayload = {
    button_label: buttonLabel,
    element,
  }

  trackUserAction('redirect_to_bet_click', payload)
}

//ScoreBet tracking
export const trackScoreBetStageChange = (stageName: string, position: TScoreBetStageChangePosition) => {
  const payload: TTrackingScoreBetStagePayload = {
    eventCategory: 'play game',
    eventAction: 'scorebet_stage_change',
    eventLabel: stageName,
    position,
  }

  trackUserAction('scorebet_stage_change', payload)
}

export const trackScoreBetWidgetShow = () => {
  const payload: TTrackingPayload = {
    eventCategory: 'play game',
    eventAction: 'scorebetwidget_show',
  }

  trackUserAction('scorebetwidget_show', payload)
}

export const trackScoreBetWidgetClick = (buttonLabel?: string, matchId?: number, userSub?: string) => {
  const payload: TTrackingPayload = {
    eventCategory: 'play game',
    eventAction: 'scorebetwidget_click',
    eventLabel: String(matchId),
    button_label: buttonLabel,
  }

  trackUserAction('scorebetwidget_click', payload)
}

// General widget tracking
export const trackWidgetShow = (iframeName: TIframeName, step: number) => {
  const payload = {
    eventAction: 'trophy_widget_show',
    iframe_id: undefined,
    iframe_name: iframeName,
    button_label: '',
    step,
    is_iframe: true,
  }

  trackBlickUserAction('trophy_widget_show', payload)
}

export const trackWidgetClick = (
  iframeName: TIframeName,
  step: number | null,
  buttonLabel: string | undefined,
  link: string | undefined,
) => {
  const payload = {
    eventAction: 'trophy_widget_click',
    iframe_id: undefined,
    iframe_name: iframeName,
    button_label: buttonLabel,
    step,
    is_iframe: true,
    ...(link && { link_url: link }),
  }

  trackBlickUserAction('trophy_widget_click', payload)
}

//External link
export const trackExternalLinkClick = (linkText: string, href: string) => {
  const payload: TTrackingPayload = {
    eventCategory: 'external link',
    eventAction: linkText,
    eventLabel: href,
  }

  trackUserAction('external_link', payload)
}

//EndOfSeason tracking
export const trackEndOfSeasonModalOpen = () => {
  const payload: TEndOfSeasonTrackingPayload = {
    eventCategory: 'endOfSeasonSummary',
    eventAction: 'shown',
  }

  trackUserAction('endOfSeasonSummary_impression', payload)
}

export const trackEndOfSeasonModalClick = (action: 'close' | 'next' | 'back', step: number) => {
  const payload: TEndOfSeasonTrackingPayload = {
    eventCategory: 'endOfSeasonSummary',
    eventAction: action,
    step,
  }

  trackUserAction('endOfSeasonSummary_click', payload)
}

export const trackEndOfSeasonModalShare = (shareType: ShareType, step: number) => {
  const payload: TEndOfSeasonTrackingPayload = {
    eventCategory: 'endOfSeasonSummary',
    eventAction: 'share',
    eventLabel: shareType,
    step,
  }

  trackUserAction('endOfSeasonSummary_share', payload)
}

//EndOdRound tracking
export const trackEndOfRoundModalOpen = () => {
  const payload: TEndOfSeasonTrackingPayload = {
    eventCategory: 'endOfRound',
    eventAction: 'shown',
  }

  trackUserAction('endOfRound_impression', payload)
}

export const trackEndOfRoundModalShare = (shareType: ShareType, step: number) => {
  const payload: TEndOfSeasonTrackingPayload = {
    eventCategory: 'endOfRound',
    eventAction: 'share',
    eventLabel: shareType,
    step,
  }

  trackUserAction('endOfRound_share', payload)
}

export const trackEndOfRoundModalClick = (action: 'close' | 'next' | 'back', step: number) => {
  const payload: TEndOfSeasonTrackingPayload = {
    eventCategory: 'endOfRound',
    eventAction: action,
    step,
  }

  trackUserAction('endOfRound_click', payload)
}

//JoinLink Modal tracking
export const trackJoinLinkModalShow = () => {
  const payload: TTrackingPayload = {
    eventCategory: 'joinLink',
    eventAction: 'show',
  }

  trackUserAction('joinlink_modal_show', payload)
}

export const trackJoinLinkModalOnAccept = () => {
  const payload: TTrackingPayload = {
    eventCategory: 'joinLink',
    eventAction: 'accept',
  }

  trackUserAction('joinlink_modal_accept', payload)
}

export const trackJoinLinkModalOnDecline = () => {
  const payload: TTrackingPayload = {
    eventCategory: 'joinLink',
    eventAction: 'decline',
  }

  trackUserAction('joinlink_modal_decline', payload)
}

export const trackTop3ParticipantsClick = (buttonLabel: string, method?: string) => {
  const payload: TTop3ParticipantsTrackingPayload = {
    button_label: buttonLabel,
    element: 'top3_participants',
    ...(method && { method }),
  }

  trackUserAction('element_click', payload)
}

export const trackGroupBannerImpression = () => trackUserAction('userGroupBannerImpression')

export const trackUserGroupClick = (buttonLabel: string, method: string | undefined, position: string | undefined) => {
  const payload: TUserGroupClickTrackingPayload = {
    button_label: buttonLabel,
    method,
    position,
    element: 'user_group',
  }

  trackUserAction('element_click', payload)
}

export const trackUserIconClick = (buttonLabel: string, method: string | undefined, position: string | undefined) => {
  const payload: TUserIconClickTrackingPayload = {
    button_label: buttonLabel,
    method,
    position,
    element: 'user_icon',
  }

  trackUserAction('element_click', payload)
}
