const ANIMATION_BASE_CLASSNAME = 'drawer'

export const ANIMATION_DURATION = 300

export const ANIMATION_CLASSNAMES = {
  ENTER: `.${ANIMATION_BASE_CLASSNAME}-enter`,
  ENTER_ACTIVE: `.${ANIMATION_BASE_CLASSNAME}-enter-active`,
  ENTER_DONE: `.${ANIMATION_BASE_CLASSNAME}-enter-done`,
  EXIT: `.${ANIMATION_BASE_CLASSNAME}-exit`,
  EXIT_ACTIVE: `.${ANIMATION_BASE_CLASSNAME}-exit-active`,
  EXIT_DONE: `.${ANIMATION_BASE_CLASSNAME}-exit-done`,
}

export const DRAWER_WIDTH = 407
export const DRAWER_MOBILE_TOP_OFFSET = 24

export const DRAWER_INDEX = {
  USER_DASHBOARD: 'user-dashboard',
  GROUP_DASHBOARD: 'group-dashboard',
  ADD_POWERUP: 'add-powerup',
  MY_POWERUPS: 'my-powerups',
  DETAILED_GROUP_OVERVIEW: 'detailed-group-overview',
  GROUP_NAME: 'group-name',
  INVITE_PLAYERS: 'invite-players',
  GROUP_DESCRIPTION: 'group-description',
  SUCCESS_GROUP_CREATE: 'success-group-create',
  REQUESTS_TO_JOIN: 'requests-to-join',
  LIBERTATEA_MOBILE_MENU: 'libetatea-mobile-menu',
}
