import { TGroupMemberWithPoints, TGroupWithId } from './types'

export const getGroupMembersAfterUserDelete = (group: TGroupWithId, userName: string) => {
  const userToDelete = group?.members.find((item) => item.username === userName)
  const isUserToDeleteHasPoints = !!(userToDelete?.points && userToDelete.points > 0)

  let newGroupMembers = group.members
  if (isUserToDeleteHasPoints) {
    newGroupMembers = group.members.map((member) => {
      if (member.username === userName) {
        return { ...member, isCurrentMember: false }
      }
      return member
    })
  } else {
    newGroupMembers = group.members.filter((item) => item.username !== userName)
  }
  sortGroupMembers(newGroupMembers)
  return newGroupMembers
}

export const sortGroupMembers = (groupMembers: TGroupMemberWithPoints[]) => {
  groupMembers.sort((memberA, _) => (memberA.isCurrentMember ? -1 : 1))
}
