import { defaultsDeep } from 'lodash-es'

import { getPowerupTranslationsDescriptionKey } from 'src/utils/powerups'
import { PowerupType } from 'src/api/powerups/enums'
import { powerupsTranslations, TPowerupsTranslations } from 'src/translations/de/default/powerups'

export const wmtrophyPowerupsTranslations: TPowerupsTranslations = defaultsDeep({}, powerupsTranslations)

wmtrophyPowerupsTranslations.availablePowerups = (totalPowerupsLength: number, avalablePowerupsLength: number) =>
  `Du hast ${avalablePowerupsLength} von ${totalPowerupsLength} Boostern bis zum Ende dieser Runde zur Verfügung.`
wmtrophyPowerupsTranslations.noAvailablePowerups = (
  <>
    Du hast leider schon alle Booster der aktuellen Runde verbraucht.
    <br />
    <br />
    Neue Booster gibt es wieder in der nächsten Runde.
  </>
)
wmtrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.SwissParticipant)] =
  'Wenn die Schweiz in diesem Spiel gewinnt, erhältst du die dreifache Punktzahl.'
wmtrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.DoubleOrNothing)] =
  'Erhalte die doppelte Punktzahl, wenn du richtig tippst. Falls du daneben liegst, kriegst du leider auch keinen Trostpunkt.'
wmtrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.Always12Points)] =
  'Du erhältst garantiert 12 Punkte. Ganz egal, ob du richtig oder falsch getippt hast.'
wmtrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.OneForAll)] =
  'Wenn du auf den richtigen Gewinner tippst, kriegst du 3 Zusatzpunkte. Falls du zudem einem Team angehörst, erhält dein Team 6 Punkte zusätzlich.'
