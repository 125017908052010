import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { bluefrostColors } from 'src/theme/themes/bluefrost/colors'
import { TQuizStyle } from 'src/theme/themes/default/components/quiz'

const quiz: TQuizStyle = defaultsDeep({}, defaultTheme.components.Quiz)

quiz.background = bluefrostColors.blueLight1
quiz.padding = '32px 0px 0px 0px'
quiz.wrapperMargin = '0'
quiz.innerWrapperPadding = '16px'
quiz.innerWrapperPaddingMobile = '8px'
quiz.indicatorPadding = '0'
quiz.indicatorPaddingMobile = '0'
quiz.indicatorTextStyle = `
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
`
quiz.indicatorTextStyleMobile = quiz.indicatorTextStyle
quiz.titleTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    font-family: ${defaultTheme.fonts.sourceSansPro};
`
quiz.titleTextStyleMobile = quiz.titleTextStyle
quiz.titleTextAlign = 'left'
quiz.titleMargin = '0 0 32px 0'
quiz.titleMarginMobile = '0 0 32px 0'
quiz.answersWrapperPadding = '0'
quiz.questionsWrapperPadding = '16px'
quiz.questionsWrapperPaddingMobile = '8px'
quiz.answersWrapperPaddingMobile = quiz.answersWrapperPadding
quiz.answerJustifyContent = 'flex-start'
quiz.answerBoxShadow = '0px 1px 4px 1px rgba(0, 0, 0, 0.25)'
quiz.answerMinHeight = '52px'
quiz.answerMinHeightMobile = '52px'
quiz.answerPadding = '0 64px 0 24px'
quiz.answerPaddingMobile = '0 64px 0 16px'
quiz.answerTextStyle = `
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
`
quiz.answerTextStyleMobile = quiz.answerTextStyle
quiz.subTitleTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
`
quiz.resultsTitleMargin = '0 0 16px 0'
quiz.resultsTitleMarginMobile = '0 0 16px 0'
quiz.resultWrapperAlign = 'left'
quiz.resultsTitleTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
`
quiz.resultsTitleTextStyleMobile = quiz.resultsTitleTextStyle
quiz.subTitleTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
`
quiz.subTitleTextStyleMobile = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
`
quiz.resultTableBackground = defaultTheme.colors.shades.white
quiz.resultWrapperPadding = '12px'
quiz.resultWrapperPaddingMobile = '8px'
quiz.resultsQuestionsIndicatorTextStyle = `
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
`
quiz.resultTableMargin = '32px 0 16px 0'
quiz.resultTableMarginMobile = '24px 0 16px 0'
quiz.resultTableHeadRowHeight = '34px'
quiz.resultTableHeadCellTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-size: 14px;
    line-height: 28px;
    color: ${defaultTheme.colors.blick.grey100};
    &:last-child {
        font-weight: 700;
    }
`
quiz.resultTableRowHeight = '39px'
quiz.resultTableCellTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-size: 18px;
    line-height: 23px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.resultTableCellPadding = '0 16px'
quiz.resultTableCellFirstChildPadding = '0 0 0 16px'
quiz.resultTableCellLastChildPadding = '0 16px 0 0'
quiz.resultTableCellPaddingMobile = '0 8px'
quiz.progressBarWidth = '210px'
quiz.progressBarPosition = 'absolute'
quiz.progressBarTextStyle = `
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
`

export const bluefrostQuiz = quiz
