export enum HttpErrorMessage {
  AlreadyMemberOfGroup = 'already_member_of_a_group',
  CurrentUserMemberOfGroup = 'current_user_already_member_of_a_group',
  EventHasResult = 'event_already_has_result',
  RaceHasResults = 'race_already_has_results',
  RaceBetsOrResultsExist = 'race_bets_or_results_exist',
  BettingClosed = 'betting_closed',
  IdInvalid = 'id_invalid',
  InvalidProduct = 'invalid_product',
  InvalidTeam = 'invalid_team',
  InvitationNotFound = 'invitation_not_found',
  MatchHasBetsOrOutcomes = 'match_already_has_associated_bets_or_outcomes',
  NoActiveEvent = 'no_active_event',
  NoEventForThisSeason = 'no_event_for_specified_season',
  DoNotParticipateInCurrentSeason = 'team_or_participant_do_not_participate_in_current_season',
  GroupDoesNotExist = 'group_does_not_exist',
  MaximumUsesOfJoinLink = 'maximum_uses_of_group_join_link_reached',
  NoJoinRequestsFound = 'no_join_request_found',
  NoSuchQuestion = 'no_such_question_for_user',
  NotLastQuestion = 'not_last_question',
  QuestionIsLinked = 'question_is_linked',
  QuestionOptionDoesNotExist = 'question_option_does_not_exist',
  UserNotMember = 'user_is_not_member',
  EventWithProvidedIdDoesNotExist = 'event_with_provided_id_does_not_exist',
  MatchDoesNotExist = 'match_does_not_exist.',
  NoPermissionToAcceptUserJoinRequest = 'no_permission_to_accept_user_join_request',
  NoPermissionToCreateGroupJoinLink = 'no_permission_to_create_group_join_link',
  NoPermissionsToDeclineUserJoinRequest = 'no_permission_to_decline_user_join_request',
  NoPermissionToDeleteJoinRequest = 'no_permission_to_delete_join_request_of_another_user',
  AlreadyExists = 'already_exists',
  MatchAlreadyHasResult = 'match_already_has_result',
  NoMoreQuestions = 'no_more_questions',
  RaceDoesNotExist = 'race_does_not_exist',
  TeamDoesNotExist = 'team_does_not_exist',
  QuestionDoesNotExist = 'question_does_not_exist',
  UserDoesNotExist = 'user_does_not_exist',
  NoPermissions = 'no_permissions',
  GroupFull = 'group_full',
  UserNameNotFound = 'username_not_found',
  ProductDoesNotExist = 'product_does_not_exist',
  RoundDoesNotExist = 'round_does_not_exist',
  GroupNotFound = 'group_not_found',
  SeasonDoesNotExist = 'season_does_not_exist',
}
