import { Theme } from 'src/theme/styled'
import { defaultsDeep } from 'lodash-es'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { ThemeNames } from 'src/theme/enums'
import { accentwayQuiz } from './components/quiz'
import { accentwayCommon } from './components/common'
import { accentwayLeaderboard } from './components/leaderboard'
import { accentwayButton } from './components/button'
import { accentwayTab } from './components/tab'
import { accentwayCollapsible } from './components/collapsible'
import { accentwayScoreBet } from './components/scoreBet'
import { accentwayInfoSection } from './components/infoSection'
import { accentwayRules } from './components/rules'
import { accentwayHero } from './components/hero'
import { accentwaySummary } from './components/summary'
import { accentwayEndOfSeasonModal } from './components/endOfSeasonModal'
import { accentwayFeedback } from './components/feedback'

const theme: Theme = defaultsDeep({}, defaultTheme)

theme.name = ThemeNames.ACCENTWAY

theme.components.common = accentwayCommon
theme.components.Quiz = accentwayQuiz
theme.components.Leaderboard = accentwayLeaderboard
theme.components.Button = accentwayButton
theme.components.Tab = accentwayTab
theme.components.Collapsible = accentwayCollapsible
theme.components.ScoreBet = accentwayScoreBet
theme.components.InfoSection = accentwayInfoSection
theme.components.Rules = accentwayRules
theme.components.Hero = accentwayHero
theme.components.Summary = accentwaySummary
theme.components.EndOfSeasonModal = accentwayEndOfSeasonModal
theme.components.Feedback = accentwayFeedback

export const accentway = theme
