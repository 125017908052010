import { TButtonStyle } from 'src/theme/themes/default/components/button'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'

const button: TButtonStyle = defaultsDeep({}, defaultTheme.components.Button)
button.componentTheme.primary.background = defaultTheme.colors.blick.grey100
button.componentTheme.primary.hover.background = defaultTheme.colors.blick.grey80
button.componentTheme.underline.fontFamily = defaultTheme.fonts.sourceSansPro
button.componentTheme.underline.fontSize = '16px'
button.componentTheme.underline.color = defaultTheme.colors.blick.grey80
button.componentTheme.underline.textTransform = 'none'

export const accentwayButton = button
