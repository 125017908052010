export enum ActionTypes {
  FETCH_CHAMPIONS = '@CHAMPIONS/FETCH_CHAMPIONS',
  FETCH_CHAMPIONS_SUCCESS = '@CHAMPIONS/FETCH_CHAMPIONS_SUCCESS',
  FETCH_CHAMPIONS_FAILURE = '@CHAMPIONS/FETCH_CHAMPIONS_FAILURE',

  PLACE_CHAMPIONS_BET = '@CHAMPIONS/PLACE_CHAMPIONS_BET',
  PLACE_CHAMPIONS_BET_SUCCESS = '@CHAMPIONS/PLACE_CHAMPIONS_BET_SUCCESS',
  PLACE_CHAMPIONS_BET_FAILURE = '@CHAMPIONS/PLACE_CHAMPIONS_BET_FAILURE',

  RESET_CHAMPIONS_BET = '@CHAMPIONS/RESET_CHAMPIONS_BET',
}

export enum ChampionsBetVariety {
  ChampionBet = 'championBet',
  TopScorerBet = 'topScorerBet',
  GoalieBet = 'goalieBet',
  BadBoyBet = 'badBoyBet',
}
