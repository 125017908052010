export enum ActionTypes {
  FETCH_POWERUPS = '@POWERUPS/FETCH_POWERUPS',
  FETCH_POWERUPS_SUCCESS = '@POWERUPS/FETCH_POWERUPS_SUCCESS',
  FETCH_POWERUPS_FAILURE = '@POWERUPS/FETCH_POWERUPS_FAILURE',

  APPLY_POWERUPS = '@POWERUPS/APPLY_POWERUPS',
  APPLY_POWERUPS_SUCCESS = '@POWERUPS/APPLY_POWERUPS_SUCCESS',
  APPLY_POWERUPS_FAILURE = '@POWERUPS/APPLY_POWERUPS_FAILURE',

  SET_PREVIOUSLY_SELECTED_POWERUP = '@POWERUPS/SET_PREVIOUSLY_SELECTED_POWERUP',

  SELECT_POWERUP = '@POWERUPS/SELECT_POWERUP',
  DESELECT_POWERUP = '@POWERUPS/DESELECT_POWERUP',

  OPEN_DISCARD_MODAL = '@POWERUPS/OPEN_DISCARD_MODAL',
  CLOSE_DISCARD_MODAL = '@POWERUPS/CLOSE_DISCARD_MODAL',

  RESET_POWERUPS = '@POWERUPS/RESET_POWERUPS',
}

export enum PowerupState {
  Upcoming = 'upcoming',
  Available = 'available',
  Expired = 'expired',
}

export enum PowerupType {
  DoubleOrNothing = 'doubleornothing',
  Insurance = 'insurance',
  Double = 'double',
  AlwaysSixPoints = 'alwayssixpoints',
  PlusOne = 'plusone',
  SwissParticipant = 'swissparticipant',
  OneForAll = 'oneforall',
  AlwaysEighteenPoints = 'alwayseighteenpoints',
  Always12Points = 'always12points',
  AlwaysFullPoints = 'alwaysfullpoints',
  AtLeastOneCorrect = 'atleastonecorrect',
}
