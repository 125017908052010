import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { greenfieldColors } from 'src/theme/themes/greenfield/colors'
import { TScoreBetStyle } from 'src/theme/themes/default/components/scoreBet'

const scoreBet: TScoreBetStyle = defaultsDeep({}, defaultTheme.components.ScoreBet)

scoreBet.matchCardBackgroundColor = greenfieldColors.green5
scoreBet.successBorderColor = greenfieldColors.green55
scoreBet.successBackgroundColor = greenfieldColors.green5
scoreBet.successColor = greenfieldColors.green55
scoreBet.powerupButtonBg = greenfieldColors.intenseGreen

export const greenfieldScoreBet = scoreBet
