import { Path } from './enums'

export const NO_NAVIGATION_PATHS = [
  Path.EndOfRoundGroupSummary,
  Path.EndOfRoundSummary,
  Path.EndOfSeasonGroupSummary,
  Path.EndOfSeasonSummary,
]

export const WIDGET_PATHS = [Path.QuizWidget, Path.ScorebetWidget, Path.MatchbetWidget]
