export type TNavigationLinksTranslations = typeof navigationLinksTranslations

export const navigationLinksTranslations = {
  hero: 'Intro',
  infoBlock: 'Start',
  rules: 'Spielregeln',
  prizes: 'Preise',
  leaderboard: 'Rangliste',
  leaderboardrounded: 'Rangliste',
  leaderboardgroup: 'Tippgruppe',
  champions: 'Bonusfragen vor WM-Start',
  medalcount: 'WM-Special',
  top3participants: 'Tippspiel',
  matchbet: 'Tippspiel',
  quiz: 'Tagesquiz',
  quizbetting: 'Tagesquiz',
  scorebet: 'Tippspiel',
  backButton: 'Zurück',
}
