import { TLeaderboardTranslations } from 'src/translations/de/default/leaderboard'

export const leaderboardTranslations: TLeaderboardTranslations = {
  title: 'Ranking',
  titleGroup: '',
  titleGroupName: (groupName: string) => '',
  description: '',
  descriptionGroup: '',
  leaderboard_page_button: 'Pokaż pełną listę rankingową',
  leaderboard_group_page_button: '',
  leaderboard_round: 'Runda',
  fallbackText: 'Nie ma jeszcze graczy',
  headerMobileTitle: 'Ranking',
  headerDesktopTitle: 'Ranking',
  searchPlaceholder: 'Szukaj gracza',
  searchGroupPlaceholder: '',
  rank: 'Miejsce',
  userName: 'Nazwa',
  userNameShort: 'Nazwa',

  headerDesktopTitleGroup: '',
  headerMobileTitleGroup: '',
  teamName: '',
  total: 'Suma',
  groupTotal: 'Suma',
  gained: 'Dzisiaj',
  groupGained: 'Dzisiaj',
  more: 'Pokaż więcej',
}
