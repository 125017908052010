import { addBreadcrumb } from '@sentry/browser'
import { config } from 'src/config'

import { trackError } from './trackError'
import { BreadcrumbData } from './types'

// see the docs for all available properties
// https://docs.sentry.io/enriching-error-data/breadcrumbs/?platform=browser
export const trackBreadcrumb = (breadcrumbData: BreadcrumbData) => {
  if (config.tracking.isEnabled) {
    const breadcrumbModifiedData = {
      ...breadcrumbData,
      data: {
        ...breadcrumbData.data,
        env: process.env.REACT_APP_ENV,
      },
    }

    try {
      addBreadcrumb({
        level: 'info',
        ...breadcrumbModifiedData,
      })
    } catch (err) {
      const { category, message } = breadcrumbData

      trackError(`Failed to add breadcrumb '${category}: ${message}'`)
    }
  }
}
