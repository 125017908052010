import { TLeaderboardTranslations } from 'src/translations/de/default/leaderboard'

export const leaderboardTranslations: TLeaderboardTranslations = {
  title: 'Ranking',
  titleGroup: '',
  titleGroupName: (groupName: string) => '',
  description: '',
  descriptionGroup: '',
  leaderboard_page_button: 'Show full ranking list',
  leaderboard_group_page_button: '',
  leaderboard_round: 'Round',
  fallbackText: 'There are no players yet',
  headerMobileTitle: 'Ranking',
  headerMobileTitleGroup: 'Team Ranking',
  headerDesktopTitle: 'Ranking',
  headerDesktopTitleGroup: 'Team Ranking',
  searchPlaceholder: 'Search for player',
  searchGroupPlaceholder: 'Search for group',
  rank: 'Rank',
  userName: 'Name',
  userNameShort: 'Name',
  teamName: 'Teamname',
  total: 'Total',
  groupTotal: 'Total',
  gained: '',
  groupGained: '',
  more: '',
}
