import dayjs from 'dayjs'
import { PowerupState, PowerupType } from 'src/api/powerups/enums'
import { TPowerup } from 'src/api/powerups/types'
import { GameId } from 'src/enums/other'
import { AddPowerupsStatus } from 'src/enums/powerups'
import { TTranslations } from 'src/translations/types'

export const getExpiredPowerupOnEvent = (powerups: TPowerup[], eventId?: number) => {
  return powerups.find(
    (powerup) => powerup.state === PowerupState.Expired && !!(eventId && eventId === powerup.eventId),
  )
}

export const getPowerupsToShow = ({
  powerups,
  state,
  gameId,
  eventId,
  showAssignedInAnyState,
}: {
  powerups: TPowerup[]
  state: PowerupState[]
  gameId?: GameId
  eventId?: number
  showAssignedInAnyState?: boolean
}) => {
  const filteredPowerups = powerups.filter((powerup) => {
    const isPowerupIsUsedForCurrentEventId = !!(eventId && eventId === powerup.eventId)

    const isPowerupAssignedForAnyBet = !!powerup.eventId

    const powerupGameIdFilter = gameId ? powerup.gameId === null || powerup.gameId === gameId : true
    const powerupEventIdFilter = eventId ? powerup.eventId === null || powerup.eventId === eventId : true
    const powerupStateFilter = state.includes(powerup.state)

    return (
      (powerupEventIdFilter && powerupGameIdFilter && powerupStateFilter) ||
      isPowerupIsUsedForCurrentEventId ||
      (showAssignedInAnyState && isPowerupAssignedForAnyBet)
    )
  })

  filteredPowerups.sort((a, b) => {
    const aDate = dayjs(a.availableAt)
    const bDate = dayjs(b.availableAt)
    return aDate.isBefore(bDate) ? -1 : 1
  })

  const expiredPowerupOntEvent = getExpiredPowerupOnEvent(filteredPowerups, eventId)

  return expiredPowerupOntEvent ? [expiredPowerupOntEvent] : filteredPowerups
}

export const getUnusedPowerups = (powerups: TPowerup[]) => {
  return powerups.filter((powerup) => !powerup.eventId && powerup.state === PowerupState.Available)
}

export const getAvailablePowerups = (powerups: TPowerup[]) => {
  return powerups.filter((powerup) => powerup.state === PowerupState.Available)
}

export const getAddPowerupStatus = (previouslySelectedPowerup: number | null, selectedPowerup: number | null) => {
  if (previouslySelectedPowerup === selectedPowerup) {
    return null
  }
  if (previouslySelectedPowerup && !selectedPowerup) {
    return AddPowerupsStatus.Disable
  }
  if (!previouslySelectedPowerup && selectedPowerup) {
    return AddPowerupsStatus.Enable
  }
  return AddPowerupsStatus.Change
}

export const getPowerupTranslationsTitleKey = (powerupType: PowerupType) => `title${powerupType}`
export const getPowerupTitle = (powerupType: PowerupType, translations: TTranslations) => {
  return (translations.powerups[getPowerupTranslationsTitleKey(powerupType)] || 'Unknown powerup') as string
}

export const getPowerupTranslationsDescriptionKey = (powerupType: PowerupType) => `description${powerupType}`
export const getPowerupDescription = (powerupType: PowerupType, translations: TTranslations) => {
  return (translations.powerups[getPowerupTranslationsDescriptionKey(powerupType)] || 'Unknown powerup') as string
}

export const pointsToString = (incomingPoints: number) => {
  if (incomingPoints >= 0) {
    return String(incomingPoints)
  }
  return incomingPoints.toString().split('').join(' ')
}
