import { defaultsDeep } from 'lodash-es'
import { summaryTranslations, TSummaryTranslations } from 'src/translations/de/default/summary'

export const schwingenSummaryTranslations: TSummaryTranslations = defaultsDeep({}, summaryTranslations)

schwingenSummaryTranslations.title = (userRank?: number) => {
  if (userRank) {
    return <>Du hast dich auf Platz {userRank} der Rangliste gekämpft!</>
  }
  return <>Schade, dass du nicht mitgespielt hast. Vielleicht beim nächsten Mal?</>
}
schwingenSummaryTranslations.subscriptionTitle =
  'Die Blick Schwingen Trophy ist leider vorbei. Schon bald aber gibt es neue Spiele!'
schwingenSummaryTranslations.subscriptionText = 'Erhalte eine E-Mail sobald es neue Spiele gibt!'
