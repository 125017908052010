import { isEmpty } from 'lodash-es'
import { Tenants } from 'src/enums/tenants'

import { TReduxState } from 'src/redux/store'
import { getTenant } from 'src/utils/tenant'
import { getCookie } from 'src/utils/cookie'
import { config } from 'src/config'

export const getUserNickName = (state: TReduxState) => state.auth.user?.nickname
export const getUserNicknameUpdateIsLoading = (state: TReduxState) => state.auth.updateNickname.isLoading
export const getUserNicknameUpdateIsResolved = (state: TReduxState) => state.auth.updateNickname.isResolved
export const getUserNicknameUpdateError = (state: TReduxState) => state.auth.updateNickname.error
export const getUserId = (state: TReduxState) => state.auth.user?.id
export const getUserMarketingOptin = (state: TReduxState) => !!state.auth.user?.marketingOptin
export const getUserAvatarUrl = (state: TReduxState) => state.auth.user?.avatarUrl

export const getUserFirstName = (state: TReduxState) => state.auth.user?.firstName
export const getUserLastName = (state: TReduxState) => state.auth.user?.lastName

export const getIsAuthResolved = (state: TReduxState): boolean => state.auth.authResolved
export const getDidReturnFromLogin = (state: TReduxState) => state.auth.didReturnFromLogin
export const getLoginMethod = (state: TReduxState) => state.auth.loginMethod
export const getIsLoggedIn = (state: TReduxState): boolean => state.auth.loggedIn
export const getAuthLoading = (state: TReduxState): boolean => state.auth.isLoading
export const getIsAgreementsSigned = (state: TReduxState): boolean => !!state.auth.user?.gamePlatformAgreementSigned
export const getIsValidUser = (state: TReduxState) => {
  const nickname = getUserNickName(state)
  const isAgreementsSigned = getIsAgreementsSigned(state)
  return !isEmpty(nickname) && isAgreementsSigned
}

export const getShouldCacheGtmEvents = () => {
  const tenant = getTenant()
  if (config.isLocalhost) return false
  if (tenant === Tenants.BLICK) {
    return null === getCookie('OptanonAlertBoxClosed')
  } else {
    return false
  }
}

export const getShouldShowNicknameModal = (state: TReduxState): boolean => state.auth.showNicknameModal

export const getUserSub = (state: TReduxState) => {
  const tenant = getTenant()
  return (
    (tenant === Tenants.BLICK
      ? state.auth.metaData?.sub //blickId
      : state.auth.user?.sub) || null
  )
}
