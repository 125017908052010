import { defaultsDeep } from 'lodash-es'
import { ExternalLink } from 'src/components/links/ExternalLink'

import { quizTranslations, TQuizTranslations } from 'src/translations/de/default/quiz'

export const frauenwmQuizTranslations: TQuizTranslations = defaultsDeep({}, quizTranslations)

frauenwmQuizTranslations.title = 'Teste dein Wissen im täglichen Quiz'
frauenwmQuizTranslations.description = (
  <>
    Beantworte <b key="bold-1">täglich drei neue Fragen</b> und erhalte <b key="bold-2">drei Punkte</b> für jede
    richtige Antwort.
  </>
)
frauenwmQuizTranslations.blickButtonText = 'Blick-WM-News'
frauenwmQuizTranslations.seasonEndCardTitle = 'Die Fussball WM Trophy ist beendet - Danke fürs Mitspielen!'
frauenwmQuizTranslations.seasonEndCardText = (
  <>
    Wie war die Trophy für dich? Lass es uns gerne wissen und sende eine kurze Mail an{' '}
    <ExternalLink href="mailto:community@blick.ch">community@blick.ch</ExternalLink>.
  </>
)
frauenwmQuizTranslations.seasonEndCardButtonText = ''
