import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TToastStyle } from 'src/theme/themes/default/components/toast'

const toast: TToastStyle = defaultsDeep({}, defaultTheme.components.Toast)

toast.defaultBorderRadius = '4px'
toast.successColor = defaultTheme.colors.texts.inverted
toast.warningBackgroundColor = defaultTheme.colors.food.food60
toast.warningBorderColor = defaultTheme.colors.food.food60
toast.warningColor = defaultTheme.colors.texts.inverted
toast.errorBackgroundColor = defaultTheme.colors.feedback.negative
toast.errorBorderColor = defaultTheme.colors.feedback.negative
toast.errorColor = defaultTheme.colors.texts.inverted
toast.defaultColor = defaultTheme.colors.blick.grey100
toast.defaultBackgroundColor = defaultTheme.colors.blick.grey15
toast.defaultBorderColor = defaultTheme.colors.blick.grey15

export const bluefrostToast = toast
