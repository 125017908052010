import { Reducer } from 'redux'

import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { TUsersState, TActions } from './types'
import { ActionTypes } from './enums'

export const initialState: TUsersState = {
  data: {
    users: [],
  },
  loading: {
    fetchUsers: false,
  },
  error: {
    fetchUsers: null,
  },
  resolved: {
    fetchUsers: false,
  },
}

export const reducer: Reducer<TUsersState, TActions> = (state = initialState, action): TUsersState => {
  switch (action.type) {
    case ActionTypes.FETCH_USERS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchUsers'),
      }
    }
    case ActionTypes.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          users: action.payload,
        },
        ...getReduxSuccessStates(state, 'fetchUsers'),
      }
    }
    case ActionTypes.FETCH_USERS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchUsers', action.payload),
      }
    }

    default:
      return state
  }
}
