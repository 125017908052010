import { useMemo, useEffect } from 'react'

export const useResizeObserver = (
  ref: React.RefObject<HTMLElement>,
  onResize: (entries: ResizeObserverEntry[]) => void,
): void => {
  const observer = useMemo(() => {
    return new ResizeObserver((entries) => onResize(entries))
  }, [onResize])

  useEffect(() => {
    if (ref.current) {
      const node = ref.current

      observer.observe(node)

      return () => {
        observer.unobserve(node)
      }
    }

    return undefined
  }, [observer, ref])
}
