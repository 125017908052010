import { defaultsDeep } from 'lodash-es'

import { scoreBetTranslations, TScoreBetTranslations } from 'src/translations/de/default/scorebet'

export const frauenwmScoreBetTranslations: TScoreBetTranslations = defaultsDeep({}, scoreBetTranslations)

frauenwmScoreBetTranslations.title = 'Tippe auf die WM-Spiele'
frauenwmScoreBetTranslations.description = (
  <>
    Du erhältst <b>12 Punkte</b> für das exakte Resultat oder <b>6 Punkte</b>, wenn du den Sieger oder das Unentschieden
    richtig tippst. Zudem hast du eine begrenzte Anzahl Booster pro Runde zur Verfügung, um deine Punktzahl zu erhöhen.
    Falls du daneben liegst, kriegst du einen Trostpunkt fürs Mitspielen.
  </>
)
