import { defaultsDeep } from 'lodash-es'
import { InternalLink } from 'src/components/links/InternalLink'

import { scoreBetTranslations, TScoreBetTranslations } from 'src/translations/de/default/scorebet'

export const wmtrophyScoreBetTranslations: TScoreBetTranslations = defaultsDeep({}, scoreBetTranslations)

wmtrophyScoreBetTranslations.description = (
  <>
    Du erhältst <b>12 Punkte</b> für das exakte Resultat oder <b>6 Punkte</b>, wenn du den Sieger oder das Unentschieden
    richtig tippst. Zudem hast du eine begrenzte Anzahl von Boostern pro Runde zur Verfügung, um deine Punktzahl zu
    erhöhen. Falls du daneben liegst, kriegst du einen Trostpunkt fürs Mitspielen.{' '}
    <InternalLink smooth to={'#spielregeln'}>
      Mehr Infos zu den Tipp-Regeln.
    </InternalLink>
  </>
)

wmtrophyScoreBetTranslations.title = 'Tippe auf die WM-Spiele'
