export const libertateaColors = {
  grey: '#D8D8D8',
  greyLight: '#EDEDEF',
  greyVeryLight: '#F5F5F7',
  greyVeryLightAlt: '#F7F7F7',
  ultraLightGrey: '#F8F8F8',
  greyDark: '#333333',
  black: '#000000',
  red: '#EA2700',
  redDark: '#C62100',
  errorRedMedium: '#FDB6A8',
  errorRedLight: '#FFF3F2',
  successGreen: '#62A01A',
  successGreenMedium: '#A9E29A',
  successGreenLight: '#EBF5F0',
}
