import { defaultsDeep } from 'lodash-es'
import { Bold } from 'src/components/Bold'
import { quizWidgetTranslations, TQuizWidgetTranslations } from 'src/translations/de/default/quizwidget'

export const skitrophyQuizWidgetTranslations: TQuizWidgetTranslations = defaultsDeep({}, quizWidgetTranslations)
skitrophyQuizWidgetTranslations.resultSummaryText = (
  points: number,
  correctAnswerCount: number,
  maxQuestionCount: number | undefined,
  pointsPerCorrectQuestion: number | undefined,
) => (
  <>
    {correctAnswerCount ? (
      <>
        Du hast die Frage richtig beantwortet und erhältst{' '}
        <Bold>{pointsPerCorrectQuestion ? correctAnswerCount * pointsPerCorrectQuestion : 0} Punkte.</Bold>
      </>
    ) : (
      <>
        Das gibt leider <Bold>keinen Punkt</Bold> für dich.
      </>
    )}
  </>
)
