import { defaultsDeep } from 'lodash-es'
import { InternalLink } from 'src/components/links/InternalLink'

import {
  endOfSeasonModalTranslations,
  TEndOfSeasonModalTranslations,
} from 'src/translations/de/default/endofseasonmodal'

export const skiTrophyEndOfSeasonModalTranslations: TEndOfSeasonModalTranslations = defaultsDeep(
  {},
  endOfSeasonModalTranslations,
)

skiTrophyEndOfSeasonModalTranslations.subtitle = 'So lief deine TROPHY'
skiTrophyEndOfSeasonModalTranslations.getSummarytitle = (userRank?: number, userName?: string) => {
  return <>Du hast dich auf Platz {userRank} der Rangliste gekämpft</>
}
skiTrophyEndOfSeasonModalTranslations.subscriptionText =
  'Möchtest du eine E-Mail erhalten, wenn es ein neues Spiel gibt?'
skiTrophyEndOfSeasonModalTranslations.feedbackSubscriptionText = 'Danke, fürs Anmelden!'
skiTrophyEndOfSeasonModalTranslations.feedbackTitle = 'Wie zufrieden warst du mit dieser Blick Trophy?'
skiTrophyEndOfSeasonModalTranslations.thanksTitle = 'Danke, dass du mitgemacht hast!'
skiTrophyEndOfSeasonModalTranslations.thanksText = (link: string) => (
  <>
    Auf <InternalLink to={link}>deiner Übersichtsseite</InternalLink> kannst du deine Resultate sehen oder dich für den
    Newsletter anmelden.
  </>
)
