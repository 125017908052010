import { StatisticksBlockStyles } from 'src/enums/summary'
import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'

export type TEndOfSeasonModalStyle = typeof defaultEndOfSeasonModal

export const defaultEndOfSeasonModal = {
  basicColor: colors.blick.grey100,
  basicFontWeight: 400,
  padding: '16px',
  backgroundColor: colors.blick.blueLight1,
  backgroundLinear: ``,
  backgroundLinearSubscribe: ``,
  backgroundImage: '',
  stepTitleText: `
    font-family: ${fonts.goodOffcPro};
    font-size: 22px;
    line-height: 25px;
  `,
  statisticsSubtitleBgColor: colors.blick.brand,
  statisticsSubtitleColor: colors.shades.white,
  statisticsBlockBgColor: colors.shades.white,
  statisticsBlockColor: colors.blick.grey100,
  statisticsBlockValueFontSize: '14px',
  statisticksBlockStyle: StatisticksBlockStyles.Flag,
  statisticksBlockIconHeight: '40px',
  messageAnimationWidth: '124px',
  messageAnimationWidthMobile: '124px',
  thanksAnimationWidthDesktop: '360px',
  thanksAnimationWidthMobile: '328px',
  feedbackStarColor: colors.blick.grey100,
  transparentButtonHover: '',
}
