import { defaultsDeep } from 'lodash-es'
import { summaryTranslations, TSummaryTranslations } from 'src/translations/de/default/summary'

export const skiTrophySummaryTranslations: TSummaryTranslations = defaultsDeep({}, summaryTranslations)

skiTrophySummaryTranslations.subtitle = () => <>Ski TROPHY</>
skiTrophySummaryTranslations.title = (userRank?: number, userName?: string) => {
  return (
    <>
      {userName} hat sich auf Platz {userRank} der Rangliste gekämpft
    </>
  )
}
skiTrophySummaryTranslations.subscriptionTitle =
  'Die Ski Trophy ist leider vorbei. Schon bald aber gibt es neue Spiele!'
skiTrophySummaryTranslations.subscriptionText = 'Erhalte eine E-Mail sobald es neue Spiele gibt!'
skiTrophySummaryTranslations.team = {
  title: (rank?: number, name?: string) => (
    <>
      Team {name} ist auf Rang {rank} gelandet
    </>
  ),
  subtitle: <>Ski TROPHY</>,
}
skiTrophySummaryTranslations.round.team = {
  title: (rank?: number, name?: string) => (
    <>
      {name} ist auf Rang {rank} gelandet
    </>
  ),
  subtitle: (dinamicText?: string) => <>Ski trophy im {dinamicText}</>,
}
