import { defaultsDeep } from 'lodash-es'

import { endOfRoundModalTranslations, TEndOfRoundModalTranslations } from 'src/translations/de/default/endofroundmodal'

export const skiTrophyEndOfRoundModalTranslations: TEndOfRoundModalTranslations = defaultsDeep(
  {},
  endOfRoundModalTranslations,
)

skiTrophyEndOfRoundModalTranslations.getSubtitle = (round: string) => `Ski TROPHY im ${round}`
skiTrophyEndOfRoundModalTranslations.getGroupStepTitle = (roundRank: number) =>
  `Deine Tippgruppe ist auf Rang ${roundRank} gelandet`
