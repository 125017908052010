export const firstLetter = (s?: string): string => {
  if (!s) return ''

  // There exists polyfill for Intl.Segmenter but it's large. A workaround for old browsers is enough.
  if (!Intl.Segmenter) {
    const [unicodeChar] = s
    return unicodeChar || ''
  }

  const [unicodeSegment] = new Intl.Segmenter().segment(s)
  return unicodeSegment?.segment || ''
}

export const sanitizeUrl = (url?: string, fallBackUrl?: string): string => {
  if (url) {
    return url.replace(/\/+$/, '')
  }
  return fallBackUrl ? fallBackUrl.replace(/\/+$/, '') : ''
}

export function paginate<T>(arr: Array<T>, limit: number): Array<T> {
  return arr.slice(0, limit)
}

export const stripHtml = (html: string) => {
  if (!html) {
    return
  }

  return html.replace(/(<([^>]+)>)/gi, '')
}

export const getRounded = (num: number | undefined) => num && Math.round(num * 10) / 10
