import { Reducer } from 'redux'

import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'

import { ActionTypes } from './enums'
import { ActionTypes as UserActionTypes } from 'src/api/user/enums'
import { TAddPointsOptimisticAction } from 'src/api/user/types'
import { TFetchProductAction } from 'src/api/product/types'
import { ActionTypes as ProductActionTypes } from 'src/api/product/enums'
import { TActions, TLeaderboardRoundedState } from './types'

export const initialState: TLeaderboardRoundedState = {
  data: {
    leaderboard: {
      leaderboardDetails: {
        users: [],
        groups: [],
      },
      rounds: [],
    },
  },
  loading: {
    fetchLeaderBoard: false,
  },
  error: {
    fetchLeaderBoard: null,
  },
  resolved: {
    fetchLeaderBoard: false,
  },
}

export const reducer: Reducer<TLeaderboardRoundedState, TActions | TFetchProductAction | TAddPointsOptimisticAction> = (
  state = initialState,
  action,
): TLeaderboardRoundedState => {
  switch (action.type) {
    case ActionTypes.FETCH_LEADERBOARD: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchLeaderBoard'),
      }
    }
    case ActionTypes.FETCH_LEADERBOARD_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        ...getReduxSuccessStates(state, 'fetchLeaderBoard'),
      }
    }
    case ActionTypes.FETCH_LEADERBOARD_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchLeaderBoard', action.payload),
      }
    }

    case ProductActionTypes.FETCH_PRODUCT: {
      return initialState
    }

    case UserActionTypes.ADD_POINTS_OPTIMISTIC: {
      const { userName, groupName, points } = action.payload

      if (userName) {
        const leaderBoardUsers = state.data.leaderboard.leaderboardDetails.users

        //Updating points for user
        const leaderBoardUserIndex = leaderBoardUsers.findIndex(
          (leaderBoardUser) => leaderBoardUser.nickname === userName,
        )
        const leaderBoardUser =
          leaderBoardUserIndex > -1
            ? leaderBoardUsers.find((leaderBoardUser) => leaderBoardUser.nickname === userName)
            : null
        const leaderBoardUserTotalPoints = leaderBoardUser ? leaderBoardUser.totalPoints + points : 0
        const leaderBoardUserRoundedPoints = leaderBoardUser
          ? [
              ...leaderBoardUser.roundPoints.slice(0, leaderBoardUser.roundPoints.length - 1),
              leaderBoardUser.roundPoints[leaderBoardUser.roundPoints.length - 1] + points,
            ]
          : []
        const newLeaderBoardUsers = leaderBoardUser
          ? [
              ...leaderBoardUsers.slice(0, leaderBoardUserIndex),
              {
                ...leaderBoardUser,
                totalPoints: leaderBoardUserTotalPoints,
                roundPoints: leaderBoardUserRoundedPoints,
              },
              ...leaderBoardUsers.slice(leaderBoardUserIndex + 1),
            ]
          : leaderBoardUsers

        //Updating points for user's group
        const leaderBoardGroups = state.data.leaderboard.leaderboardDetails.groups
        const leaderBoardGroupIndex = leaderBoardGroups.findIndex(
          (leaderBoardGroup) => leaderBoardGroup.name === groupName,
        )
        const leaderBoardGroup =
          leaderBoardGroupIndex > -1
            ? leaderBoardGroups.find((leaderBoardGroup) => leaderBoardGroup.name === groupName)
            : null
        const leaderBoardGroupTotalPoints = leaderBoardGroup ? leaderBoardGroup.totalPoints + points : 0
        const leaderBoardGroupRoundedPoints = leaderBoardGroup
          ? [
              ...leaderBoardGroup.roundPoints.slice(0, leaderBoardGroup.roundPoints.length - 1),
              leaderBoardGroup.roundPoints[leaderBoardGroup.roundPoints.length - 1] + points,
            ]
          : []
        const newLeaderBoardGroups = leaderBoardGroup
          ? [
              ...leaderBoardGroups.slice(0, leaderBoardGroupIndex),
              {
                ...leaderBoardGroup,
                totalPoints: leaderBoardGroupTotalPoints,
                roundPoints: leaderBoardGroupRoundedPoints,
              },
              ...leaderBoardGroups.slice(leaderBoardGroupIndex + 1),
            ]
          : leaderBoardGroups

        return {
          ...state,
          data: {
            ...state.data,
            leaderboard: {
              ...state.data.leaderboard,
              leaderboardDetails: {
                ...state.data.leaderboard.leaderboardDetails,
                groups: newLeaderBoardGroups,
                users: newLeaderBoardUsers,
              },
            },
          },
        }
      }

      return state
    }

    default: {
      return state
    }
  }
}
