import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { greenfieldColors } from 'src/theme/themes/greenfield/colors'
import { TTabStyle } from 'src/theme/themes/default/components/tab'

const tab: TTabStyle = defaultsDeep({}, defaultTheme.components.Tab)

tab.activeColor = greenfieldColors.green55
tab.borderRadius = '4'

export const greenfieldTab = tab
