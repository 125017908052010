import { TRulesStyle } from 'src/theme/themes/default/components/rules'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'

const rules: TRulesStyle = defaultsDeep({}, defaultTheme.components.Rules)

rules.itemsIndent = 24
rules.marginBottomDesktop = '13px'
rules.marginBottomMobile = '0'

export const libertateaRules = rules
