import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TInfoSectionStyle } from 'src/theme/themes/default/components/infoSection'

const infoSection: TInfoSectionStyle = defaultsDeep({}, defaultTheme.components.InfoSection)
infoSection.titleTextStyleMobile = `
    font-family: ${defaultTheme.fonts.goodOffcPro};
    font-style: normal;
    font-weight: 950;
    font-size: 28px;
    line-height: 36px;
`

export const bluefrostInfoSection = infoSection
