import { config } from 'src/config'
import { matchPath } from 'react-router-dom'
import { Path } from 'src/router/enums'
import { getTenant } from './tenant'
import { Tenants } from 'src/enums/tenants'

const isCertainProduct = (productId: string) => {
  return Boolean(matchPath(`${Path.Home}${productId}/*`, window.location.pathname))
}

export const getAuthSource = (): string => {
  return config.api.onelog.source
}

export const getAuthLoginCase = (): string => {
  const tenant = getTenant()
  if (tenant === Tenants.LIBERTATEA) {
    return 'game_dailyquiz_2023'
  } else if (tenant === Tenants.BLICK) {
    const isSchwingenProduct = isCertainProduct('schwingen')
    const isUnihockeyProduct = isCertainProduct('unihockeywm')
    const isEihockeyProduct = isCertainProduct('eishockeywm')
    const isSkiTrophyProduct = isCertainProduct('skitrophy')
    const isWmTrophyProduct = isCertainProduct('wmtrophy')
    const isEmTrophyProduct = isCertainProduct('emtrophy')
    const isFrauenwmProduct = isCertainProduct('fussballfrauenwm')

    if (isSchwingenProduct) {
      return 'esaf2022_trophy_game'
    }
    if (isUnihockeyProduct) {
      return 'unihockey_wm_2022_game'
    }
    if (isEihockeyProduct) {
      return 'eishockey_wm_2023_game'
    }
    if (isSkiTrophyProduct) {
      return 'ski_trophy_2324_game'
    }
    if (isWmTrophyProduct) {
      return 'soccer_wm_2022_game'
    }
    if (isEmTrophyProduct) {
      return 'SoccerEM_game_2024'
    }
    if (isFrauenwmProduct) {
      if (window.onelogLoginCaseVariant === 1) {
        return 'womansoccer_wm_2023_game_v1'
      }

      if (window.onelogLoginCaseVariant === 2) {
        return 'womansoccer_wm_2023_game_v2'
      }

      return 'womansoccer_wm_2023_game_cv'
    }
  }

  return config.api.onelog.loginCase
}

export const getSelfUrl = (): string => {
  const configSelfUrl = config.selfUrl || window.location.origin

  return configSelfUrl
}

export const getProductFromPath = () => {
  return window.location.pathname.split('/')[1]
}

export const saveUtmParams = (params: string) => {
  sessionStorage.setItem('utmParams', params)
}

export const getUtmParams = () => {
  const params = sessionStorage.getItem('utmParams')
  return params ? params : ''
}
