import { SplashScreen, SplashScreenText } from 'src/components/SplashScreen'
import { config } from 'src/config'
import { Button } from 'src/components/buttons/Button'
import { DEFAULT_ERRORS } from 'src/utils/errors'

export const Error500 = () => {
  const onClickBackToBlick = () => {
    window.location.href = config.externalLinks.blickUrl
  }

  return (
    <SplashScreen message={DEFAULT_ERRORS.TRY_REFRESH} onClickLogo={onClickBackToBlick} isFullScreen>
      <SplashScreenText>
        Zurück zu <Button onClick={onClickBackToBlick}>Blick.ch</Button>
      </SplashScreenText>
    </SplashScreen>
  )
}
