import { translations } from 'src/translations/de/default'
import { defaultsDeep } from 'lodash-es'

import { quizTranslations } from './quiz'
import { quizWidgetTranslations } from './quizwidget'
import { rulesTranslations } from './rules'
import { userDashBoardTranslations } from './userDashBoard'
import { navigationLinksTranslations } from './navigationLinks'
import { endOfSeasonModalTranslations } from './endofseasonmodal'
import { endOfRoundModalTranslations } from './endofroundmodal'
import { leaderboardTranslations } from './leaderboard'
import { summaryTranslations } from './summary'

export const plTranslations = defaultsDeep({}, translations)

plTranslations.rules = rulesTranslations
plTranslations.quiz = quizTranslations
plTranslations.quizwidget = quizWidgetTranslations
plTranslations.userDashBoard = userDashBoardTranslations
plTranslations.navigationLinks = navigationLinksTranslations
plTranslations.endofseasonmodal = endOfSeasonModalTranslations
plTranslations.endofroundmodal = endOfRoundModalTranslations
plTranslations.summary = summaryTranslations
plTranslations.leaderboard = leaderboardTranslations
