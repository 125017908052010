import { TReduxState } from 'src/redux/store'

export const getGroup = (state: TReduxState) => state.userGroup.data.group
export const getGroupId = (state: TReduxState) => state.userGroup.data.group?.id
export const getJoinRequests = (state: TReduxState) => state.userGroup.data.joinRequests
export const getOpenedModal = (state: TReduxState) => state.userGroup.data.openedModal
export const getJoinLink = (state: TReduxState) => state.userGroup.data.joinLink
export const getJoinGroup = (state: TReduxState) => state.userGroup.data.joinGroup
export const getGroupCaptainName = (state: TReduxState) => state.userGroup.data.group?.captain
export const getGroupName = (state: TReduxState) => state.userGroup.data.group?.name

export const getManagedName = (state: TReduxState) => state.userGroup.data.groupManagement.name
export const getManagedNameValidationErrror = (state: TReduxState) =>
  state.userGroup.data.groupManagement.nameValidationError
export const getManagedInvitations = (state: TReduxState) => state.userGroup.data.groupManagement.invitations
export const getManagedInvitationsValidationError = (state: TReduxState) =>
  state.userGroup.data.groupManagement.invitationsValidationError
export const getManagedDescription = (state: TReduxState) => state.userGroup.data.groupManagement.description
export const getManagedDescriptionValidationError = (state: TReduxState) =>
  state.userGroup.data.groupManagement.descriptionValidationError

export const getCreateGroupLoading = (state: TReduxState) => state.userGroup.loading.createGroup
export const getFetchGroupLoading = (state: TReduxState) => state.userGroup.loading.fetchGroup
export const getUpdateGroupNameLoading = (state: TReduxState) => state.userGroup.loading.updateGroupName
export const getUpdateGroupDescriptionLoading = (state: TReduxState) => state.userGroup.loading.updateGroupDescription
export const getLeaveGroupLoading = (state: TReduxState) => state.userGroup.loading.leaveGroup
export const getDeleteUserFromGroupLoading = (state: TReduxState) => state.userGroup.loading.deleteUserFromGroup
export const getAcceptInvitationLoading = (state: TReduxState) => state.userGroup.loading.acceptInvitation
export const getDeclineInvitationLoading = (state: TReduxState) => state.userGroup.loading.declineInvitation
export const getDeleteInvitationLoading = (state: TReduxState) => state.userGroup.loading.deleteInvitation
export const getInvitePlayersLoading = (state: TReduxState) => state.userGroup.loading.invitePlayers
export const getAcceptJoinGroupLoading = (state: TReduxState) => state.userGroup.loading.acceptJoinGroup
export const getAcceptIncomingJoinRequestLoading = (state: TReduxState) =>
  state.userGroup.loading.acceptIncomingJoinRequest
export const getDeclineIncomingJoinRequestLoading = (state: TReduxState) =>
  state.userGroup.loading.declineIncomingJoinRequest

export const getCreateGroupResolved = (state: TReduxState) => state.userGroup.resolved.createGroup
export const getFetchGroupResolved = (state: TReduxState) => {
  //We call either fetchGroup or fetchInvitations(if user hasn't any group) and to check resolved we need to check one of this parameters to be true
  return state.userGroup.resolved.fetchGroup || state.userGroup.resolved.fetchInvitations
}

export const getUpdateGroupResolved = (state: TReduxState) => state.userGroup.resolved.updateGroup
export const getUpdateGroupNameResolved = (state: TReduxState) => state.userGroup.resolved.updateGroupName
export const getUpdateGroupDescriptionResolved = (state: TReduxState) => state.userGroup.resolved.updateGroupDescription
export const getLeaveGroupResolved = (state: TReduxState) => state.userGroup.resolved.leaveGroup
export const getDeleteUserFromGroupResolved = (state: TReduxState) => state.userGroup.resolved.deleteUserFromGroup
export const getAcceptInvitationResolved = (state: TReduxState) => state.userGroup.resolved.acceptInvitation
export const getDeleteInvitationResolved = (state: TReduxState) => state.userGroup.resolved.deleteInvitation
export const getInvitePlayersResolved = (state: TReduxState) => state.userGroup.resolved.invitePlayers
export const getFetchJoinGroupResolved = (state: TReduxState) => state.userGroup.resolved.fetchJoinGroup
export const getAcceptJoinGroupResolved = (state: TReduxState) => state.userGroup.resolved.acceptJoinGroup

export const getAnimationState = (state: TReduxState) => state.userGroup.data.animation
