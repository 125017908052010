import { Reducer } from 'redux'
import { TActions, TMedalcountState } from './types'
import { ActionTypes } from './enums'

import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'

export const initialState: TMedalcountState = {
  data: null,
  loading: {
    fetchMedalcount: false,
    placeMedalcountBet: false,
  },
  error: {
    fetchMedalcount: null,
    placeMedalcountBet: null,
  },
  resolved: {
    fetchMedalcount: false,
    placeMedalcountBet: false,
  },
}

export const reducer: Reducer<TMedalcountState, TActions> = (state = initialState, action): TMedalcountState => {
  switch (action.type) {
    case ActionTypes.FETCH_MEDALCOUNT: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchMedalcount'),
      }
    }
    case ActionTypes.FETCH_MEDALCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ...getReduxSuccessStates(state, 'fetchMedalcount'),
      }
    }
    case ActionTypes.FETCH_MEDALCOUNT_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchMedalcount', action.payload),
      }
    }

    case ActionTypes.PLACE_MEDALCOUNT_BET: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'placeMedalcountBet'),
      }
    }
    case ActionTypes.PLACE_MEDALCOUNT_BET_SUCCESS: {
      const { bet } = action.payload

      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              currentEventBet: bet,
            }
          : state.data,
        ...getReduxSuccessStates(state, 'placeMedalcountBet'),
      }
    }
    case ActionTypes.PLACE_MEDALCOUNT_BET_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'placeMedalcountBet', action.payload),
      }
    }
    default: {
      return state
    }
  }
}
