import { ThunkAction } from 'redux-thunk'
import { TReduxState } from 'src/redux/store'

import { config } from 'src/config'
import { buildUrl } from 'src/api/utils/buildUrl'
import { privateClient } from 'src/utils/clients'
import { ActionTypes, UserGroupModal } from './enums'
import { dismissNotification, fetchNotifications } from 'src/api/notifications/actions'
import { TOtherUser } from 'src/api/users/types'
import {
  TGroup,
  TCreateGroupRequest,
  TCreateGroupResponse,
  TCreateGroupAction,
  TCreateGroupSuccessAction,
  TCreateGroupFailureAction,
  TClearCreateGroupStatesAction,
  TUpdateGroupNameRequest,
  TUpdateGroupNameAction,
  TUpdateGroupNameSuccessAction,
  TUpdateGroupNameFailureAction,
  TUpdateGroupDescriptionRequest,
  TUpdateGroupDescriptionAction,
  TUpdateGroupDescriptionSuccessAction,
  TUpdateGroupDescriptionFailureAction,
  TClearUpdateGroupDescriptionStatesAction,
  TClearUpdateGroupNameStatesAction,
  TFetchGroupAction,
  TFetchGroupSuccessAction,
  TFetchGroupFailureAction,
  TDeleteUserFromGroupAction,
  TDeleteUserFromGroupSuccessAction,
  TDeleteUserFromGroupFailureAction,
  TLeaveGroupAction,
  TLeaveGroupSuccessAction,
  TLeaveGroupFailureAction,
  TClearLeaveGroupStates,
  TFetchInvitationsAction,
  TFetchInvitationsResponse,
  TFetchInvitationsSuccessAction,
  TFetchInvitationsFailureAction,
  TManageInviteRequest,
  TAcceptInvitationAction,
  TAcceptInvitationSuccessAction,
  TAcceptInvitationFailureAction,
  TClearAcceptInvitationStatesAction,
  TDeclineInvitationAction,
  TDeclineInvitationSuccessAction,
  TDeclineInvitationFailureAction,
  TDeleteInvitationAction,
  TDeleteInvitationSuccessAction,
  TDeleteInvitationFailureAction,
  TClearDeleteInvitationStates,
  TInvitePlayersAction,
  TInvitePlayersSuccessAction,
  TInvitePlayersFailureAction,
  TClearInvitePlayersStatesAction,
  TCreateJoinLinkAction,
  TCreateJoinLinkSuccessAction,
  TCreateJoinLinkFailureAction,
  TAcceptIncomingJoinRequestAction,
  TAcceptIncomingJoinRequestSuccessAction,
  TAcceptIncomingJoinRequestFailureAction,
  TDeclineIncomingJoinRequestAction,
  TDeclineIncomingJoinRequestSuccessAction,
  TDeclineIncomingJoinRequestFailureAction,
  TGroupJoinLink,
  TSetOpenedModalAction,
  TUserGroupModal,
  TInviteUserRequest,
  TManageRequestToJoinRequest,
  TManageGroupNameAction,
  TManageGroupNameValidationErrorAction,
  TClearManageGroupNameData,
  TManageGroupInvitationsAction,
  TManageGroupInvitationsValidationError,
  TClearManageGroupInvitationsData,
  TManageGroupDescriptionAction,
  TManageGroupDescriptionValidationError,
  TClearManageGroupDescriptionData,
  TFetchJoinGroupAction,
  TFetchJoinGroupSuccessAction,
  TFetchJoinGroupFailureAction,
  TJoinGroup,
  TAcceptJoinGroup,
  TAcceptJoinGroupSuccessAction,
  TAcceptJoinGroupFailureAction,
  TAcceptJoinGroupAction,
  TFetchJoinRequestsFailureAction,
  TFetchJoinRequestsAction,
  TFetchJoinRequestsSuccessAction,
  TFetchJoinGroupResponse,
  TDeleteJoinRequestAction,
  TDeleteJoinRequestSuccessAction,
  TDeleteJoinRequestFailureAction,
  TSendJoinRequestAction,
  TSendJoinRequestSuccessAction,
  TSendJoinRequestFailureAction,
  TClearUpdateGroupStatesAction,
  TUpdateGroupAction,
  TUpdateGroupSuccessAction,
  TUpdateGroupRequest,
  TUpdateGroupFailureAction,
  TStartAnimation,
  TStopAnimation,
  TClearJoinRequestsAction,
  TAcceptJoinGroupResetAction,
} from './types'
import { getReduxErrorObject, checkBackendError, showToastError } from 'src/utils/errors'
import { toasty } from 'src/containers/toast/toasty'
import { TSelectOption } from 'src/components/types'
import { DRAWER_INDEX } from 'src/drawer'
import { getTranslations } from 'src/translations/utils'

export const createGroup =
  (
    request: TCreateGroupRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TCreateGroupAction | TCreateGroupSuccessAction | TCreateGroupFailureAction | TClearJoinRequestsAction
  > =>
  async (dispatch) => {
    const url = buildUrl(`${config.api.backend.url}/groups`)
    try {
      dispatch({
        type: ActionTypes.CREATE_GROUP,
      })

      const res = await privateClient.post<TCreateGroupResponse>(url, request)

      checkBackendError(res, 'Create of the group was failed')

      dispatch({
        type: ActionTypes.CREATE_GROUP_SUCCESS,
      })

      const newGroupId = res.data.id

      //To fetch group data after creation of the team
      dispatch(fetchGroup(newGroupId))
      //Update notifications
      dispatch(fetchNotifications(request.seasonId))
      //Clear join requests
      dispatch(clearJoinRequests())
    } catch (err) {
      dispatch({
        type: ActionTypes.CREATE_GROUP_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const clearCreateGroupStates = (): TClearCreateGroupStatesAction => ({
  type: ActionTypes.CLEAR_CREATE_GROUP_STATES,
})

export const updateGroup =
  (
    request: TUpdateGroupRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TUpdateGroupAction | TUpdateGroupSuccessAction | TUpdateGroupFailureAction
  > =>
  async (dispatch) => {
    const { groupId, ...rest } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}`)
    try {
      dispatch({
        type: ActionTypes.UPDATE_GROUP,
      })

      const res = await privateClient.put(url, { ...rest })

      checkBackendError(res, 'Update of the group was failed')

      dispatch({
        type: ActionTypes.UPDATE_GROUP_SUCCESS,
        payload: request,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.UPDATE_GROUP_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const clearUpdateGroupStates = (): TClearUpdateGroupStatesAction => ({
  type: ActionTypes.CLEAR_UPDATE_GROUP_STATES,
})

export const fetchGroup =
  (
    groupId: number,
  ): ThunkAction<void, TReduxState, unknown, TFetchGroupAction | TFetchGroupSuccessAction | TFetchGroupFailureAction> =>
  async (dispatch) => {
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}`)
    try {
      dispatch({
        type: ActionTypes.FETCH_GROUP,
      })
      const res = await privateClient.get<TGroup>(url)

      checkBackendError(res, "Group wasn't received")

      dispatch({
        type: ActionTypes.FETCH_GROUP_SUCCESS,
        payload: {
          ...res.data,
          id: groupId,
        },
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_GROUP_FAILURE,
        payload: getReduxErrorObject(err),
      })
    }
  }

export const updateGroupName =
  (
    request: TUpdateGroupNameRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TUpdateGroupNameAction | TUpdateGroupNameSuccessAction | TUpdateGroupNameFailureAction
  > =>
  async (dispatch) => {
    const { groupId, name } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/name`)
    try {
      dispatch({
        type: ActionTypes.UPDATE_GROUP_NAME,
      })
      const res = await privateClient.put(url, { name })

      checkBackendError(res, 'Update of group name was failed.')

      dispatch({
        type: ActionTypes.UPDATE_GROUP_NAME_SUCCESS,
        payload: request,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.UPDATE_GROUP_NAME_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }
export const clearUpdateGroupNameStates = (): TClearUpdateGroupNameStatesAction => ({
  type: ActionTypes.CLEAR_UPDATE_GROUP_NAME_STATES,
})

export const updateGroupDescription =
  (
    request: TUpdateGroupDescriptionRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TUpdateGroupDescriptionAction | TUpdateGroupDescriptionSuccessAction | TUpdateGroupDescriptionFailureAction
  > =>
  async (dispatch, getState) => {
    const { groupId, description } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/description`)
    try {
      dispatch({
        type: ActionTypes.UPDATE_GROUP_DESCRIPTION,
      })
      const res = await privateClient.put(url, { description })

      checkBackendError(res, 'Update of group description was failed.')

      dispatch({
        type: ActionTypes.UPDATE_GROUP_DESCRIPTION_SUCCESS,
        payload: request,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.UPDATE_GROUP_DESCRIPTION_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }
export const clearUpdateGroupDescriptionStates = (): TClearUpdateGroupDescriptionStatesAction => ({
  type: ActionTypes.CLEAR_UPDATE_GROUP_DESCRIPTION_STATES,
})

export const deleteUserFromGroup =
  (
    request: TInviteUserRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TDeleteUserFromGroupAction | TDeleteUserFromGroupSuccessAction | TDeleteUserFromGroupFailureAction
  > =>
  async (dispatch, getState) => {
    const { groupId, userName } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/members/${userName}`)
    try {
      dispatch({
        type: ActionTypes.DELETE_USER_FROM_GROUP,
        payload: request,
      })
      const res = await privateClient.delete(url)

      checkBackendError(res, 'Error when delete user from group')

      dispatch({
        type: ActionTypes.DELETE_USER_FROM_GROUP_SUCCESS,
        payload: request,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.DELETE_USER_FROM_GROUP_FAILURE,
        payload: request,
        errorPayload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const leaveGroup =
  (
    request: TInviteUserRequest,
  ): ThunkAction<void, TReduxState, unknown, TLeaveGroupAction | TLeaveGroupSuccessAction | TLeaveGroupFailureAction> =>
  async (dispatch, getState) => {
    const { groupId, userName } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/members/${userName}`)
    try {
      dispatch({
        type: ActionTypes.LEAVE_GROUP,
      })
      const res = await privateClient.delete(url)

      checkBackendError(res, 'Error when leave group')

      dispatch({
        type: ActionTypes.LEAVE_GROUP_SUCCESS,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.LEAVE_GROUP_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }
export const clearLeaveGroupStates = (): TClearLeaveGroupStates => ({
  type: ActionTypes.CLEAR_LEAVE_GROUP_STATES,
})

export const fetchInvitations =
  (
    seasonId: number,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchInvitationsAction | TFetchInvitationsSuccessAction | TFetchInvitationsFailureAction
  > =>
  async (dispatch) => {
    const url = buildUrl(`${config.api.backend.url}/groups/seasons/${seasonId}/invites`)
    try {
      dispatch({
        type: ActionTypes.FETCH_INVITATIONS,
      })
      const res = await privateClient.get<TFetchInvitationsResponse>(url)

      checkBackendError(res, 'Error when fetch invitations')

      dispatch({
        type: ActionTypes.FETCH_INVITATIONS_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_INVITATIONS_FAILURE,
        payload: getReduxErrorObject(err),
      })
    }
  }

export const acceptInvitation =
  (
    request: TManageInviteRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TAcceptInvitationAction | TAcceptInvitationSuccessAction | TAcceptInvitationFailureAction | TStartAnimation
  > =>
  async (dispatch, getState) => {
    const { groupId, seasonId, notificationId } = request
    const url = buildUrl(`${config.api.backend.url}/groups/seasons/${seasonId}/invites/${groupId}/accept`)
    const productId = getState().product.data?.id
    const translations = getTranslations(productId)
    try {
      dispatch({
        type: ActionTypes.ACCEPT_INVITATION,
        payload: request,
      })
      const res = await privateClient.post(url)

      checkBackendError(res, 'Accept invitation failed')

      dispatch({
        type: ActionTypes.ACCEPT_INVITATION_SUCCESS,
        payload: request,
      })

      //Should also remove certain notification
      dispatch(dismissNotification(notificationId))

      //After successfully accepted invitation we need to show user his group
      dispatch(fetchGroup(groupId))
      dispatch(startAnimation())
      toasty(translations.team.invite_accepted, { type: 'success', closeButton: false })
    } catch (err) {
      dispatch({
        type: ActionTypes.ACCEPT_INVITATION_FAILURE,
        payload: request,
        errorPayload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const clearAcceptInvitationStates = (): TClearAcceptInvitationStatesAction => ({
  type: ActionTypes.CLEAR_ACCEPT_INVITATION_STATES,
})

export const declineInvitation =
  (
    request: TManageInviteRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TDeclineInvitationAction | TDeclineInvitationSuccessAction | TDeclineInvitationFailureAction
  > =>
  async (dispatch, getState) => {
    const { groupId, seasonId, notificationId } = request
    const url = buildUrl(`${config.api.backend.url}/groups/seasons/${seasonId}/invites/${groupId}/decline`)
    const productId = getState().product.data?.id
    const translations = getTranslations(productId)
    try {
      dispatch({
        type: ActionTypes.DECLINE_INVITATION,
        payload: request,
      })
      const res = await privateClient.post(url)

      checkBackendError(res, 'Decline invitation failed')

      dispatch({
        type: ActionTypes.DECLINE_INVITATION_SUCCESS,
        payload: request,
      })

      //Should also remove certain notification
      dispatch(dismissNotification(notificationId))

      toasty(translations.team.invitation_declined)
    } catch (err) {
      dispatch({
        type: ActionTypes.DECLINE_INVITATION_FAILURE,
        payload: request,
        errorPayload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const deleteInvitation =
  (
    request: TInviteUserRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TDeleteInvitationAction | TDeleteInvitationSuccessAction | TDeleteInvitationFailureAction
  > =>
  async (dispatch) => {
    const { groupId, userName } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/invites/${userName}`)
    try {
      dispatch({
        type: ActionTypes.DELETE_INVITATION,
        payload: request,
      })
      const res = await privateClient.delete(url)

      checkBackendError(res, `Delete invitation for ${userName} was failed`)

      dispatch({
        type: ActionTypes.DELETE_INVITATION_SUCCESS,
        payload: request,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.DELETE_INVITATION_FAILURE,
        payload: request,
        errorPayload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const clearDeleteInvitationStates = (userName: string): TClearDeleteInvitationStates => ({
  type: ActionTypes.CLEAR_DELETE_INVITATION_STATES,
  payload: userName,
})

export const invitePlayers =
  (request: {
    groupId: number
    users: TOtherUser[]
  }): ThunkAction<
    void,
    TReduxState,
    unknown,
    TInvitePlayersAction | TInvitePlayersSuccessAction | TInvitePlayersFailureAction
  > =>
  async (dispatch, getState) => {
    const { groupId, users } = request
    try {
      dispatch({
        type: ActionTypes.INVITE_PLAYERS,
      })

      const responses = await Promise.all(
        users.map(async ({ username }) => {
          const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/invites/${username}`)
          const res = await privateClient.post(url)
          return res
        }),
      )

      responses.forEach((response) => checkBackendError(response, 'Not all the users were invited'))

      dispatch({
        type: ActionTypes.INVITE_PLAYERS_SUCCESS,
        payload: users,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.INVITE_PLAYERS_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const fetchJoinRequests =
  (
    seasonId: number,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchJoinRequestsAction | TFetchJoinRequestsSuccessAction | TFetchJoinRequestsFailureAction
  > =>
  async (dispatch) => {
    const url = buildUrl(`${config.api.backend.url}/groups/seasons/${seasonId}/join-requests`)
    try {
      dispatch({
        type: ActionTypes.FETCH_JOIN_REQUESTS,
      })
      const res = await privateClient.get<TFetchJoinGroupResponse>(url)

      checkBackendError(res, 'Error when fetch join requests')

      dispatch({
        type: ActionTypes.FETCH_JOIN_REQUESTS_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_JOIN_REQUESTS_FAILURE,
        payload: getReduxErrorObject(err),
      })
    }
  }

export const deleteJoinRequest =
  (
    groupId: number,
    userName: string,
    seasonId: number,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TDeleteJoinRequestAction | TDeleteJoinRequestSuccessAction | TDeleteJoinRequestFailureAction
  > =>
  async (dispatch, getState) => {
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/join-requests/${userName}`)
    const productId = getState().product.data?.id
    const translations = getTranslations(productId)
    try {
      dispatch({
        type: ActionTypes.DELETE_JOIN_REQUEST,
        payload: { groupId },
      })
      const res = await privateClient.delete(url)

      checkBackendError(res, 'Error delete join request')

      toasty(translations.team.join_request_removed)

      dispatch(fetchJoinRequests(seasonId))

      dispatch({
        type: ActionTypes.DELETE_JOIN_REQUEST_SUCCESS,
        payload: { groupId },
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.DELETE_JOIN_REQUEST_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err)
    }
  }

export const clearJoinRequests = (): TClearJoinRequestsAction => ({
  type: ActionTypes.CLEAR_JOIN_REQUESTS,
})

export const fetchJoinGroup =
  (request: {
    ticket: string
    groupId: string | number
  }): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchJoinGroupAction | TFetchJoinGroupSuccessAction | TFetchJoinGroupFailureAction
  > =>
  async (dispatch, getState) => {
    const { ticket, groupId } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/join-link/${ticket}`)

    try {
      dispatch({
        type: ActionTypes.FETCH_JOIN_GROUP,
      })

      const res = await privateClient.get<TJoinGroup>(url)

      checkBackendError(res, 'Failed to get group for join')

      dispatch({
        type: ActionTypes.FETCH_JOIN_GROUP_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_JOIN_GROUP_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err)
    }
  }

export const acceptJoinGroup =
  (request: {
    ticket: string
    groupId: string | number
    seasonId: number
    groupName?: string
  }): ThunkAction<
    void,
    TReduxState,
    unknown,
    TAcceptJoinGroupAction | TAcceptJoinGroupSuccessAction | TAcceptJoinGroupFailureAction | TStartAnimation
  > =>
  async (dispatch, getState) => {
    const { ticket, groupId, seasonId } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/join-link/${ticket}/accept`)
    const productId = getState().product.data?.id
    const translations = getTranslations(productId)

    try {
      dispatch({
        type: ActionTypes.ACCEPT_JOIN_GROUP,
      })

      const res = await privateClient.post<TAcceptJoinGroup>(url)

      checkBackendError(res, 'Failed to accept group join')

      dispatch(fetchJoinRequests(seasonId))

      dispatch({
        type: ActionTypes.ACCEPT_JOIN_GROUP_SUCCESS,
        payload: res.data,
      })
      dispatch(startAnimation())
      dispatch(fetchGroup(Number(groupId)))
      toasty(translations.team.join_request_accept, { type: 'success' })
    } catch (err) {
      dispatch({
        type: ActionTypes.ACCEPT_JOIN_GROUP_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err)
    }
  }

export const acceptJoinGroupReset = (): TAcceptJoinGroupResetAction => ({
  type: ActionTypes.ACCEPT_JOIN_GROUP_RESET,
})

export const clearInvitePlayersStates = (): TClearInvitePlayersStatesAction => ({
  type: ActionTypes.CLEAR_INVITE_PLAYERS_STATES,
})

export const createJoinLink =
  (
    groupId: number,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TCreateJoinLinkAction | TCreateJoinLinkSuccessAction | TCreateJoinLinkFailureAction
  > =>
  async (dispatch, getState) => {
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/join-link`)
    try {
      dispatch({
        type: ActionTypes.CREATE_JOIN_LINK,
      })
      const res = await privateClient.post<TGroupJoinLink>(url)

      checkBackendError(res, `Create join link was failed`)

      dispatch({
        type: ActionTypes.CREATE_JOIN_LINK_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.CREATE_JOIN_LINK_FAILURE,
        payload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const acceptIncomingJoinRequest =
  (
    request: TManageRequestToJoinRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    | TAcceptIncomingJoinRequestAction
    | TAcceptIncomingJoinRequestSuccessAction
    | TAcceptIncomingJoinRequestFailureAction
    | TStartAnimation
  > =>
  async (dispatch, getState) => {
    const { groupId, userName, notificationId } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/join-requests/${userName}/accept`)
    const productId = getState().product.data?.id
    const translations = getTranslations(productId)
    try {
      dispatch({
        type: ActionTypes.ACCEPT_INCOMING_JOIN_REQUEST,
        payload: request,
      })
      const res = await privateClient.post(url)

      checkBackendError(res, `Accept join request for ${userName} was failed`)

      dispatch({
        type: ActionTypes.ACCEPT_INCOMING_JOIN_REQUEST_SUCCESS,
        payload: request,
      })

      //Should also remove certain notification
      dispatch(dismissNotification(notificationId))

      dispatch(startAnimation())
      toasty(translations.team.joinRequestAcceptedText(userName), { type: 'success', closeButton: false })
    } catch (err) {
      dispatch({
        type: ActionTypes.ACCEPT_INCOMING_JOIN_REQUEST_FAILURE,
        payload: request,
        errorPayload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const declineIncomingJoinRequest =
  (
    request: TManageRequestToJoinRequest,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    | TDeclineIncomingJoinRequestAction
    | TDeclineIncomingJoinRequestSuccessAction
    | TDeclineIncomingJoinRequestFailureAction
  > =>
  async (dispatch, getState) => {
    const { groupId, userName, notificationId } = request
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/join-requests/${userName}/decline`)
    const productId = getState().product.data?.id
    const translations = getTranslations(productId)
    try {
      dispatch({
        type: ActionTypes.DECLINE_INCOMING_JOIN_REQUEST,
        payload: request,
      })
      const res = await privateClient.post(url)

      checkBackendError(res, `Decline join request for ${userName} was failed`)

      dispatch({
        type: ActionTypes.DECLINE_INCOMING_JOIN_REQUEST_SUCCESS,
        payload: request,
      })

      //Should also remove certain notification
      dispatch(dismissNotification(notificationId))

      toasty(translations.team.joinRequestDeclinedText(userName))
    } catch (err) {
      dispatch({
        type: ActionTypes.DECLINE_INCOMING_JOIN_REQUEST_FAILURE,
        payload: request,
        errorPayload: getReduxErrorObject(err),
      })

      showToastError(err, { type: 'error' })
    }
  }

export const sendJoinRequest =
  (
    groupId: number,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TSendJoinRequestAction | TSendJoinRequestSuccessAction | TSendJoinRequestFailureAction
  > =>
  async (dispatch) => {
    const url = buildUrl(`${config.api.backend.url}/groups/${groupId}/join-request`)
    try {
      dispatch({
        type: ActionTypes.SEND_JOIN_REQUEST,
      })
      const res = await privateClient.post<TGroup>(url)

      checkBackendError(res, 'Error while sending join request to group')

      dispatch({
        type: ActionTypes.SEND_JOIN_REQUEST_SUCCESS,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.SEND_JOIN_REQUEST_FAILURE,
        payload: getReduxErrorObject(err),
      })
    }
  }

export const setOpenedModal = (value: TUserGroupModal | null): TSetOpenedModalAction => ({
  type: ActionTypes.SET_OPENED_MODAL,
  payload: value,
})

export const manageGroupName = (value: string): TManageGroupNameAction => ({
  type: ActionTypes.MANAGE_GROUP_NAME,
  payload: value,
})
export const manageGroupNameValidationError = (value: string): TManageGroupNameValidationErrorAction => ({
  type: ActionTypes.MANAGE_GROUP_NAME_VALIDATION_ERROR,
  payload: value,
})
export const clearManageGroupNameData = (): TClearManageGroupNameData => ({
  type: ActionTypes.CLEAR_MANAGE_GROUP_NAME_DATA,
})
export const manageGroupInvitations = (value: TSelectOption[]): TManageGroupInvitationsAction => {
  const payload = value.map((item) => {
    return {
      username: String(item.label),
      id: Number(item.value),
    }
  })

  return {
    type: ActionTypes.MANAGE_GROUP_INVITATIONS,
    payload,
  }
}
export const manageGroupInvitationsValidationError = (value: string): TManageGroupInvitationsValidationError => ({
  type: ActionTypes.MANAGE_GROUP_INVITATIONS_VALIDATION_ERROR,
  payload: value,
})
export const clearManageGroupInvitationsData = (): TClearManageGroupInvitationsData => ({
  type: ActionTypes.CLEAR_MANAGE_GROUP_INVITATIONS_DATA,
})
export const manageGroupDescription = (value: string): TManageGroupDescriptionAction => ({
  type: ActionTypes.MANAGE_GROUP_DESCRIPTION,
  payload: value,
})
export const manageGroupDescriptionValidationError = (value: string): TManageGroupDescriptionValidationError => ({
  type: ActionTypes.MANAGE_GROUP_DESCRIPTION_VALIDATION_ERROR,
  payload: value,
})
export const clearManageGroupDescriptionData = (): TClearManageGroupDescriptionData => ({
  type: ActionTypes.CLEAR_MANAGE_GROUP_DESCRIPTION_DATA,
})

//To handle the case when user start group editing (edit name,invite players, edit description) but didn't submit yet
export const onCloseGroupManagementDrawer =
  (id: string, closeDrawerFunc: (id: string) => void): ThunkAction<void, TReduxState, unknown, TSetOpenedModalAction> =>
  (dispatch, getState) => {
    const group = getState().userGroup.data.group

    const groupManagementName = getState().userGroup.data.groupManagement.name
    const groupManagementInvitations = getState().userGroup.data.groupManagement.invitations
    const groupManagementDescription = getState().userGroup.data.groupManagement.description

    const isNameModified = group ? group.name !== groupManagementName : groupManagementName
    const isDescriptionModified = group ? group.description !== groupManagementDescription : groupManagementDescription
    const isInvitationsModified = groupManagementInvitations.length > 0

    const getIsGroupManagementModified = () => {
      if (id === DRAWER_INDEX.GROUP_NAME) {
        return isNameModified
      }
      if (id === DRAWER_INDEX.GROUP_DESCRIPTION) {
        return isDescriptionModified
      }
      if (id === DRAWER_INDEX.INVITE_PLAYERS) {
        return isInvitationsModified
      }

      return false
    }

    if (getIsGroupManagementModified()) {
      return dispatch(
        setOpenedModal({
          name: group ? UserGroupModal.DiscardChangesEdit : UserGroupModal.DiscardChanges,
          payload: {
            drawerToClose: id,
          },
        }),
      )
    }

    closeDrawerFunc(id)
  }

export const startAnimation = (): TStartAnimation => ({
  type: ActionTypes.START_ANIMATION,
})

export const stopAnimation = (): TStopAnimation => ({
  type: ActionTypes.STOP_ANIMATION,
})
