import { Reducer } from 'redux'

import { TActions, TPowerupsState } from './types'
import { ActionTypes } from './enums'

import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { getAddPowerupStatus } from 'src/utils/powerups'

export const initialState: TPowerupsState = {
  data: {
    powerups: [],
    selectedPowerups: [],
    previouslySelectedPowerup: null,
    isDiscardModalOpened: false,
    addPowerupStatus: null,
  },
  loading: {
    fetchPowerups: false,
    applyPowerups: false,
  },
  error: {
    fetchPowerups: null,
    applyPowerups: null,
  },
  resolved: {
    fetchPowerups: false,
    applyPowerups: false,
  },
}

export const reducer: Reducer<TPowerupsState, TActions> = (state = initialState, action): TPowerupsState => {
  switch (action.type) {
    case ActionTypes.FETCH_POWERUPS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchPowerups'),
      }
    }
    case ActionTypes.FETCH_POWERUPS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        ...getReduxSuccessStates(state, 'fetchPowerups'),
      }
    }
    case ActionTypes.FETCH_POWERUPS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchPowerups', action.payload),
      }
    }

    case ActionTypes.APPLY_POWERUPS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'applyPowerups'),
      }
    }
    case ActionTypes.APPLY_POWERUPS_SUCCESS: {
      const { powerupId, eventId, gameId } = action.payload
      const powerups = state.data.powerups
      const powerupIndex = powerups.findIndex((item) => item.id === powerupId)
      const powerup = state.data.powerups.find((item) => item.id === powerupId)
      let newPowerups = powerups

      if (powerup) {
        newPowerups = [
          ...powerups.slice(0, powerupIndex),
          { ...powerup, eventId, gameId },
          ...powerups.slice(powerupIndex + 1),
        ]
      }

      return {
        ...state,
        data: {
          ...state.data,
          powerups: newPowerups,
        },
        ...getReduxSuccessStates(state, 'applyPowerups'),
      }
    }
    case ActionTypes.APPLY_POWERUPS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'applyPowerups', action.payload),
      }
    }

    case ActionTypes.SET_PREVIOUSLY_SELECTED_POWERUP: {
      return {
        ...state,
        data: {
          ...state.data,
          previouslySelectedPowerup: action.payload,
        },
      }
    }

    case ActionTypes.SELECT_POWERUP: {
      const selectedPowerupId = action.payload
      const newSelectedPowerups = [selectedPowerupId]

      const previouslySelectedPowerup = state.data.previouslySelectedPowerup

      const addPowerupStatus = getAddPowerupStatus(previouslySelectedPowerup, selectedPowerupId)
      return {
        ...state,
        data: {
          ...state.data,
          selectedPowerups: newSelectedPowerups,
          addPowerupStatus,
        },
      }
    }
    case ActionTypes.DESELECT_POWERUP: {
      const deSelectedPowerupId = action.payload
      const newSelectedPowerups = state.data.selectedPowerups.filter((id) => id !== deSelectedPowerupId)

      const previouslySelectedPowerup = state.data.previouslySelectedPowerup
      const addPowerupStatus = getAddPowerupStatus(previouslySelectedPowerup, null)

      return {
        ...state,
        data: {
          ...state.data,
          selectedPowerups: newSelectedPowerups,
          addPowerupStatus,
        },
      }
    }

    case ActionTypes.OPEN_DISCARD_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          isDiscardModalOpened: true,
        },
      }
    }
    case ActionTypes.CLOSE_DISCARD_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          isDiscardModalOpened: false,
        },
      }
    }

    case ActionTypes.RESET_POWERUPS: {
      return initialState
    }

    default: {
      return state
    }
  }
}
