import { Bold } from 'src/components/Bold'
import { TQuizWidgetTranslations } from 'src/translations/de/default/quizwidget'

export const quizWidgetTranslations: TQuizWidgetTranslations = {
  resultTitle: {
    all: 'Świetna robota!',
    some: 'Świetna robota!',
    none: 'O nie!',
  },
  resultSummaryText: (
    points: number,
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number | undefined,
  ) =>
    points ? (
      <>
        Odpowiedziałeś dzisiaj na{' '}
        <Bold>
          {correctAnswerCount}/{maxQuestionCount} pytań poprawnie
        </Bold>{' '}
        i zdobyłeś{' '}
        <Bold>
          {pointsPerCorrectQuestion && maxQuestionCount
            ? `${points}/${pointsPerCorrectQuestion * maxQuestionCount}`
            : '0'}{' '}
          punktów
        </Bold>
        !
        <br />
        <br />
        Wróć jutro, aby zagrać ponownie!
      </>
    ) : (
      <>Nie odpowiedziałeś poprawnie na żadne pytanie. Wróć jutro, aby zagrać ponownie!</>
    ),
  quizTitle: <>Sprawdź swoją sportową wiedzę!</>,
  resultButtonUser: 'Zobacz swój ranking',
  resultButtonLogin: 'Zaloguj się i zagraj',
  resultButtonLoginAlt: 'Zaloguj się i zagraj',
  resultButtonLoginNotValidUser: 'Zaloguj się i zagraj',
  resultBottomTitle: 'Sprawdź swoje miejsce w rankingu',
  resultBottomText: 'Zobacz jak Ci poszło w porównaniu do innych.',
  // not used
  blickButtonText: '',
  guestBottomResultText1: '',
  guestBottomResultText2: '',
  guestBottomResultText3: '',
  guestBottomResultTitle: '',
  indicatiorText: () => '',
  leaderboardButtonText: '',
  resultBottomTextWithBets: () => <></>,
  resultButtonUserWithBets: '',
  resultSummaryEndText: '',
  resultTable: {
    gained: '',
    nickname: '',
    rank: '',
    total: '',
  },
  singleQuestionFailureDescription: <></>,
  singleQuestionFailureTitle: '',
  singleQuestionSuccessDescription: <></>,
  singleQuestionSuccessTitle: '',
  continue: 'Kontynuuj',
}
