import { defaultsDeep } from 'lodash-es'

import { TTranslations } from 'src/translations/types'
import { translations as defaultTranslations } from 'src/translations/de/default'
import { skitrophyQuizTranslations } from './quiz'
import { skitrophyLeaderboardTranslations } from './leaderboard'
import { skiTrophyEndOfRoundModalTranslations } from './endofroundmodal'
import { skiTrophyEndOfSeasonModalTranslations } from './endofseasonmodal'
import { skiTrophySummaryTranslations } from './summary'
import { skitrophyPowerupsTranslations } from './powerups'
import { skitrophyPrizesTranslations } from './prizes'
import { skitrophyQuizWidgetTranslations } from './quizwidget'

export const translations: TTranslations = defaultsDeep({}, defaultTranslations)

translations.quiz = skitrophyQuizTranslations
translations.quizwidget = skitrophyQuizWidgetTranslations
translations.leaderboard = skitrophyLeaderboardTranslations
translations.endofroundmodal = skiTrophyEndOfRoundModalTranslations
translations.endofseasonmodal = skiTrophyEndOfSeasonModalTranslations
translations.summary = skiTrophySummaryTranslations
translations.powerups = skitrophyPowerupsTranslations
translations.prizes = skitrophyPrizesTranslations
