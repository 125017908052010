import { TMeasures } from 'src/theme/measures'
import { defaultsDeep } from 'lodash-es'
import { theme as defaultTheme } from 'src/theme/themes/default'

const measures: TMeasures = defaultsDeep({}, defaultTheme.measures)

measures.navigation.headerHeightDesktop = '140px'
measures.navigation.headerHeightMobile = '108px'

export const onetMeasures = measures
