import { Theme } from 'src/theme/styled'
import { defaultsDeep } from 'lodash-es'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { ThemeNames } from 'src/theme/enums'
import { greenfieldQuiz } from './components/quiz'
import { greenfieldCommon } from './components/common'
import { greenfieldLeaderboard } from './components/leaderboard'
import { greenfieldButton } from './components/button'
import { greenfieldTab } from './components/tab'
import { greenfieldCollapsible } from './components/collapsible'
import { greenfieldScoreBet } from './components/scoreBet'
import { greenfieldInfoSection } from './components/infoSection'
import { greenfieldRules } from './components/rules'
import { greenfieldEndOfSeasonModal } from './components/endOfSeasonModal'
import { greenfieldSummary } from './components/summary'
import { greenfieldQuizWidget } from './components/quizwidget'
import { greenfieldHero } from './components/hero'
import { greenfieldTable } from './components/table'

const theme: Theme = defaultsDeep({}, defaultTheme)

theme.name = ThemeNames.GREENFIELD
theme.components.common = greenfieldCommon
theme.components.Quiz = greenfieldQuiz
theme.components.QuizWidget = greenfieldQuizWidget
theme.components.Leaderboard = greenfieldLeaderboard
theme.components.Button = greenfieldButton
theme.components.Tab = greenfieldTab
theme.components.Collapsible = greenfieldCollapsible
theme.components.ScoreBet = greenfieldScoreBet
theme.components.InfoSection = greenfieldInfoSection
theme.components.Rules = greenfieldRules
theme.components.EndOfSeasonModal = greenfieldEndOfSeasonModal
theme.components.Summary = greenfieldSummary
theme.components.Hero = greenfieldHero
theme.components.Table = greenfieldTable

export const greenfield = theme
