import { TEndOfSeasonModalStyle } from 'src/theme/themes/default/components/endOfSeasonModal'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { onetFonts } from 'src/theme/themes/onet/fonts'
import { onetColors } from 'src/theme/themes/onet/colors'
import { defaultsDeep } from 'lodash-es'
import backgroundImage from 'src/assets/images/onet/summary_modal.svg'
import { StatisticksBlockStyles } from 'src/enums/summary'

const endOfSeasonModal: TEndOfSeasonModalStyle = defaultsDeep({}, defaultTheme.components.EndOfSeasonModal)

endOfSeasonModal.basicColor = '#fff'
endOfSeasonModal.basicFontWeight = 300
endOfSeasonModal.backgroundColor = onetColors.errorRed
endOfSeasonModal.backgroundLinear = `linear-gradient(to right bottom, ${onetColors.errorRedMedium} 40%, ${onetColors.errorRed} 40%)`
endOfSeasonModal.backgroundImage = backgroundImage
endOfSeasonModal.stepTitleText = `
    font-family: ${onetFonts.firaSans};
    font-size: 24px;
    line-height: 140%;
    font-weight: 500;
`
endOfSeasonModal.statisticsBlockValueFontSize = '16px'
endOfSeasonModal.statisticsBlockColor = onetColors.darkGrey
endOfSeasonModal.statisticsBlockBgColor = '#fff'
endOfSeasonModal.statisticksBlockStyle = StatisticksBlockStyles.Triangle
endOfSeasonModal.messageAnimationWidth = '125px'
endOfSeasonModal.thanksAnimationWidthDesktop = '200px'
endOfSeasonModal.thanksAnimationWidthMobile = '180px'
endOfSeasonModal.transparentButtonHover = `
    color: ${onetColors.darkGrey};
`
endOfSeasonModal.feedbackStarColor = defaultTheme.colors.shades.white

export const onetEndOfSeasonModal = endOfSeasonModal
