import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { TQuizStyle } from 'src/theme/themes/default/components/quiz'
import { libertateaCommon } from './common'

const quiz: TQuizStyle = defaultsDeep({}, defaultTheme.components.Quiz)

quiz.borderRadius = libertateaCommon.borderRadius
quiz.background = libertateaColors.greyLight
quiz.padding = '32px 0px 0px 0px'
quiz.wrapperMargin = '0'
quiz.innerWrapperPadding = '12px'
quiz.innerWrapperPaddingMobile = '8px'
quiz.indicatorPadding = '0'
quiz.indicatorPaddingMobile = '0'
quiz.indicatorTextStyleMobile = quiz.indicatorTextStyle
quiz.titleTextStyle = `
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 120%;
`
quiz.titleTextStyleMobile = quiz.titleTextStyle
quiz.titleTextAlign = 'center'
quiz.titleMarginMobile = '16px 0 32px 0'
quiz.answersWrapperPadding = '0'
quiz.questionsWrapperPadding = '20px'
quiz.simpleTeaserMargin = '-8px 0 24px 0'
quiz.simpleTeaserMarginMobile = '-8px 0 24px 0'
quiz.simpleTeaserTextStyle = `
    font-weight: 300;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
`
quiz.simpleTeaserTextStyleMobile = `
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
`
quiz.innerWrapperNoTeaserPadding = '20px 150px'
quiz.questionsWrapperPaddingMobile = '12px'
quiz.answersWrapperPaddingMobile = quiz.answersWrapperPadding
quiz.answerJustifyContent = 'flex-start'
quiz.answerBoxShadow = '0px 1px 4px 1px rgba(0, 0, 0, 0.25)'
quiz.answerMinHeight = '66px'
quiz.answerMinHeightMobile = '57px'
quiz.answerPadding = '0 50px 0 24px'
quiz.answerPaddingMobile = '0 50px 0 16px'
quiz.answerTextStyle = `
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 140%;
`
quiz.answerTextStyleMobile = `
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
`
quiz.subTitleTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
`
quiz.resultTableBackground = defaultTheme.colors.shades.white
quiz.resultWrapperPadding = '12px'
quiz.resultWrapperPaddingMobile = '8px'
quiz.resultTableMargin = '32px 0 16px 0'
quiz.resultTableMarginMobile = '24px 0 16px 0'
quiz.resultTableHeadRowHeight = '34px'
quiz.resultTableHeadCellTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-size: 14px;
    line-height: 32px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.resultTableRowHeight = '39px'
quiz.resultTableCellTextStyle = `
    font-family: 'Source Sans Pro';
    font-size: 18px;
    line-height: 23px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.resultTableCellPadding = '0 8px'
quiz.resultTableCellPaddingMobile = '0 8px'
quiz.resultTableCellFirstChildPadding = '0 0 0 16px'
quiz.resultTableCellLastChildPadding = '0 16px 0 0'
quiz.teaserTitleTextStyle = `
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: ${libertateaColors.greyDark};
`
quiz.teaserArticleTitleTextStyle = `
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: ${libertateaColors.greyDark};
`
quiz.teaserArticleTitleTextStyleMobile = quiz.teaserArticleTitleTextStyle
quiz.teaserArticleSubTitleTextStyle = `
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    color: ${libertateaColors.greyDark};
`
quiz.teaserArticleSubTitleTextStyleMobile = quiz.teaserArticleSubTitleTextStyle

quiz.answerDefaultBackground = defaultTheme.colors.shades.white
quiz.answerDefaultBackgroundHover = libertateaColors.ultraLightGrey
quiz.answerDefaultBoxShadow = '0px 1px 4px 1px rgba(0, 0, 0, 0.25)'
quiz.answerDefaultBorder = `2px solid ${defaultTheme.colors.shades.white}`
quiz.answerDefaultInnerBackground = libertateaColors.greyVeryLight
quiz.answerDefaultPercentageBackground = libertateaColors.greyVeryLight
quiz.answerDefaultPercentageBorder = `2px solid ${libertateaColors.grey}`

quiz.answerLoadingBackground = libertateaColors.greyVeryLight
quiz.answerLoadingBorder = `2px solid ${libertateaColors.greyVeryLight}`

quiz.answerSuccessBackground = libertateaColors.successGreenLight
quiz.answerSuccessBorder = `2px solid ${libertateaColors.successGreen}`
quiz.answerSuccessInnerBackground = libertateaColors.successGreenMedium

quiz.answerFailureBackground = libertateaColors.errorRedLight
quiz.answerFailureBorder = `2px solid ${libertateaColors.red}`
quiz.answerFailureInnerBackground = libertateaColors.errorRedMedium
quiz.answerBorderRadius = libertateaCommon.borderRadius

quiz.answerPercentageTextStyle = `
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
`
quiz.cardPadding = '12px 52px'
quiz.cardPaddingMobile = '16px'
quiz.cardTitleTextStyle = `
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 120%;
    text-align: center;
    margin:0 auto;
`
quiz.cardTextStyle = `
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
`
quiz.cardButtonsJustifyContent = 'center'

export const libertateaQuiz = quiz
