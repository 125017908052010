import { InternalLink } from 'src/components/links/InternalLink'
import { TEndOfSeasonModalTranslations } from 'src/translations/de/default/endofseasonmodal'

export const endOfSeasonModalTranslations: TEndOfSeasonModalTranslations = {
  subtitle: '', // Not used
  getSummarytitle: (userRank, userName) => (
    <>Mulțumim {userName} pentru participarea la concursul “Câștigă cu Întrebarea Zilei“</>
  ),
  getGroupStepTitle: (seasonRank: number) => `Dein Team und du seid auf Rang ${seasonRank} gelandet`, // Not used
  statisticsSubtitle: '', // Not used
  feedbackSubscriptionText: 'Mlțumim pentru înscriere!',
  feedbackTitle: 'Ultima întrebare pentru astăzi! Cât de mulțumit ai fost de acest joc?',
  feedbackPlaceholder: (
    <>
      <b>Feedback-ul tău</b> (Opțional)
    </>
  ),
  subscriptionText: 'Dorești să primești un e-mail atunci când apare un concurs nou?',
  thanksTitle: 'Mulțumim că ai jucat "Întrebarea zilei"!',
  thanksText: (link: string) => (
    <>
      Dacă nu te-ai abonat deja, te poți încă <InternalLink to={link}>înregistra la Newsletter.</InternalLink>
    </>
  ),
  socialTitle: 'Distribuie rezultatul',
  showLaterButton: 'Arată mai târziu',
  nextButton: 'Următorul',
  backButton: 'Înapoi',
  closeButton: 'Închide',
  approveButton: 'Da, te rog',
  rejectButton: 'Nu, mulțumesc',
  sharingText: 'Salut! Iată scorul meu concursul "Întrebarea zilei" de pe Libertatea.ro!',
  sharingSubject: 'Rezultatele "Întrebarea zilei"',
  copyText: 'Linkul a fost copiat',
}
