import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TFeedbackStyle } from 'src/theme/themes/default/components/feedback'
import { bluefrostColors } from 'src/theme/themes/bluefrost/colors'

const feedback: TFeedbackStyle = defaultsDeep({}, defaultTheme.components.Feedback)
feedback.boxShadow = 'none'
feedback.border = `1px solid ${defaultTheme.colors.blick.grey30}`
feedback.pointsTextColor = bluefrostColors.blueDark
feedback.pointsColor = defaultTheme.colors.feedbackAlt.positive

export const bluefrostFeedback = feedback
