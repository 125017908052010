import { Bold } from 'src/components/Bold'
import { ExternalLink } from 'src/components/links/ExternalLink'

export type TQuizTranslations = Modify<
  typeof quizTranslations,
  {
    seasonEndCardText: JSX.Element | string
    seasonEndCardButtonText: string | null
  }
>

export const quizTranslations = {
  title: 'Teste dein Wissen',
  description: (
    <>
      Beantworte die drei täglichen Quizfragen und erhalte <b key="bold-1">drei Punkte</b> für jede richtige Antwort!
    </>
  ),
  seasonEndCardTitle: 'Die Ski Trophy ist beendet - Danke fürs Mitspielen!',
  seasonEndCardText: (
    <>
      Wie war die Trophy für dich? Lass es uns gerne wissen und sende eine kurze Mail an
      <ExternalLink href="mailto:community@blick.ch">community@blick.ch.</ExternalLink>
    </>
  ),
  indicatiorText: (maxQuestionCount?: number) => `Fragen beantwortet ${maxQuestionCount}/${maxQuestionCount}`,
  resultTopText: 'Ergebnis des TagesQuiz',
  resultTitle: {
    all: 'Volltreffer!',
    some: 'Super!',
    none: 'Knapp daneben ist auch vorbei!',
  },
  resultSummaryText: (
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number,
    gainedPoints: number,
  ) => (
    <>
      Du hast{' '}
      <Bold>
        {correctAnswerCount}/{maxQuestionCount}
      </Bold>{' '}
      Quizfragen richtig beantwortet und{' '}
      <Bold>
        {gainedPoints > 0 && maxQuestionCount && pointsPerCorrectQuestion
          ? `${gainedPoints}/${maxQuestionCount * pointsPerCorrectQuestion}`
          : 0}{' '}
        Punkte
      </Bold>{' '}
      gesammelt.
    </>
  ),
  resultSummaryTextGroup: (
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number,
    gainedPoints: number,
  ) => {
    const gainedPointsRatio =
      gainedPoints > 0 && maxQuestionCount && pointsPerCorrectQuestion
        ? `${gainedPoints}/${maxQuestionCount * pointsPerCorrectQuestion}`
        : 0

    return (
      <>
        Du hast{' '}
        <Bold>
          {correctAnswerCount}/{maxQuestionCount} Quizfragen
        </Bold>{' '}
        richtig beantwortet und <Bold>{gainedPointsRatio} Punkte für dich sowie für deine Tippgruppe</Bold> gesammelt.
      </>
    )
  },
  resultSummaryEndText: 'Morgen kannst du dein Wissen aufs Neue testen!',
  resultSummaryEndTextGroup: (_: number, __: number) => quizTranslations.resultSummaryEndText,
  resultTable: {
    rank: 'Rang',
    nickname: 'Spieler',
    group: 'Team',
    total: 'Total',
    gained: 'Heute',
  },
  leaderboardButtonText: 'Zur Rangliste',
  blickButtonText: 'Blick Ski Nachrichten',
  cardErrorTitle: 'Hoppla...',
  noMoreQuestionMessage: (
    <>
      Es konnten keine neuen Fragen geladen werden. Versuch es bitte später nochmal. Sollte das Problem erneut
      auftreten, kontaktiere uns bitte unter{' '}
      <ExternalLink href="mailto:community@blick.ch">community@blick.ch.</ExternalLink>
    </>
  ),
  unknownErrorMessage: (
    <>
      Leider ist ein unbekannter Fehler aufgetreten. Lade bitte die Seite neu. Sollte das Problem erneut auftreten,
      kontaktiere uns bitte unter <ExternalLink href="mailto:community@blick.ch">community@blick.ch.</ExternalLink>
    </>
  ),
  collectedPoints: (collectedPoints: number) => (
    <>
      {collectedPoints === 0 ? '' : '+'} {collectedPoints} Punkte
    </>
  ),
  questionName: 'Frage',
  answersName: 'Antworten',
  nextResultsHint: 'Heutige Resultate folgen',
  nextQuestionHint: 'Nächste Frage folgt',
  continue: 'Weiter',
  nextQuestion: 'Weiter',
  teaser: (
    <>
      <b key="bold-1">Psst!</b> Die richtige Antwort findest du hier:
    </>
  ),
  simpleTeaser: (url: string, onClick?: () => void) => (
    <>
      <b>Psst!</b> Finde hier die{' '}
      <ExternalLink onClick={onClick} target="_blank" href={url}>
        richtige Antwort
      </ExternalLink>
      .
    </>
  ),
  allAnsweredCard: {
    title: 'Frage bereits beantwortet',
    description:
      'Danke, dass du dich eingeloggt hast! Da du diese Frage bereits beantwortet hast, kannst du jetzt deine Quiz-Ergebnisse ansehen.',
    button: 'Quiz-Ergebnisse anzeigen',
  },
  alreadyAnsweredCard: {
    title: 'Frage bereits beantwortet',
    description:
      'Danke, dass du dich eingeloggt hast! Da du diese Frage bereits beantwortet hast, erhältst du eine neue Frage.',
    button: 'MIT NEUER FRAGE WEITERSPIELEN',
  },
  singleQuestionSuccessTitle: 'Great job!',
  singleQuestionSuccessDescription: (
    <>
      The more you play the higher your chance to win! <br /> <br /> Come back tomorrow to answer another daily quiz
      question.
    </>
  ),
  singleQuestionFailureTitle: 'Oh no!',
  singleQuestionFailureDescription: (
    <>
      Come back tomorrow and try again!
      <br />
      The link with the hint helps you to find the correct answer.
      <br />
      <br />
      The more you play the higher your chance to win!
    </>
  ),
  singleQuestionResultButton: 'Stay up to date with Libertatea',
}
