import { translations } from 'src/translations/de/default'
import { defaultsDeep } from 'lodash-es'

import { endOfSeasonModalTranslations } from './endofseasonmodal'
import { navigationLinksTranslations } from './navigationLinks'
import { prizesTranslations } from './prizes'
import { quizTranslations } from './quiz'
import { quizWidgetTranslations } from './quizwidget'
import { userDashBoardTranslations } from './userDashBoard'
import { summaryTranslations } from './summary'
import { rulesTranslations } from './rules'
import { winnersTranslations } from './winners'

export const roTranslations = defaultsDeep({}, translations)

roTranslations.endofseasonmodal = endOfSeasonModalTranslations
roTranslations.navigationLinks = navigationLinksTranslations
roTranslations.prizes = prizesTranslations
roTranslations.quiz = quizTranslations
roTranslations.quizwidget = quizWidgetTranslations
roTranslations.userDashBoard = userDashBoardTranslations
roTranslations.summary = summaryTranslations
roTranslations.rules = rulesTranslations
roTranslations.winners = winnersTranslations
