export const setCookie = (name: string, value: string, days?: number) => {
  let expires = ''

  const date = new Date()
  date.setDate(date.getDate() + (days || 365 * 10))
  expires = '; expires=' + date.toUTCString()

  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const getCookie = (name: string) => {
  const nameEQ = name + '='
  const cookiesArr = document.cookie.split(';')

  for (let i = 0; i < cookiesArr.length; i++) {
    let cookie = cookiesArr[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1, cookie.length)
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }

  return null
}

export const eraseCookie = (name: string) => {
  setCookie(name, '', -1)
}

export const eraseAllCookie = () => {
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
  })
}
