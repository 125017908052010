import { Reducer } from 'redux'

import { TRouterState, TLocationChangeAction } from 'src/state/router/types'
import { ActionTypes } from 'src/state/router/enums'

const initialState: TRouterState = {
  currentLocation: null,
}

type RegisteredActions = TLocationChangeAction

export const reducer: Reducer<TRouterState, RegisteredActions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOCATION_CHANGE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
