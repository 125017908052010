import { colors } from 'src/theme/colors'

export type THeaderStyle = typeof defaultHeader

export const defaultHeader = {
  background: colors.shades.white,
  height: '60px',
  heightMobile: '48px',
  shadow: '0px 2px 2px rgba(0, 0, 0, 0.11)',
  logoHeight: '100%',
  logoHeightMobile: '45px',
  logoLinkHeight: '100%',
  logoLinkHeightMobile: '100%',
  infoBlockBackground: colors.shades.white,
  infoBlockBorder: `1px solid ${colors.blick.grey80}`,
  userNameTextStyle: `
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 0.01em;
    color: ${colors.blick.grey100};
  `,
  userNameTextStyleMobile: `
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: 0.01em;
    color: ${colors.blick.grey100};
  `,
  userPointsTextStyle: `
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${colors.blick.grey100};
  `,
  userPointsTextStyleMobile: `
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: ${colors.blick.grey100};
  `,
  iconColor: colors.blick.grey100,
  infoDividerColor: colors.blick.grey100,
  notLoggedInTextStyle: `
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
  `,
  notLoggedInTextStyleMobile: `
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
  `,
  navMenuBackgroundColor: colors.shades.shade0,
  navMenuBorderTop: 'none',
  navMenuHeight: '36px',
  navMenuHeightMobile: '28px',
  navMenuPadding: '0',
  navMenuPaddingMobile: '0 16px',
  navLinkPadding: '0',
  navLinkPaddingMobile: '0',
  navLinkMargin: '0 24px 0 0',
  navLinkBorderLeft: 'none',
  navLinkTextStyle: `
    font-size: 16px;
    line-height: 24px;
    color: ${colors.shades.shade15};
    text-decoration: none;
  `,
  navLinkTextStyleMobile: `
    font-size: 16px;
    line-height: 20px;
    color: ${colors.shades.shade15};
    text-decoration: none;
  `,
  navLinkHoverTextStyle: ``,
  navLinkSelectedTextStyle: `
    font-weight: 700;
  `,
}
