export type TPrizesTranslations = typeof prizesTranslations

export const prizesTranslations = {
  title: 'Das sind die Spielregeln',
  description: (link?: string) => (
    <>
      Du kannst mit richtigen Antworten im täglichen Quiz sowie mit deinen Tipps Punkte sammeln. Jeden Monat gibt es
      einen Preis für die drei besten Spieler und einen Tombolapreis, der unter allen Spielern verlost wird. Ausserdem
      gewinnt das Team auf Platz 1 jeden Monat einen Preis.
    </>
  ),
}
