import { TRulesStyle } from 'src/theme/themes/default/components/rules'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'

const rules: TRulesStyle = defaultsDeep({}, defaultTheme.components.Rules)

rules.itemsIndent = 24
rules.marginBottomDesktop = '13px'
rules.marginBottomMobile = '0'
rules.textStyle = `
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
`
rules.padding = '0 16px 16px 52px'
rules.paddingMobile = '0 16px 16px 44px'

export const onetRules = rules
