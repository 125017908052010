import { TFeedback } from 'src/types/feedback'

import { ThemeNames } from 'src/theme/enums'

import confettiExplosion from 'src/components/lottie/json/confetti_explosion.json'
import confettiExplosionBlue from 'src/components/lottie/json/confetti_explosion_blue.json'

export const getFeedback = (num: number): TFeedback => (num >= 0 ? (num > 0 ? 'positive' : 'neutral') : 'negative')

export const getFeedbackAnimation = (themeName: ThemeNames) => {
  switch (themeName) {
    case ThemeNames.BLUEFROST:
      return confettiExplosionBlue
    case ThemeNames.GREENFIELD:
      return confettiExplosionBlue
    default:
      return confettiExplosion
  }
}

export const getShouldShowFeedback = (
  shouldShowFeedback: boolean,
  isSeasonEnded: boolean,
  isBettingFeedback: boolean,
  isUserInAGroup: boolean,
) => {
  if (isBettingFeedback) {
    return shouldShowFeedback && !isSeasonEnded && isUserInAGroup
  }
  return shouldShowFeedback && !isSeasonEnded
}
