import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { accentwayColors } from 'src/theme/themes/accentway/colors'
import { TQuizStyle } from 'src/theme/themes/default/components/quiz'

const quiz: TQuizStyle = defaultsDeep({}, defaultTheme.components.Quiz)

quiz.background = defaultTheme.colors.shades.white
quiz.border = `1px solid ${accentwayColors.grey10}`
quiz.padding = '32px 0px 0px 0px'
quiz.wrapperMargin = 'auto'
quiz.innerWrapperPadding = '12px'
quiz.innerWrapperPaddingMobile = '8px'
quiz.indicatorTextStyle = `
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
`
quiz.indicatorTextStyleMobile = quiz.indicatorTextStyle
quiz.titleTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    font-family: ${defaultTheme.fonts.sourceSansPro};
`
quiz.titleTextStyleMobile = quiz.titleTextStyle
quiz.titleTextAlign = 'left'
quiz.titleMarginMobile = '16px 0 32px 0'
quiz.answersWrapperPadding = '0'
quiz.questionsWrapperPadding = '12px'
quiz.questionsWrapperPaddingMobile = '8px'
quiz.answersWrapperPaddingMobile = quiz.answersWrapperPadding
quiz.answerJustifyContent = 'flex-start'
quiz.answerBoxShadow = '0px 1px 4px 1px rgba(0, 0, 0, 0.25)'
quiz.answerPadding = '0 64px 0 24px'
quiz.answerPaddingMobile = '0 64px 0 16px'
quiz.answerDefaultBackground = accentwayColors.turquoise
quiz.answerDefaultBackgroundHover = '#076F83'
quiz.answerLoadingBackground = defaultTheme.colors.blick.grey30
quiz.answerSuccessBackground = defaultTheme.colors.blick.sport5
quiz.answerFailureBackground = defaultTheme.colors.blick.brand10
quiz.answerDefaultBorder = `2px solid ${defaultTheme.colors.shades.white}`
quiz.answerLoadingBorder = defaultTheme.colors.blick.grey50
quiz.answerSuccessBorder = defaultTheme.colors.blick.sport60
quiz.answerFailureBorder = `2px solid ${defaultTheme.colors.feedback.negative}`
quiz.answerDefaultColor = defaultTheme.colors.shades.white
quiz.answerLoadingColor = defaultTheme.colors.blick.grey100
quiz.answerSuccessColor = defaultTheme.colors.blick.grey100
quiz.answerFailureColor = defaultTheme.colors.blick.grey100
quiz.subTitleTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
`
quiz.resultWrapperPadding = '12px'
quiz.resultWrapperPaddingMobile = '8px'
quiz.resultTableBackground = defaultTheme.colors.blick.grey10
quiz.resultTableBorder = `1px solid ${accentwayColors.grey40}`
quiz.resultTableMargin = '0 0 16px 0'
quiz.resultTableMarginMobile = '0 0 16px 0'
quiz.resultTableHeadRowHeight = '50px'
quiz.resultTableHeadCellTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-size: 14px;
    line-height: 28px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.resultTableRowHeight = '55px'
quiz.resultTableCellTextStyle = `
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.resultTableCellPadding = '0 16px'
quiz.resultTableCellFirstChildPadding = '0 0 0 16px'
quiz.resultTableCellLastChildPadding = '0 16px 0 0'
quiz.resultTableCellPaddingMobile = '0 8px'
quiz.progressBarWidth = '210px'
quiz.progressBarPosition = 'absolute'
quiz.progressBarTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${accentwayColors.green};
`
quiz.progressBarBackground = defaultTheme.colors.shades.white
quiz.progressBarBorder = `1px solid ${accentwayColors.green}`
quiz.progressBarBorderRadius = '20px'
quiz.progressBarInnerBarBackground = accentwayColors.blue
quiz.bettingBannerBackground = accentwayColors.blue
quiz.bettingBannerTitleTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.bettingBannerTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.bettingButtonBackground = accentwayColors.turquoise
quiz.bettingButtonColor = defaultTheme.colors.shades.white
quiz.bettingButtonBackgroundDisabled = defaultTheme.colors.blick.grey50
quiz.bettingButtonColorDisabled = defaultTheme.colors.blick.grey70
quiz.collectedPointsBackground = accentwayColors.turquoise

export const accentwayQuiz = quiz
