import { components } from 'src/theme/themes/default/components'
import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'
import { measures } from 'src/theme/measures'
import { media } from 'src/theme/media'
import { zIndex } from 'src/theme/zIndex'
import { ThemeNames } from 'src/theme/enums'

export type TTheme = typeof theme

export const theme = {
  name: ThemeNames.DEFAULT,
  components,
  colors,
  fonts,
  measures,
  media,
  zIndex,
}
