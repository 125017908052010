import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TLeaderboardStyle } from 'src/theme/themes/default/components/leaderboard'
import { onetFonts } from 'src/theme/themes/onet/fonts'
import { onetColors } from 'src/theme/themes/onet/colors'

const leaderboard: TLeaderboardStyle = defaultsDeep({}, defaultTheme.components.Leaderboard)

leaderboard.currentUserRow.background = onetColors.yellow
leaderboard.currentUserRow.color = onetColors.darkGrey

leaderboard.goToLeaderBoardButton.background = 'transperant'
leaderboard.goToLeaderBoardButton.hover.background = onetColors.yellow
leaderboard.goToLeaderBoardButton.fontFamily = onetFonts.firaSans
leaderboard.goToLeaderBoardButton.textTransform = 'uppercase'
leaderboard.goToLeaderBoardButton.textDecoration = 'none'
leaderboard.goToLeaderBoardButton.fontWeight = '700'
leaderboard.hasRewardRow.background = '#FFFFFF'

export const onetLeaderboard = leaderboard
