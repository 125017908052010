import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'

import { TActions, TNotificationState } from './types'
import { ActionTypes } from './enums'

export const initialState: TNotificationState = {
  data: [],
  loading: {
    fetchNotifications: false,
    dismissNotification: false,
  },
  error: {
    fetchNotifications: null,
    dismissNotification: null,
  },
  resolved: {
    fetchNotifications: false,
    dismissNotification: false,
  },
}

export const reducer: Reducer<TNotificationState, TActions> = (state = initialState, action): TNotificationState => {
  switch (action.type) {
    case ActionTypes.FETCH_NOTIFICATIONS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchNotifications'),
      }
    }
    case ActionTypes.FETCH_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.notifications,
        ...getReduxSuccessStates(state, 'fetchNotifications'),
      }
    }
    case ActionTypes.FETCH_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchNotifications', action.payload),
      }
    }
    case ActionTypes.DISMISS_NOTIFICATION: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'dismissNotification'),
      }
    }
    case ActionTypes.DISMISS_NOTIFICATION_SUCCESS: {
      const notifications = state.data
      const notificationIndex = state.data.findIndex((notification) => notification.id === action.payload.id)
      if (notificationIndex > -1) {
        notifications.splice(notificationIndex, 1)
      }
      return {
        ...state,
        data: notifications,
        ...getReduxSuccessStates(state, 'dismissNotification'),
      }
    }
    case ActionTypes.DISMISS_NOTIFICATION_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'dismissNotification', action.payload),
      }
    }

    default: {
      return state
    }
  }
}
