import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TMedalCountStyle } from 'src/theme/themes/default/components/medalCount'
import { bluefrostColors } from 'src/theme/themes/bluefrost/colors'

const medalCount: TMedalCountStyle = defaultsDeep({}, defaultTheme.components.MedalCount)

medalCount.backgroundColor = bluefrostColors.blueLight1
medalCount.footerRowBg = bluefrostColors.blueLight2

export const bluefrostMedalCount = medalCount
