import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { TQuizWidgetStyle } from 'src/theme/themes/default/components/quizWidget'
import { libertateaCommon } from './common'
import { colors } from 'src/theme/colors'

const quizwidget: TQuizWidgetStyle = defaultsDeep({}, defaultTheme.components.QuizWidget)

quizwidget.borderRadius = libertateaCommon.borderRadius
quizwidget.border = `1.2px solid ${libertateaColors.grey}`
quizwidget.widgetTitlePadding = '0px 32px 12px'
quizwidget.widgetTitlePaddingMobile = quizwidget.widgetTitlePadding
quizwidget.widgetTitleTextStyle = `
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
`
quizwidget.wigetTitleTextStyleMobile = quizwidget.widgetTitleTextStyle
quizwidget.titleTextStyle = `
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
`
quizwidget.titleTextStyleMobile = quizwidget.titleTextStyle
quizwidget.titleTextAlign = 'center'
quizwidget.titleMarginMobile = '8px 0 8px 0'
quizwidget.simpleTeaserMargin = '-8px 0 24px 0'
quizwidget.simpleTeaserMarginMobile = '-8px 0 24px 0'
quizwidget.simpleTeaserTextStyle = `
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
`
quizwidget.simpleTeaserTextStyleMobile = quizwidget.simpleTeaserTextStyle

quizwidget.questionsWrapperPadding = '4px 32px 32px 32px'

quizwidget.answerDefaultBackground = libertateaColors.ultraLightGrey
quizwidget.answerDefaultBackgroundHover = defaultTheme.colors.shades.white
quizwidget.answerDefaultBoxShadow = ' 0px 1px 4px 1px rgba(0, 0, 0, 0.25)'
quizwidget.answerDefaultBorder = `2px solid ${libertateaColors.ultraLightGrey}`
quizwidget.answerDefaultInnerBackground = libertateaColors.greyVeryLight
quizwidget.answerDefaultPercentageBackground = colors.shades.white
quizwidget.answerDefaultPercentageBorder = `2px solid ${libertateaColors.grey}`

quizwidget.answerLoadingBackground = libertateaColors.greyVeryLight
quizwidget.answerLoadingBorder = `2px solid ${libertateaColors.greyVeryLight}`

quizwidget.answerSuccessBackground = libertateaColors.successGreenLight
quizwidget.answerSuccessBorder = `2px solid ${libertateaColors.successGreen}`
quizwidget.answerSuccessInnerBackground = libertateaColors.successGreenMedium

quizwidget.answerFailureBackground = libertateaColors.errorRedLight
quizwidget.answerFailureBorder = `2px solid ${libertateaColors.red}`
quizwidget.answerFailureInnerBackground = libertateaColors.errorRedMedium
quizwidget.answerBorderRadius = libertateaCommon.borderRadius

quizwidget.answerTextStyle = `
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 140%;
`
quizwidget.answerTextStyleMobile = `
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 140%;
`

quizwidget.bottomWrapperTextAlignLoggedin = 'center'
quizwidget.bottomWrapperPaddingDesktop = '32px'
quizwidget.bottomWrapperBackground = libertateaColors.ultraLightGrey
quizwidget.bottomWrapperMargin = '0 -32px -32px -32px'
quizwidget.bottomWrapperMarginMobile = '0 -16px -16px -16px'

export const libertateaQuizWidget = quizwidget
