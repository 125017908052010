import { Bold } from 'src/components/Bold'
import { TQuizWidgetTranslations } from 'src/translations/de/default/quizwidget'

export const quizWidgetTranslations: TQuizWidgetTranslations = {
  indicatiorText: (maxQuestionCount?: number) => `Fragen beantwortet ${maxQuestionCount}/${maxQuestionCount}`,
  resultTitle: {
    all: 'Great job!',
    some: 'Great job!',
    none: 'Oh no!',
  },
  resultSummaryText: (
    points: number,
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number | undefined,
  ) =>
    points ? (
      <>
        You’ve answered{' '}
        <Bold>
          {correctAnswerCount}/{maxQuestionCount} questions correctly
        </Bold>{' '}
        and have collected{' '}
        <Bold>
          {pointsPerCorrectQuestion && maxQuestionCount
            ? `${points}/${pointsPerCorrectQuestion * maxQuestionCount}`
            : '0'}{' '}
          points
        </Bold>{' '}
        today!
        <br />
        <br />
        Come back tomorrow to play again!
      </>
    ) : (
      <>
        You didn't answer <b>a question correctly</b>. Unfortunately, that doesn't give you a point.
      </>
    ),
  resultSummaryEndText: 'Teste dein Wissen morgen erneut!',
  resultTable: {
    rank: 'Rank',
    nickname: 'Benutzername',
    total: 'Total',
    gained: 'Heute',
  },
  leaderboardButtonText: 'Zur Rangliste',
  blickButtonText: 'Blick Ski Nachrichten',

  quizTitle: (
    <>
      Win with the
      <br /> Question of the day!
    </>
  ),
  resultButtonUserWithBets: 'Rennen warten auf tipps',
  resultButtonUser: 'Continue to quiz',
  resultButtonLogin: 'Sign up or login to continue',
  resultButtonLoginAlt: 'Sign up or login to continue',
  resultButtonLoginNotValidUser: 'Sign up or login to continue',
  resultBottomTitle: 'Check out the great prices!',
  resultBottomTextWithBets: (availableBets: number) => <>Du hast noch ungetippte Ski-Rennen.</>,
  resultBottomText: 'Win a price every 2 weeks and find surprise prices from our partners during the game.',
  guestBottomResultTitle: 'Sign up or log in to win prices!',
  guestBottomResultText1: 'Houndreds of great prices to win',
  guestBottomResultText2: 'Price raffle every 2 weeks',
  guestBottomResultText3: 'The more you play, the higher the chance to win',
  singleQuestionSuccessTitle: 'Great job!',
  singleQuestionSuccessDescription: (
    <>
      The more you play the higher your chance to win! <br /> <br /> Come back tomorrow to answer another daily quiz
      question.
    </>
  ),
  singleQuestionFailureTitle: 'Oh no!',
  singleQuestionFailureDescription: (
    <>
      Come back tomorrow and try again!
      <br />
      The link with the hint helps you to find the correct answer.
      <br />
      <br />
      The more you play the higher your chance to win!
    </>
  ),
  continue: 'Continue',
}
