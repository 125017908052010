import styled from 'styled-components/macro'
import { HashLink, HashLinkProps } from 'react-router-hash-link'

export const InternalLink = styled(HashLink)<HashLinkProps>`
  color: ${({ theme }) => theme.components.Link.color};
  text-decoration: ${({ theme }) => theme.components.Link.textDecoration};
  &:hover {
    color: ${({ theme }) => theme.components.Link.hoverColor};
  }
`
