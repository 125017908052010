import { TNavigationLinksTranslations } from 'src/translations/de/default/navigationLinks'

export const navigationLinksTranslations: TNavigationLinksTranslations = {
  hero: 'Intro',
  infoBlock: 'Intro',
  rules: 'Regulament',
  prizes: 'Premii',
  leaderboard: 'Câștigători',
  leaderboardrounded: 'Câștigători',
  leaderboardgroup: 'Team-Ranking', //Not used
  champions: 'Champions', //Not used
  medalcount: 'Medalcount', //Not used
  top3participants: 'Tippspiel', //Not used
  matchbet: 'Bet', //Not used
  quiz: 'Întrebarea Zilei',
  quizbetting: 'Întrebarea Zilei',
  scorebet: 'Tippspiel', //Not used
  backButton: 'Înapoi',
}
