import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'

export type TButtonStyle = typeof defaultButton

const componentTheme = {
  primary: {
    color: colors.shades.white,
    background: colors.blick.grey100,
    hover: {
      background: colors.blick.grey80,
    },
    disabled: {
      background: colors.blick.grey10,
      color: colors.blick.grey70,
    },
  },
  secondary: {
    color: colors.blick.grey100,
    background: colors.shades.white,
    hover: {
      color: colors.blick.grey75,
    },
    disabled: {
      color: colors.blick.grey70,
    },
  },
  ghost: {
    color: colors.blick.grey100,
    background: colors.shades.transparent,
    padding: '4px 8px',
    hover: {
      color: colors.blick.grey90,
      background: colors.blick.grey10,
    },
  },
  underline: {
    fontFamily: '',
    fontSize: '14px',
    color: colors.texts.primary,
    textTransform: 'uppercase',
    fontWeight: 'normal',
  },
  link: {
    padding: 0,
    background: colors.shades.transparent,
    fontSize: 'inherit',
    color: 'inherit',
    height: 'auto',
    textTransform: 'none',
    textDecoration: 'underline',
    fontWeight: '400',
    hover: {
      color: 'inherit',
      background: colors.shades.transparent,
    },
  },
  highlighted: {
    background: colors.blick.sport55,
    color: colors.shades.white,
  },
}

export const defaultButton = {
  //other style
  height: '40px',
  borderRadius: '25px',
  fontFamily: fonts.goodOffcPro,
  fontWeight: 400,
  fontSize: '14px',
  componentTheme,
}
