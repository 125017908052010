import { TNotificationVariables } from 'src/api/notifications/types'
import { Notification } from 'src/api/notifications/enums'

export type TNotificationsTranslations = typeof notificationsTranslations

export const notificationsTranslations: { [key in Notification]: (variables: TNotificationVariables) => JSX.Element } =
  {
    member_left_group: ({ memberName }: TNotificationVariables) => (
      <>
        <b>{memberName}</b> hat die Gruppe verlassen.
      </>
    ),
    member_joined_group: ({ memberName }: TNotificationVariables) => (
      <>
        <b>{memberName}</b> ist der Gruppe beigetreten. Eine tolle Ergänzung für das Team!
      </>
    ),
    group_join_request_denied: ({ groupName }: TNotificationVariables) => (
      <>
        Deine Beitrittsanfrage für die Tippgruppe <b>{groupName}</b> wurde nicht genehmigt.
      </>
    ),
    user_removed_from_group: ({ groupName }: TNotificationVariables) => (
      <>
        Du wurdest aus der Gruppe <b>{groupName}</b> ausgeladen.
      </>
    ),
    group_admin_left: ({ oldAdmin, newAdmin }: TNotificationVariables) => (
      <>
        Gruppen-Captain <b>{oldAdmin}</b> hat die Gruppe verlassen. <b>{newAdmin}</b> ist der neue Captain.
      </>
    ),
    group_admin_left_you_admin: ({ oldAdmin }: TNotificationVariables) => (
      <>
        Gruppen-Captain <b>{oldAdmin}</b> hat die Gruppe verlassen. Du bist der neue Captain.
      </>
    ),
    new_pending_join_request: ({ joinRequestPending }) => (
      <>
        Der Spieler <b>{joinRequestPending?.username}</b> möchte deiner Tippgruppe beitreten.
      </>
    ),
    new_group_invitation: ({ invitation }: TNotificationVariables) => (
      <>
        <b>{invitation?.captain}</b> möchte, dass du der Tippgruppe <b>{invitation?.groupName}</b> beitrittst. Willst du
        die Einladung annehmen?
      </>
    ),
  }
