import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { THeaderStyle } from 'src/theme/themes/default/components/header'
import { onetColors } from 'src/theme/themes/onet/colors'

const header: THeaderStyle = defaultsDeep({}, defaultTheme.components.Header)

header.background = onetColors.red
header.height = '70px'
header.heightMobile = '60px'
header.shadow = '0px 2px 2px rgba(0, 0, 0, 0.11)'
header.logoHeight = '37px'
header.logoHeightMobile = '24px'
header.logoLinkHeight = '37px'
header.logoLinkHeightMobile = '24px'
header.infoBlockBackground = onetColors.red
header.infoBlockBorder = `1px solid ${defaultTheme.colors.shades.white}`
header.userNameTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: ${defaultTheme.colors.shades.white};
`
header.userNameTextStyleMobile = `
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: ${defaultTheme.colors.shades.white};
`
header.notLoggedInTextStyle = header.userNameTextStyle
header.notLoggedInTextStyleMobile = header.userNameTextStyle
header.userPointsTextStyle = header.userNameTextStyle
header.userPointsTextStyleMobile = header.userNameTextStyleMobile
header.iconColor = defaultTheme.colors.shades.white
header.infoDividerColor = defaultTheme.colors.shades.white
header.navMenuBackgroundColor = onetColors.lightGrey2
header.navMenuHeight = '50px'
header.navMenuHeightMobile = '50px'
header.navLinkMargin = '0 32px 0 0'
header.navLinkTextStyle = `
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    text-decoration: none;
    color: ${onetColors.darkGrey};
    text-transform: uppercase;
`
header.navLinkTextStyleMobile = header.navLinkTextStyle
header.navLinkHoverTextStyle = `
    color: ${onetColors.red};
`
header.navLinkSelectedTextStyle = header.navLinkHoverTextStyle

export const onetHeader = header
