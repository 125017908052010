import { Bold } from 'src/components/Bold'
import { ExternalLink } from 'src/components/links/ExternalLink'
import { InternalLink } from 'src/components/links/InternalLink'

import { TQuizTranslations } from 'src/translations/de/default/quiz'

export const quizTranslations: TQuizTranslations = {
  title: 'Question of the Day',
  description: <>Answer to the daily question and get the chance to win great prizes. Good luck!</>,
  seasonEndCardTitle: 'Thanks for playing Libertatea quiz!',
  seasonEndCardText: (
    <>
      Our quiz game is now over. Find all the winners in the{' '}
      <InternalLink to={'#rangliste'}>winner section</InternalLink> below.
    </>
  ),
  seasonEndCardButtonText: null,
  indicatiorText: (maxQuestionCount?: number) => `Fragen beantwortet ${maxQuestionCount}/${maxQuestionCount}`,
  resultTopText: 'Ergebnis des TagesQuiz',
  resultTitle: {
    all: 'Good job!',
    some: 'Good job!',
    none: 'Oh no!',
  },
  resultSummaryText: (
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number,
    gainedPoints: number,
  ) => (
    <>
      Du hast{' '}
      <Bold>
        {correctAnswerCount}/{maxQuestionCount}
      </Bold>{' '}
      Quizfragen richtig beantwortet und{' '}
      <Bold>
        {gainedPoints > 0 && maxQuestionCount && pointsPerCorrectQuestion
          ? `${gainedPoints}/${maxQuestionCount * pointsPerCorrectQuestion}`
          : 0}{' '}
        Punkte
      </Bold>{' '}
      gesammelt.
    </>
  ),
  resultSummaryTextGroup: (
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number,
    gainedPoints: number,
  ) => {
    const gainedPointsRatio =
      gainedPoints > 0 && maxQuestionCount && pointsPerCorrectQuestion
        ? `${gainedPoints}/${maxQuestionCount * pointsPerCorrectQuestion}`
        : 0

    return (
      <>
        Du hast{' '}
        <Bold>
          {correctAnswerCount}/{maxQuestionCount} Quizfragen
        </Bold>{' '}
        richtig beantwortet und <Bold>{gainedPointsRatio} Punkte für dich sowie für dein Team</Bold> gesammelt.
      </>
    )
  },
  resultSummaryEndText: 'Morgen kannst du dein Wissen aufs Neue testen!',
  resultSummaryEndTextGroup: (_: number, __: number) => quizTranslations.resultSummaryEndText,
  resultTable: {
    rank: 'Rank',
    nickname: 'Name',
    group: 'Team',
    total: 'Total',
    gained: 'Heute',
  },
  leaderboardButtonText: 'Zur Rangliste',
  blickButtonText: 'Blick Ski Nachrichten',
  cardErrorTitle: 'Ooops...',
  noMoreQuestionMessage: (
    <>
      Question could not be loaded. Please come back later. If this error reoccures, please contact{' '}
      <ExternalLink href="mailto:suport@libertatea.ro">suport@libertatea.ro</ExternalLink>.
    </>
  ),
  unknownErrorMessage: (
    <>
      There is an unknown error. Please refresh the page. If this error reoccures, please contact{' '}
      <ExternalLink href="mailto:suport@libertatea.ro">suport@libertatea.ro</ExternalLink>.
    </>
  ),
  collectedPoints: (collectedPoints: number) => (
    <>
      {collectedPoints === 0 ? '' : '+'} {collectedPoints} Punkte
    </>
  ),
  questionName: 'Question',
  nextResultsHint: 'Heutige Resultate folgen',
  nextQuestionHint: 'Nächste Frage folgt',
  continue: 'Continue',
  nextQuestion: 'Continue',
  teaser: <>Try to find the correct answer in this article:</>,
  simpleTeaser: (url: string, onClick?: () => void) => (
    <>
      <b>Psst!</b> Find here the{' '}
      <ExternalLink onClick={onClick} target="_blank" href={url}>
        correct answer
      </ExternalLink>
      .
    </>
  ),
  answersName: 'Answers',
  allAnsweredCard: {
    title: 'You have already answered the question of the day',
    description:
      'Thank you for logging in! You have already answered this question. Click on the button below to see how you played.',
    button: 'See quiz result',
  },
  alreadyAnsweredCard: {
    title: 'You have already answered the question of the day',
    description:
      'Thank you for logging in! You have already answered this question. Click on the button below to see how you played.',
    button: 'See quiz result',
  },
  singleQuestionSuccessTitle: 'Great job!',
  singleQuestionSuccessDescription: (
    <>
      The more you play the higher your chance to win! <br /> <br /> Come back tomorrow to answer another daily quiz
      question.
    </>
  ),
  singleQuestionFailureTitle: 'Oh no!',
  singleQuestionFailureDescription: (
    <>
      Come back tomorrow and try again!
      <br />
      The link with the hint helps you to find the correct answer.
      <br />
      <br />
      The more you play the higher your chance to win!
    </>
  ),
  singleQuestionResultButton: 'Stay up to date with Libertatea',
}
