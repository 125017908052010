export const deviceWidths = {
  mobile: 1024,
}

export const media = {
  isMobile: `@media (max-width: ${deviceWidths.mobile}px)`,
  isDesktop: `@media (min-width: ${deviceWidths.mobile + 1}px)`,
  isHover: ':hover',
  isActive: ':active',
}
