import { TCommonStyle } from 'src/theme/themes/default/components/common'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { greenfieldColors } from 'src/theme/themes/greenfield/colors'

const common: TCommonStyle = defaultsDeep({}, defaultTheme.components.common)

common.background = greenfieldColors.green
common.borderRadius = '8px'
common.boxShadow = 'none'

export const greenfieldCommon = common
