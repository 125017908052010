import { defaultsDeep } from 'lodash-es'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { TLinkStyle } from 'src/theme/themes/default/components/link'

const link: TLinkStyle = defaultsDeep({}, defaultTheme.components.Link)

link.color = libertateaColors.red
link.hoverColor = libertateaColors.redDark
link.textDecoration = 'none'

export const libertateaLink = link
