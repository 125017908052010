import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { Actions, TWinnersState } from './types'

export const initialState: TWinnersState = {
  data: null,
  loading: {
    fetchWinners: false,
  },
  error: {
    fetchWinners: null,
  },
  resolved: {
    fetchWinners: false,
  },
}

export const reducer: Reducer<TWinnersState, Actions> = (state = initialState, action): TWinnersState => {
  switch (action.type) {
    case ActionTypes.FETCH_WINNERS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchWinners'),
      }
    }
    case ActionTypes.FETCH_WINNERS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ...getReduxSuccessStates(state, 'fetchWinners'),
      }
    }
    case ActionTypes.FETCH_WINNERS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchWinners', action.payload),
      }
    }
    default:
      return state
  }
}
