export enum ActionTypes {
  CREATE_GROUP = '@USER_GROUP/CREATE_GROUP',
  CREATE_GROUP_SUCCESS = '@USER_GROUP/CREATE_GROUP_SUCCESS',
  CREATE_GROUP_FAILURE = '@USER_GROUP/CREATE_GROUP_FAILURE',
  CLEAR_CREATE_GROUP_STATES = '@USER_GROUP/CLEAR_CREATE_GROUP_STATES',

  UPDATE_GROUP = '@USER_GROUP/UPDATE_GROUP',
  UPDATE_GROUP_SUCCESS = '@USER_GROUP/UPDATE_GROUP_SUCCESS',
  UPDATE_GROUP_FAILURE = '@USER_GROUP/UPDATE_GROUP_FAILURE',
  CLEAR_UPDATE_GROUP_STATES = '@USER_GROUP/CLEAR_UPDATE_GROUP_STATES',

  FETCH_GROUP = '@USER_GROUP/FETCH_GROUP',
  FETCH_GROUP_SUCCESS = '@USER_GROUP/FETCH_GROUP_SUCCESS',
  FETCH_GROUP_FAILURE = '@USER_GROUP/FETCH_GROUP_FAILURE',

  UPDATE_GROUP_NAME = '@USER_GROUP/UPDATE_GROUP_NAME',
  UPDATE_GROUP_NAME_SUCCESS = '@USER_GROUP/UPDATE_GROUP_NAME_SUCCESS',
  UPDATE_GROUP_NAME_FAILURE = '@USER_GROUP/UPDATE_GROUP_NAME_FAILURE',
  CLEAR_UPDATE_GROUP_NAME_STATES = '@USER_GROUP/CLEAR_UPDATE_GROUP_NAME_STATES',

  UPDATE_GROUP_DESCRIPTION = '@USER_GROUP/UPDATE_GROUP_DESCRIPTION',
  UPDATE_GROUP_DESCRIPTION_SUCCESS = '@USER_GROUP/UPDATE_GROUP_DESCRIPTION_SUCCESS',
  UPDATE_GROUP_DESCRIPTION_FAILURE = '@USER_GROUP/UPDATE_GROUP_DESCRIPTION_FAILURE',
  CLEAR_UPDATE_GROUP_DESCRIPTION_STATES = '@USER_GROUP/UPDATE_GROUP_DESCRIPTION_STATES',

  DELETE_USER_FROM_GROUP = '@USER_GROUP/DELETE_USER_FROM_GROUP',
  DELETE_USER_FROM_GROUP_SUCCESS = '@USER_GROUP/DELETE_USER_FROM_GROUP_SUCCESS',
  DELETE_USER_FROM_GROUP_FAILURE = '@USER_GROUP/DELETE_USER_FROM_GROUP_FAILURE',

  LEAVE_GROUP = '@USER_GROUP/LEAVE_GROUP',
  LEAVE_GROUP_SUCCESS = '@USER_GROUP/LEAVE_GROUP_SUCCESS',
  LEAVE_GROUP_FAILURE = '@USER_GROUP/LEAVE_GROUP_FAILURE',
  CLEAR_LEAVE_GROUP_STATES = '@USER_GROUP/CLEAR_LEAVE_GROUP_STATES',

  FETCH_INVITATIONS = '@USER_GROUP/FETCH_INVITATIONS',
  FETCH_INVITATIONS_SUCCESS = '@USER_GROUP/FETCH_INVITATIONS_SUCCESS',
  FETCH_INVITATIONS_FAILURE = '@USER_GROUP/FETCH_INVITATIONS_FAILURE',

  ACCEPT_INVITATION = '@USER_GROUP/ACCEPT_INVITATION',
  ACCEPT_INVITATION_SUCCESS = '@USER_GROUP/ACCEPT_INVITATION_SUCCESS',
  ACCEPT_INVITATION_FAILURE = '@USER_GROUP/ACCEPT_INVITATION_FAILURE',
  CLEAR_ACCEPT_INVITATION_STATES = '@USER_GROUP/CLEAR_ACCEPT_INVITATION_STATES',

  DECLINE_INVITATION = '@USER_GROUP/DECLINE_INVITATION',
  DECLINE_INVITATION_SUCCESS = '@USER_GROUP/DECLINE_INVITATION_SUCCESS',
  DECLINE_INVITATION_FAILURE = '@USER_GROUP/DECLINE_INVITATION_FAILURE',

  DELETE_INVITATION = '@USER_GROUP/DELETE_INVITATION',
  DELETE_INVITATION_SUCCESS = '@USER_GROUP/DELETE_INVITATION_SUCCESS',
  DELETE_INVITATION_FAILURE = '@USER_GROUP/DELETE_INVITATION_FAILURE',
  CLEAR_DELETE_INVITATION_STATES = '@USER_GROUP/CLEAR_DELETE_INVITATION_STATES',

  INVITE_PLAYERS = '@USER_GROUP/INVITE_PLAYERS',
  INVITE_PLAYERS_SUCCESS = '@USER_GROUP/INVITE_PLAYERS_SUCCESS',
  INVITE_PLAYERS_FAILURE = '@USER_GROUP/INVITE_PLAYERS_FAILURE',
  CLEAR_INVITE_PLAYERS_STATES = '@USER_GROUP/CLEAR_INVITE_PLAYERS_STATES',

  FETCH_JOIN_GROUP = '@USER_GROUP/FETCH_JOIN_GROUP',
  FETCH_JOIN_GROUP_SUCCESS = '@USER_GROUP/FETCH_JOIN_GROUP_SUCCESS',
  FETCH_JOIN_GROUP_FAILURE = '@USER_GROUP/FETCH_JOIN_GROUP_FAILURE',

  FETCH_JOIN_REQUESTS = '@USER_GROUP/FETCH_JOIN_REQUESTS',
  FETCH_JOIN_REQUESTS_SUCCESS = '@USER_GROUP/FETCH_JOIN_REQUESTS_SUCCESS',
  FETCH_JOIN_REQUESTS_FAILURE = '@USER_GROUP/FETCH_JOIN_REQUESTS_FAILURE',

  DELETE_JOIN_REQUEST = '@USER_GROUP/DELETE_JOIN_REQUEST',
  DELETE_JOIN_REQUEST_SUCCESS = '@USER_GROUP/DELETE_JOIN_REQUEST_SUCCESS',
  DELETE_JOIN_REQUEST_FAILURE = '@USER_GROUP/DELETE_JOIN_REQUEST_FAILURE',
  CLEAR_JOIN_REQUESTS = '@USER_GROUP/CLEAR_JOIN_REQUESTS',

  ACCEPT_JOIN_GROUP = '@USER_GROUP/ACCEPT_JOIN_GROUP',
  ACCEPT_JOIN_GROUP_SUCCESS = '@USER_GROUP/ACCEPT_JOIN_GROUP_SUCCESS',
  ACCEPT_JOIN_GROUP_FAILURE = '@USER_GROUP/ACCEPT_JOIN_GROUP_FAILURE',
  ACCEPT_JOIN_GROUP_RESET = '@USER_GROUP/ACCEPT_JOIN_GROUP_RESET',

  CREATE_JOIN_LINK = '@USER_GROUP/CREATE_JOIN_LINK',
  CREATE_JOIN_LINK_SUCCESS = '@USER_GROUP/CREATE_JOIN_LINK_SUCCESS',
  CREATE_JOIN_LINK_FAILURE = '@USER_GROUP/CREATE_JOIN_LINK_FAILURE',

  ACCEPT_INCOMING_JOIN_REQUEST = '@USER_GROUP/ACCEPT_INCOMING_JOIN_REQUEST',
  ACCEPT_INCOMING_JOIN_REQUEST_SUCCESS = '@USER_GROUP/ACCEPT_INCOMING_JOIN_REQUEST_SUCCESS',
  ACCEPT_INCOMING_JOIN_REQUEST_FAILURE = '@USER_GROUP/ACCEPT_INCOMING_JOIN_REQUEST_FAILURE',

  DECLINE_INCOMING_JOIN_REQUEST = '@USER_GROUP/DECLINE_INCOMING_JOIN_REQUEST',
  DECLINE_INCOMING_JOIN_REQUEST_SUCCESS = '@USER_GROUP/DECLINE_INCOMING_JOIN_REQUEST_SUCCESS',
  DECLINE_INCOMING_JOIN_REQUEST_FAILURE = '@USER_GROUP/DECLINE_INCOMING_JOIN_REQUEST_FAILURE',

  SEND_JOIN_REQUEST = '@USER_GROUP/SEND_JOIN_REQUEST',
  SEND_JOIN_REQUEST_SUCCESS = '@USER_GROUP/SEND_JOIN_REQUEST_SUCCESS',
  SEND_JOIN_REQUEST_FAILURE = '@USER_GROUP/SEND_JOIN_REQUEST_FAILURE',

  SET_OPENED_MODAL = '@USER_GROUP/SET_OPENED_MODAL',

  MANAGE_GROUP_NAME = '@USER_GROUP/MANAGE_GROUP_NAME',
  MANAGE_GROUP_NAME_VALIDATION_ERROR = '@USER_GROUP/MANAGE_GROUP_NAME_VALIDATION_ERROR',
  CLEAR_MANAGE_GROUP_NAME_DATA = '@USER_GROUP/CLEAR_MANAGE_GROUP_NAME_DATA',
  MANAGE_GROUP_INVITATIONS = '@USER_GROUP/MANAGE_GROUP_INVITATIONS',
  MANAGE_GROUP_INVITATIONS_VALIDATION_ERROR = '@USER_GROUP/MANAGE_GROUP_INVITATIONS_VALIDATION_ERROR',
  CLEAR_MANAGE_GROUP_INVITATIONS_DATA = '@USER_GROUP/CLEAR_MANAGE_GROUP_INVITATIONS_DATA',
  MANAGE_GROUP_DESCRIPTION = '@USER_GROUP/MANAGE_GROUP_DESCRIPTION',
  MANAGE_GROUP_DESCRIPTION_VALIDATION_ERROR = '@USER_GROUP/MANAGE_GROUP_DESCRIPTION_VALIDATION_ERROR',
  CLEAR_MANAGE_GROUP_DESCRIPTION_DATA = '@USER_GROUP/CLEAR_MANAGE_GROUP_DESCRIPTION_DATA',

  START_ANIMATION = '@USER_GROUP/START_ANIMATION',
  STOP_ANIMATION = '@USER_GROUP/STOP_ANIMATION',
}

export enum UserGroupModal {
  PendingRequests = 'pending-requests',
  LeaveGroup = 'leave-group',
  DeleteUserFromGroup = 'delete-user-from-group',
  DeleteInvitation = 'delete-invitation',
  DiscardChanges = 'discard-changes',
  DiscardChangesEdit = 'discard-changes-edit',
}
