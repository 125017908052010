import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { THeroStyle } from 'src/theme/themes/default/components/hero'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'

const hero: THeroStyle = defaultsDeep({}, defaultTheme.components.Hero)

hero.width = '100vw'
hero.margin = `0 0 0 calc((${defaultTheme.measures.contentWidth} - 100vw) / 2)`
hero.padding = '40px 0 0 0'
hero.background = libertateaColors.greyVeryLightAlt
hero.slideDesktopHeight = '360px'
hero.slideMobileHeight = 'auto'

export const libertateaHero = hero
