import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { greenfieldColors } from 'src/theme/themes/greenfield/colors'
import { TTableStyle } from 'src/theme/themes/default/components/table'

const table: TTableStyle = defaultsDeep({}, defaultTheme.components.Table)

table.totalRow.background = '#EBF5F0'
table.activeRowColor = greenfieldColors.green55
table.descriptionFontSize = '12px'

export const greenfieldTable = table
