import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { Actions, TRulesState } from './types'

export const initialState: TRulesState = {
  data: null,
  loading: {
    fetchRules: false,
  },
  error: {
    fetchRules: null,
  },
  resolved: {
    fetchRules: false,
  },
}

export const reducer: Reducer<TRulesState, Actions> = (state = initialState, action): TRulesState => {
  switch (action.type) {
    case ActionTypes.FETCH_RULES: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchRules'),
      }
    }
    case ActionTypes.FETCH_RULES_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        ...getReduxSuccessStates(state, 'fetchRules'),
      }
    }
    case ActionTypes.FETCH_RULES_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchRules', action.payload),
      }
    }
    default:
      return state
  }
}
