import { TEndOfSeasonModalStyle } from 'src/theme/themes/default/components/endOfSeasonModal'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { accentwayColors } from 'src/theme/themes/accentway/colors'
import { colors } from 'src/theme/colors'

const endOfSeasonModal: TEndOfSeasonModalStyle = defaultsDeep({}, defaultTheme.components.EndOfSeasonModal)

endOfSeasonModal.backgroundColor = accentwayColors.turquoise
endOfSeasonModal.backgroundLinear = `linear-gradient(135deg, ${accentwayColors.blueAlt} 45%, ${accentwayColors.turquoise} 45%)`
endOfSeasonModal.basicColor = '#fff'
endOfSeasonModal.statisticsSubtitleBgColor = colors.blick.grey100
endOfSeasonModal.statisticsSubtitleColor = '#fff'
endOfSeasonModal.statisticsBlockBgColor = '#fff'
endOfSeasonModal.statisticsBlockColor = accentwayColors.turquoise

export const accentwayEndOfSeasonModal = endOfSeasonModal
