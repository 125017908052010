import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes } from './enums'
import { Actions, TFeedbackState } from './types'

export const initialState: TFeedbackState = {
  data: null,
  loading: {
    fetchFeedback: false,
    postFeedback: false,
  },
  error: {
    fetchFeedback: null,
    postFeedback: null,
  },
  resolved: {
    fetchFeedback: false,
    postFeedback: false,
  },
}

export const reducer: Reducer<TFeedbackState, Actions> = (state = initialState, action): TFeedbackState => {
  switch (action.type) {
    case ActionTypes.FETCH_FEEDBACK: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchFeedback'),
      }
    }
    case ActionTypes.FETCH_FEEDBACK_SUCCESS: {
      return {
        ...state,
        data: {
          fetchFeedback: action.payload,
          postFeedback: {
            isSubmited: false,
          },
        },
        ...getReduxSuccessStates(state, 'fetchFeedback'),
      }
    }
    case ActionTypes.FETCH_FEEDBACK_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchFeedback', action.payload),
      }
    }
    case ActionTypes.POST_FEEDBACK: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'postFeedback'),
      }
    }
    case ActionTypes.POST_FEEDBACK_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          postFeedback: action.payload,
        },
        ...getReduxSuccessStates(state, 'postFeedback'),
      }
    }
    case ActionTypes.POST_FEEDBACK_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'postFeedback', action.payload),
      }
    }
    case ActionTypes.CLOSE_FEEDBACK: {
      return {
        ...state,
        data: {
          fetchFeedback: {
            ...state.data,
            shouldShow: false,
            ticket: null,
          },
        },
      }
    }
    default:
      return state
  }
}
