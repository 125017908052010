import { Reducer } from 'redux'
import { cloneDeep } from 'lodash-es'

import { config } from 'src/config'
import { ActionTypes as AuthActionTypes } from 'src/api/auth/enums'
import { TLogoutAction } from 'src/api/auth/types'

import { ActionTypes } from './enums'
import { TBettingState, TBetType, TActions, TRace, TParticipantsType } from './types'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { ActionTypes as PowerupsActionTypes } from 'src/api/powerups/enums'
import { GameId } from 'src/enums/other'

export const initialState: TBettingState = {
  data: {
    detail: {
      selectedParticipants: [],
    },
    races: [],
    participants: {
      male: [],
      female: [],
    },
  },
  loading: {
    fetchRaces: false,
    fetchRace: false,
    fetchBets: false,
    placeBet: false,
  },
  error: {
    fetchRaces: null,
    fetchRace: null,
    fetchBets: null,
    placeBet: null,
  },
  resolved: {
    fetchRaces: false,
    fetchRace: false,
    fetchBets: false,
    placeBet: false,
  },
}

export const reducer: Reducer<TBettingState, TActions | TLogoutAction> = (
  state = initialState,
  action,
): TBettingState => {
  switch (action.type) {
    case ActionTypes.DETAIL_SELECT_PARTICIPANT: {
      const skier = action.payload.participant
      let selectedParticipants: TBetType[] = cloneDeep(state.data.detail.selectedParticipants)
      const skierIndex = selectedParticipants.findIndex((s) => s.id === skier.id)

      // Add the skier if not already present in the selection
      if (skierIndex < 0) {
        selectedParticipants =
          selectedParticipants.length < config.game.maxSkiersOnBet
            ? [...selectedParticipants, skier]
            : selectedParticipants
      }

      return {
        ...state,
        data: {
          ...state.data,
          detail: { selectedParticipants },
        },
      }
    }
    case ActionTypes.DETAIL_DESELECT_PARTICIPANT: {
      const skier = action.payload.participant
      const stateSelectedParticipants = state.data.detail.selectedParticipants
      const selectedParticipants =
        stateSelectedParticipants.length > 0
          ? stateSelectedParticipants.filter((s) => s.id !== skier.id)
          : stateSelectedParticipants

      return {
        ...state,
        data: {
          ...state.data,
          detail: { selectedParticipants },
        },
      }
    }
    case ActionTypes.FETCH_RACES: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchRaces'),
      }
    }
    case ActionTypes.FETCH_RACES_SUCCESS: {
      const races: TRace[] = action.payload.races
      const participants: TParticipantsType = action.payload.participants

      if (!Array.isArray(races)) {
        return {
          ...state,
          ...getReduxSuccessStates(state, 'fetchRaces'),
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          races,
          participants,
        },
        ...getReduxSuccessStates(state, 'fetchRaces'),
      }
    }
    case ActionTypes.FETCH_RACES_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchRaces', action.payload),
      }
    }
    case ActionTypes.FETCH_RACE: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchRace'),
      }
    }
    case ActionTypes.FETCH_RACE_SUCCESS: {
      const races = state.data.races
      const race = action.payload
      const raceIndex = races.findIndex((currentRace) => currentRace.race.id === race.race.id)

      if (raceIndex > -1) {
        races.splice(raceIndex, 1, race)
      } else {
        races.push(race)
      }

      return {
        ...state,
        data: {
          ...state.data,
          races: races,
          detail: {
            selectedParticipants: race?.bet || [],
          },
        },
        ...getReduxSuccessStates(state, 'fetchRace'),
      }
    }
    case ActionTypes.FETCH_RACE_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchRace', action.payload),
      }
    }
    case ActionTypes.PLACE_BET: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'placeBet'),
      }
    }
    case ActionTypes.PLACE_BET_SUCCESS:
      return {
        ...state,
        ...getReduxSuccessStates(state, 'placeBet'),
      }
    case ActionTypes.PLACE_BET_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'placeBet', action.payload),
      }
    }
    case PowerupsActionTypes.APPLY_POWERUPS_SUCCESS: {
      const { eventId, powerupId, powerupType, gameId } = action.payload

      if (gameId !== GameId.Top3participants) {
        return state
      }

      const eventToUpdate = state.data.races.find((race) => race.race.id === eventId)
      const eventToUpdateIndex = state.data.races.findIndex((race) => race.race.id === eventId)
      let updatedRaces = state.data.races

      //Assign new powerups values after updates
      if (eventToUpdateIndex >= 0 && eventToUpdate) {
        updatedRaces = [
          ...updatedRaces.slice(0, eventToUpdateIndex),
          { ...eventToUpdate, powerupId, powerupType },
          ...updatedRaces.slice(eventToUpdateIndex + 1),
        ]
      }

      return {
        ...state,
        data: {
          ...state.data,
          races: updatedRaces,
        },
      }
    }
    case AuthActionTypes.AUTH_LOGOUT:
    case ActionTypes.RESET_BETS_FETCH_STATES: {
      return {
        ...initialState,
        data: {
          ...state.data,
        },
      }
    }
    default:
      return state
  }
}
