import { TCollapsibleStyle } from 'src/theme/themes/default/components/collapsible'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'

const collapsible: TCollapsibleStyle = defaultsDeep({}, defaultTheme.components.Collapsible)
collapsible.borderRadius = '0px'
collapsible.headerTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    color: ${defaultTheme.colors.blick.grey90};
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
`
collapsible.boxShadow = '0px 2px 4px 1px rgba(0, 0, 0, 0.15)'
collapsible.arrowColor = defaultTheme.colors.blick.grey80

export const accentwayCollapsible = collapsible
