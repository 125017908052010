import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { greenfieldColors } from 'src/theme/themes/greenfield/colors'
import { TQuizWidgetStyle } from 'src/theme/themes/default/components/quizWidget'

const quizWidget: TQuizWidgetStyle = defaultsDeep({}, defaultTheme.components.QuizWidget)

quizWidget.indicatorTextStyleMobile = quizWidget.indicatorTextStyle
quizWidget.progressBarTextStyle = `
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${greenfieldColors.intenseGreen};
`
quizWidget.progressBarBackground = defaultTheme.colors.shades.white
quizWidget.progressBarInnerBarBackground = greenfieldColors.green
quizWidget.bottomWrapperBackground = greenfieldColors.green5
quizWidget.bottomWrapperMargin = '0 -66px -16px -12px'

export const greenfieldQuizWidget = quizWidget
