import { Reducer } from 'redux'

import { TActions, TScoreBetState } from './types'
import { ActionTypes } from './enums'
import { ActionTypes as PowerupsActionTypes } from 'src/api/powerups/enums'

import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { GameId } from 'src/enums/other'

export const initialState: TScoreBetState = {
  data: {
    matches: [],
  },
  loading: {
    fetchMatches: false,
    placeBet: [],
    fetchBetsWithScore: false,
  },
  error: {
    fetchMatches: null,
    placeBet: [],
    fetchBetsWithScore: null,
  },
  resolved: {
    fetchMatches: false,
    placeBet: [],
    fetchBetsWithScore: false,
  },
}

export const reducer: Reducer<TScoreBetState, TActions> = (state = initialState, action): TScoreBetState => {
  switch (action.type) {
    case ActionTypes.FETCH_MATCHES: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchMatches'),
      }
    }
    case ActionTypes.FETCH_MATCHES_SUCCESS: {
      const matches = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          matches,
        },
        ...getReduxSuccessStates(state, 'fetchMatches'),
      }
    }
    case ActionTypes.FETCH_MATCHES_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchMatches', action.payload),
      }
    }

    case ActionTypes.PLACE_BET: {
      const { matchId } = action.payload
      const newPlaceBetLoading = [...state.loading.placeBet, matchId]
      const newPlaceBetResolved = state.resolved.placeBet.filter((item) => item !== matchId)
      return {
        ...state,
        loading: {
          ...state.loading,
          placeBet: newPlaceBetLoading,
        },
        resolved: {
          ...state.resolved,
          placeBet: newPlaceBetResolved,
        },
      }
    }
    case ActionTypes.PLACE_BET_SUCCESS: {
      const { matchId, bet } = action.payload
      const newPlaceBetLoading = state.loading.placeBet.filter((item) => item !== matchId)
      const newPlaceBetResolved = [...state.resolved.placeBet, matchId]

      let newMatches = state.data.matches

      if (action.payload.component !== 'scrobetwidget') {
        const matchToUpdateIndex = state.data.matches.findIndex((item) => item.id === matchId)
        const matchToUpdate = state.data.matches.find((item) => item.id === matchId)

        if (matchToUpdate) {
          newMatches = [
            ...newMatches.slice(0, matchToUpdateIndex),
            { ...matchToUpdate, bet },
            ...newMatches.slice(matchToUpdateIndex + 1),
          ]
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          matches: newMatches,
        },
        loading: {
          ...state.loading,
          placeBet: newPlaceBetLoading,
        },
        resolved: {
          ...state.resolved,
          placeBet: newPlaceBetResolved,
        },
      }
    }
    case ActionTypes.PLACE_BET_FAILURE: {
      const { matchId } = action.payload
      const newPlaceBetLoading = state.loading.placeBet.filter((item) => item !== matchId)
      const newPlaceBetError = [...state.error.placeBet, { matchId, error: action.errorPayload }]

      return {
        ...state,
        loading: {
          ...state.loading,
          placeBet: newPlaceBetLoading,
        },
        error: {
          ...state.error,
          placeBet: newPlaceBetError,
        },
      }
    }

    case ActionTypes.CLEAR_PLACE_BET_ERROR: {
      const { matchId } = action.payload
      const newPlaceBetError = state.error.placeBet.filter((item) => item.matchId !== matchId)

      return {
        ...state,
        error: {
          ...state.error,
          placeBet: newPlaceBetError,
        },
      }
    }

    case PowerupsActionTypes.APPLY_POWERUPS_SUCCESS: {
      const { eventId, powerupId, powerupType, gameId } = action.payload

      if (gameId !== GameId.ScoreBet) {
        return state
      }

      const eventToUpdate = state.data.matches.find((match) => match.id === eventId)
      const eventToUpdateIndex = state.data.matches.findIndex((match) => match.id === eventId)
      let updatedMatches = state.data.matches

      //Assign new powerups values after updates
      if (eventToUpdateIndex > -1 && eventToUpdate) {
        updatedMatches = [
          ...updatedMatches.slice(0, eventToUpdateIndex),
          { ...eventToUpdate, powerupId, powerupType },
          ...updatedMatches.slice(eventToUpdateIndex + 1),
        ]
      }

      return {
        ...state,
        data: {
          ...state.data,
          matches: updatedMatches,
        },
      }
    }

    case ActionTypes.FETCH_BETS_WITH_SCORE: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchBetsWithScore'),
      }
    }
    case ActionTypes.FETCH_BETS_WITH_SCORE_SUCCESS: {
      const { id, betWithScore } = action.payload
      const matches = state.data.matches.slice()
      const matchIndex = matches.findIndex((match) => match.id === id)
      const match = matches[matchIndex]
      if (match) {
        match.fractionOfBetsWithSameScore = betWithScore.fraction
        matches.splice(matchIndex, 1, match)
      }

      return {
        ...state,
        data: {
          ...state.data,
          matches,
        },
        ...getReduxSuccessStates(state, 'fetchBetsWithScore'),
      }
    }
    case ActionTypes.FETCH_BETS_WITH_SCORE_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchBetsWithScore', action.payload),
      }
    }

    default: {
      return state
    }
  }
}
