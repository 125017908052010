import { defaultsDeep } from 'lodash-es'

import { powerupsTranslations, TPowerupsTranslations } from 'src/translations/de/default/powerups'

export const frauenwmPowerupsTranslations: TPowerupsTranslations = defaultsDeep({}, powerupsTranslations)

frauenwmPowerupsTranslations.infoMyPowerups = () =>
  `Das sind deine bisher eingesetzten oder noch verfügbaren Booster. In jeder Runde gibt es neue Booster.`
frauenwmPowerupsTranslations.noAvailablePowerups = (
  <>
    Du hast leider schon alle Booster der aktuellen Runde verbraucht.
    <br /> <br /> Neue Booster gibt es wieder in der nächsten Runde.
  </>
)
frauenwmPowerupsTranslations.availablePowerups = (totalPowerupsLength: number, avalablePowerupsLength: number) =>
  `Du hast ${avalablePowerupsLength} Booster bis zum Ende dieser Runde zur Verfügung.`
