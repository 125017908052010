import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TUserDashBoardStyle } from 'src/theme/themes/default/components/userDashBoard'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'

const userDashBoard: TUserDashBoardStyle = defaultsDeep({}, defaultTheme.components.UserDashBoard)

userDashBoard.sectionPadding = '32px 32px'
userDashBoard.sectionWidePadding = '32px 32px'
userDashBoard.sectionPaddingMobile = '32px 16px'
userDashBoard.sectionBorderColor = libertateaColors.grey
userDashBoard.sectionTitleText = `
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
`
userDashBoard.sectionRowIndent = '16px'
userDashBoard.sectionValueText = `
  font-weight:300;
`
userDashBoard.headerPadding = '24px 32px 32px'
userDashBoard.headerPaddingMobile = '24px 16px 32px'
userDashBoard.actionButtonTextStyle = `
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: ${libertateaColors.red};
`

export const libertateaUserDashBoard = userDashBoard
