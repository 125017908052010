import { createContext, useMemo } from 'react'
import { TTranslations, TTranslationsProvider } from './types'
import { getTranslations } from './utils'
import { translations as defaultTranslations } from './de/default'

export const TranslationsContext = createContext<TTranslations>(defaultTranslations)

export const TranslationsProvider = ({ children, productId }: TTranslationsProvider) => {
  const productTranslations = useMemo(() => getTranslations(productId), [productId])
  return <TranslationsContext.Provider value={productTranslations}>{children}</TranslationsContext.Provider>
}
