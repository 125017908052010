import { Bold } from 'src/components/Bold'

export type TQuizBettingTranslations = typeof quizBettingTranslations

export const quizBettingTranslations = {
  title: 'Teste dein Wissen',
  description: (isMobile: boolean) => (
    <>
      Beantworte die drei täglichen Quizfragen und erhalte <b key="bold-description-1">drei</b> Punkte für jede richtige
      Antwort.{' '}
      {isMobile && (
        <>
          <br />
          <br />
        </>
      )}
      Bei der vierten Frage kannst du auf <b key="bold-description-2">Risiko</b> gehen und die{' '}
      <b key="bold-description-3">doppelte</b> Punktzahl erhalten!
    </>
  ),
  indicatiorText: 'Fragen beantwortet',
  bettingBannerTitle: 'Geh ein Risiko ein und hol dir doppelte Punkte (+ 6 Pkt)!',
  bettingBannerText: (
    <>
      Vorsicht! Wenn du daneben liegst, werden dir bei einer falschen Risiko-Antwort <b>3 Punkte abgezogen.</b>
    </>
  ),
  bettingBannerAcceptButton: 'Risiko eingehen',
  bettingBannerDeclineButton: 'Kein risiko',
  resultTitle: {
    all: 'Volltreffer!',
    some: 'Super!',
    none: 'Knapp daneben ist auch vorbei!',
  },
  resultSummaryText: (points: number | string, correctAnswerCount: number, maxQuestionCount: number | undefined) => (
    <>
      Du hast{' '}
      <Bold>
        {correctAnswerCount}/{maxQuestionCount}
      </Bold>{' '}
      Quizfragen richtig beantwortet und <Bold>{points} Punkte</Bold> erhalten.
    </>
  ),
  resultSummarySuccessfulBetText: (points: number | undefined) => (
    <>
      Das Risiko hat sich gelohnt: Du hast <Bold>{points} zusätzliche Punkte</Bold> erhalten - bravo!
    </>
  ),
  resultSummaryFailedBetText: (points: number | undefined) => (
    <>Du bist ein Risiko eingegangen und hast die Frage leider falsch beantwortet – minus {points} Punkte!</>
  ),
  resultSummaryEndText: 'Teste dein Wissen morgen erneut!',
  resultTable: {
    rank: 'Rang',
    nickname: 'Benutzername',
    total: 'Total',
    gained: 'Heute',
  },
  leaderboardButtonText: 'Zur Rangliste',
  blickButtonText: 'Blick Schwingen Nachrichten',
  moreInfoButtonText: 'Mehr info',
  infoModalTitle: 'Gehe ein Risiko ein und hol dir doppelte Punkte (+ 6 Pkt)!',
  infoModalText: (
    <>
      Wenn du mit Risiko spielst und richtig antwortest, erhältst du die doppelte Punktzahl (6 Punkte). Bei einer
      falschen Antwort bei der Risiko-Frage, werden dir 3 Punkte abgezogen.
      <br />
      <br />
      Wenn du auf Nummer sicher gehen willst und ohne Risiko spielst, wird die letzte Frage, wie die drei zuvor
      behandelt: 3 Punkte für die richtige Antwort und keine Punkte für die falsche Antwort.
    </>
  ),
  infoModalButtonText: 'ALLES KLAR',
}
