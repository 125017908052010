import { TCommonStyle } from 'src/theme/themes/default/components/common'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { libertateaFonts } from 'src/theme/themes/libertatea/fonts'

const common: TCommonStyle = defaultsDeep({}, defaultTheme.components.common)

common.background = libertateaColors.greyLight
common.color = libertateaColors.greyDark
common.font = libertateaFonts.signika
common.borderRadius = '0px'
common.boxShadow = 'none'
common.scrollMarginTop = '105px'
common.scrollMarginTopMobile = '60px'

export const libertateaCommon = common
