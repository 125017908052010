import { useMediaQuery } from 'react-responsive'
import { deviceWidths } from 'src/theme/media'

export const useMedia = () => {
  const normalizedUserAgent = navigator.userAgent.toLowerCase()
  const isDesktop = useMediaQuery({ query: `(min-width: ${deviceWidths.mobile}px)` })
  const isMobile = !isDesktop // This changes as we introduce tablet
  const isMobileDevice = /(android|iphone|ipad|mobile)/i.test(normalizedUserAgent)
  const isAndroidWebView = window.blickIframeUtils?.getIsParentAndroid?.()
  const isiOSWebView = window.blickIframeUtils?.getIsParentiOS?.()
  const sendEventToNativeParent = (event: AnyObject) => window.blickIframeUtils?.sendEventToNativeParent?.(event)
  const sendHeightToParent = (height: number) => window.blickIframeUtils?.sendDimensionsToParent?.(height, height)
  const sendEventToParent = (event: AnyObject) => window.blickIframeUtils?.sendEventToParent?.(event)

  return {
    isDesktop,
    isMobile,
    isMobileDevice,
    isAndroidWebView,
    isiOSWebView,
    sendEventToNativeParent,
    sendEventToParent,
    sendHeightToParent,
  }
}
