import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'

export type TMatchBetStyle = typeof defaultMatchBet

export const defaultMatchBet = {
  matchBetCardBackground: colors.blick.blueLight1,
  matchBetCardPadding: '24px 32px',
  matchBetCardPaddingMobile: '16px',
  matchBetCardShadow: 'none',

  leagueButtonWidth: '173px',
  leagueButtonWidthMobile: '125px',
  leagueButtonHeight: '159px',
  leagueButtonHeightMobile: '156px',
  startDate: {
    font: fonts.sourceSansPro,
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '23px',
  },
}
