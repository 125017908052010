import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TSummaryStyle } from 'src/theme/themes/default/components/summary'
import { greenfieldColors } from 'src/theme/themes/greenfield/colors'

const summary: TSummaryStyle = defaultsDeep({}, defaultTheme.components.Summary)
summary.bannerBackgroundPosition = 'right'
summary.subtitleBackgrounnd = defaultTheme.colors.blick.brand
summary.subscriptionTitleTextStyle = `
font-family: ${defaultTheme.fonts.goodOffcPro};
font-style: normal;
font-weight: 950;
font-size: 36px;
line-height: 44px;
letter-spacing: 0.02em;
`
summary.titleTextStyle = `
    font-size: 36px;
    line-height: 44px;
    text-shadow: 0px 0px 8px #1F1F1F;
    font-family: ${defaultTheme.fonts.goodOffcPro};
    font-weight: 9500;
    letter-spacing: 0.4px;
    max-width: 415px;
`
summary.titleTextStyleMobile = `
    font-family: ${defaultTheme.fonts.goodOffcPro};
    font-weight: 950;
    font-size: 28px;
    line-height: 36px;
    text-shadow: 0px 0px 8px #1F1F1F;
    max-width: 220px;
`
summary.infoBlockBackground = defaultTheme.colors.shades.white
summary.infoBlockPadding = '8px 24px 8px 16px'
summary.infoBlockMargin = '0 auto 4px 0'
summary.infoBlockShadow = 'none'
summary.infoBlockValueTextStyle = `
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    color: ${defaultTheme.colors.blick.grey100}
`
summary.infoBlockDescriptionTextStyle = `
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${defaultTheme.colors.blick.grey100}
`
summary.infoBlockAfter = `
    display:none;
`
summary.infoBlockBorderRadius = `
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
`
summary.subscriptionBackground = defaultTheme.colors.blick.grey15
summary.subscriptionShadow = 'none'
summary.subscriptionFormSuccessBackground = greenfieldColors.intenseGreen
summary.textPaddingLeftDesktop = '200px'

export const greenfieldSummary = summary
