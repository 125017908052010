import styled from 'styled-components/macro'
import confettiSoccer from 'src/components/lottie/json/confetti_soccer.json'
import { LottieAnimation } from 'src/components/lottie/LottieAnimation'
import { useDispatch, useSelector } from 'react-redux'
import { getAnimationState } from 'src/api/userGroup/selectors'
import { stopAnimation } from 'src/api/userGroup/actions'

export const GlobalAnimation = () => {
  const animationState = useSelector(getAnimationState)
  const dispatch = useDispatch()
  return animationState ? (
    <AnimationContainer>
      <LottieAnimation
        animationData={confettiSoccer}
        autoplay
        loop={1}
        onLoopComplete={() => dispatch(stopAnimation())}
      />
    </AnimationContainer>
  ) : null
}

const AnimationContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
`
