import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TQuizWidgetStyle } from 'src/theme/themes/default/components/quizWidget'
import { bluefrostColors } from 'src/theme/themes/bluefrost/colors'

const quizWidget: TQuizWidgetStyle = defaultsDeep({}, defaultTheme.components.QuizWidget)

quizWidget.background = defaultTheme.colors.shades.white

quizWidget.answerDefaultBorder = `2px solid ${defaultTheme.colors.blick.grey10}`
quizWidget.answerMinHeight = '36px'
quizWidget.answerMinHeightMobile = '36px'

quizWidget.bottomWrapperBackground = bluefrostColors.blueLight1
export const bluefrostQuizWidget = quizWidget
