import { TEndOfRoundModalTranslations } from 'src/translations/de/default/endofroundmodal'

export const endOfRoundModalTranslations: TEndOfRoundModalTranslations = {
  getSubtitle: (round: string) => ``,
  getSummaryTitle: (roundRank: number, userName?: string, round?: string) => (
    <>
      Hi {userName} <br />
      This is how you played in {round}.
    </>
  ),
  finalStepTitle: 'New month, new prizes',
  getGroupStepTitle: (roundRank: number) => `Dein Team und du seid auf Rang ${roundRank} gelandet`,
  finalStepText: <>Every month you have the chance to win new prizes. Come back and try again!</>,
}
