import { TButtonStyle } from 'src/theme/themes/default/components/button'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { onetColors } from 'src/theme/themes/onet/colors'
import { onetFonts } from 'src/theme/themes/onet/fonts'

const button: TButtonStyle = defaultsDeep({}, defaultTheme.components.Button)
button.height = '50px'
button.fontFamily = onetFonts.firaSans
button.fontWeight = 700
button.fontSize = '16px'
button.borderRadius = '0'

//Primary
button.componentTheme.primary.color = onetColors.darkGrey
button.componentTheme.primary.background = onetColors.yellow
button.componentTheme.primary.hover.background = onetColors.darkYellow

//Underline
button.componentTheme.underline.fontSize = '18px'
button.componentTheme.underline.textTransform = 'none'

//Ghost
button.componentTheme.ghost.padding = '0 16px'

export const onetButton = button
