import { defaultsDeep } from 'lodash-es'
import { summaryTranslations, TSummaryTranslations } from 'src/translations/de/default/summary'

export const wmtrophySummaryTranslations: TSummaryTranslations = defaultsDeep({}, summaryTranslations)

wmtrophySummaryTranslations.title = (userRank?: number) => {
  if (userRank) {
    return (
      <>
        Du hast dich auf Platz {userRank} der <br /> Rangliste gekämpft
      </>
    )
  }
  return <>Schade, dass du nicht mitgespielt hast. Vielleicht beim nächsten Mal?</>
}
wmtrophySummaryTranslations.round = {
  subtitle: (dinamicText?: string) => <>So hat {dinamicText} gespielt</>,
  titleMobile: (rank?: number, name?: string) => (
    <>
      Du hast dich auf <br /> Platz {rank} gekämpft
    </>
  ),
  titleDesktop: (rank?: number, name?: string) => (
    <>
      Du hast dich auf Platz {rank} <br />
      der Rangliste gekämpft
    </>
  ),
  bannerTitle: 'Spiel mit bei der WM Trophy und gewinne coole Preise!',
  team: {
    title: (rank?: number, name?: string) => (
      <>
        Team {name} ist auf Rang {rank} gelandet
      </>
    ),
    subtitle: (dinamicText?: string) => <>{dinamicText} TEAMS</>,
  },
  buttonTextLoggedIn: 'jetzt mitspielen',
  buttonTextLoggedOut: 'anmelden',
}
wmtrophySummaryTranslations.subscriptionTitle =
  'Die Blick WM Trophy ist leider vorbei. Schon bald aber gibt es neue Spiele!'
wmtrophySummaryTranslations.subscriptionText = 'Erhalte eine E-Mail sobald es neue Spiele gibt!'
