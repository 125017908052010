import { combineReducers } from 'redux'

import { reducer as product } from 'src/api/product/reducer'
import { reducer as rules } from 'src/api/rules/reducer'
import { reducer as app } from 'src/state/app/reducer'
import { reducer as leaderBoardDefault } from 'src/api/leaderboard/default/reducer'
import { reducer as leaderBoardRounded } from 'src/api/leaderboard/rounded/reducer'
import { reducer as auth } from 'src/api/auth/reducer'
import { reducer as user } from 'src/api/user/reducer'
import { reducer as userGroup } from 'src/api/userGroup/reducer'
import { reducer as users } from 'src/api/users/reducer'
import { reducer as router } from 'src/state/router/reducer'
import { reducer as champions } from 'src/api/champions/reducer'
import { reducer as feedback } from 'src/api/feedback/reducer'
import { reducer as medalcount } from 'src/api/medalcount/reducer'
import { reducer as betting } from 'src/api/betting/reducer'
import { reducer as quizMain } from 'src/api/quiz/main/reducer'
import { reducer as quizWidget } from 'src/api/quiz/widget/reducer'
import { reducer as quizBetting } from 'src/api/quiz/betting/reducer'
import { reducer as matchbetting } from 'src/api/matchbetting/reducer'
import { reducer as powerups } from 'src/api/powerups/reducer'
import { reducer as scorebet } from 'src/api/scorebet/reducer'
import { reducer as articles } from 'src/api/articles/reducer'
import { reducer as summary } from 'src/api/summary/reducer'
import { reducer as marketing } from 'src/api/marketing/reducer'
import { reducer as notifications } from 'src/api/notifications/reducer'
import { reducer as winners } from 'src/api/winners/reducer'

export const rootReducer = combineReducers({
  app,
  router,
  auth,
  user,
  userGroup,
  users,
  feedback,
  product,
  rules,
  leaderBoard: combineReducers({
    default: leaderBoardDefault,
    rounded: leaderBoardRounded,
  }),
  champions,
  medalcount,
  betting,
  quiz: combineReducers({
    main: quizMain,
    widget: quizWidget,
    betting: quizBetting,
  }),
  matchbetting,
  powerups,
  scorebet,
  articles,
  summary,
  marketing,
  notifications,
  winners,
})
