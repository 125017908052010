export type TEndOfRoundModalTranslations = typeof endOfRoundModalTranslations

export const endOfRoundModalTranslations = {
  getSubtitle: (round: string) => `DEINE im ${round}`,
  getSummaryTitle: (roundRank: number, userName?: string, round?: string) => (
    <>Du hast dich auf Platz {roundRank} der Rangliste gekämpft</>
  ),
  finalStepTitle: 'Neuer Monat, neues Glück!',
  getGroupStepTitle: (roundRank: number) => `Dein Team und du seid auf Rang ${roundRank} gelandet`,
  finalStepText: (
    <>
      Im letzten Monat nicht gewonnen? Kein Problem! Ab sofort hast du wieder die Chance,{' '}
      <b key="bold-text-final-0">coole Preise</b> abzustauben.
      <br key={'br-final-0'} />
      <br key={'br-final-1'} />
      Dafür werden dir <b key="bold-text-final-1">neue Booster</b> freigeschaltet. Setze diese klug ein und sichere dir
      viele Extrapunkte.
    </>
  ),
}
