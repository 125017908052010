import { Reducer } from 'redux'

import { getReduxFailureStates, getReduxFetchStates, getReduxResetStates, getReduxSuccessStates } from 'src/utils/redux'
import { TUserGroupState, TActions } from './types'
import { ActionTypes } from './enums'
import { ActionTypes as UserActionTypes } from 'src/api/user/enums'
import { TAddPointsOptimisticAction } from 'src/api/user/types'
import { getGroupMembersAfterUserDelete, sortGroupMembers } from './utils'

export const initialState: TUserGroupState = {
  data: {
    group: null,
    invitations: [],
    joinRequests: [],
    openedModal: null,
    groupManagement: {
      name: '',
      nameValidationError: '',
      invitations: [],
      invitationsValidationError: '',
      description: '',
      descriptionValidationError: '',
    },
    joinLink: null,
    joinGroup: null,
    animation: false,
  },
  loading: {
    createGroup: false,
    updateGroup: false,
    fetchGroup: false,
    updateGroupName: false,
    updateGroupDescription: false,
    deleteUserFromGroup: [],
    leaveGroup: false,
    fetchInvitations: false,
    acceptInvitation: [],
    declineInvitation: [],
    deleteInvitation: [],
    invitePlayers: false,
    createJoinLink: false,
    fetchJoinGroup: false,
    acceptJoinGroup: false,
    acceptIncomingJoinRequest: [],
    declineIncomingJoinRequest: [],
    fetchJoinRequests: false,
    deleteJoinRequest: [],
    sendJoinRequest: false,
  },
  error: {
    createGroup: null,
    updateGroup: null,
    fetchGroup: null,
    updateGroupName: null,
    updateGroupDescription: null,
    deleteUserFromGroup: null,
    leaveGroup: null,
    fetchInvitations: null,
    acceptInvitation: null,
    declineInvitation: null,
    deleteInvitation: null,
    invitePlayers: null,
    createJoinLink: null,
    fetchJoinGroup: null,
    acceptJoinGroup: null,
    acceptIncomingJoinRequest: null,
    declineIncomingJoinRequest: null,
    fetchJoinRequests: null,
    deleteJoinRequest: null,
    sendJoinRequest: null,
  },
  resolved: {
    createGroup: false,
    updateGroup: false,
    fetchGroup: false,
    updateGroupName: false,
    updateGroupDescription: false,
    deleteUserFromGroup: [],
    leaveGroup: false,
    fetchInvitations: false,
    acceptInvitation: [],
    declineInvitation: [],
    deleteInvitation: [],
    invitePlayers: false,
    createJoinLink: false,
    fetchJoinGroup: false,
    acceptJoinGroup: false,
    acceptIncomingJoinRequest: [],
    declineIncomingJoinRequest: [],
    fetchJoinRequests: false,
    deleteJoinRequest: [],
    sendJoinRequest: false,
  },
}

export const reducer: Reducer<TUserGroupState, TActions | TAddPointsOptimisticAction> = (
  state = initialState,
  action,
): TUserGroupState => {
  switch (action.type) {
    case ActionTypes.CREATE_GROUP: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'createGroup'),
      }
    }
    case ActionTypes.CREATE_GROUP_SUCCESS: {
      return {
        ...state,
        ...getReduxSuccessStates(state, 'createGroup'),
      }
    }
    case ActionTypes.CREATE_GROUP_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'createGroup', action.payload),
      }
    }
    case ActionTypes.CLEAR_CREATE_GROUP_STATES: {
      return {
        ...state,
        loading: {
          ...state.loading,
          createGroup: initialState.loading.createGroup,
        },
        resolved: {
          ...state.resolved,
          createGroup: initialState.resolved.createGroup,
        },
        error: {
          ...state.error,
          createGroup: initialState.error.createGroup,
        },
      }
    }

    case ActionTypes.UPDATE_GROUP: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'updateGroup'),
      }
    }
    case ActionTypes.UPDATE_GROUP_SUCCESS: {
      const group = state.data.group
      if (group) {
        group.name = action.payload.name
        group.description = action.payload.description ? action.payload.description : ''
      }
      return {
        ...state,
        data: {
          ...state.data,
          group,
        },
        ...getReduxSuccessStates(state, 'updateGroup'),
      }
    }
    case ActionTypes.UPDATE_GROUP_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'updateGroup', action.payload),
      }
    }
    case ActionTypes.CLEAR_UPDATE_GROUP_STATES: {
      return {
        ...state,
        loading: {
          ...state.loading,
          updateGroup: initialState.loading.updateGroup,
        },
        resolved: {
          ...state.resolved,
          updateGroup: initialState.resolved.updateGroup,
        },
        error: {
          ...state.error,
          updateGroup: initialState.error.updateGroup,
        },
      }
    }

    case ActionTypes.FETCH_GROUP: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchGroup'),
      }
    }
    case ActionTypes.FETCH_GROUP_SUCCESS: {
      const group = action.payload
      sortGroupMembers(group.members)
      return {
        ...state,
        data: {
          ...state.data,
          group: action.payload,
        },
        ...getReduxSuccessStates(state, 'fetchGroup'),
      }
    }
    case ActionTypes.FETCH_GROUP_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchGroup', action.payload),
      }
    }

    case ActionTypes.FETCH_JOIN_REQUESTS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchJoinRequests'),
      }
    }
    case ActionTypes.FETCH_JOIN_REQUESTS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          joinRequests: action.payload.invitations,
        },
        ...getReduxSuccessStates(state, 'fetchJoinRequests'),
      }
    }
    case ActionTypes.FETCH_JOIN_REQUESTS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchJoinRequests', action.payload),
      }
    }

    case ActionTypes.DELETE_JOIN_REQUEST: {
      const { groupId } = action.payload
      const newDeleteJoinRequestLoading = [...state.resolved.deleteJoinRequest, groupId]
      const newDeleteJoinRequestResolved = state.loading.deleteJoinRequest.filter((item) => item !== groupId)

      return {
        ...state,
        loading: {
          ...state.loading,
          deleteJoinRequest: newDeleteJoinRequestLoading,
        },
        resolved: {
          ...state.resolved,
          deleteJoinRequest: newDeleteJoinRequestResolved,
        },
      }
    }
    case ActionTypes.DELETE_JOIN_REQUEST_SUCCESS: {
      const { groupId } = action.payload
      const newDeleteJoinRequestLoading = state.loading.deleteJoinRequest.filter((item) => item !== groupId)
      const newDeleteJoinRequestResolved = [...state.resolved.deleteJoinRequest, groupId]

      return {
        ...state,
        loading: {
          ...state.loading,
          deleteJoinRequest: newDeleteJoinRequestLoading,
        },
        resolved: {
          ...state.resolved,
          deleteJoinRequest: newDeleteJoinRequestResolved,
        },
      }
    }
    case ActionTypes.DELETE_JOIN_REQUEST_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'deleteJoinRequest', action.payload),
      }
    }
    case ActionTypes.CLEAR_JOIN_REQUESTS: {
      return {
        ...state,
        data: {
          ...state.data,
          joinRequests: initialState.data.joinRequests,
        },
      }
    }

    case ActionTypes.FETCH_JOIN_GROUP: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchJoinGroup'),
      }
    }
    case ActionTypes.FETCH_JOIN_GROUP_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          joinGroup: action.payload,
        },
        ...getReduxSuccessStates(state, 'fetchJoinGroup'),
      }
    }
    case ActionTypes.FETCH_JOIN_GROUP_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchJoinGroup', action.payload),
      }
    }

    case ActionTypes.ACCEPT_JOIN_GROUP: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'acceptJoinGroup'),
      }
    }
    case ActionTypes.ACCEPT_JOIN_GROUP_SUCCESS: {
      // payload not handled because it's not currently needed
      return {
        ...state,
        data: {
          ...state.data,
          joinGroup: null,
        },
        ...getReduxSuccessStates(state, 'acceptJoinGroup'),
      }
    }
    case ActionTypes.ACCEPT_JOIN_GROUP_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'acceptJoinGroup', action.payload),
      }
    }
    case ActionTypes.ACCEPT_JOIN_GROUP_RESET: {
      return {
        ...state,
        ...getReduxResetStates(state, initialState, ['acceptJoinGroup']),
      }
    }

    case ActionTypes.UPDATE_GROUP_NAME: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'updateGroupName'),
      }
    }
    case ActionTypes.UPDATE_GROUP_NAME_SUCCESS: {
      const group = state.data.group
      const updatedName = action.payload.name
      return {
        ...state,
        data: {
          ...state.data,
          ...(group && {
            group: {
              ...group,
              name: updatedName,
            },
          }),
        },
        ...getReduxSuccessStates(state, 'updateGroupName'),
      }
    }
    case ActionTypes.UPDATE_GROUP_NAME_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'updateGroupName', action.payload),
      }
    }
    case ActionTypes.CLEAR_UPDATE_GROUP_NAME_STATES: {
      return {
        ...state,
        loading: {
          ...state.loading,
          updateGroupName: initialState.loading.updateGroupName,
        },
        resolved: {
          ...state.resolved,
          updateGroupName: initialState.resolved.updateGroupName,
        },
        error: {
          ...state.error,
          updateGroupName: initialState.error.updateGroupName,
        },
      }
    }

    case ActionTypes.UPDATE_GROUP_DESCRIPTION: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'updateGroupDescription'),
      }
    }
    case ActionTypes.UPDATE_GROUP_DESCRIPTION_SUCCESS: {
      const group = state.data.group
      const updatedDescription = action.payload.description
      return {
        ...state,
        data: {
          ...state.data,
          ...(group && {
            group: {
              ...group,
              description: updatedDescription,
            },
          }),
        },
        ...getReduxSuccessStates(state, 'updateGroupDescription'),
      }
    }
    case ActionTypes.UPDATE_GROUP_DESCRIPTION_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'updateGroupDescription', action.payload),
      }
    }

    case ActionTypes.SEND_JOIN_REQUEST: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'sendJoinRequest'),
      }
    }
    case ActionTypes.SEND_JOIN_REQUEST_SUCCESS: {
      return {
        ...state,
        ...getReduxSuccessStates(state, 'sendJoinRequest'),
      }
    }
    case ActionTypes.SEND_JOIN_REQUEST_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'sendJoinRequest', action.payload),
      }
    }

    case ActionTypes.CLEAR_UPDATE_GROUP_DESCRIPTION_STATES: {
      return {
        ...state,
        loading: {
          ...state.loading,
          updateGroupDescription: initialState.loading.updateGroupDescription,
        },
        resolved: {
          ...state.resolved,
          updateGroupDescription: initialState.resolved.updateGroupDescription,
        },
        error: {
          ...state.error,
          updateGroupDescription: initialState.error.updateGroupDescription,
        },
      }
    }

    case ActionTypes.DELETE_USER_FROM_GROUP: {
      const { userName } = action.payload
      const newDeleteUserFromGroupLoading = [...state.loading.deleteUserFromGroup, userName]
      const newDeleteUserFromGroupResolved = state.resolved.deleteUserFromGroup.filter((item) => item !== userName)
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteUserFromGroup: newDeleteUserFromGroupLoading,
        },
        resolved: {
          ...state.resolved,
          deleteUserFromGroup: newDeleteUserFromGroupResolved,
        },
      }
    }
    case ActionTypes.DELETE_USER_FROM_GROUP_SUCCESS: {
      const { userName } = action.payload
      const group = state.data.group

      const newGroupMembers = group ? getGroupMembersAfterUserDelete(group, userName) : []

      const newDeleteUserFromGroupLoading = state.loading.deleteUserFromGroup.filter((item) => item !== userName)
      const newDeleteUserFromGroupResolved = [...state.resolved.deleteUserFromGroup, userName]
      return {
        ...state,
        data: {
          ...state.data,
          ...(group && {
            group: {
              ...group,
              members: newGroupMembers,
            },
          }),
        },
        loading: {
          ...state.loading,
          deleteUserFromGroup: newDeleteUserFromGroupLoading,
        },
        resolved: {
          ...state.resolved,
          deleteUserFromGroup: newDeleteUserFromGroupResolved,
        },
      }
    }
    case ActionTypes.DELETE_USER_FROM_GROUP_FAILURE: {
      const { userName } = action.payload
      const newDeleteUserFromGroupLoading = state.loading.deleteUserFromGroup.filter((item) => item !== userName)
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteUserFromGroup: newDeleteUserFromGroupLoading,
        },
        error: {
          ...state.error,
          deleteUserFromGroup: action.errorPayload,
        },
      }
    }

    case ActionTypes.LEAVE_GROUP: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'leaveGroup'),
      }
    }
    case ActionTypes.LEAVE_GROUP_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          group: null,
        },
        ...getReduxSuccessStates(state, 'leaveGroup'),
      }
    }
    case ActionTypes.LEAVE_GROUP_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'leaveGroup', action.payload),
      }
    }
    case ActionTypes.CLEAR_LEAVE_GROUP_STATES: {
      return {
        ...state,
        loading: {
          ...state.loading,
          leaveGroup: initialState.loading.leaveGroup,
        },
        resolved: {
          ...state.resolved,
          leaveGroup: initialState.resolved.leaveGroup,
        },
        error: {
          ...state.error,
          leaveGroup: initialState.error.leaveGroup,
        },
      }
    }

    case ActionTypes.FETCH_INVITATIONS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchInvitations'),
      }
    }
    case ActionTypes.FETCH_INVITATIONS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          invitations: action.payload.invitations,
        },
        ...getReduxSuccessStates(state, 'fetchInvitations'),
      }
    }
    case ActionTypes.FETCH_INVITATIONS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchInvitations', action.payload),
      }
    }

    case ActionTypes.ACCEPT_INVITATION: {
      const { groupId } = action.payload
      const newAcceptInvitationLoading = [...state.loading.acceptInvitation, groupId]
      const newAcceptInvitationResolved = state.resolved.acceptInvitation.filter((item) => item !== groupId)
      return {
        ...state,
        loading: {
          ...state.loading,
          acceptInvitation: newAcceptInvitationLoading,
        },
        resolved: {
          ...state.resolved,
          acceptInvitation: newAcceptInvitationResolved,
        },
      }
    }
    case ActionTypes.ACCEPT_INVITATION_SUCCESS: {
      const { groupId } = action.payload
      const newAcceptInvitationLoading = state.loading.acceptInvitation.filter((item) => item !== groupId)
      const newAcceptInvitationResolved = [...state.resolved.acceptInvitation, groupId]
      return {
        ...state,
        data: {
          ...state.data,
          invitations: [],
        },
        loading: {
          ...state.loading,
          acceptInvitation: newAcceptInvitationLoading,
        },
        resolved: {
          ...state.resolved,
          acceptInvitation: newAcceptInvitationResolved,
        },
      }
    }
    case ActionTypes.ACCEPT_INVITATION_FAILURE: {
      const { groupId } = action.payload
      const newAcceptInvitationLoading = state.loading.acceptInvitation.filter((item) => item !== groupId)
      return {
        ...state,
        loading: {
          ...state.loading,
          acceptInvitation: newAcceptInvitationLoading,
        },
        error: {
          ...state.error,
          acceptInvitation: action.errorPayload,
        },
      }
    }
    case ActionTypes.CLEAR_ACCEPT_INVITATION_STATES: {
      return {
        ...state,
        loading: {
          ...state.loading,
          acceptInvitation: initialState.loading.acceptInvitation,
        },
        resolved: {
          ...state.resolved,
          acceptInvitation: initialState.resolved.acceptInvitation,
        },
        error: {
          ...state.error,
          acceptInvitation: initialState.error.acceptInvitation,
        },
      }
    }

    case ActionTypes.DECLINE_INVITATION: {
      const { groupId } = action.payload
      const newDeclinetInvitationLoading = [...state.loading.declineInvitation, groupId]
      const newDeclineInvitationResolved = state.resolved.declineInvitation.filter((item) => item !== groupId)
      return {
        ...state,
        loading: {
          ...state.loading,
          declineInvitation: newDeclinetInvitationLoading,
        },
        resolved: {
          ...state.resolved,
          declineInvitation: newDeclineInvitationResolved,
        },
      }
    }
    case ActionTypes.DECLINE_INVITATION_SUCCESS: {
      const { groupId } = action.payload
      const newInvitationsArray = state.data.invitations.filter((item) => item.groupId !== groupId)
      const newDeclinetInvitationLoading = state.loading.declineInvitation.filter((item) => item !== groupId)
      const newDeclineInvitationResolved = [...state.resolved.declineInvitation, groupId]
      return {
        ...state,
        data: {
          ...state.data,
          invitations: newInvitationsArray,
        },
        loading: {
          ...state.loading,
          declineInvitation: newDeclinetInvitationLoading,
        },
        resolved: {
          ...state.resolved,
          declineInvitation: newDeclineInvitationResolved,
        },
      }
    }
    case ActionTypes.DECLINE_INVITATION_FAILURE: {
      const { groupId } = action.payload
      const newDeclineInvitationLoading = state.loading.declineInvitation.filter((item) => item !== groupId)
      return {
        ...state,
        loading: {
          ...state.loading,
          declineInvitation: newDeclineInvitationLoading,
        },
        error: {
          ...state.error,
          declineInvitation: action.errorPayload,
        },
      }
    }

    case ActionTypes.DELETE_INVITATION: {
      const { userName } = action.payload
      const newDeleteInvitationLoading = [...state.loading.deleteInvitation, userName]
      const newDeleteInvitationResolved = state.resolved.deleteInvitation.filter((item) => item !== userName)
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteInvitation: newDeleteInvitationLoading,
        },
        resolved: {
          ...state.resolved,
          deleteInvitation: newDeleteInvitationResolved,
        },
      }
    }
    case ActionTypes.DELETE_INVITATION_SUCCESS: {
      const { userName } = action.payload
      const group = state.data.group
      const newGroupInvitesPending = group ? group.invitesPending.filter((item) => item.username !== userName) : []
      const newDeleteInvitationLoading = state.loading.deleteInvitation.filter((item) => item !== userName)
      const newDeleteInvitationResolved = [...state.resolved.deleteInvitation, userName]
      return {
        ...state,
        data: {
          ...state.data,
          ...(group && {
            group: {
              ...group,
              invitesPending: newGroupInvitesPending,
            },
          }),
        },
        loading: {
          ...state.loading,
          deleteInvitation: newDeleteInvitationLoading,
        },
        resolved: {
          ...state.resolved,
          deleteInvitation: newDeleteInvitationResolved,
        },
      }
    }
    case ActionTypes.DELETE_INVITATION_FAILURE: {
      const { userName } = action.payload
      const newDeleteInvitationLoading = state.loading.deleteInvitation.filter((item) => item !== userName)
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteInvitation: newDeleteInvitationLoading,
        },
        error: {
          ...state.error,
          deleteInvitation: action.errorPayload,
        },
      }
    }
    case ActionTypes.CLEAR_DELETE_INVITATION_STATES: {
      const userName = action.payload
      const newDeleteInvitationResolved = state.resolved.deleteInvitation.filter((item) => item !== userName)
      const newDeleteInvitationsLoading = state.loading.deleteInvitation.filter((item) => item !== userName)
      return {
        ...state,
        loading: {
          ...state.loading,
          deleteInvitation: newDeleteInvitationsLoading,
        },
        resolved: {
          ...state.resolved,
          deleteInvitation: newDeleteInvitationResolved,
        },
        error: {
          ...state.error,
          deleteInvitation: initialState.error.deleteInvitation,
        },
      }
    }

    case ActionTypes.INVITE_PLAYERS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'invitePlayers'),
      }
    }
    case ActionTypes.INVITE_PLAYERS_SUCCESS: {
      const group = state.data.group
      const newInvitesPending = action.payload.map((item) => ({
        username: item.username,
        requestedAt: new Date(),
      }))
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            invitations: initialState.data.groupManagement.invitations,
          },
          ...(group && {
            group: {
              ...group,
              invitesPending: [...group.invitesPending, ...newInvitesPending],
            },
          }),
        },
        ...getReduxSuccessStates(state, 'invitePlayers'),
      }
    }
    case ActionTypes.INVITE_PLAYERS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'invitePlayers', action.payload),
      }
    }
    case ActionTypes.CLEAR_INVITE_PLAYERS_STATES: {
      return {
        ...state,
        loading: {
          ...state.loading,
          invitePlayers: initialState.loading.invitePlayers,
        },
        resolved: {
          ...state.resolved,
          invitePlayers: initialState.resolved.invitePlayers,
        },
        error: {
          ...state.error,
          invitePlayers: initialState.error.invitePlayers,
        },
      }
    }

    case ActionTypes.CREATE_JOIN_LINK: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'createJoinLink'),
      }
    }
    case ActionTypes.CREATE_JOIN_LINK_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          joinLink: action.payload,
        },
        ...getReduxSuccessStates(state, 'createJoinLink'),
      }
    }
    case ActionTypes.CREATE_JOIN_LINK_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'createJoinLink', action.payload),
      }
    }

    case ActionTypes.ACCEPT_INCOMING_JOIN_REQUEST: {
      const { userName } = action.payload
      const newAcceptJoinRequestLoading = [...state.loading.acceptIncomingJoinRequest, userName]
      const newAcceptJoinRequestResolved = state.resolved.acceptIncomingJoinRequest.filter((item) => item !== userName)
      return {
        ...state,
        loading: {
          ...state.loading,
          acceptIncomingJoinRequest: newAcceptJoinRequestLoading,
        },
        resolved: {
          ...state.resolved,
          acceptIncomingJoinRequest: newAcceptJoinRequestResolved,
        },
      }
    }
    case ActionTypes.ACCEPT_INCOMING_JOIN_REQUEST_SUCCESS: {
      const group = state.data.group
      const { userName } = action.payload

      const newAcceptJoinRequestLoading = state.loading.acceptIncomingJoinRequest.filter((item) => item !== userName)
      const newAcceptJoinRequestResolved = [...state.resolved.acceptIncomingJoinRequest, userName]

      const newJoinRequestsPending = group?.joinRequestsPending?.filter((item) => item.username !== userName)
      const newMembers = group
        ? [
            ...group.members,
            { username: userName, points: 0, pointsToday: 0, currentRoundPoints: 0, isCurrentMember: true },
          ]
        : []

      return {
        ...state,
        data: {
          ...state.data,
          ...(group && {
            group: {
              ...group,
              members: newMembers,
              joinRequestsPending: newJoinRequestsPending,
            },
          }),
        },
        loading: {
          ...state.loading,
          acceptIncomingJoinRequest: newAcceptJoinRequestLoading,
        },
        resolved: {
          ...state.resolved,
          acceptIncomingJoinRequest: newAcceptJoinRequestResolved,
        },
      }
    }
    case ActionTypes.ACCEPT_INCOMING_JOIN_REQUEST_FAILURE: {
      const { userName } = action.payload
      const newAcceptRequestLoading = state.loading.acceptIncomingJoinRequest.filter((item) => item !== userName)
      return {
        ...state,
        loading: {
          ...state.loading,
          acceptIncomingJoinRequest: newAcceptRequestLoading,
        },
        error: {
          ...state.error,
          acceptIncomingJoinRequest: action.errorPayload,
        },
      }
    }

    case ActionTypes.DECLINE_INCOMING_JOIN_REQUEST: {
      const { userName } = action.payload
      const newDeclineJoinRequestLoading = [...state.loading.declineIncomingJoinRequest, userName]
      const newDeclineJoinRequestResolved = state.resolved.declineIncomingJoinRequest.filter(
        (item) => item !== userName,
      )
      return {
        ...state,
        loading: {
          ...state.loading,
          declineIncomingJoinRequest: newDeclineJoinRequestLoading,
        },
        resolved: {
          ...state.resolved,
          declineIncomingJoinRequest: newDeclineJoinRequestResolved,
        },
      }
    }
    case ActionTypes.DECLINE_INCOMING_JOIN_REQUEST_SUCCESS: {
      const group = state.data.group
      const { userName } = action.payload

      const newDeclineJoinRequestLoading = state.loading.declineIncomingJoinRequest.filter((item) => item !== userName)
      const newDeclineJoinRequestResolved = [...state.resolved.declineIncomingJoinRequest, userName]

      const newJoinRequestsPending = group?.joinRequestsPending?.filter((item) => item.username !== userName)

      return {
        ...state,
        data: {
          ...state.data,
          ...(group && {
            group: {
              ...group,
              joinRequestsPending: newJoinRequestsPending,
            },
          }),
        },
        loading: {
          ...state.loading,
          declineIncomingJoinRequest: newDeclineJoinRequestLoading,
        },
        resolved: {
          ...state.resolved,
          declineIncomingJoinRequest: newDeclineJoinRequestResolved,
        },
      }
    }
    case ActionTypes.DECLINE_INCOMING_JOIN_REQUEST_FAILURE: {
      const { userName } = action.payload
      const newDeclineRequestLoading = state.loading.declineIncomingJoinRequest.filter((item) => item !== userName)
      return {
        ...state,
        loading: {
          ...state.loading,
          declineIncomingJoinRequest: newDeclineRequestLoading,
        },
        error: {
          ...state.error,
          declineIncomingJoinRequest: action.errorPayload,
        },
      }
    }

    case ActionTypes.SET_OPENED_MODAL: {
      return {
        ...state,
        data: {
          ...state.data,
          openedModal: action.payload,
        },
      }
    }

    case ActionTypes.MANAGE_GROUP_NAME: {
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            name: action.payload,
          },
        },
      }
    }
    case ActionTypes.MANAGE_GROUP_NAME_VALIDATION_ERROR: {
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            nameValidationError: action.payload,
          },
        },
      }
    }
    case ActionTypes.CLEAR_MANAGE_GROUP_NAME_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            name: initialState.data.groupManagement.name,
            nameValidationError: initialState.data.groupManagement.nameValidationError,
            description: initialState.data.groupManagement.description,
            descriptionValidationError: initialState.data.groupManagement.descriptionValidationError,
          },
        },
      }
    }
    case ActionTypes.MANAGE_GROUP_INVITATIONS: {
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            invitations: action.payload,
          },
        },
      }
    }
    case ActionTypes.MANAGE_GROUP_INVITATIONS_VALIDATION_ERROR: {
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            invitationsValidationError: action.payload,
          },
        },
      }
    }
    case ActionTypes.CLEAR_MANAGE_GROUP_INVITATIONS_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            invitations: initialState.data.groupManagement.invitations,
            invitationsValidationError: initialState.data.groupManagement.invitationsValidationError,
          },
        },
      }
    }
    case ActionTypes.MANAGE_GROUP_DESCRIPTION: {
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            description: action.payload,
          },
        },
      }
    }
    case ActionTypes.MANAGE_GROUP_DESCRIPTION_VALIDATION_ERROR: {
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            descriptionValidationError: action.payload,
          },
        },
      }
    }
    case ActionTypes.CLEAR_MANAGE_GROUP_DESCRIPTION_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          groupManagement: {
            ...state.data.groupManagement,
            description: initialState.data.groupManagement.description,
            descriptionValidationError: initialState.data.groupManagement.descriptionValidationError,
          },
        },
      }
    }
    case ActionTypes.START_ANIMATION: {
      return {
        ...state,
        data: {
          ...state.data,
          animation: true,
        },
      }
    }
    case ActionTypes.STOP_ANIMATION: {
      return {
        ...state,
        data: {
          ...state.data,
          animation: false,
        },
      }
    }

    case UserActionTypes.ADD_POINTS_OPTIMISTIC: {
      const { points, groupName, userName } = action.payload
      if (userName && groupName) {
        const group = state.data.group
        const groupMembers = group?.members

        if (group && groupMembers) {
          const groupMemberIndex = groupMembers.findIndex((member) => member.username === userName)
          const groupMember = groupMemberIndex > -1 ? groupMembers.find((member) => member.username === userName) : null

          const newGroupMembers = groupMember
            ? [
                ...groupMembers.slice(0, groupMemberIndex),
                {
                  ...groupMember,
                  points: groupMember.points + points,
                  pointsToday: groupMember.pointsToday + points,
                  currentRoundPoints: groupMember.currentRoundPoints + points,
                },
                ...groupMembers.slice(groupMemberIndex + 1),
              ]
            : groupMembers

          return {
            ...state,
            data: {
              ...state.data,
              group: {
                ...group,
                members: newGroupMembers,
              },
            },
          }
        }
      }
      return state
    }

    default:
      return state
  }
}
