import { defaultsDeep } from 'lodash-es'

import { TTranslations } from 'src/translations/types'
import { translations as defaultTranslations } from 'src/translations/de/default'

import { unihockeyLeaderboardTranslations } from './leaderboard'
import { unihockeyQuizBettingTranslations } from './quizbetting'
import { unihockeyEndOfSeasonModalTranslations } from './endofseasonmodal'
import { unihockeySummaryTranslations } from './summary'

export const translations: TTranslations = defaultsDeep({}, defaultTranslations)

translations.leaderboard = unihockeyLeaderboardTranslations
translations.quizbetting = unihockeyQuizBettingTranslations
translations.endofseasonmodal = unihockeyEndOfSeasonModalTranslations
translations.summary = unihockeySummaryTranslations
