import dayjs from 'dayjs'
import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { TLogoutAction } from 'src/api/auth/types'
import { ActionTypes } from 'src/api/quiz/widget/enums'
import { TActions, TAnswerWidgetQuestionAPIResponse, TQuizState } from 'src/api/quiz/widget/types'

export const initialState: TQuizState = {
  data: {
    date: undefined,
    currQuestionIndex: -1, // -1 if no question is loaded
    answers: [],
    questions: [],
    storedStep: undefined,
    selectedAnswer: undefined,
    status: undefined,
    storedWidgetAnswers: undefined,
  },
  loading: {
    assignWidgetQuiz: false,
    getWidgetStatus: false,
    getNextWidgetQuestion: false,
    answerWidgetQuestion: false,
    storeWidgetAnswers: false,
  },
  error: {
    assignWidgetQuiz: null,
    getWidgetStatus: null,
    getNextWidgetQuestion: null,
    answerWidgetQuestion: null,
    storeWidgetAnswers: null,
  },
  resolved: {
    assignWidgetQuiz: false,
    getWidgetStatus: false,
    getNextWidgetQuestion: false,
    answerWidgetQuestion: false,
    storeWidgetAnswers: false,
  },
  notifications: {
    error: false,
  },
}

export const reducer: Reducer<TQuizState, TActions | TLogoutAction> = (state = initialState, action): TQuizState => {
  switch (action.type) {
    case ActionTypes.ASSIGN_WIDGET_QUIZ: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'assignWidgetQuiz'),
      }
    }
    case ActionTypes.ASSIGN_WIDGET_QUIZ_SUCCESS: {
      return {
        ...state,
        ...getReduxSuccessStates(state, 'assignWidgetQuiz'),
      }
    }
    case ActionTypes.ASSIGN_WIDGET_QUIZ_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'assignWidgetQuiz', action.payload),
      }
    }

    case ActionTypes.GET_WIDGET_STATUS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'getWidgetStatus'),
      }
    }
    case ActionTypes.GET_WIDGET_STATUS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          status: action.payload,
        },
        ...getReduxSuccessStates(state, 'getWidgetStatus'),
      }
    }
    case ActionTypes.GET_WIDGET_STATUS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'getWidgetStatus', action.payload),
      }
    }

    case ActionTypes.GET_NEXT_WIDGET_QUESTION: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'getNextWidgetQuestion'),
      }
    }
    case ActionTypes.GET_NEXT_WIDGET_QUESTION_SUCCESS: {
      const questions = [...state.data.questions, action.payload]
      const currQuestionIndex = questions.length - 1
      const date = dayjs().toDate()

      // Ensure unique question ids
      if (state.data.questions.some((q) => q.id === action.payload.id)) {
        return state
      }

      return {
        ...state,
        data: {
          ...state.data,
          date,
          currQuestionIndex,
          questions,
        },
        ...getReduxSuccessStates(state, 'getNextWidgetQuestion'),
      }
    }
    case ActionTypes.GET_NEXT_WIDGET_QUESTION_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'getNextWidgetQuestion', action.payload),
      }
    }

    case ActionTypes.ANSWER_WIDGET_QUESTION: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'answerWidgetQuestion'),
      }
    }
    case ActionTypes.ANSWER_WIDGET_QUESTION_SUCCESS: {
      const numberOfAnsweredQuestions = state.data.status?.numberOfAnsweredQuestions || 0
      const addAnswer = !action.payload.alreadyAnswered ? 1 : 0
      const numberOfCorrectlyAnsweredQuestions = state.data.status?.numberOfCorrectlyAnsweredQuestions || 0
      const addCorrectAnswer = !action.payload.alreadyAnswered && action.payload.result ? 1 : 0

      return {
        ...state,
        data: {
          ...state.data,
          date: dayjs().toDate(),
          answers: [...state.data.answers, action.payload],
          status: {
            ...state.data.status,
            numberOfAnsweredQuestions: numberOfAnsweredQuestions + addAnswer,
            numberOfCorrectlyAnsweredQuestions: numberOfCorrectlyAnsweredQuestions + addCorrectAnswer,
          },
        },
        ...getReduxSuccessStates(state, 'answerWidgetQuestion'),
      }
    }
    case ActionTypes.ANSWER_WIDGET_QUESTION_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'answerWidgetQuestion', action.payload),
      }
    }

    case ActionTypes.STORE_WIDGET_ANSWERS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'storeWidgetAnswers'),
      }
    }
    case ActionTypes.STORE_WIDGET_ANSWERS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          storedWidgetAnswers: action.payload,
        },
        ...getReduxSuccessStates(state, 'storeWidgetAnswers'),
      }
    }
    case ActionTypes.STORE_WIDGET_ANSWERS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'storeWidgetAnswers', action.payload),
      }
    }

    case ActionTypes.SET_SELECTED_WIDGET_ANSWER: {
      return {
        ...state,
        data: {
          ...state.data,
          selectedAnswer: action.payload,
        },
      }
    }

    case ActionTypes.SET_LAST_ANSWERED_QUESTION: {
      const { answerDistribution, correctAnswer, quiz, selectedAnswer, wasRight } = action.payload
      const currQuestionIndex = 0
      const questions = [quiz]
      const answers: TAnswerWidgetQuestionAPIResponse[] = [
        {
          alreadyAnswered: false,
          answerDistribution,
          optionText: selectedAnswer,
          questionId: quiz.id,
          result: wasRight,
          rightAnswerOptionText: correctAnswer,
          staticLinkToAnswer: null,
        },
      ]
      return {
        ...state,
        data: {
          ...state.data,
          currQuestionIndex,
          selectedAnswer,
          questions,
          answers,
        },
      }
    }

    case ActionTypes.RESET_STORE_WIDGET_ANSWERS: {
      return {
        ...state,
        data: {
          ...state.data,
          storedWidgetAnswers: undefined,
        },
      }
    }

    default:
      return state
  }
}
