import styled, { css } from 'styled-components/macro'

type TCloseIconColor = 'black' | 'red' | 'white' | 'grey' | 'greyLight'

type Props = {
  size?: number
  lineWidth?: number
  color?: TCloseIconColor
}

export const CloseIcon = styled.div<Props>`
  position: relative;
  z-index: 1;
  flex-shrink: 0;

  ${({ size }) => css`
    width: ${size || 32}px;
    height: ${size || 32}px;
  `}

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    left: ${({ size }) => (size ? size / 2 - 1 : 15)}px;
    content: ' ';
    height: ${({ size }) => (size ? size + 1 : 33)}px;
    width: ${({ lineWidth }) => lineWidth || '2'}px;
    background-color: ${({ color, theme }) => {
      if (color === 'red') {
        return theme.colors.blick.red
      }
      if (color === 'white') {
        return theme.colors.shades.white
      }
      if (color === 'grey') {
        return theme.colors.blick.grey100
      }
      if (color === 'greyLight') {
        return theme.colors.blick.grey75
      }
      return theme.colors.blick.brand100
    }};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`
