import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TTextInputStyle } from 'src/theme/themes/default/components/textInput'
import { onetColors } from 'src/theme/themes/onet/colors'

const textInput: TTextInputStyle = defaultsDeep({}, defaultTheme.components.TextInput)

textInput.borderRadius = '4px'
textInput.backgroundColor = onetColors.ultraLightGrey
textInput.errorColor = onetColors.errorRed
textInput.defaultColor = onetColors.darkGrey
textInput.borderColor = onetColors.grey2
textInput.errorLabelColor = onetColors.darkGrey
textInput.borderRadius = '4px'
textInput.labelStyle = `
  text-align: start;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 5px;
`
textInput.floatWrapperPadding = '0'

textInput.errorMessageStyle = `
  display: inline-block;
  margin-top: 8px;
  text-align: start;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${onetColors.errorRed}
`

export const onetTextInput = textInput
