import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TCollapsibleStyle } from 'src/theme/themes/default/components/collapsible'

const collapsible: TCollapsibleStyle = defaultsDeep({}, defaultTheme.components.Collapsible)

collapsible.paddingMobile = '16px'
collapsible.arrowHeight = '24px'
collapsible.arrowWidth = '24px'
collapsible.arrowColor = defaultTheme.colors.blick.grey100
collapsible.headerTextStyle = `
font-size: 22px;
line-height: 25px;
font-weight: 600;
`

export const bluefrostCollapsible = collapsible
