import { fonts } from 'src/theme/fonts'

export type TInfoSectionStyle = typeof defaultInfoSection

export const defaultInfoSection = {
  padding: '56px 16px 0px 16px',
  paddingMobile: '40px 16px 48px 16px',
  alignment: 'left',
  titleAfter: '',
  titleAfterMobile: '',
  titlePadding: '0',
  titleTextStyle: `
    font-family: ${fonts.goodOffcPro};
    font-size: 42px;
    font-weight: 950;
    line-height: 50px;
    letter-spacing: 0.01em;
    margin: 0 0 16px 0;
  `,
  titleTextStyleMobile: `
    font-family: ${fonts.goodOffcPro};
    font-size: 28px;
    font-weight: 950;
    line-height: 36px;
  `,
  descriptionMaxWidth: 'none',
  contentTextStyle: `
    font-size: 18px;
    line-height: 23px;
  `,
  contentTextStyleMobile: `
    font-family: ${fonts.sourceSansPro};
    font-weight: 400;
    letter-spacing: 0.01em;
    font-size: 16px;
    line-height: 20px;
  `,
  iconStyleDesktop: `
    margin: 0 auto 8px;
    width: 64px;
    height: 64px;
  `,
  iconStyleMobile: `
    margin: 0 auto 8px;
    width: 48px;
    height: 48px;
  `,
}
