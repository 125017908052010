import { getTenant } from 'src/utils/tenant'
import { Tenants } from 'src/enums/tenants'

import { theme as defaultTheme } from './themes/default'
import { greenfield } from 'src/theme/themes/greenfield'
import { accentway } from './themes/accentway'
import { bluefrost } from './themes/bluefrost'
import { libertatea } from './themes/libertatea'
import { onet } from './themes/onet'

export const getTheme = (productId: string | undefined) => {
  const tenant = getTenant()

  switch (tenant) {
    case Tenants.LIBERTATEA:
      return libertatea
    case Tenants.ONET:
      return onet
  }

  switch (productId) {
    // Temp solution until we have theme name from backend
    case 'uefafrauen':
    case 'wmtrophy':
    case 'emtrophy':
    case 'fussballfrauenwm':
      return greenfield
    case 'schwingen':
    case 'unihockeywm':
      return accentway
    case 'skitrophy':
      return bluefrost
    default:
      return defaultTheme
  }
}
