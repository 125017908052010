import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TTextInputStyle } from 'src/theme/themes/default/components/textInput'

const textInput: TTextInputStyle = defaultsDeep({}, defaultTheme.components.TextInput)

textInput.labelStyle = `
  font-size: 18px;
  line-height: 140%;
  font-weight: 700;
  margin-bottom: 8px;
`
textInput.borderRadius = '0'
textInput.height = '50px'

export const libertateaTextInput = textInput
