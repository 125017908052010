import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TInfoSectionStyle } from 'src/theme/themes/default/components/infoSection'
import { onetColors } from 'src/theme/themes/onet/colors'

const infoSection: TInfoSectionStyle = defaultsDeep({}, defaultTheme.components.InfoSection)

infoSection.padding = `40px 0px 48px 0px`
infoSection.paddingMobile = `40px 16px 48px 16px`
infoSection.titleAfter = `
    content: '';
    position: absolute;
    top: -6px;
    left: 0;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: ${onetColors.yellow}
`
infoSection.titleAfterMobile = infoSection.titleAfter
infoSection.titlePadding = '0 0 0 7px'
infoSection.titleTextStyle = `
    font-weight: 700;
    font-size: 15px;
    line-height: 120%;
    text-transform: uppercase;
`
infoSection.titleTextStyleMobile = infoSection.titleTextStyle
infoSection.descriptionMaxWidth = '654px'
infoSection.contentTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
`
infoSection.contentTextStyleMobile = infoSection.contentTextStyle

export const onetInfoSection = infoSection
