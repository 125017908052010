import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { AppLayout } from 'src/AppLayout'
import { ThemeProvider } from 'styled-components/macro'

import { TranslationsProvider } from 'src/translations/TranslationsContext'
import { GlobalStyle } from 'src/components/GlobalStyle'
import { GlobalToastStyle } from 'src/containers/toast/GlobalToastStyle'
import { ToastContainer } from 'src/containers/toast/ToastContainer'
import { getProduct } from 'src/api/product/selectors'
import { getTheme } from 'src/theme/utils'
import { getTenant } from 'src/utils/tenant'
import { Tenants } from 'src/enums/tenants'
import { getIsAuthResolved, getIsLoggedIn } from 'src/api/auth/selectors'
import { pushSATIEvents } from 'src/utils/pushSATIEvents'
import { GlobalAnimation } from 'src/components/lottie/GlobalAnimation'

export const App = () => {
  const product = useSelector(getProduct)
  const tenant = getTenant()
  const isAuthResolved = useSelector(getIsAuthResolved)
  const isLoggedIn = useSelector(getIsLoggedIn)

  useEffect(() => {
    if ([Tenants.LIBERTATEA, 'libertatea2'].includes(tenant) && isAuthResolved) {
      pushSATIEvents(isLoggedIn)
    }
  }, [isAuthResolved, isLoggedIn, tenant])

  const theme = useMemo(() => getTheme(product?.id), [product])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <GlobalToastStyle />
      <BrowserRouter>
        <TranslationsProvider productId={product?.id}>
          <AppLayout />
        </TranslationsProvider>
      </BrowserRouter>
      <ToastContainer />
      <GlobalAnimation />
    </ThemeProvider>
  )
}
