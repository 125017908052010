import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { fonts } from 'src/theme/fonts'
import { TLeaderboardStyle } from 'src/theme/themes/default/components/leaderboard'

const leaderboard: TLeaderboardStyle = defaultsDeep({}, defaultTheme.components.Leaderboard)

leaderboard.hasRewardRow.background = 'inherit'
leaderboard.hasRewardRow.borderColor = 'inherit'
leaderboard.currentUserRow.background = defaultTheme.colors.blick.blueGreyDark
leaderboard.currentUserRow.color = defaultTheme.colors.texts.inverted
leaderboard.rewardIconSize = '20px'
leaderboard.rewardIconMarginLeft = '10px'
leaderboard.rewardIconMarginRight = '18px'
leaderboard.rowHeight = '52px'
leaderboard.goToLeaderBoardButton.background = 'none'
leaderboard.goToLeaderBoardButton.hover.background = defaultTheme.colors.blick.grey10
leaderboard.goToLeaderBoardButton.textTransform = 'uppercase'
leaderboard.goToLeaderBoardButton.textDecoration = 'none'
leaderboard.goToLeaderBoardButton.borderRadius = '25px'
leaderboard.goToLeaderBoardButton.padding = '10px 12px'
leaderboard.goToLeaderBoardButton.fontFamily = fonts.goodOffcPro

export const bluefrostLeaderboard = leaderboard
