import { colors } from 'src/theme/colors'
import { defaultCommon } from './common'

export type TQuizWidgetStyle = typeof defaultQuizWidget

export const defaultQuizWidget = {
  background: colors.shades.white,
  borderRadius: defaultCommon.borderRadius,
  border: `1.2px solid ${colors.blick.grey60}`,
  padding: '0',
  wrapperMargin: '0 auto',
  innerWrapperPadding: '0',
  innerWrapperPaddingMobile: '0',
  indicatorPadding: '0',
  indicatorPaddingMobile: '0',
  indicatorTextStyle: `
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
  `,
  indicatorTextStyleMobile: `
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
  `,
  widgetTitlePadding: '16px 72px 16px 16px',
  widgetTitlePaddingMobile: '16px 72px 16px 16px',
  widgetTitleTextStyle: `
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
  `,
  wigetTitleTextStyleMobile: `
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
  `,
  titleTextStyle: `
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  `,
  titleTextStyleMobile: `
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  `,
  titleTextAlign: 'left',
  titleMargin: '0 0 16px 0',
  titleMarginMobile: '0 0 16px 0',
  simpleTeaserMargin: '-8px 0 16px 0',
  simpleTeaserMarginMobile: '-8px 0 16px 0',
  simpleTeaserTextStyle: `
    font-size: 16px;
  `,
  simpleTeaserTextStyleMobile: `
    font-size: 16px;
  `,
  questionsWrapperPadding: '0px 24px 24px 24px',
  questionsWrapperPaddingMobile: '0px 16px 16px 16px',
  innerWrapperNoTeaserPadding: '0',
  innerWrapperNoTeaserPaddingMobile: '0',
  answersWrapperPadding: '0 66px',
  answersWrapperPaddingMobile: '0 2px',
  answerJustifyContent: 'flex-start',
  answerBorderRadius: defaultCommon.borderRadius,
  answerBoxShadow: '',
  answerMinHeight: '55px',
  answerMinHeightMobile: '34px',
  answerPadding: '0 64px 0 24px',
  answerPaddingMobile: '0 64px 0 16px',
  answerMargin: '0 0 16px 0',
  answerTextStyle: `
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  `,
  answerTextStyleMobile: `
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  `,

  answerDefaultBackground: colors.blick.grey10,
  answerDefaultBackgroundHover: colors.blick.grey10,
  answerDefaultBoxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.25)',
  answerDefaultBorder: `2px solid ${colors.blick.grey10}`,
  answerDefaultInnerBackground: colors.blick.grey15,
  answerDefaultPercentageBackground: colors.shades.white,
  answerDefaultPercentageBorder: `2px solid ${colors.blick.grey30}`,
  answerDefaultPercentageColor: colors.blick.grey100,
  answerDefaultColor: '',

  answerLoadingBackground: colors.blick.grey10,
  answerLoadingBorder: `2px solid ${colors.blick.grey20}`,
  answerLoadingColor: '',

  answerSuccessBackground: colors.blick.sport5,
  answerSuccessBorder: `2px solid ${colors.blick.sport55}`,
  answerSuccessColor: '',
  answerSuccessInnerBackground: colors.blick.sport40,
  answerSuccessPointTextColor: colors.blick.sport55,

  answerFailureBackground: colors.blick.brand5,
  answerFailureBorder: `2px solid ${colors.feedback.negative}`,
  answerFailureColor: '',
  answerFailureInnerBackground: colors.blick.brand20,
  answerFailurePointTextColor: colors.feedback.negative,
  footerMargin: '16px 0 0 0',

  subTitleTextStyle: `
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
    `,
  subTitleTextStyleMobile: `
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
  `,
  resultWrapperPadding: '0',
  resultWrapperPaddingMobile: '0',
  resultTableBackground: 'transparent',
  resultTableBorder: 'none',
  resultTableMargin: '0',
  resultTableHeadRowHeight: '',
  resultTableHeadCellTextStyle: '',
  resultTableRowHeight: '',
  resultTableCellTextStyle: '',
  resultTableCellPadding: '',
  resultTableCellFirstChildPadding: '',
  resultTableCellLastChildPadding: '',
  resultTableCellPaddingMobile: '',
  progressBarWidth: '210px',
  progressBarPosition: 'absolute',
  progressBarPositionTop: '-3px',
  progressBarTextStyle: `
    font-size: 14px;
    line-height: 22px;
    color: ${colors.shades.white};
    text-transform: uppercase;
  `,
  progressBarBackground: colors.blick.blueGrey,
  progressBarBorder: 'none',
  progressBarBorderRadius: '24px',
  progressBarBoxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.12)',
  progressBarInnerBarBackground: colors.blick.blueGreyAlt,
  bettingBannerBackground: '',
  bettingBannerTitleTextStyle: '',
  bettingBannerTextStyle: '',
  bettingButtonBackground: '',
  bettingButtonColor: '',
  bettingButtonBackgroundDisabled: '',
  bettingButtonColorDisabled: '',
  collectedPointsBackground: colors.blick.grey100,
  collectedPointsColor: colors.shades.white,
  bottomWrapperBackground: colors.blick.grey20,
  bottomWrapperMargin: '0 -16px -23px -16px',
  bottomWrapperMarginMobile: '0 -16px -16px -16px',
  bottomWrapperPaddingDesktop: '16px',
  bottomWrapperPaddingMobile: '16px',
  bottomWrapperTextAlignLoggedin: 'left',
  bottomWrapperSubTitleBackground: colors.blick.brand55,
  pointsBackground: colors.blick.brand55,
  pointsTextStyle: `
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.shades.white}
  `,
  resultsTitleTextStyle: `
    font-weight: 700;
  `,
  resultsTitleMargin: '0',
}
