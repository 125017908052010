import { TCollapsibleStyle } from 'src/theme/themes/default/components/collapsible'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'

const collapsible: TCollapsibleStyle = defaultsDeep({}, defaultTheme.components.Collapsible)
collapsible.headerTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    color: ${defaultTheme.colors.blick.grey90};
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
`
collapsible.arrowColor = defaultTheme.colors.blick.grey80
collapsible.arrowWidth = '24p'
collapsible.arrowHeight = '24px'

export const greenfieldCollapsible = collapsible
