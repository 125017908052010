import { translations } from 'src/translations/de/default'
import { defaultsDeep } from 'lodash-es'

import { quizTranslations } from './quiz'
import { quizWidgetTranslations } from './quizwidget'
import { rulesTranslations } from './rules'
import { prizesTranslations } from './prizes'
import { userDashBoardTranslations } from './userDashBoard'
import { navigationLinksTranslations } from './navigationLinks'
import { endOfSeasonModalTranslations } from './endofseasonmodal'
import { endOfRoundModalTranslations } from './endofroundmodal'
import { leaderboardTranslations } from './leaderboard'
import { summaryTranslations } from './summary'

export const enTranslations = defaultsDeep({}, translations)

enTranslations.rules = rulesTranslations
enTranslations.quiz = quizTranslations
enTranslations.quizwidget = quizWidgetTranslations
enTranslations.prizes = prizesTranslations
enTranslations.userDashBoard = userDashBoardTranslations
enTranslations.navigationLinks = navigationLinksTranslations
enTranslations.endofseasonmodal = endOfSeasonModalTranslations
enTranslations.endofroundmodal = endOfRoundModalTranslations
enTranslations.summary = summaryTranslations
enTranslations.leaderboard = leaderboardTranslations
