import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'

export type THeroStyle = typeof defaultHero

export const defaultHero = {
  width: 'auto',
  margin: '0',
  padding: '0',
  paddingMobile: '0',
  background: 'none',
  showLogo: true,
  showLogoMobile: true,
  logoPositionLeftMobile: '8px',
  slideDesktopHeight: '360px',
  slideMobileHeight: '360px',
  imagePosition: 'top right',
  slideText: {
    letterSpacing: '0.01em',
    textAlign: 'left',
    color: colors.shades.white,
    fontFamily: fonts.goodOffcPro,
    fontSize: '28px',
    fontWeight: '900',
    width: 'calc(100% - 32px)',
    left: '16px',
    top: 'auto',
    right: 'auto',
    bottom: '22px',
    lineHeight: '30px',
    textShadow: '0px 0px 8px #1F1F1F',
    margin: '0',
    desktop: {
      letterSpacing: '0.01em',
      width: '580px',
      height: 'auto',
      left: '24px',
      bottom: '24px',
      top: 'auto',
      right: 'auto',
      fontWeight: '950',
      fontSize: '36px',
      lineHeight: '44px',
    },
  },
}
