import styled from 'styled-components/macro'

export const CloseButtonText = () => <Wrap>Verstanden</Wrap>

const Wrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0 8px;
  border-radius: 20px;
  &:hover {
    background-color: #f8f8f8;
  }
`
