export type TMeasures = typeof measures
type TMeasure = string | number
// TODO: evaluate the use of REM size, e.g. polished.rem()
const buildMeasure = (measure: TMeasure): string => (typeof measure === 'number' ? `${measure}px` : measure)
const buildMeasures = (args: TMeasure[]): string => args.map((arg) => buildMeasure(arg)).join(' ')

const sectionPaddings = {
  sides: '16px',
  top: '40px',
  bottom: '48px',
}

export const measures = {
  contentWidth: '994px',

  navigation: {
    miniHeaderHeight: '58px',
    headerHeightDesktop: '110px',
    headerHeightMobile: '76px',
    navigationScrollThreshold: '50px',
  },

  button: {
    height: '30px',
  },

  search: {
    height: '34px',
  },

  tabs: {
    height: '45px',
  },

  section: {
    paddingHorizontal: sectionPaddings.sides,
    paddingTop: sectionPaddings.top,
    paddingBottom: sectionPaddings.bottom,
    padding: `${sectionPaddings.top} ${sectionPaddings.sides} ${sectionPaddings.bottom} ${sectionPaddings.sides}`,
  },

  utils: {
    // Transforms multiple values into a size string,
    // e.g. multiple(0, 0) -> "0px 0px"
    // e.g. multiple("10px", 10) -> "10px 10px"
    multiple: (...args: TMeasure[]) => buildMeasures(args),
  },
}
