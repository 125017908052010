import { defaultsDeep } from 'lodash-es'

import { TTranslations } from 'src/translations/types'
import { translations as defaultTranslations } from 'src/translations/de/default'

import { schwingenLeaderboardTranslations } from './leaderboard'
import { schwingenSummaryTranslations } from './summary'
import { schwingenEndOfSeasonModalTranslations } from './endofseasonmodal'

export const translations: TTranslations = defaultsDeep({}, defaultTranslations)

translations.leaderboard = schwingenLeaderboardTranslations
translations.summary = schwingenSummaryTranslations
translations.endofseasonmodal = schwingenEndOfSeasonModalTranslations
