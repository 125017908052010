import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TDrawerStyle } from 'src/theme/themes/default/components/drawer'

const drawer: TDrawerStyle = defaultsDeep({}, defaultTheme.components.Drawer)

drawer.bodyShadow = '0px 1px 4px 1px rgba(0, 0, 0, 0.25)'
drawer.headerShadow = 'none'
drawer.footerShadow = 'none'
drawer.borderRadius = '0 0 0 0'

export const libertateaDrawer = drawer
