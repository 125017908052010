import { defaultsDeep } from 'lodash-es'

import { prizesTranslations, TPrizesTranslations } from 'src/translations/de/default/prizes'

export const wmtrophyPrizesTranslations: TPrizesTranslations = defaultsDeep({}, prizesTranslations)

wmtrophyPrizesTranslations.title = 'Tolle Preise zu gewinnen'
wmtrophyPrizesTranslations.description = () => (
  <>
    Nach der Gruppenphase werden unter allen Einzelspielern zwei Tombolapreise verlost. Am Schluss der WM gewinnen die
    Top 3 der Einzelspieler sowie das beste Team jeweils einen Preis. Zudem werden nochmals zwei Tombolapreise unter
    allen Einzelspielern ausgelost.
  </>
)
