import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TInfoSectionStyle } from 'src/theme/themes/default/components/infoSection'

const infoSection: TInfoSectionStyle = defaultsDeep({}, defaultTheme.components.InfoSection)

infoSection.padding = '56px 0px 0px 0px'
infoSection.paddingMobile = '40px 16px 48px 16px'
infoSection.alignment = 'center'
infoSection.titleTextStyle = `
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.01em;
`
infoSection.titleTextStyleMobile = `
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.01em;
`
infoSection.contentTextStyle = `
    font-size: 18px;
    line-height: 25px;
`
infoSection.contentTextStyleMobile = `
    font-size:18px;
    font-weight: 300;
    line-height: 25px;
`

export const libertateaInfoSection = infoSection
