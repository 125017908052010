import { BoldContent } from 'src/components/Bold'

export type TChampionsTranslations = typeof championsTranslations

export const championsTranslations = {
  title: 'Wette auf die Besten der WM',
  description: 'Platziere deine Tipps vor den ersten Bullys und sichere dir einen Spitzenplatz in der Rangliste.',
  pendingWinnerLabelText: 'Die WM läuft bereits',
  countDownText: (days: string, time: string) => `WM startet in ${days} Tagen und ${time} Std`,
  placeholderBetFinished: 'Keinen Tipp abgegeben',
  placeholderBetNotFinished: 'Spieler auswählen...',
  placeholderBetNotFinishedTeams: 'Team auswählen...',
  placeholderBetNotFinishedGolie: 'Goalie auswählen...',
  champion: {
    title: 'Weltmeister',
    subtitle: (rewardPoints: number, isDesktop: boolean) => (
      <>
        Welches Team jubelt am Schluss über den Titel? {isDesktop && <br />}
        <b>{rewardPoints} Punkte.</b>
      </>
    ),
  },
  goalie: {
    title: 'Bester Torhüter',
    subtitle: (rewardPoints: number, isDesktop: boolean) => (
      <>
        Welcher Torhüter schnappt sich die meisten Shutouts? {isDesktop && <br />}
        <b>{rewardPoints} Punkte.</b>
      </>
    ),
  },
  topSkorer: {
    title: 'Topskorer',
    subtitle: (rewardPoints: number, isDesktop: boolean) => (
      <>
        Welcher Spieler sichert sich die Topskorer-Krone? {isDesktop && <br />}
        <b>{rewardPoints} Punkte.</b>
      </>
    ),
  },
  badBoy: {
    title: 'Wertvollster Spieler',
    titleAlt: '"MVP"',
    subtitle: (rewardPoints: number, isDesktop: boolean) => (
      <>
        Welcher Spieler wird zum Most Valuable Player gewählt? {isDesktop && <br />}
        <b>{rewardPoints} Punkte.</b>
      </>
    ),
  },
  result: {
    pointsLabel: {
      correct: 'Pkt',
      incorrect: (points: number | null) => (points === 1 ? 'Trostpunkt' : 'Trostpunkte'),
    },
    allCorrectTitle: 'Wahnsinn!',
    someCorrectTitle: 'Gut gemacht!',
    allIncorrectTitle: 'Leider falsch getippt...',
    noBetTitle: 'Kein Tipp abgegeben',
    correctPointsText: (points: number, betType: string) => (
      <>
        <BoldContent>+{points}</BoldContent> {betType} richtig getippt
      </>
    ),
    consolationPointsText: (points: number) => (
      <>
        <BoldContent>+{points}</BoldContent> {points === 1 ? 'Trostpunkt' : 'Trostpunkte'} fürs Mitspielen
      </>
    ),
    noBetText: '0 Punkte',
    winnerText: (winner: string | null) => (
      <>
        Richtig ist: <BoldContent>{winner}</BoldContent>.
      </>
    ),
  },
}
