export type TMessagesTranslations = typeof messagesTranslations

export const messagesTranslations = {
  champions_success_placeBet: 'Dein Tipp wurde gespeichert.',
  bet_success_create:
    'Du hast erfolgreich auf ein Rennen getippt. Aktiviere einen Booster, um zusätzliche Punkte zu sammeln.',
  bet_failure_selection:
    'Du hast bereits drei Athleten ausgewählt. Entferne ein Auswahl aus der Liste oder bestätige den Tipp.',
  bet_success_create_matchbet: 'Dein Tipp wurde gespeichert.',
  bet_success_remove_matchBet: 'Dein Tipp wurde gelöscht.',
  medalcount_success_update: 'Dein Tipp wurde gespeichert.',
  powerups_success_apply: 'Booster wurde aktiviert.',
  powerups_success_reset: 'Der Booster wurde erfolgreich entfernt.',
  scorebet_success_placebet: 'Dein Tipp wurde gespeichert.',
}
