import { defaultsDeep } from 'lodash-es'

import { TTranslations } from 'src/translations/types'
import { translations as defaultTranslations } from 'src/translations/de/default'

import { wmtrophyQuizWidgetTranslations } from './quizwidget'
import { wmtrophyLeaderboardTranslations } from './leaderboard'
import { wmtrophyPowerupsTranslations } from './powerups'
import { wmtrophyQuizTranslations } from './quiz'
import { wmtrophyEndOfSeasonModalTranslations } from './endofseasonmodal'
import { wmtrophyEndOfRoundModalTranslations } from './endofroundmodal'
import { wmtrophyScoreBetTranslations } from './scorebet'
import { wmtrophySummaryTranslations } from './summary'
import { wmtrophyPrizesTranslations } from './prizes'

export const translations: TTranslations = defaultsDeep({}, defaultTranslations)

translations.quiz = wmtrophyQuizTranslations
translations.quizwidget = wmtrophyQuizWidgetTranslations
translations.leaderboard = wmtrophyLeaderboardTranslations
translations.powerups = wmtrophyPowerupsTranslations
translations.endofseasonmodal = wmtrophyEndOfSeasonModalTranslations
translations.endofroundmodal = wmtrophyEndOfRoundModalTranslations
translations.summary = wmtrophySummaryTranslations
translations.scorebet = wmtrophyScoreBetTranslations
translations.endofseasonmodal = wmtrophyEndOfSeasonModalTranslations
translations.endofroundmodal = wmtrophyEndOfRoundModalTranslations
translations.summary = wmtrophySummaryTranslations
translations.prizes = wmtrophyPrizesTranslations
