import { defaultCommon } from './common'
import { defaultLeaderboard } from './leaderboard'
import { defaultQuiz } from './quiz'
import { defaultButton } from './button'
import { defaultLink } from './link'
import { defaultTab } from './tab'
import { defaultTable } from './table'
import { defaultCollapsible } from './collapsible'
import { defaultScoreBet } from './scoreBet'
import { defaultToast } from './toast'
import { defaultInfoSection } from './infoSection'
import { defaultRules } from './rules'
import { defaultHero } from './hero'
import { defaultEndOfSeasonModal } from './endOfSeasonModal'
import { defaultSummary } from './summary'
import { defaultFeedback } from './feedback'
import { defaultBet } from './bet'
import { defaultMatchBet } from './matchBet'
import { defaultPowerups } from './powerups'
import { defaultDrawer } from './drawer'
import { defaultUserDashBoard } from './userDashBoard'
import { defaultModal } from './modal'
import { defaultTextInput } from './textInput'
import { defaultQuizWidget } from './quizWidget'
import { defaultMedalCount } from './medalCount'
import { defaultHeader } from './header'
import { defaultPrizeAlt } from './prizeAlt'

export const components = {
  common: defaultCommon,
  Header: defaultHeader,
  Quiz: defaultQuiz,
  QuizWidget: defaultQuizWidget,
  Leaderboard: defaultLeaderboard,
  Button: defaultButton,
  Link: defaultLink,
  Tab: defaultTab,
  Table: defaultTable,
  Collapsible: defaultCollapsible,
  ScoreBet: defaultScoreBet,
  Toast: defaultToast,
  InfoSection: defaultInfoSection,
  Rules: defaultRules,
  Hero: defaultHero,
  EndOfSeasonModal: defaultEndOfSeasonModal,
  Summary: defaultSummary,
  Feedback: defaultFeedback,
  Bet: defaultBet,
  MatchBet: defaultMatchBet,
  Powerups: defaultPowerups,
  Drawer: defaultDrawer,
  UserDashBoard: defaultUserDashBoard,
  MedalCount: defaultMedalCount,
  Modal: defaultModal,
  TextInput: defaultTextInput,
  PrizeAlt: defaultPrizeAlt,
}
