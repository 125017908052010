import { ActionCreator } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { config } from 'src/config'
import { TReduxState, store } from 'src/redux/store'
import { privateClient } from 'src/utils/clients'
import { LoginMethods } from 'src/tracking/gtm/enums'
import { getAuthSource, getAuthLoginCase, getSelfUrl, getUtmParams, getProductFromPath } from 'src/utils/auth'
import { ActionTypes } from './enums'
import {
  IAuthUser,
  IAuthMetaData,
  TLoginAction,
  TLoginSuccessAction,
  TNicknameUpdateAction,
  TNicknameUpdateSuccessAction,
  TNicknameUpdateFailureAction,
  TLogoutAction,
  TLoginCheckStartAction,
  TLoginCheckFinishAction,
  TLoginOptions,
  TReturnedFromLoginAction,
} from './types'
import { getIsLoggedIn } from './selectors'
import { trackUserStartLogin } from 'src/tracking/gtm/actions'
import { trackError } from 'src/tracking/sentry'
import { checkBackendError, isBackendError, isHttpError } from 'src/utils/errors'
import { toasty } from 'src/containers/toast/toasty'
import { getCookie, setCookie } from 'src/utils/cookie'
import { getProductSeasonId } from 'src/api/product/selectors'
import { v4 as uuidv4 } from 'uuid'

export const login: ActionCreator<TLoginAction> = (method: LoginMethods, { returnUrl = '' }: TLoginOptions = {}) => {
  let selfUrl: URL
  if (returnUrl.match(/:\/\//)) {
    selfUrl = new URL(returnUrl)
  } else {
    selfUrl = new URL(returnUrl, getSelfUrl())
  }

  // Set a cookie so we can track if user came back from login-flow on page load
  const state = uuidv4()
  setCookie(`loginstate_${state}`, JSON.stringify({ method }).toString(), 1)
  selfUrl.searchParams.set('state', state)

  const productId = getProductFromPath()
  const loginCase = getAuthLoginCase()
  const source = getAuthSource()
  const utmParams = getUtmParams()
  const gacid = getCookie('_ga')
  const ga4_sid = 'MLMFLVGTVS'
  const ga4_value = getCookie('_ga_MLMFLVGTVS')
  const seasonId = getProductSeasonId(store.getState())

  trackUserStartLogin(method)

  const url = `${config.api.backend.url}/auth/begin
?loginRedirect=${encodeURIComponent(selfUrl.href)}
&loginCase=${loginCase}
&source=${source}_${productId}_${seasonId}_${method}
&utmParams=${utmParams}
&gacid=${gacid}
&ga4_sid=${ga4_sid}
&ga4_value=${ga4_value}`

  if (window.top) {
    window.top.location.href = url
  } else {
    window.location.href = url
  }

  return {
    type: ActionTypes.AUTH_LOGIN,
  }
}

export const returnedFromLogin =
  (payload: {
    didReturnFromLogin: true
    loginMethod: string | null
  }): ThunkAction<void, TReduxState, unknown, TReturnedFromLoginAction> =>
  (dispatch) => {
    dispatch({
      type: ActionTypes.AUTH_RETURNED_FROM_LOGIN,
      payload,
    })
  }

const loginSuccess =
  (payload: {
    user: IAuthUser
    metaData: IAuthMetaData | undefined
  }): ThunkAction<void, TReduxState, unknown, TLoginSuccessAction> =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.AUTH_LOGIN_SUCCESS,
      payload,
    })
  }

export const logout: ActionCreator<TLogoutAction> = ({ returnUrl = '' }: { returnUrl?: string }) => {
  const selfUrl = returnUrl.match(/:\/\//) ? returnUrl : getSelfUrl() + returnUrl

  const url = `${config.api.backend.url}/auth/logout?logoutRedirect=${encodeURIComponent(selfUrl)}`

  window.location.href = url

  return {
    type: ActionTypes.AUTH_LOGOUT,
  }
}

export const checkBlickAuth =
  (): ThunkAction<
    void,
    TReduxState,
    unknown,
    TLoginSuccessAction | TLogoutAction | TLoginCheckStartAction | TLoginCheckFinishAction
  > =>
  async (dispatch) => {
    const url = `${config.api.backend.url}/session`
    const metadataUrl = `${config.api.onelog.metadataUrl}`
    let res
    let metadataRes
    dispatch({
      type: ActionTypes.AUTH_CHECK_START,
    })

    try {
      res = await privateClient.get<IAuthUser>(url)

      if (isBackendError(res) && res.response?.status === 401) {
        throw new Error('Unauthorized') // Successful request with customized BE error message needs to be handled separately.
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT,
      })
    }

    try {
      metadataRes = await privateClient.get<{ metadata: IAuthMetaData }>(metadataUrl)
    } catch (err) {
      trackError(err)
    }

    // Offline? User should not be logged out.
    if (!res) {
      return
    }

    const isLoggedIn = Boolean(res.data?.sub && metadataRes?.data)
    const isStoreLoggedIn = getIsLoggedIn(store.getState())

    // Login the redux store and update session data
    if (isLoggedIn) {
      dispatch(loginSuccess({ user: res.data, metaData: metadataRes?.data.metadata }))
    }

    // Logout the redux store, if it isn't logged in
    if (!isLoggedIn && isStoreLoggedIn) {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT,
      })
    }

    dispatch({
      type: ActionTypes.AUTH_CHECK_FINISH,
    })
  }

export const checkBackendAuth =
  (): ThunkAction<
    void,
    TReduxState,
    unknown,
    TLoginSuccessAction | TLogoutAction | TLoginCheckStartAction | TLoginCheckFinishAction
  > =>
  async (dispatch) => {
    const url = `${config.api.backend.url}/session`
    let res
    dispatch({
      type: ActionTypes.AUTH_CHECK_START,
    })

    try {
      res = await privateClient.get<IAuthUser>(url)

      if (isBackendError(res) && res.response?.status === 401) {
        throw new Error('Unauthorized') // Successful request with customized BE error message needs to be handled separately.
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT,
      })
    }

    // Offline? User should not be logged out.
    if (!res) {
      return
    }

    const isLoggedIn = Boolean(res.data?.sub)
    const isStoreLoggedIn = getIsLoggedIn(store.getState())

    // Login the redux store and update session data
    if (isLoggedIn) {
      dispatch(loginSuccess({ user: res.data, metaData: undefined }))
    }

    // Logout the redux store, if it isn't logged in
    if (!isLoggedIn && isStoreLoggedIn) {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT,
      })
    }

    dispatch({
      type: ActionTypes.AUTH_CHECK_FINISH,
    })
  }

export const updateNickname =
  (
    nickname: string,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TNicknameUpdateAction | TNicknameUpdateSuccessAction | TNicknameUpdateFailureAction
  > =>
  async (dispatch) => {
    const url = `${config.api.backend.url}/user/nickname`
    let res
    dispatch({
      type: ActionTypes.AUTH_UPDATE_NICKNAME,
    })
    try {
      res = await privateClient.put<{ nickname: string }>(url, { nickname })

      checkBackendError(res, 'Aktualizacja nicka nie powiodła się')

      dispatch({
        type: ActionTypes.AUTH_UPDATE_NICKNAME_SUCCESS,
        payload: { nickname },
      })

      toasty('Nick zaktualizowany', { type: 'success' })
    } catch (err) {
      const getErrorText = (message: string, status?: number) => {
        if (status === 422) {
          return 'Nick powinien mieć od 2 do 30 znaków i nie powinien zawierać znaków specjalnych.'
        }

        switch (message) {
          case 'already_taken':
            return 'Ten nick już istnieje. Proszę wybrać inny.'
          case 'not_allowed':
          case 'Not allowed':
            return 'Ten nick jest niedozwolony. Proszę wybrać inny.'

          default:
            return message
        }
      }
      if (isHttpError(err)) {
        console.log(err)
        dispatch({
          type: ActionTypes.AUTH_UPDATE_NICKNAME_FAILURE,
          payload: { ...err, message: getErrorText(err.message, err.status) },
        })
      }
    }
  }

export const resetNicknameError = () => ({
  type: ActionTypes.AUTH_RESET_NICKNAME_ERROR,
})

export const showNicknameModal = () => ({
  type: ActionTypes.AUTH_SHOW_REQ_MODAL,
})

export const hideNicknameModal = () => ({
  type: ActionTypes.AUTH_HIDE_REQ_MODAL,
})
