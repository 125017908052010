import { defaultsDeep } from 'lodash-es'
import { summaryTranslations, TSummaryTranslations } from 'src/translations/de/default/summary'

export const unihockeySummaryTranslations: TSummaryTranslations = defaultsDeep({}, summaryTranslations)

unihockeySummaryTranslations.title = (userRank?: number) => {
  if (userRank) {
    return <>Du hast dich auf Platz {userRank} der Rangliste gekämpft!</>
  }
  return <>Schade, dass du nicht mitgespielt hast. Vielleicht beim nächsten Mal?</>
}
unihockeySummaryTranslations.subscriptionTitle =
  'Die Blick Unihockey Trophy ist leider vorbei. Schon bald aber gibt es neue Spiele!'
unihockeySummaryTranslations.subscriptionText = 'Erhalte eine E-Mail sobald es neue Spiele gibt!'
