import { TModalStyle } from 'src/theme/themes/default/components/modal'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { onetFonts } from 'src/theme/themes/onet/fonts'

const modal: TModalStyle = defaultsDeep({}, defaultTheme.components.Modal)

modal.padding = '24px'
modal.baseLineHeight = '140%'
modal.modalTitleStyle = `
    font-family: ${onetFonts.firaSans};
    font-size: 24px;
    line-height: 140%;
    font-weight: 500;
`

export const onetModal = modal
