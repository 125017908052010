export enum ActionTypes {
  SET_MINI_HEADER_ACTIVE = '@APP/SET_MINI_HEADER_ACTIVE',
  SET_DASHBOARD_ACTIVE_TAB = '@APP/SET_DASHBOARD_ACTIVE_TAB',
  SET_USED_LOGIN_COMPONENT = '@APP/SET_USED_LOGIN_COMPONENT',
}

export enum DashboardTab {
  MyPlayer = 'my-player',
  MyGroup = 'my-group',
}
