import { defaultsDeep } from 'lodash-es'

import {
  endOfSeasonModalTranslations,
  TEndOfSeasonModalTranslations,
} from 'src/translations/de/default/endofseasonmodal'

export const unihockeyEndOfSeasonModalTranslations: TEndOfSeasonModalTranslations = defaultsDeep(
  {},
  endOfSeasonModalTranslations,
)

unihockeyEndOfSeasonModalTranslations.statisticsSubtitle = 'DEINE UNIHOCKEY TROPHY'
unihockeyEndOfSeasonModalTranslations.feedbackTitle = 'Wie zufrieden warst du mit dieser Blick Trophy?'
