import { defaultsDeep } from 'lodash-es'

import { leaderboardTranslations, TLeaderboardTranslations } from 'src/translations/de/default/leaderboard'

export const uefafrauenLeaderboardTranslations: TLeaderboardTranslations = defaultsDeep({}, leaderboardTranslations)

uefafrauenLeaderboardTranslations.title = 'Rangliste'
uefafrauenLeaderboardTranslations.description =
  'Es zählen alle Punkte, welche du im Tagesquiz und im Tippspiel sammelst. Die erfolgreichsten Spielerinnen und Spieler klassieren sich in der Weltklasse-Liga, im Anschluss folgt die Elite- und Amateur-Rangliste. '
uefafrauenLeaderboardTranslations.leaderboard_page_button = 'Zur kompletten Rangliste'
