import { PowerupType } from 'src/api/powerups/enums'
import { getPowerupTranslationsTitleKey, getPowerupTranslationsDescriptionKey } from 'src/utils/powerups'

export type TPowerupsTranslations = typeof powerupsTranslations

export const powerupsTranslations = {
  expiredPowerupIsNotEditable:
    'Der Booster kann nicht mehr geändert werden, da das Sportereignis in eine neue Runde verschoben wurde.',
  noAvailablePowerups: (
    <>
      Du hast leider schon alle Booster der aktuellen Runde verbraucht. <br />
      <br />
      Neue Booster gibt es wieder in der nächsten Runde.
    </>
  ),
  availablePowerups: (totalPowerupsLength: number, avalablePowerupsLength: number) =>
    `Du hast ${avalablePowerupsLength} Booster bis zum Ende des Tippspiels zur Verfügung.`,
  infoMyPowerups: (totalPowerupsLength: number) =>
    `Das sind deine bisher eingesetzten oder noch verfügbaren Booster. Du hast ${totalPowerupsLength} Booster für das gesamte Tippspiel zur Verfügung.`,
  infoTooltipTitle: 'Booster für mehr Punkte!',
  infoTooltipText:
    'Booster geben dir die Möglichkeit, noch mehr Punkte zu sammeln. Um einen Booster zu aktivieren, musst du zuerst einen Resultattipp abgeben. Für jede Runde stehen dir neue Booster zur Verfügung. Probiere es jetzt aus!',
  buttonIsActive: 'BOOSTER IST AKTIV',
  buttonActivate: 'BOOSTER AKTIVIEREN',
  buttonNotActivated: 'Kein Booster aktiviert',
  rejectModal: {
    title: (isDisablePowerupsAction: boolean) =>
      `Booster wurde nicht ${isDisablePowerupsAction ? 'deaktiviert' : 'aktiviert'}`,
    text: (isDisablePowerupsAction: boolean) =>
      `Der gewählte Booster wird nur ${isDisablePowerupsAction ? 'deaktiviert' : 'aktiviert'}, wenn du zuerst auf
      «Auswahl bestätigen» klickst.`,
    discardButtonLabel: (isDisablePowerupsAction: boolean) =>
      `nicht ${isDisablePowerupsAction ? 'deaktivieren' : 'aktivieren'}`,
    returnButtonLabel: (isDisablePowerupsAction: boolean) =>
      `zurück, um booster zu ${isDisablePowerupsAction ? 'deaktivieren' : 'aktivieren'}`,
  },
  infoModal: {
    title: 'Gib zuerst einen Tipp ab, um den Booster zu aktivieren',
    text: 'Mit dem Booster kannst du deine Punkte vermehren. Tippe auf das Spiel, klicke auf «Booster aktivieren» und wähle dann einen Booster aus.',
  },
  [getPowerupTranslationsTitleKey(PowerupType.AlwaysSixPoints)]: '6 Punkte garantiert',
  [getPowerupTranslationsTitleKey(PowerupType.Insurance)]: 'Insurance',
  [getPowerupTranslationsTitleKey(PowerupType.Double)]: 'Doppelt gemoppelt',
  [getPowerupTranslationsTitleKey(PowerupType.DoubleOrNothing)]: 'Doppelt oder Nix',
  [getPowerupTranslationsTitleKey(PowerupType.PlusOne)]: 'Extrapunkt',
  [getPowerupTranslationsTitleKey(PowerupType.SwissParticipant)]: 'Hopp Schwiiz',
  [getPowerupTranslationsTitleKey(PowerupType.OneForAll)]: 'Einer für Alle',
  [getPowerupTranslationsTitleKey(PowerupType.AlwaysEighteenPoints)]: 'Dein Weihnachtsgeschenk',
  [getPowerupTranslationsTitleKey(PowerupType.Always12Points)]: 'Volle Punkte garantiert',
  [getPowerupTranslationsTitleKey(PowerupType.AlwaysFullPoints)]: 'Volle Punkte garantiert',
  [getPowerupTranslationsTitleKey(PowerupType.AtLeastOneCorrect)]: 'Einer für Alles',

  [getPowerupTranslationsDescriptionKey(PowerupType.AlwaysSixPoints)]:
    'Du erhältst garantiert 6 Punkte. Egal, ob du richtig oder falsch getippt hast.',
  [getPowerupTranslationsDescriptionKey(PowerupType.Insurance)]: 'Insurance',
  [getPowerupTranslationsDescriptionKey(PowerupType.Double)]:
    'Du erhältst die doppelte Punktzahl. Egal, ob du richtig oder falsch getippt hast.',
  [getPowerupTranslationsDescriptionKey(PowerupType.DoubleOrNothing)]:
    'Erhalte die doppelte Punktzahl, wenn du richtig tippst. Falls du daneben liegst, kriegst du leider auch keinen Trostpunkt.',
  [getPowerupTranslationsDescriptionKey(PowerupType.PlusOne)]:
    'Du erhältst einen Extrapunkt. Egal, ob du richtig oder falsch getippt hast.',
  [getPowerupTranslationsDescriptionKey(PowerupType.SwissParticipant)]:
    'Wenn die Schweiz in diesem Spiel gewinnt, erhältst du die dreifache Punktzahl.',
  [getPowerupTranslationsDescriptionKey(PowerupType.OneForAll)]:
    'Wenn du mindestens einen Fahrer richtig getippt hast, erhältst du 3 Zusatzpunkte. Falls du in einem Team spielst, erhält dein Team zusätzlich 6 Punkte.',
  [getPowerupTranslationsDescriptionKey(PowerupType.AlwaysEighteenPoints)]:
    'Du erhältst garantiert 18 Punkte. Egal, ob du richtig oder falsch getippt hast. Frohe Festtage!',
  [getPowerupTranslationsDescriptionKey(PowerupType.Always12Points)]:
    'Du erhältst garantiert 12 Punkte. Ganz egal, ob du richtig oder falsch getippt hast.',
  [getPowerupTranslationsDescriptionKey(PowerupType.AlwaysFullPoints)]:
    'Du erhältst garantiert 5 Punkte. Ganz egal, ob du richtig oder falsch getippt hast.',
  [getPowerupTranslationsDescriptionKey(PowerupType.AtLeastOneCorrect)]:
    'Wenn du mindestens einen Fahrer richtig getippt hast, kriegst du die maximale Punktzahl.',
}
