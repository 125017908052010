import axios, { AxiosError } from 'axios'
import { identity, defaultsDeep } from 'lodash-es'

import { config } from 'src/config'
import { trackError } from 'src/tracking/sentry'
import { trackErrorEvent } from 'src/tracking/gtm/actions'
import { sessionStorageGetItem } from 'src/utils/sessionStorage'

// The return value was not forced before, as axios interceptors are not properly typed.
// response.use does not complain about interceptors not having return values, nor does axios carry an Interceptor definition.
// We introduce our own type for now:
type AxiosInterceptor<T = unknown> = (t: T) => T

const successInterceptor: AxiosInterceptor = identity
const errorInterceptor: AxiosInterceptor<AxiosError> = (error) => {
  if (window.blickIframeUtils?.getIsParentiOS?.() || window.blickIframeUtils?.getIsParentAndroid?.()) {
    return error // if we are in iOS WebView or Android webview: do not track errors as we do no support this environment
  }

  //Sentry tracking
  if (error.response?.data?.message) {
    // Enhance to include message from backend (e.g. no_session, or betting_closed)
    error.message += `: ${error.response?.data?.message}`
  }
  trackError(error)

  //GTM tracking
  trackErrorEvent(error)

  return error
}

// Always make sure to use one of the clients for your requests!

// For public resources
export const publicClient = axios.create()
publicClient.interceptors.response.use(successInterceptor, errorInterceptor)

// For protected resources
export const privateClient = axios.create()
privateClient.defaults.withCredentials = true
privateClient.interceptors.response.use(successInterceptor, errorInterceptor)

if (config.isDev) {
  const devDateInterceptor: AxiosInterceptor = (config) => {
    const devTimeShift = parseInt(sessionStorageGetItem('dev-time-shift') || '0')

    if (!devTimeShift) {
      return config
    }

    return defaultsDeep(
      {
        headers: {
          common: {
            'X-Dev-Testing-Date': new Date(Date.now() + devTimeShift + /*emulate ping delay*/ 90).toISOString(),
          },
        },
      },
      config,
    )
  }

  publicClient.interceptors.request.use(devDateInterceptor)
  privateClient.interceptors.request.use(devDateInterceptor)
}
