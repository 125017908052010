import { colors } from 'src/theme/colors'

export type TFeedbackStyle = typeof defaultFeedback

export const defaultFeedback = {
  boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.25)',
  border: 'none',
  pointsTextColor: colors.blick.grey100,
  pointsColor: colors.feedbackAlt.positive,
}
