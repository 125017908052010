import { defaultsDeep } from 'lodash-es'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { accentwayColors } from 'src/theme/themes/accentway/colors'
import { TLeaderboardStyle } from 'src/theme/themes/default/components/leaderboard'

const leaderboard: TLeaderboardStyle = defaultsDeep({}, defaultTheme.components.Leaderboard)

leaderboard.hasRewardRow.background = accentwayColors.blue
leaderboard.currentUserRow.background = accentwayColors.turquoise
leaderboard.currentUserRow.color = '#fff'
leaderboard.rewardIconSize = '20px'
leaderboard.sectionTitleBorderColor = accentwayColors.green
leaderboard.rewardIconMarginLeft = '10px'
leaderboard.rewardIconMarginRight = '18px'
leaderboard.sectionTitleFontWeight = 900
leaderboard.goToLeaderBoardButton.marginTop = '0'

export const accentwayLeaderboard = leaderboard
