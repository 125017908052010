import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { onetColors } from 'src/theme/themes/onet/colors'
import { TQuizStyle } from 'src/theme/themes/default/components/quiz'

const quiz: TQuizStyle = defaultsDeep({}, defaultTheme.components.Quiz)

quiz.borderRadius = '6px'
quiz.background = onetColors.lightGrey2
quiz.padding = '32px 0px 0px 0px'
quiz.wrapperMargin = '0'
quiz.innerWrapperPadding = '24px'
quiz.innerWrapperPaddingMobile = '8px'
quiz.indicatorPadding = '0'
quiz.indicatorPaddingMobile = '0'
quiz.indicatorTextStyle = `
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: left;
    text-transform: uppercase;
`
quiz.indicatorTextStyleMobile = `
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
`
quiz.titleTextStyle = `
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 120%;
    text-align: left;
`
quiz.titleTextStyleMobile = `
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    text-align: center;
`
quiz.titleTextAlign = 'left'
quiz.titleMarginMobile = '16px 0 32px 0'
quiz.answersWrapperPadding = '0'
quiz.questionsWrapperPadding = '8px'
quiz.simpleTeaserMargin = '-8px 0 24px 0'
quiz.simpleTeaserMarginMobile = '-8px 0 24px 0'
quiz.simpleTeaserTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    text-align: left;
`
quiz.simpleTeaserTextStyleMobile = `
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
`
quiz.innerWrapperNoTeaserPadding = '20px 150px'
quiz.questionsWrapperPaddingMobile = '16px'
quiz.answersWrapperPaddingMobile = quiz.answersWrapperPadding
quiz.answerBorderRadius = '6px'
quiz.answerMargin = '0 0 16px 0'
quiz.answerJustifyContent = 'flex-start'
quiz.answerBoxShadow = 'none'
quiz.answerMinHeight = '66px'
quiz.answerMinHeightMobile = '57px'
quiz.answerPadding = '0 50px 0 24px'
quiz.answerPaddingMobile = '0 50px 0 16px'
quiz.answerTextStyle = `
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
`
quiz.answerTextStyleMobile = quiz.answerTextStyle

quiz.subTitleTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
`
quiz.subTitleTextStyleMobile = quiz.subTitleTextStyle
quiz.resultTableBackground = defaultTheme.colors.shades.white
quiz.resultWrapperPadding = '12px'
quiz.resultWrapperPaddingMobile = '16px'
quiz.resultsTitleMargin = '0px 0 16px 0'
quiz.resultsTitleMarginMobile = quiz.resultsTitleMargin
quiz.resultTableMargin = '24px 0 16px 0'
quiz.resultTableMarginMobile = quiz.resultTableMargin
quiz.resultTableHeadRowHeight = '36px'
quiz.resultTableHeadCellTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-size: 14px;
    line-height: 32px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.resultTableRowHeight = '54px'
quiz.resultTableCellTextStyle = `
    font-family: 'Source Sans Pro';
    font-size: 16px;
    line-height: 23px;
    color: ${defaultTheme.colors.blick.grey100};
`
quiz.resultTableCellPadding = '0 8px'
quiz.resultTableCellPaddingMobile = '0 8px'
quiz.resultTableCellFirstChildPadding = '0 0 0 16px'
quiz.resultTableCellLastChildPadding = '0 16px 0 0'
quiz.teaserTitlePadding = '16px 8px'
quiz.teaserBoxShadow = 'none'
quiz.teaserTitleTextStyle = `
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: ${onetColors.darkGrey};
`
quiz.teaserArticleTitleTextStyle = `
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: ${onetColors.darkGrey};
`
quiz.teaserArticleTitleTextStyleMobile = quiz.teaserArticleTitleTextStyle
quiz.teaserArticleSubTitleTextStyle = `
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: ${onetColors.darkGrey};
`
quiz.teaserArticleSubTitleTextStyleMobile = quiz.teaserArticleSubTitleTextStyle

quiz.answerDefaultBackground = defaultTheme.colors.shades.white
quiz.answerDefaultBackgroundHover = onetColors.ultraLightGrey
quiz.answerDefaultBoxShadow = 'none'
quiz.answerDefaultBorder = `1px solid ${onetColors.grey2}`
quiz.answerDefaultInnerBackground = onetColors.veryLightGrey
quiz.answerDefaultPercentageBackground = defaultTheme.colors.shades.white
quiz.answerDefaultPercentageBorder = `2px solid ${onetColors.grey}`

quiz.answerLoadingBackground = onetColors.veryLightGrey
quiz.answerLoadingBorder = `2px solid ${onetColors.veryLightGrey}`

quiz.answerSuccessBackground = onetColors.successGreenLight
quiz.answerSuccessBorder = `2px solid ${onetColors.successGreen}`
quiz.answerSuccessInnerBackground = onetColors.successGreenMedium

quiz.answerFailureBackground = onetColors.errorRedLight
quiz.answerFailureBorder = `2px solid ${onetColors.errorRed}`
quiz.answerFailureInnerBackground = onetColors.errorRedMedium

quiz.answerPercentageTextStyle = `
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
`
quiz.cardPadding = '12px 52px'
quiz.cardPaddingMobile = '16px'
quiz.cardTitleTextStyle = `
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
    margin:0 auto;
`
quiz.cardTextStyle = `
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    // text-align: center;
`
quiz.resultsTitleTextStyle = `
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
`
quiz.resultsTitleTextStyleMobile = `
    font-weight: 400;
    font-size: 24px;
    line-height: 140%;
`
export const onetQuiz = quiz
