import { defaultsDeep } from 'lodash-es'
import { Bold } from 'src/components/Bold'

import { quizTranslations, TQuizTranslations } from 'src/translations/de/default/quiz'

export const skitrophyQuizTranslations: TQuizTranslations = defaultsDeep({}, quizTranslations)

skitrophyQuizTranslations.title = 'Teste dein Wissen mit der Tagesfrage'
skitrophyQuizTranslations.description = (
  <>
    Beantworte <Bold>täglich eine Frage</Bold> und erhalte <Bold>drei Punkte</Bold> für die richtige Antwort.
  </>
)
skitrophyQuizTranslations.indicatiorText = () => 'Ergebnis des TagesQuiz'

skitrophyQuizTranslations.resultTitle = {
  ...quizTranslations.resultTitle,
  none: 'Knapp daneben...',
}

skitrophyQuizTranslations.resultSummaryText = (
  _: number,
  __: number | undefined,
  pointsPerCorrectQuestion: number,
  gainedPoints: number,
) => {
  const isSingleQuestionCorrect = gainedPoints === pointsPerCorrectQuestion
  if (isSingleQuestionCorrect) {
    return (
      <>
        Du hast die Frage richtig beantwortet und erhältst <Bold>3 Punkte.</Bold> Morgen kannst du dein Wissen aufs Neue
        testen!
      </>
    )
  }

  return (
    <>
      Du hast die Tagesfrage falsch beantwortet. Das gibt leider <Bold>keinen Punkt.</Bold> Kopf hoch, morgen kannst du
      dein Wissen aufs Neue testen!
    </>
  )
}

skitrophyQuizTranslations.resultSummaryTextGroup = (
  _: number,
  __: number | undefined,
  pointsPerCorrectQuestion: number,
  gainedPoints: number,
) => {
  const isSingleQuestionCorrect = gainedPoints === pointsPerCorrectQuestion
  if (isSingleQuestionCorrect) {
    return (
      <>
        Du hast die Tagesfrage richtig beantwortet und glatte <Bold>3 Punkte für dich sowie für deine Tippgruppe</Bold>{' '}
        gesammelt.
      </>
    )
  }

  return (
    <>
      Du hast die Tagesfrage falsch beantwortet. Das gibt leider{' '}
      <Bold>keinen Punkt für dich sowie für deine Tippgruppe.</Bold>
    </>
  )
}

skitrophyQuizTranslations.resultSummaryEndText = ''

skitrophyQuizTranslations.resultSummaryEndTextGroup = (pointsPerCorrectQuestion: number, gainedPoints: number) => {
  const isSingleQuestionCorrect = gainedPoints === pointsPerCorrectQuestion
  if (isSingleQuestionCorrect) {
    return 'Morgen kannst du dein Wissen aufs Neue testen!'
  }

  return 'Kopf hoch, morgen kannst du dein Wissen aufs Neue testen!'
}
skitrophyQuizTranslations.blickButtonText = 'Blick Ski-News'
skitrophyQuizTranslations.answersName = 'Antworten insgesamt'

skitrophyQuizTranslations.allAnsweredCard = {
  title: 'Tagesfrage bereits beantwortet',
  description:
    'Danke, dass du dich eingeloggt hast! Du hast die Tagesfrage heute bereits beantwortet. Schau dir jetzt dein Ergebnis an.',
  button: 'Ergebnis anzeigen',
}
skitrophyQuizTranslations.alreadyAnsweredCard = {
  title: 'Frage bereits beantwortet',
  description:
    'Danke, dass du dich eingeloggt hast! Da du diese Frage während der Trophy bereits beantwortet hast, erhältst du eine neue Frage.',
  button: 'MIT NEUER FRAGE WEITERSPIELEN',
}
