import { TPrizesTranslations } from 'src/translations/de/default/prizes'

export const prizesTranslations: TPrizesTranslations = {
  title: 'Awards',
  description: () => (
    <>
      In the <b>"Win with Question of the Day"</b> competition you have the chance to win one of over 270 prizes. In
      addition, throughout the competition period, we will also have extra prizes.
    </>
  ),
}
