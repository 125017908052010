import { defaultsDeep } from 'lodash-es'

import { leaderboardTranslations, TLeaderboardTranslations } from 'src/translations/de/default/leaderboard'

export const frauenwmLeaderboardTranslations: TLeaderboardTranslations = defaultsDeep({}, leaderboardTranslations)

frauenwmLeaderboardTranslations.title = 'Rangliste Spieler'
frauenwmLeaderboardTranslations.description =
  'Alle Punkte, die du im Tagesquiz sowie im Tippspiel sammelst, werden zusammengezählt.'
frauenwmLeaderboardTranslations.userName = 'Spieler'
