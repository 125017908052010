import { TCollapsibleStyle } from 'src/theme/themes/default/components/collapsible'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { libertateaFonts } from 'src/theme/themes/libertatea/fonts'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { defaultsDeep } from 'lodash-es'

const collapsible: TCollapsibleStyle = defaultsDeep({}, defaultTheme.components.Collapsible)

collapsible.borderRadius = '0'
collapsible.background = '#fff'
collapsible.contentBackground = 'transparent'
collapsible.padding = '16px 24px'
collapsible.headerTextStyle = `
      font-family: ${libertateaFonts.signika};
      font-size: 24px;
      line-height: 140%;
      font-weight: 300;
`
collapsible.boxShadow = '0px 0px 8px 2px rgba(0, 0, 0, 0.12)'
collapsible.arrowHeight = '24px'
collapsible.arrowWidth = '24px'
collapsible.collapsedColor = libertateaColors.red

export const libertateaCollapsible = collapsible
