import { TEndOfSeasonModalStyle } from 'src/theme/themes/default/components/endOfSeasonModal'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { libertateaFonts } from 'src/theme/themes/libertatea/fonts'
import { libertateaColors } from 'src/theme/themes/libertatea/colors'
import { defaultsDeep } from 'lodash-es'
//import backgroundImage from 'src/assets/images/libertatea/summary_modal.svg'
import { StatisticksBlockStyles } from 'src/enums/summary'

const endOfSeasonModal: TEndOfSeasonModalStyle = defaultsDeep({}, defaultTheme.components.EndOfSeasonModal)

endOfSeasonModal.basicColor = '#fff'
endOfSeasonModal.basicFontWeight = 300
endOfSeasonModal.backgroundColor = libertateaColors.red
endOfSeasonModal.backgroundImage = 'none' //backgroundImage
endOfSeasonModal.stepTitleText = `
    font-family: ${libertateaFonts.signika};
    font-size: 24px;
    line-height: 33px;
    font-weight: 700;
`
endOfSeasonModal.statisticsBlockValueFontSize = '16px'
endOfSeasonModal.statisticsBlockColor = libertateaColors.greyDark
endOfSeasonModal.statisticsBlockBgColor = '#fff'
endOfSeasonModal.statisticksBlockStyle = StatisticksBlockStyles.Triangle
endOfSeasonModal.messageAnimationWidth = '125px'
endOfSeasonModal.thanksAnimationWidthDesktop = '200px'
endOfSeasonModal.thanksAnimationWidthMobile = '180px'
endOfSeasonModal.transparentButtonHover = `
    color: #fff;
    opacity: 0.9;
`
endOfSeasonModal.feedbackStarColor = defaultTheme.colors.shades.white

export const libertateaEndOfSeasonModal = endOfSeasonModal
