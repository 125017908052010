import { Bold } from 'src/components/Bold'
import { ExternalLink } from 'src/components/links/ExternalLink'
import { TQuizTranslations } from 'src/translations/de/default/quiz'

export const quizTranslations: TQuizTranslations = {
  title: 'Codzienny Quiz',
  description: (
    <>
      Odpowiedz na 3 codzienne pytania quizowe i zdobądź <b>3 punkty za każdą poprawną odpowiedź</b>. Każdego miesiąca
      rozpoczyna się nowa Runda Quizu.
    </>
  ),
  seasonEndCardTitle: 'Dzięki za grę!',
  seasonEndCardText: 'Nasza gra quizowa dobiegła końca. Wszystkich graczy znajdziesz w rankingu poniżej.',
  resultTitle: {
    all: 'Dobra robota!',
    some: 'Dobra robota!',
    none: 'O nie!',
  },
  resultSummaryText: (
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number,
    gainedPoints: number,
  ) => (
    <>
      Odpowiedziałeś dzisiaj na{' '}
      <Bold>
        {correctAnswerCount}/{maxQuestionCount} pytań poprawnie
      </Bold>{' '}
      i zdobyłeś{' '}
      <Bold>
        {gainedPoints > 0 && maxQuestionCount && pointsPerCorrectQuestion
          ? `${gainedPoints}/${maxQuestionCount * pointsPerCorrectQuestion}`
          : 0}{' '}
        punktów
      </Bold>
      !
    </>
  ),
  resultSummaryEndText: '',
  resultSummaryEndTextGroup: (_: number, __: number) => quizTranslations.resultSummaryEndText,
  resultTable: {
    rank: 'Miejsce',
    nickname: 'Nazwa',
    group: 'Drużyna',
    total: 'Razem',
    gained: 'Dzisiaj',
  },
  leaderboardButtonText: 'Przejdź do listy rankingowej',
  blickButtonText: 'Sprawdź wiadomości sportowe',
  cardErrorTitle: 'Ooops...',
  noMoreQuestionMessage: (
    <>
      Problem z załadowaniem pytań. Proszę wrócić później. Jeśli ten błąd będzie się powtarzał, proszę skontaktować się
      z <ExternalLink href="pomoc@onet.pl">pomoc@onet.pl</ExternalLink>.
    </>
  ),
  unknownErrorMessage: (
    <>
      Wystąpił nieznany błąd. Proszę odświeżyć stronę. Jeśli ten błąd będzie się powtarzał, proszę skontaktować się z
      <ExternalLink href="pomoc@onet.pl">pomoc@onet.pl</ExternalLink>.
    </>
  ),
  collectedPoints: (collectedPoints: number) => {
    let pointsText = 'punktów'
    if (collectedPoints === 1) {
      pointsText = 'punkt'
    } else if (collectedPoints > 1 && collectedPoints < 5) {
      pointsText = 'punkty'
    }
    return (
      <>
        {collectedPoints === 0 ? '' : '+'} {collectedPoints} {pointsText}
      </>
    )
  },
  questionName: 'Pytanie',
  continue: 'Kontynuuj',
  nextQuestion: 'Kontynuuj',
  teaser: <>Spróbuj znaleźć poprawną odpowiedź w tym artykule:</>,
  simpleTeaser: (url: string, onClick?: () => void) => (
    <>
      Psst! Znajdź tutaj{' '}
      <ExternalLink onClick={onClick} target="_blank" href={url}>
        poprawną odpowiedź
      </ExternalLink>
      .
    </>
  ),
  answersName: 'Odpowiedzi',
  allAnsweredCard: {
    title: 'Odpowiedziałeś już na wszystkie trzy pytania quizowe',
    description:
      'Dziękujemy za zalogowanie się! Odpowiedziałeś już na wszystkie pytania. Kliknij przycisk poniżej, aby zobaczyć, jak Ci poszło.',
    button: 'Zobacz wynik quizu',
  },
  alreadyAnsweredCard: {
    title: 'Już odpowiedziałeś na to pytanie',
    description:
      'Dziękujemy za zalogowanie się! Już odpowiedziałeś na to pytanie. Kliknij przycisk poniżej, aby załadować następne pytanie.',
    button: 'Następne pytanie',
  },
  singleQuestionSuccessTitle: 'Świetna robota!',
  singleQuestionSuccessDescription: (
    <>
      Im częściej grasz, tym większa szansa na wygraną! <br /> <br /> Wróć jutro, aby odpowiedzieć na kolejne pytania.
    </>
  ),
  singleQuestionFailureTitle: 'O nie!',
  singleQuestionFailureDescription: (
    <>
      Wróć jutro i spróbuj ponownie!
      <br />
      Link z podpowiedzią pomoże Ci znaleźć poprawną odpowiedź.
      <br />
      <br />
      Im częściej grasz, tym większa szansa na wygraną!
    </>
  ),
  // not used
  indicatiorText: () => '',
  nextQuestionHint: '',
  nextResultsHint: '',
  resultSummaryTextGroup: () => <></>,
  resultTopText: '',
  seasonEndCardButtonText: '',
  singleQuestionResultButton: '',
}
