import { TCollapsibleStyle } from 'src/theme/themes/default/components/collapsible'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { onetColors } from 'src/theme/themes/onet/colors'

const collapsible: TCollapsibleStyle = defaultsDeep({}, defaultTheme.components.Collapsible)

collapsible.borderRadius = '6px'
collapsible.background = onetColors.lightGrey2
collapsible.contentBackground = onetColors.lightGrey2
collapsible.padding = '16px 24px'
collapsible.headerTextStyle = `
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
`
collapsible.arrowWidth = '12px'
collapsible.arrowHeight = '16px'

export const onetCollapsible = collapsible
