import { defaultsDeep } from 'lodash-es'

import { TTranslations } from 'src/translations/types'
import { translations as defaultTranslations } from 'src/translations/de/default'

import { uefafrauenLeaderboardTranslations } from './leaderboard'
import { uefafrauenScoreBetTranslations } from './scorebet'
import { uefafrauenEndOfSeasonModalTranslations } from './endofseasonmodal'

export const translations: TTranslations = defaultsDeep({}, defaultTranslations)

translations.leaderboard = uefafrauenLeaderboardTranslations
translations.scorebet = uefafrauenScoreBetTranslations
translations.endofseasonmodal = uefafrauenEndOfSeasonModalTranslations
