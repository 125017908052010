import { TCommonStyle } from 'src/theme/themes/default/components/common'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { onetColors } from 'src/theme/themes/onet/colors'
import { onetFonts } from 'src/theme/themes/onet/fonts'

const common: TCommonStyle = defaultsDeep({}, defaultTheme.components.common)

common.background = onetColors.lightGrey
common.font = onetFonts.firaSans
common.color = onetColors.darkGrey
common.borderRadius = '6px'
common.boxShadow = 'none'

export const onetCommon = common
