export enum ActionTypes {
  FETCH_NOTIFICATIONS = '@NOTIFICATIONS/FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_SUCCESS = '@NOTIFICATIONS/FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE = '@NOTIFICATIONS/FETCH_NOTIFICATIONS_FAILURE',
  DISMISS_NOTIFICATION = '@NOTIFICATIONS/DISMISS_NOTIFICATION',
  DISMISS_NOTIFICATION_SUCCESS = '@NOTIFICATIONS/DISMISS_NOTIFICATION_SUCCESS',
  DISMISS_NOTIFICATION_FAILURE = '@NOTIFICATIONS/DISMISS_NOTIFICATION_FAILURE',
}

export enum Notification {
  MemberLeftGroup = 'member_left_group',
  MemberJoinedGroup = 'member_joined_group',
  GroupJoinRequestDenied = 'group_join_request_denied',
  UserRemovedFromGroup = 'user_removed_from_group',
  GroupAdminLeft = 'group_admin_left',
  GroupAdminLeftYouAdmin = 'group_admin_left_you_admin',
  NewPendingJoinRequest = 'new_pending_join_request',
  NewGroupInvitation = 'new_group_invitation',
}
