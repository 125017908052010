import { Bold } from 'src/components/Bold'

export type TQuizWidgetTranslations = Modify<
  typeof quizWidgetTranslations,
  {
    quizTitle: JSX.Element | string
  }
>

export const quizWidgetTranslations = {
  indicatiorText: (maxQuestionCount?: number) => `Fragen beantwortet ${maxQuestionCount}/${maxQuestionCount}`,
  resultTitle: {
    all: 'Volltreffer',
    some: 'Super',
    none: 'Knapp daneben',
  },
  resultSummaryText: (
    points: number,
    correctAnswerCount: number,
    maxQuestionCount: number | undefined,
    pointsPerCorrectQuestion: number | undefined,
  ) =>
    points ? (
      <>
        Du hast{' '}
        <Bold>
          {correctAnswerCount}/{maxQuestionCount} Quizfragen richtig
        </Bold>{' '}
        beantwortet und{' '}
        <Bold>
          {pointsPerCorrectQuestion && maxQuestionCount
            ? `${points}/${pointsPerCorrectQuestion * maxQuestionCount}`
            : '0'}{' '}
          Punkte
        </Bold>{' '}
        erhalten.
      </>
    ) : (
      <>
        Du hast <b>keine Frage richtig</b> beantwortet. Das gibt leider keinen Punkt für dich.
      </>
    ),
  resultSummaryEndText: 'Teste dein Wissen morgen erneut!',
  resultTable: {
    rank: 'Rang',
    nickname: 'Benutzername',
    total: 'Total',
    gained: 'Heute',
  },
  leaderboardButtonText: 'Zur Rangliste',
  blickButtonText: 'Blick Ski Nachrichten',

  quizTitle: 'Mitspielen und gewinnen',
  resultButtonUserWithBets: 'Rennen warten auf tipps',
  resultButtonUser: 'Zur Ski Trophy',
  resultButtonLogin: 'Anmelden und weiterspielen',
  resultButtonLoginAlt: 'Anmelden und Preise gewinnen',
  resultButtonLoginNotValidUser: 'Resultat speichern und gewinnen',
  resultBottomTitle: 'Bald startet das nächste Rennen!',
  resultBottomTextWithBets: (availableBets: number) => (
    <>
      Du hast noch <Bold>{availableBets} ungetippte Rennen.</Bold> Jetzt tippen und noch mehr Punkte sammeln.
    </>
  ),
  resultBottomText: 'Schaue dir deine bisherigen Tipps und die Rangliste an.',
  guestBottomResultTitle: 'Anmelden, Punkte sichern und gewinnen!',
  guestBottomResultText1: 'Täglich neue Quizfragen',
  guestBottomResultText2: 'Tippe auf alle Ski-Rennen',
  guestBottomResultText3: 'Gewinne tolle Preise',
  singleQuestionSuccessTitle: 'Great job!',
  singleQuestionSuccessDescription: (
    <>
      The more you play the higher your chance to win! <br /> <br /> Come back tomorrow to answer another daily quiz
      question.
    </>
  ),
  singleQuestionFailureTitle: 'Oh no!',
  singleQuestionFailureDescription: (
    <>
      Come back tomorrow and try again!
      <br />
      The link with the hint helps you to find the correct answer.
      <br />
      <br />
      The more you play the higher your chance to win!
    </>
  ),
  continue: 'Weiter',
}
