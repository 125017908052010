import { THeroStyle } from 'src/theme/themes/default/components/hero'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'

const hero: THeroStyle = defaultsDeep({}, defaultTheme.components.Hero)

hero.slideMobileHeight = '360px'
hero.showLogoMobile = true
hero.logoPositionLeftMobile = '8px'
hero.slideText.color = '#fff'
hero.slideText.desktop.width = '360px'
hero.slideText.lineHeight = '36px'
hero.imagePosition = 'top right'

export const greenfieldHero = hero
