import { TNavigationLinksTranslations } from 'src/translations/de/default/navigationLinks'

export const navigationLinksTranslations: TNavigationLinksTranslations = {
  rules: 'Zasady',
  leaderboard: 'Ranking',
  leaderboardrounded: 'Ranking',
  quiz: 'Quiz',
  backButton: 'Powrtót',

  // not used
  champions: '',
  hero: '',
  infoBlock: '',
  leaderboardgroup: '',
  matchbet: '',
  medalcount: '',
  prizes: '',
  quizbetting: '',
  scorebet: '',
  top3participants: '',
}
