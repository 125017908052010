import { TReduxState } from 'src/redux/store'
import { THeroSettings, TProduct } from './types'

export const getProduct = (state: TReduxState): TProduct | null => state.product.data
export const getProductId = (state: TReduxState) => state.product.data?.id

export const getProductFriendlyName = (state: TReduxState): string | undefined => state.product.data?.friendlyName

export const getProductSeasonId = (state: TReduxState): number | undefined => state.product.data?.season.id
export const getIsProductSeasonEnded = (state: TReduxState) => !!state.product.data?.isSeasonEnded
export const getIsProductEndOfSeasonModalEnabled = (state: TReduxState) =>
  !!state.product.data?.season.showEndOfSeasonModal
export const getIsProductEndOfRoundModalEnabled = (state: TReduxState) =>
  !!state.product.data?.season.showEndOfRoundModal

export const getProductRoundId = (state: TReduxState): number | undefined => {
  if (state.product.data?.round) {
    return state.product.data.round.id
  }

  if (state.product.data?.allRounds.length) {
    return state.product.data.allRounds[state.product.data.allRounds.length - 1].id
  }
}

export const getProductHeroSettings = (state: TReduxState): THeroSettings | null | undefined => state.product.data?.hero

export const getProductHeroLogo = (state: TReduxState): string | null | undefined => state.product.data?.hero?.logo

export const getProductFetchLoading = (state: TReduxState): boolean => state.product.loading.fetchProduct
export const getProductFetchError = (state: TReduxState) => state.product.error.fetchProduct

export const getProductIsGroupsEnabled = (state: TReduxState): boolean | undefined =>
  state.product.data?.season?.groupsEnabled

export const getProductGroupType = (state: TReduxState) => state.product.data?.season?.groupType

export const getProductAllRounds = (state: TReduxState) => state.product.data?.allRounds
export const getProductRound = (state: TReduxState) => state.product.data?.round

export const getProductContent = (state: TReduxState) => state.product.data?.content

export const getIsProductPowerupsEnabled = (state: TReduxState) => !!state.product.data?.isPowerupsEnabled
