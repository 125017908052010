import { translations as deDefaultTranslations } from './de/default'
import { translations as deUefafrauenTranslations } from './de/uefafrauen'
import { translations as deFrauenwmTranslations } from './de/frauenwm'
import { translations as deSchwingenTranslations } from './de/schwingen'
import { translations as deSkitrophyTranslations } from './de/skitrophy'
import { translations as deUnihockeyTranslations } from './de/unihockey'
import { translations as deWmtrophyTranslations } from './de/wmtrophy'
import { translations as deEishockeywmTranslations } from './de/eishockeywm'
import { translations as deEmtrophyTranslations } from './de/emtrophy'

import { enTranslations as enDefaultTranslations } from './en/default'

import { roTranslations as roDefaultTranslations } from './ro/default'

import { plTranslations as plDefaultTranslations } from './pl/default'

import { getTenant } from 'src/utils/tenant'
import { Tenants } from 'src/enums/tenants'
import { TTranslations } from './types'

export const getTranslations = (productId?: string): TTranslations => {
  const tenant = getTenant()
  switch (tenant) {
    case Tenants.ONET:
      return getPlTranslations(productId)
    case Tenants.LIBERTATEA:
      return getRoTranslations(productId)
    default:
      return getDeTranslations(productId)
  }
}

export const getDeTranslations = (productId?: string): TTranslations => {
  switch (productId) {
    case 'fussballfrauenwm':
      return deFrauenwmTranslations
    case 'uefafrauen':
      return deUefafrauenTranslations
    case 'schwingen':
      return deSchwingenTranslations
    case 'skitrophy':
      return deSkitrophyTranslations
    case 'unihockeywm':
      return deUnihockeyTranslations
    case 'wmtrophy':
      return deWmtrophyTranslations
    case 'eishockeywm':
      return deEishockeywmTranslations
    case 'emtrophy':
      return deEmtrophyTranslations
    default:
      return deDefaultTranslations
  }
}

export const getEnTranslations = (productId?: string): TTranslations => {
  switch (productId) {
    default:
      return enDefaultTranslations
  }
}

export const getRoTranslations = (productId?: string): TTranslations => {
  switch (productId) {
    default:
      return roDefaultTranslations
  }
}

export const getPlTranslations = (productId?: string): TTranslations => {
  switch (productId) {
    default:
      return plDefaultTranslations
  }
}
