import { TDefaultReduxState } from 'src/types/redux'

export const getReduxFetchStates = <T extends TDefaultReduxState>(
  state: T,
  action: string,
): Pick<T, 'loading' | 'error' | 'resolved'> => {
  return {
    loading: {
      ...state.loading,
      [action]: true,
    },
    error: {
      ...state.error,
      [action]: null,
    },
    resolved: {
      ...state.resolved,
      [action]: false,
    },
  }
}

export const getReduxSuccessStates = <T extends TDefaultReduxState>(
  state: T,
  action: string,
): Pick<T, 'loading' | 'resolved'> => {
  return {
    loading: {
      ...state.loading,
      [action]: false,
    },
    resolved: {
      ...state.resolved,
      [action]: true,
    },
  }
}

export const getReduxFailureStates = <T extends TDefaultReduxState>(
  state: T,
  action: string,
  error: AnyObject,
): Pick<T, 'loading' | 'error' | 'resolved'> => {
  return {
    loading: {
      ...state.loading,
      [action]: false,
    },
    error: {
      ...state.error,
      [action]: error,
    },
    resolved: {
      ...state.resolved,
      [action]: false,
    },
  }
}

export const getReduxResetStates = <T extends TDefaultReduxState>(
  state: T,
  initialState: T,
  actions: string[],
): Pick<T, 'loading' | 'error' | 'resolved'> => {
  //get state object from the passed array for destructuring
  const getStateObject = (status: 'loading' | 'error' | 'resolved') =>
    actions.reduce((o, key, index, arr) => Object.assign(o, { [key]: initialState[status][arr[index]] }), {})
  return {
    loading: {
      ...state.loading,
      ...getStateObject('loading'),
    },
    error: {
      ...state.error,
      ...getStateObject('error'),
    },
    resolved: {
      ...state.resolved,
      ...getStateObject('resolved'),
    },
  }
}
