import { Bold } from 'src/components/Bold'
import { defaultsDeep } from 'lodash-es'
import { quizWidgetTranslations, TQuizWidgetTranslations } from 'src/translations/de/default/quizwidget'

export const wmtrophyQuizWidgetTranslations: TQuizWidgetTranslations = defaultsDeep({}, quizWidgetTranslations)

wmtrophyQuizWidgetTranslations.quizTitle = '«Blick WM Trophy»'
wmtrophyQuizWidgetTranslations.resultButtonUserWithBets = 'Spiele warten auf Tipps'
wmtrophyQuizWidgetTranslations.resultButtonUser = 'Zur Blick WM Trophy'

wmtrophyQuizWidgetTranslations.resultBottomText = 'Schaue dir deine bisherigen Spieltipps und die Rangliste an.'
wmtrophyQuizWidgetTranslations.resultBottomTitle = 'Bald ist Anpfiff!'
wmtrophyQuizWidgetTranslations.guestBottomResultText2 = 'Tippe auf alle WM-Spiele'
wmtrophyQuizWidgetTranslations.resultBottomTextWithBets = (availableBets: number) => (
  <>
    Du hast <Bold>noch {availableBets} ungetippte Spiele.</Bold> Jetzt tippen und noch mehr Punkte sammeln.
  </>
)
