import { defaultsDeep } from 'lodash-es'

import { endOfRoundModalTranslations, TEndOfRoundModalTranslations } from 'src/translations/de/default/endofroundmodal'

export const wmtrophyEndOfRoundModalTranslations: TEndOfRoundModalTranslations = defaultsDeep(
  {},
  endOfRoundModalTranslations,
)

wmtrophyEndOfRoundModalTranslations.getSubtitle = (round: string) => `WM TROPHY - ${round}`
wmtrophyEndOfRoundModalTranslations.finalStepText = (
  <>
    <>
      In der K.o.-Phase hast du wieder
      <br /> die Chance <b>coole Preise</b> <br />
      abzustauben. Viel Glück!
    </>
  </>
)
wmtrophyEndOfRoundModalTranslations.finalStepTitle = 'Neue Runde, neues Glück!'
