export enum ActionTypes {
  FETCH_FEEDBACK = '@FEEDBACK/FETCH_FEEDBACK',
  FETCH_FEEDBACK_SUCCESS = '@FEEDBACK/FETCH_FEEDBACK_SUCCESS',
  FETCH_FEEDBACK_FAILURE = '@FEEDBACK/FETCH_FEEDBACK_FAILURE',
  POST_FEEDBACK = '@FEEDBACK/POST_FEEDBACK',
  POST_FEEDBACK_SUCCESS = '@FEEDBACK/POST_FEEDBACK_SUCCESS',
  POST_FEEDBACK_FAILURE = '@FEEDBACK/POST_FEEDBACK_FAILURE',
  CLOSE_FEEDBACK = '@FEEDBACK/CLOSE_FEEDBACK',
  FEEDBACK_STAR_CLICK = '@FEEDBACK/FEEDBACK_STAR_CLICK',
}
