import { memo, SyntheticEvent } from 'react'
import styled from 'styled-components/macro'

import { trackExternalLinkClick } from 'src/tracking/gtm/actions'
import { getChildrenText } from 'src/utils/dom/getChildrenText'

export const ExternalLink = memo(
  ({
    href,
    // do not add "_blank" target by default to non-http reference like "mailto:" to escape dummy page to blink
    // (it's opened and immediately closed)
    target = href && /^(http:|https:)/.test(href) ? '_blank' : undefined,
    onClick,
    ...props
  }: Parameters<typeof Wrapper>[0]) => {
    const onLinkClick = (event: SyntheticEvent) => {
      event.stopPropagation()

      const buttonLabel = getChildrenText(props.children)

      onClick && onClick()

      trackExternalLinkClick(buttonLabel, href)
    }

    return <Wrapper {...{ href, target, ...props }} onClick={onLinkClick} />
  },
)

ExternalLink.displayName = 'ExternalLink'

const Wrapper = styled.a`
  color: ${({ theme }) => theme.components.Link.color};
  text-decoration: ${({ theme }) => theme.components.Link.textDecoration};
  &:hover {
    color: ${({ theme }) => theme.components.Link.hoverColor};
  }
`
