export const getOnetSession = new Promise((res, rej) => {
  const callback = () => {
    try {
      // @ts-ignore
      const accountLoginSDK = new window.AccountLoginSDK({
        clientID: 'gamification.front.onetapi.pl',
        userSessionProxyEndpoint: '/user-session-proxy',
        sessionFields: ['userId', 'login', 'avatar'],
      })
      const sessionData = accountLoginSDK.ensureSession()
      res(sessionData)
    } catch {
      setTimeout(() => {
        callback()
      }, 300)
    }
  }
  callback()
})
