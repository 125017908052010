import styled from 'styled-components/macro'

export const Bold = styled.b<{ $fontWeight?: number }>`
  font-weight: ${({ $fontWeight }) => $fontWeight || 900};
`

export const BoldContent = styled.b`
  display: contents;
  font-weight: 700;
`
