import { TSummaryTranslations } from 'src/translations/de/default/summary'

export const summaryTranslations: TSummaryTranslations = {
  pageTitle: 'Libertatea Trophy',
  subtitle: (username: string | undefined) => <>so hat {username} abgeschnitten</>, //hidden
  title: (userRank, userName) => {
    return <>This is how {userName} has played “question of the day”!</>
  },
  team: {
    title: (rank?: number, name?: string) => (
      <>
        Team {name} ist auf Rang {rank} gelandet
      </>
    ),
    subtitle: <>Endergebnis Teams</>,
  },
  subscriptionTitle: '',
  subscriptionText: `The game “question of the day” is over. Get an email, when a new game is up!`,
  subscriptionSuccessfulText: 'Thanks a lot! As soon as there is a new game coming up, you will get an email.',
  invalidEmailError: 'Enter a valid e-mail address',
  requiredFieldError: 'Please fill out this field',
  inputLabel: 'Enter your e-mail address',
  buttonText: 'Sign up for notification',
  round: {
    subtitle: (dinamicText?: string) => <></>,
    titleMobile: (rank?: number, name?: string) => <>This is how {name} has played the ONET Daily Quiz</>,
    titleDesktop: (rank?: number, name?: string) => <>This is how {name} has played the ONET Daily Quiz</>,
    bannerTitle: 'Join the Quiz and win great prizes every month.',
    team: {
      title: (rank?: number, name?: string) => (
        <>
          Team {name} ist auf Rang {rank} gelandet
        </>
      ),
      subtitle: (dinamicText?: string) => <>{dinamicText} TEAMS</>,
    },
    buttonTextLoggedIn: 'Play now',
    buttonTextLoggedOut: 'Sign up and play',
  },
}
