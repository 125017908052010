import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { accentwayColors } from 'src/theme/themes/accentway/colors'
import { TScoreBetStyle } from 'src/theme/themes/default/components/scoreBet'

const scoreBet: TScoreBetStyle = defaultsDeep({}, defaultTheme.components.ScoreBet)

scoreBet.matchCardBackgroundColor = accentwayColors.green
scoreBet.successBorderColor = accentwayColors.green
scoreBet.powerupButtonBg = accentwayColors.green
scoreBet.pointsButtonBg = accentwayColors.green

export const accentwayScoreBet = scoreBet
