import { ReactNode } from 'react'
import { isArray } from 'lodash-es'
export const getChildrenText = (children: ReactNode) => {
  if (!children) {
    return ''
  }

  if (typeof children === 'string') {
    return children
  }

  if (isArray(children)) {
    const text: string = children.find((children: string | React.ReactElement) => getChildrenText(children))
    return text
  }

  //For children with texts
  if (typeof children === 'object' && 'props' in children && isArray(children.props.children)) {
    const text = children.props.children.find((children: string | React.ReactElement) => getChildrenText(children))
    return text
  }

  //For image children
  if (typeof children === 'object' && 'props' in children && 'alt' in children.props) {
    return children.props.alt
  }

  return ''
}
