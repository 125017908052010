export type TTeamTranslations = typeof teamTranslations

export const teamTranslations = {
  invitation_declined: 'Du hast die Einladung zum Team abgelehnt.',
  join_request_removed: 'Du hast deine Beitrittsanfrage zurückgezogen.',
  join_request_accept: 'Willkommen in der Tippgruppe!',
  joinRequestAcceptedText: (username: string) => `Eine gute Entscheidung und tolle Ergänzung für deine Tippgruppe!`,
  joinRequestDeclinedText: (username: string) => `Spieler ${username} wurde nicht zum Team hinzugefügt.`,
  invalid_link: 'Link ist ungültig.',
  group_already_full_error: 'Schade, dieses Team ist leider schon voll.',
  link_expired_error: 'Link ist abgelaufen.',
  url_copied: 'Link wurde kopiert.',
  invite_accepted: 'Willkommen in der Tippgruppe!',
}
