import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TPrizeAltStyle } from 'src/theme/themes/default/components/prizeAlt'
import { onetColors } from 'src/theme/themes/onet/colors'

const prizeAlt: TPrizeAltStyle = defaultsDeep({}, defaultTheme.components.PrizeAlt)

prizeAlt.background = defaultTheme.colors.shades.white
prizeAlt.boxShadow = 'none'
prizeAlt.border = `1px solid ${onetColors.grey}`
prizeAlt.borderRadius = '6px'
prizeAlt.titleTextStyle = `
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
`
prizeAlt.descriptionTextStyle = `
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
`
prizeAlt.imageWrapperBorder = `1px solid ${onetColors.grey}`

export const onetPrizeAlt = prizeAlt
