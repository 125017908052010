import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { config } from 'src/config'

const ignoreEvent = (reasonMsg = ''): null => {
  // eslint-disable-next-line
  console.log('%cSentry:ignore', 'color: red', reasonMsg)

  return null
}

export const initErrorTracking = (): void => {
  Sentry.init({
    dsn: 'https://6b1e55b3db74424e91a3424bbe04133e@o321856.ingest.sentry.io/6003073',
    environment: process.env.REACT_APP_ENV,
    release: config.release,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: process.env.REACT_APP_ENV === 'prod' ? 0.005 : 1.0, // this should be increased for prod after dec 25
    sampleRate: process.env.REACT_APP_ENV === 'prod' ? 0.01 : 1.0, // this should be set to 1.0 for prod after dec 25
    // Breadcrumb customization
    beforeBreadcrumb(breadcrumb) {
      // sentry only allows to filter all console logs, but we want to keep errors and warnings
      if (breadcrumb.category === 'console' && breadcrumb.level === 'info') {
        return null
      }

      return breadcrumb
    },
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      `Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.`,
      `Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.`,
      `Cannot read properties of null (reading 'getItem')`,
      `Network Error`,
    ],
    beforeSend(event, hint) {
      if (!config.tracking.isEnabled) {
        return ignoreEvent('Tracking is disabled')
      }

      const isAxiosError = (hint?.originalException as AxiosError)?.isAxiosError === true
      if (isAxiosError) {
        const error = hint.originalException as AxiosError
        const response = error.response as AxiosResponse
        const url = error.config.url as string

        if (error.response) {
          // The request was made and the server responded with a non-2xx status code
          event.fingerprint = ['{{ default }}', error.message, url, response.status.toString(), response.data?.message]
        }
      }

      // if you return null, the event will be discarded.
      return event
    },
  })
}
