import React, { memo, useContext, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import { throttle } from 'lodash-es'

import { useMedia } from 'src/utils/media/useMedia'
import { DrawerContext } from './DrawerContext'
import { TDrawer, TDrawerSettings } from './types'
import { ANIMATION_DURATION, ANIMATION_CLASSNAMES, DRAWER_WIDTH, DRAWER_MOBILE_TOP_OFFSET } from './constants'
import { useResizeObserver } from 'src/utils/hooks/useResizeObserver'

type Props = {
  children: React.ReactNode
} & TDrawer

export const Drawer = memo(function Drawer({
  id,
  onBackDropClick,
  header,
  footer,
  children,
  settings,
  ...rest
}: Props) {
  const [isExpanded, setExpanded] = useState(false)
  const [headerHeight, setHeaderHeight] = useState<number | null>(null)
  const [footerHeight, setFooterHeight] = useState<number | null>(null)

  const { closeDrawer } = useContext(DrawerContext)
  const nodeRef = useRef(null)
  const headerRef = useRef<HTMLDivElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)

  const { isMobile } = useMedia()

  const handleBackDropClick = () => {
    if (onBackDropClick && typeof onBackDropClick === 'function') {
      return onBackDropClick(id, closeDrawer)
    }
    return closeDrawer(id)
  }

  const handleContentScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (isMobile) {
      const scrollValue = (e.target as HTMLDivElement).scrollTop
      if (scrollValue === 0) {
        setExpanded(false)
      }
      if (scrollValue > 50) {
        setExpanded(true)
      }
    }
  }

  useResizeObserver(headerRef, (entry) => {
    const headerHeight = entry[0].contentRect.height
    setHeaderHeight(headerHeight)
  })

  useResizeObserver(footerRef, (entry) => {
    const footerHeight = entry[0].contentRect.height
    setFooterHeight(footerHeight)
  })

  return createPortal(
    <CSSTransition timeout={400} classNames="drawer" nodeRef={nodeRef} {...rest}>
      <DrawerWrap ref={nodeRef} settings={settings}>
        <DrawerBackDrop onClick={() => handleBackDropClick()}></DrawerBackDrop>
        <DrawerBody $isExpanded={isExpanded} width={settings?.width}>
          {header && <DrawerHeader ref={headerRef}>{header}</DrawerHeader>}
          <DrawerContent
            style={{
              height: `calc(100% - (${headerHeight || 0}px + ${footerHeight || 0}px + ${
                isExpanded || !isMobile ? 0 : DRAWER_MOBILE_TOP_OFFSET
              }px)`,
            }}
            onScroll={throttle(handleContentScroll, 300)}
          >
            {children}
          </DrawerContent>
          {footer && <DrawerFooter ref={footerRef}>{footer}</DrawerFooter>}
        </DrawerBody>
      </DrawerWrap>
    </CSSTransition>,
    document.querySelector('#drawer-root') as Element,
  )
})

const getPositionTransition = (settings?: TDrawerSettings) => {
  if (settings?.position === 'right') {
    return rightTransitions(settings.width)
  }

  if (settings?.position === 'bottom') {
    return bottomTransitions
  }
}

const DrawerBackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
`

const DrawerBody = styled.div<{ $isExpanded: boolean; width?: number }>`
  position: absolute;
  top: 0;
  right: 0;
  width: ${({ width }) => (width ? width : DRAWER_WIDTH)}px;
  height: 100%;
  box-shadow: ${({ theme }) => theme.components.Drawer.bodyShadow};
  background: #fff;
  ${({ theme }) => theme.media.isMobile} {
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    height: 100%;
    box-sizing: border-box;
    border-radius: ${({ theme }) => theme.components.Drawer.borderRadius};
  }
  ${({ $isExpanded }) =>
    $isExpanded &&
    `
  top:0 !important;
  border-radius: 0 0 0 0 !important;
  transition: border-radius ${ANIMATION_DURATION}ms ease-in-out, top ${ANIMATION_DURATION}ms ease-in-out !important;`}
`

const DrawerHeader = styled.div`
  box-sizing: border-box;
  ${({ theme }) => theme.media.isMobile} {
    box-shadow: ${({ theme }) => theme.components.Drawer.headerShadow};
  }
`
const DrawerContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`

const DrawerFooter = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  ${({ theme }) => theme.media.isMobile} {
    position: sticky;
    bottom: 0;
    box-shadow: ${({ theme }) => theme.components.Drawer.footerShadow};
  }
`

const rightTransitions = (width?: number) => css`
  &${ANIMATION_CLASSNAMES.ENTER} {
    ${DrawerBackDrop} {
      opacity: 0;
    }
    ${DrawerBody} {
      right: -${width ? width : DRAWER_WIDTH}px;
    }
  }
  &${ANIMATION_CLASSNAMES.ENTER_ACTIVE}, &${ANIMATION_CLASSNAMES.ENTER_DONE} {
    ${DrawerBackDrop} {
      opacity: 0.3;
      transition: opacity ${ANIMATION_DURATION}ms ease-out;
    }
    ${DrawerBody} {
      right: 0;
      transition: right ${ANIMATION_DURATION}ms ease-in-out;
    }
  }
  &${ANIMATION_CLASSNAMES.EXIT} {
    ${DrawerBackDrop} {
      opacity: 0.3;
    }
    ${DrawerBody} {
      right: 0;
    }
  }
  &${ANIMATION_CLASSNAMES.EXIT_ACTIVE} {
    ${DrawerBackDrop} {
      opacity: 0;
      transition: opacity ${ANIMATION_DURATION}ms ease-out;
    }
    ${DrawerBody} {
      right: -${width ? width : DRAWER_WIDTH}px;
      transition: right ${ANIMATION_DURATION}ms ease-out;
    }
  }
`

const bottomTransitions = css`
  &${ANIMATION_CLASSNAMES.ENTER} {
    ${DrawerBackDrop} {
      opacity: 0;
    }
    ${DrawerBody} {
      top: 100%;
    }
  }
  &${ANIMATION_CLASSNAMES.ENTER_ACTIVE}, &${ANIMATION_CLASSNAMES.ENTER_DONE} {
    ${DrawerBackDrop} {
      opacity: 0.3;
      transition: opacity ${ANIMATION_DURATION}ms ease-out;
    }
    ${DrawerBody} {
      top: ${DRAWER_MOBILE_TOP_OFFSET}px;
      transition: top ${ANIMATION_DURATION}ms ease-in-out;
    }
  }
  &${ANIMATION_CLASSNAMES.EXIT} {
    ${DrawerBackDrop} {
      opacity: 0.3;
    }
    ${DrawerBody} {
      top: ${DRAWER_MOBILE_TOP_OFFSET}px;
    }
  }
  &${ANIMATION_CLASSNAMES.EXIT_ACTIVE} {
    ${DrawerBackDrop} {
      opacity: 0;
      transition: opacity ${ANIMATION_DURATION}ms ease-out;
    }
    ${DrawerBody} {
      top: 100% !important;
      transition: top ${ANIMATION_DURATION}ms ease-out;
    }
  }
`

const DrawerWrap = styled.div<{ settings?: TDrawerSettings }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.drawer};

  ${({ settings }) =>
    settings?.position
      ? getPositionTransition(settings)
      : css`
          ${({ theme }) => theme.media.isDesktop} {
            ${rightTransitions(settings?.width)}
          }
          ${({ theme }) => theme.media.isMobile} {
            ${bottomTransitions}
          }
        `})
`
