import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { TLogoutAction, TLoginSuccessAction } from 'src/api/auth/types'
import { ActionTypes as AuthActionTypes } from 'src/api/auth/enums'
import { TFetchProductAction } from 'src/api/product/types'
import { ActionTypes as ProductActionTypes } from 'src/api/product/enums'
import { TFetchLeaderboardSuccessAction } from 'src/api/leaderboard/default/types'
import { TFetchLeaderboardRoundedSuccessAction } from 'src/api/leaderboard/rounded/types'
import { ActionTypes as LeaderboardActionTypes } from 'src/api/leaderboard/default/enums'
import { ActionTypes as LeaderboardRoundedActionTypes } from 'src/api/leaderboard/rounded/enums'

import { TUserState, TAddPointsOptimisticAction, TActions } from './types'
import { ActionTypes } from './enums'

const initialState: TUserState = {
  data: {
    gainedPoints: 0,
    totalPoints: 0,
    rank: 0,
    optimisticPoints: 0,
    loadedFromLeaderboard: false,
  },
  loading: {
    fetchPoints: false,
  },
  error: {
    fetchPoints: null,
  },
  resolved: {
    fetchPoints: false,
  },
}

export const reducer: Reducer<
  TUserState,
  | TActions
  | TAddPointsOptimisticAction
  | TLogoutAction
  | TLoginSuccessAction
  | TFetchProductAction
  | TFetchLeaderboardSuccessAction
  | TFetchLeaderboardRoundedSuccessAction
> = (state = initialState, action): TUserState => {
  switch (action.type) {
    case ActionTypes.FETCH_POINTS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'fetchPoints'),
      }
    }
    case ActionTypes.FETCH_POINTS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
          optimisticPoints: 0,
          loadedFromLeaderboard: false,
        },
        ...getReduxSuccessStates(state, 'fetchPoints'),
      }
    }
    case ActionTypes.FETCH_POINTS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'fetchPoints', action.payload),
      }
    }

    case LeaderboardActionTypes.FETCH_LEADERBOARD_SUCCESS: {
      /*
      When selecting data then state.user takes precedence over state.leaderboard so we always have
      just one source of truth.
      When fetching data state.user is updated both from user API and from leaderboard API
      so it always contains actual data and so it can serve as the source of truth.
      */
      if (!action.payload.currentUser) {
        // not logged in, so just do nothing
        return state
      }
      const userData = action.payload.data.users.find(({ nickname }) => nickname === action.payload.currentUser)
      if (!userData) {
        return state
      }

      return {
        ...state,
        data: {
          gainedPoints: userData.gainedPoints,
          totalPoints: userData.totalPoints,
          rank: userData.rank,
          optimisticPoints: 0,
          loadedFromLeaderboard: true,
        },
      }
    }

    case LeaderboardRoundedActionTypes.FETCH_LEADERBOARD_SUCCESS: {
      /*
      When selecting data then state.user takes precedence over state.leaderboard so we always have
      just one source of truth.
      When fetching data state.user is updated both from user API and from leaderboard API
      so it always contains actual data and so it can serve as the source of truth.
      */
      if (!action.payload.currentUser) {
        // not logged in, so just do nothing
        return state
      }
      const userData = action.payload.data.leaderboard.leaderboardDetails.users.find(
        ({ nickname }) => nickname === action.payload.currentUser,
      )
      if (!userData) {
        return state
      }
      return {
        ...state,
        data: {
          ...state.data,
          totalPoints: userData.totalPoints,
          rank: userData.rank,
          optimisticPoints: 0,
          loadedFromLeaderboard: true,
        },
      }
    }

    case ActionTypes.ADD_POINTS_OPTIMISTIC: {
      const { points } = action.payload
      return {
        ...state,
        data: {
          ...state.data,
          optimisticPoints: state.data.optimisticPoints + points,
        },
      }
    }

    case AuthActionTypes.AUTH_LOGIN_SUCCESS: {
      return initialState
    }
    case AuthActionTypes.AUTH_LOGOUT: {
      return initialState
    }
    case ProductActionTypes.FETCH_PRODUCT: {
      return initialState
    }

    default: {
      return state
    }
  }
}
