import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TSummaryStyle } from 'src/theme/themes/default/components/summary'

const summary: TSummaryStyle = defaultsDeep({}, defaultTheme.components.Summary)
summary.titleTextStyle = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-style: normal;
    font-weight: 950;
    font-size: 36px;
    line-height: 44px;
    color: ${defaultTheme.colors.shades.white};
    max-width: 310px;
    text-shadow: 0px 0px 8px #1F1F1F;
`
summary.titleTextStyleMobile = `
    font-family: ${defaultTheme.fonts.sourceSansPro};
    font-style: normal;
    font-weight: 950;
    font-size: 28px;
    line-height: 36px;
    color: ${defaultTheme.colors.shades.white};
    max-width: 230px;
    text-shadow: 0px 0px 8px #1F1F1F;
`

summary.infoBlockBackground = defaultTheme.colors.shades.white
summary.infoBlockPadding = '8px 24px 8px 16px'
summary.infoBlockMargin = '0 auto 4px 0'
summary.infoBlockValueTextStyle = `
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${defaultTheme.colors.blick.grey100};
`
summary.infoBlockDescriptionTextStyle = `
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${defaultTheme.colors.blick.grey100};
`
summary.infoBlockAfter = `
    display:none;
`
summary.infoBlockBorderRadius = `
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
`
export const bluefrostSummary = summary
