import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'
import { defaultTable } from './table'

export type TLeaderboardStyle = typeof defaultLeaderboard

export const defaultLeaderboard = {
  itemBorderColor: colors.blick.grey30,
  tabBorderHeight: 4,
  sectionTitleBorderColor: colors.blick.brand100,
  sectionTitleFontWeight: 700,
  goToLeaderBoardButton: {
    textTransform: 'uppercase',
    borderRadius: '0px',
    textDecoration: 'none',
    fontFamily: fonts.goodOffcPro,
    background: colors.shades.transparent,
    fontWeight: '500',
    padding: '8px 16px',
    hover: {
      background: colors.shades.transparent,
    },
    marginTop: '16px',
  },
  currentUserRow: {
    background: colors.blick.grey55,
    color: colors.texts.inverted,
  },
  hasRewardRow: {
    background: colors.blick.sport5,
    borderColor: colors.blick.grey30,
  },
  rewardIconSize: '16px',
  rewardIconMarginLeft: '0px',
  rewardIconMarginRight: '4px',
  rowHeight: defaultTable.tableRow.height,
}
