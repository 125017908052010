import { colors } from 'src/theme/colors'

export type TTableStyle = typeof defaultTable

export const defaultTable = {
  tableHead: {
    display: 'flex',
    maxWidth: '100%',
    background: colors.shades.white,
    fontSize: '14px',
    color: colors.blick.grey100,
    fontFamily: '',
    lineHeight: '28px',
    fontWeight: '400',
    textTransform: 'uppercase',
    height: '40px',
    alignItems: 'center',
  },

  tableBody: {
    borderRadiusDefault: '0 0 8px 8px',
    borderRadiusGrey: '0 0 8px 8px',
    borderRadiusTransparent: '0 0 8px 8px',
    maxWidth: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },

  tableRow: {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    color: colors.shades.grey,
    width: '100%',
    maxWidth: '100%',
    borderBottom: `1px solid ${colors.blick.grey20}`,
    boxSizing: 'border-box',
  },
  tableCell: {
    fontWeightBold: 600,
    fontWeightNormal: 400,
    fontSize: '16px',
    mobile: {
      fontSize: '16px',
    },
  },
  totalRow: {
    background: '#d2e0f2',
  },
  activeRowColor: colors.blick.grey55,
  descriptionFontSize: '14px',
}
