import { TypeOptions } from 'react-toastify'

export const MOBILE_BOTTOM_MARGIN = '12px'

export const CSS_SELECTORS = {
  // Container
  Container: '.Toastify__toast-container',
  ContainerTC: '.Toastify__toast-container--top-center',
  ContainerTR: '.Toastify__toast-container--top-right',
  ContainerTL: '.Toastify__toast-container--top-left',
  ContainerBC: '.Toastify__toast-container--bottom-center',
  ContainerBR: '.Toastify__toast-container--bottom-right',
  ContainerBL: '.Toastify__toast-container--bottom-left',
  // Toast
  Toast: '.Toastify__toast',
  ToastDark: '.Toastify__toast--dark',
  ToastDefault: '.Toastify__toast--default',
  ToastInfo: '.Toastify__toast--info',
  ToastSuccess: '.Toastify__toast--success',
  ToastWarning: '.Toastify__toast--warning',
  ToastError: '.Toastify__toast--error',
  RightToLeft: '.Toastify__toast--rtl',
  Body: '.Toastify__toast-body',
  // Close button
  CloseButton: '.Toastify__close-button',
  CloseButtonDefault: '.Toastify__close-button--default',
  CloseButtonSVGs: '.Toastify__close-button > svg',
  // Progressbar
  Progressbar: '.Toastify__progress-bar',
  ProgressbarAnimated: '.Toastify__progress-bar--animated ',
  ProgressbarControlled: '.Toastify__progress-bar--controlled ',
  ProgressbarRtl: '.Toastify__progress-bar--rtl ',
  ProgressbarDefault: '.Toastify__progress-bar--default ',
  ProgressbarDark: '.Toastify__progress-bar--dark ',
  //Icon
  Icon: '.Toastify__toast-icon',
}

export const TOAST_CLASSNAMES: Partial<Record<TypeOptions, string>> = {
  error: 'toast-error',
  success: 'toast-success',
  warning: 'toast-warning',
  info: 'toast-info',
  default: 'toast-default',
}
