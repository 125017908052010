export const sessionStorageSetItem = (key: string, value: string) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(key, value)
  }
}

export const sessionStorageGetItem = (key: string): string | null => {
  if (window.sessionStorage) {
    return window.sessionStorage.getItem(key)
  }
  return null
}

export const sessionStorageRemoveItem = (key: string) => {
  if (window.sessionStorage) {
    window.sessionStorage.removeItem(key)
  }
}
