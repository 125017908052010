import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { accentwayColors } from 'src/theme/themes/accentway/colors'
import { TTabStyle } from 'src/theme/themes/default/components/tab'

const tab: TTabStyle = defaultsDeep({}, defaultTheme.components.Tab)

tab.activeColor = accentwayColors.turquoise
tab.borderRadius = '0'

export const accentwayTab = tab
