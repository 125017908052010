import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TFeedbackStyle } from 'src/theme/themes/default/components/feedback'
import { accentwayColors } from 'src/theme/themes/accentway/colors'

const feedback: TFeedbackStyle = defaultsDeep({}, defaultTheme.components.Feedback)
feedback.boxShadow = 'none'
feedback.border = `1px solid ${defaultTheme.colors.blick.grey30}`
feedback.pointsTextColor = accentwayColors.turquoise
feedback.pointsColor = accentwayColors.turquoise

export const accentwayFeedback = feedback
