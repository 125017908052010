import dayjs from 'dayjs'
import { Reducer } from 'redux'
import { getReduxFailureStates, getReduxFetchStates, getReduxSuccessStates } from 'src/utils/redux'
import { TLogoutAction } from 'src/api/auth/types'
import { ActionTypes as AuthActionTypes } from 'src/api/auth/enums'
import { ActionTypes } from 'src/api/quiz/betting/enums'
import { TActions, TQuizBettingState } from 'src/api/quiz/betting/types'

export const initialState: TQuizBettingState = {
  data: {
    date: undefined,
    currQuestionIndex: -1, // -1 if no question is loaded
    answers: [],
    questions: [],
    storedStep: undefined,
    selectedBettingAnswer: undefined,
    status: undefined,
  },
  loading: {
    answerBettingQuestion: false,
    getNextBettingQuestion: false,
    getBettingStatus: false,
  },
  error: {
    answerBettingQuestion: null,
    getNextBettingQuestion: null,
    getBettingStatus: null,
  },
  resolved: {
    answerBettingQuestion: false,
    getNextBettingQuestion: false,
    getBettingStatus: false,
  },
  notifications: {
    alreadyAnswered: false,
    allAnswered: false,
    noMoreQuestions: false,
    error: false,
  },
}

export const reducer: Reducer<TQuizBettingState, TActions | TLogoutAction> = (
  state = initialState,
  action,
): TQuizBettingState => {
  switch (action.type) {
    case ActionTypes.ANSWER_BETTING_QUESTION: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'answerBettingQuestion'),
      }
    }
    case ActionTypes.ANSWER_BETTING_QUESTION_SUCCESS: {
      const numberOfAnsweredQuestions = state.data.status?.numberOfAnsweredQuestions || 0
      const numberOfCorrectlyAnsweredQuestions = state.data.status?.numberOfCorrectlyAnsweredQuestions || 0
      const addAnswer = !action.payload.alreadyAnswered ? 1 : 0
      const addCorrectAnswer = !action.payload.alreadyAnswered && action.payload.result ? 1 : 0

      return {
        ...state,
        data: {
          ...state.data,
          date: dayjs().toDate(),
          answers: [...state.data.answers, action.payload],
          status: {
            ...state.data.status,
            numberOfAnsweredQuestions: numberOfAnsweredQuestions + addAnswer,
            numberOfCorrectlyAnsweredQuestions: numberOfCorrectlyAnsweredQuestions + addCorrectAnswer,
          },
        },
        ...getReduxSuccessStates(state, 'answerBettingQuestion'),
      }
    }
    case ActionTypes.ANSWER_BETTING_QUESTION_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'answerBettingQuestion', action.payload),
      }
    }

    case ActionTypes.GET_NEXT_BETTING_QUESTION: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'getNextBettingQuestion'),
      }
    }
    case ActionTypes.GET_NEXT_BETTING_QUESTION_SUCCESS: {
      const questions = [...state.data.questions, action.payload]
      const currQuestionIndex = questions.length - 1
      const date = dayjs().toDate()

      // Ensure unique question ids
      if (state.data.questions.some((q) => q.id === action.payload.id)) {
        return state
      }

      return {
        ...state,
        data: {
          ...state.data,
          date,
          currQuestionIndex,
          questions,
        },
        ...getReduxSuccessStates(state, 'getNextBettingQuestion'),
      }
    }
    case ActionTypes.GET_NEXT_BETTING_QUESTION_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'getNextBettingQuestion', action.payload),
      }
    }

    case ActionTypes.GET_BETTING_STATUS: {
      return {
        ...state,
        ...getReduxFetchStates(state, 'getBettingStatus'),
      }
    }
    case ActionTypes.GET_BETTING_STATUS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          status: action.payload,
        },
        ...getReduxSuccessStates(state, 'getBettingStatus'),
      }
    }
    case ActionTypes.GET_BETTING_STATUS_FAILURE: {
      return {
        ...state,
        ...getReduxFailureStates(state, 'getBettingStatus', action.payload),
      }
    }

    case ActionTypes.STORE_BETTING_STEP: {
      return {
        ...state,
        data: {
          ...state.data,
          storedStep: action.payload,
        },
        loading: {
          ...state.loading,
          answerBettingQuestion: true,
        },
      }
    }

    case ActionTypes.RESTORE_BETTING_STEP: {
      const questions = [action.payload.question]
      return {
        ...state,
        data: {
          ...state.data,
          storedStep: undefined,
          questions,
          selectedBettingAnswer: action.payload.answer.optionText,
        },
      }
    }

    case ActionTypes.SET_SELECTED_BETTING_ANSWER: {
      return {
        ...state,
        data: {
          ...state.data,
          selectedBettingAnswer: action.payload,
        },
      }
    }

    case ActionTypes.SET_BETTING_ALREADY_ANSWERED_NOTIFICATION: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          alreadyAnswered: action.payload,
        },
      }
    }
    case ActionTypes.SET_BETTING_ALL_ANSWERED_NOTIFICATION: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          allAnswered: action.payload,
        },
      }
    }
    case ActionTypes.SET_BETTING_NO_MORE_QUESTIONS_NOTIFICATION: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          noMoreQuestions: action.payload,
        },
      }
    }
    case ActionTypes.SET_BETTING_ERROR_NOTIFICATION: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          error: action.payload,
        },
      }
    }

    case ActionTypes.RESET_BETTING_QUIZ:
      return initialState

    case AuthActionTypes.AUTH_LOGOUT:
      return {
        ...initialState,
        data: {
          ...initialState.data,
          ...(state.data.storedStep && { storedStep: state.data.storedStep }),
        },
      }

    default:
      return state
  }
}
