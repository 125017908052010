import { ThunkAction } from 'redux-thunk'

import { TReduxState } from 'src/redux/store'
import { checkBackendError, getReduxErrorObject } from 'src/utils/errors'
import { ActionTypes } from './enums'
import { config } from 'src/config'
import { privateClient } from 'src/utils/clients'
import {
  TNotificationsAPIResponse,
  TFetchNotificationsAction,
  TFetchNotificationsSuccessAction,
  TFetchNotificationsFailureAction,
  TFetchDismissNotificationAction,
  TFetchDismissNotificationSuccessAction,
  TFetchDismissNotificationFailureAction,
} from './types'

/**
 * Fetches the notifications
 */
export const fetchNotifications =
  (
    seasonId: number,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchNotificationsAction | TFetchNotificationsSuccessAction | TFetchNotificationsFailureAction
  > =>
  async (dispatch) => {
    const url = `${config.api.backend.url}/notifications?seasonId=${seasonId}`
    try {
      dispatch({
        type: ActionTypes.FETCH_NOTIFICATIONS,
      })

      const res = await privateClient.get<TNotificationsAPIResponse>(url)

      checkBackendError(res, 'Error while fetching user notifications')

      dispatch({
        type: ActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
        payload: res.data,
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.FETCH_NOTIFICATIONS_FAILURE,
        payload: getReduxErrorObject(error),
      })
    }
  }

/**
 * Dismisses the notifications
 */
export const dismissNotification =
  (
    id: number,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchDismissNotificationAction | TFetchDismissNotificationSuccessAction | TFetchDismissNotificationFailureAction
  > =>
  async (dispatch) => {
    const url = `${config.api.backend.url}/notifications/${id}/dismiss`
    try {
      dispatch({
        type: ActionTypes.DISMISS_NOTIFICATION,
      })

      const res = await privateClient.post(url)

      checkBackendError(res, 'Error while dismissing notification')

      dispatch({
        type: ActionTypes.DISMISS_NOTIFICATION_SUCCESS,
        payload: { id },
      })
    } catch (error) {
      dispatch({
        type: ActionTypes.DISMISS_NOTIFICATION_FAILURE,
        payload: getReduxErrorObject(error),
      })
    }
  }
