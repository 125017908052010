import { defaultsDeep } from 'lodash-es'
import { getPowerupTranslationsTitleKey, getPowerupTranslationsDescriptionKey } from 'src/utils/powerups'
import { powerupsTranslations, TPowerupsTranslations } from 'src/translations/de/default/powerups'
import { PowerupType } from 'src/api/powerups/enums'

export const skitrophyPowerupsTranslations: TPowerupsTranslations = defaultsDeep({}, powerupsTranslations)

skitrophyPowerupsTranslations.infoTooltipTitle = 'Booster für mehr Punkte!'
skitrophyPowerupsTranslations.infoMyPowerups = () =>
  `Das sind deine bisher eingesetzten oder noch verfügbaren Booster. In jeder Runde gibt es neue Booster.`
skitrophyPowerupsTranslations.infoTooltipText =
  'Booster geben dir die Möglichkeit, noch mehr Punkte zu sammeln. Um einen Booster zu aktivieren, musst du zuerst einen Resultattipp abgeben. Für jede Runde stehen dir neue Booster zur Verfügung. Probiere es jetzt aus!'
skitrophyPowerupsTranslations[getPowerupTranslationsTitleKey(PowerupType.AlwaysEighteenPoints)] = 'Frohes neues Jahr'
skitrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.AlwaysEighteenPoints)] =
  'Für einen super Start ins neue Jahr erhältst garantiert 18 Punkte. Egal, ob du richtig oder falsch getippt hast.'
skitrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.Double)] =
  'Für jeden Fahrer auf dem Podest, den du richtig getippt hast, erhältst du die doppelte Punktzahl.'
skitrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.DoubleOrNothing)] =
  'Erhalte die doppelte Punktzahl für jeden richtigen Tipp. Wenn alle Tipps falsch sind, erhältst du leider auch keine Trostpunkte.'
skitrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.SwissParticipant)] =
  'Für jeden Schweizer auf dem Podest, den du richtig getippt hast, erhältst du die dreifache Punktzahl.'
skitrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.OneForAll)] =
  'Wenn du mindestens einen Fahrer richtig getippt hast, kriegst du die maximale Punktzahl.'
skitrophyPowerupsTranslations[getPowerupTranslationsDescriptionKey(PowerupType.AlwaysFullPoints)] =
  'Für einen super Start ins neue Jahr erhältst garantiert 18 Punkte. Egal, ob du richtig oder falsch getippt hast.'
