import { InternalLink } from 'src/components/links/InternalLink'

export type TScoreBetTranslations = typeof scoreBetTranslations

export const scoreBetTranslations = {
  title: 'Tippe auf die EM-Spiele',
  description: (
    <>
      Du erhältst <b key="bold-text-0">12 Punkte</b> für das exakte Resultat oder <b key="bold-text-1">6 Punkte</b>,
      wenn du den Sieger oder das Unentschieden richtig tippst. Falls du daneben liegst, kriegst du einen{' '}
      <b key="bold-text-2">Trostpunkt fürs Mitspielen</b>.{' '}
      <InternalLink to={'#rule-2'} smooth>
        Mehr Infos zu den Tipp-Regeln
      </InternalLink>
      .
    </>
  ),
}
