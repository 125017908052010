import { TEndOfRoundModalTranslations } from 'src/translations/de/default/endofroundmodal'

export const endOfRoundModalTranslations: TEndOfRoundModalTranslations = {
  getSubtitle: (round: string) => ``,
  getSummaryTitle: (roundRank: number, userName?: string, round?: string) => (
    <>
      Cześć {userName} <br />
      Twoje wyniki w {round}.
    </>
  ),
  finalStepTitle: 'Nowy miesiąc, nowa runda',
  finalStepText: (
    <>Każdego miesiąca masz nową szansę na dostanie się na szczyt listy rankingowej. Wróć i spróbuj ponownie!</>
  ),
  // not used
  getGroupStepTitle: () => '',
}
