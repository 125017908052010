import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { TSummaryStyle } from 'src/theme/themes/default/components/summary'
import { accentwayColors } from 'src/theme/themes/accentway/colors'

const summary: TSummaryStyle = defaultsDeep({}, defaultTheme.components.Summary)
summary.bannerBackground = accentwayColors.turquoise
summary.subtitleBackgrounnd = defaultTheme.colors.blick.grey100
summary.titleTextStyle = `
    ${defaultTheme.components.Summary.titleTextStyle}
    font-size:36px;
    font-weight:900;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
`
summary.titleTextStyleMobile = `
    font-family: ${defaultTheme.fonts.goodOffcPro};
    font-weight: 900;
    font-size: 28px;
    line-height: 36px;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
`
summary.infoBlockBackground = defaultTheme.colors.shades.white
summary.infoBlockPadding = '8px 24px 8px 16px'
summary.infoBlockMargin = '0 auto 4px 0'
summary.infoBlockValueTextStyle = `
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${accentwayColors.turquoise};
`
summary.infoBlockDescriptionTextStyle = `
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${accentwayColors.turquoise};
`
summary.infoBlockAfter = `
    display:none;
`
summary.infoBlockBorderRadius = `
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
`
summary.subscriptionTitleTextStyle = `
    ${defaultTheme.components.Summary.subscriptionTitleTextStyle}
    font-weight: 900;
    font-size: 42px;
`
summary.subscriptionTitleTextStyleMobile = `
    ${defaultTheme.components.Summary.subscriptionTitleTextStyleMobile}
    font-weight:900;
`
summary.subscriptionBackground = defaultTheme.colors.blick.grey15
summary.subscriptionShadow = 'none'
summary.subscriptionFormSuccessBackground = defaultTheme.colors.feedback.positive

export const accentwaySummary = summary
