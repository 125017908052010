export enum ActionTypes {
  ANSWER_QUESTION = '@QUIZ/ANSWER_QUESTION',
  ANSWER_QUESTION_SUCCESS = '@QUIZ/ANSWER_QUESTION_SUCCESS',
  ANSWER_QUESTION_FAILURE = '@QUIZ/ANSWER_QUESTION_FAILURE',

  GET_NEXT_QUESTION = '@QUIZ/GET_NEXT_QUESTION',
  GET_NEXT_QUESTION_SUCCESS = '@QUIZ/GET_NEXT_QUESTION_SUCCESS',
  GET_NEXT_QUESTION_FAILURE = '@QUIZ/GET_NEXT_QUESTION_FAILURE',

  GET_STATUS = '@QUIZ/GET_STATUS',
  GET_STATUS_SUCCESS = '@QUIZ/GET_STATUS_SUCCESS',
  GET_STATUS_FAILURE = '@QUIZ/GET_STATUS_FAILURE',

  RESET_QUIZ = '@QUIZ/RESET_QUIZ',
  STORE_STEP = '@QUIZ/STORE_STEP',
  RESTORE_STEP = '@QUIZ/RESTORE_STEP',
  SET_SELECTED_ANSWER = '@QUIZ/SET_SELECTED_ANSWER',

  SET_ALREADY_ANSWERED_NOTIFICATION = '@QUIZ/SET_ALREADY_ANSWERED_NOTIFICATION',
  SET_ALL_ANSWERED_NOTIFICATION = '@QUIZ/SET_ALL_ANSWERED_NOTIFICATION',
  SET_NO_MORE_QUESTIONS_NOTIFICATION = '@QUIZ/SET_NO_MORE_QUESTIONS_NOTIFICATION',
  SET_ERROR_NOTIFICATION = '@QUIZ/SET_ERROR_NOTIFICATION',
}

export enum Errors {
  NO_MORE_QUESTIONS = 'no_more_questions',
}
