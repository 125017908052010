import { TEndOfSeasonModalStyle } from 'src/theme/themes/default/components/endOfSeasonModal'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { greenfieldColors } from 'src/theme/themes/greenfield/colors'
import { colors } from 'src/theme/colors'
import { StatisticksBlockStyles } from 'src/enums/summary'

const endOfSeasonModal: TEndOfSeasonModalStyle = defaultsDeep({}, defaultTheme.components.EndOfSeasonModal)

endOfSeasonModal.backgroundColor = greenfieldColors.intenseGreen
endOfSeasonModal.backgroundLinear = `linear-gradient(140deg, ${greenfieldColors.greenDarkBg} 45%, ${greenfieldColors.greenLightBg} 45%)`
endOfSeasonModal.basicColor = '#fff'
endOfSeasonModal.statisticsSubtitleBgColor = colors.blick.grey100
endOfSeasonModal.statisticsSubtitleColor = colors.shades.white
endOfSeasonModal.statisticsBlockBgColor = '#fff'
endOfSeasonModal.statisticsBlockColor = greenfieldColors.intenseGreen
endOfSeasonModal.statisticksBlockStyle = StatisticksBlockStyles.Round
endOfSeasonModal.statisticksBlockIconHeight = '120%'
endOfSeasonModal.feedbackStarColor = defaultTheme.colors.shades.white

export const greenfieldEndOfSeasonModal = endOfSeasonModal
