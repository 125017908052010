import { defaultsDeep } from 'lodash-es'
import { InternalLink } from 'src/components/links/InternalLink'

import {
  endOfSeasonModalTranslations,
  TEndOfSeasonModalTranslations,
} from 'src/translations/de/default/endofseasonmodal'

export const schwingenEndOfSeasonModalTranslations: TEndOfSeasonModalTranslations = defaultsDeep(
  {},
  endOfSeasonModalTranslations,
)

schwingenEndOfSeasonModalTranslations.statisticsSubtitle = 'DEINE SCHWINGEN TROPHY'
schwingenEndOfSeasonModalTranslations.feedbackTitle = 'Wie zufrieden warst du mit der Schwingen Trophy?'
schwingenEndOfSeasonModalTranslations.subscriptionText =
  'Möchtest du eine E-Mail erhalten, wenn es ein neues Spiel gibt?'
schwingenEndOfSeasonModalTranslations.thanksTitle = 'Danke, dass du mitgemacht hast!'
schwingenEndOfSeasonModalTranslations.thanksText = (link: string) => (
  <>
    Auf deiner Übersichtseite kannst du dich auch später für den &nbsp;
    <InternalLink to={link}>Newsletter anmelden</InternalLink>
  </>
)
