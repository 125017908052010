import { TSummaryTranslations } from 'src/translations/de/default/summary'

export const summaryTranslations: TSummaryTranslations = {
  pageTitle: 'Onet',
  title: (userRank, userName) => {
    return <>Tak {userName} grał/a w Codziennym Quizie</>
  },
  subscriptionTitle: '',
  subscriptionText: `Codzienny Quiz dobiegł końca. Otrzymaj e-mail, kiedy pojawi się nowa gra!`,
  subscriptionSuccessfulText: 'Dziękujemy! Jak tylko pojawi się nowa gra, otrzymasz e-mail.',
  invalidEmailError: 'Wprowadź poprawny adres e-mail',
  requiredFieldError: 'Proszę wypełnić to pole',
  inputLabel: 'Wprowadź swój adres e-mail',
  buttonText: 'Zapisz się na powiadomienia',
  round: {
    subtitle: (dinamicText?: string) => <></>,
    titleMobile: (rank?: number, name?: string) => <>Tak {name} grał/a w Codziennym Quizie</>,
    titleDesktop: (rank?: number, name?: string) => <>Tak {name} grał/a w Codziennym Quizie</>,
    bannerTitle: 'Dołącz do Quizu i graj co miesiąc.',
    buttonTextLoggedIn: 'Zagraj teraz',
    buttonTextLoggedOut: 'Zarejestruj się i zagraj',
    // not used
    team: {
      title: () => <></>,
      subtitle: () => <></>,
    },
  },
  // not used
  subtitle: () => <></>,
  team: {
    title: () => <></>,
    subtitle: <></>,
  },
}
