import { ReactComponent as Warning } from 'src/assets/images/warning_icon_toast.svg'
import { ReactComponent as Success } from 'src/assets/images/success_icon_toast.svg'
import { ReactComponent as Error } from 'src/assets/images/error_icon_toast.svg'
import { ReactComponent as Info } from 'src/assets/images/info_icon_toast.svg'

export const WarningIcon = () => <Warning />

export const SuccessIcon = () => <Success />

export const ErrorIcon = () => <Error />

export const InfoIcon = () => <Info />
