export type TLeaderboardTranslations = typeof leaderboardTranslations

export const leaderboardTranslations = {
  title: 'Punkte und Rangliste',
  titleGroup: 'Rangliste Tippgruppen',
  titleGroupName: (groupName: string) => `Deine Tippgruppe “${groupName}”`,
  description: 'Alle Punkte, die du im Tippspiel sammelst, werden hier zusammengezählt.',
  descriptionGroup: 'Klicke auf einen Namen, um einer Gruppe beizutreten oder um Statistiken zu sehen.',
  leaderboard_page_button: 'ganze Rangliste zeigen',
  leaderboard_group_page_button: 'alle tippgruppen zeigen',
  leaderboard_round: 'Runde',
  fallbackText: 'Es gibt noch keine Spieler',
  headerMobileTitle: 'Rangliste',
  headerMobileTitleGroup: 'Rangliste',
  headerDesktopTitle: 'Rangliste',
  headerDesktopTitleGroup: 'Rangliste',
  searchPlaceholder: 'Suche nach Spielername',
  searchGroupPlaceholder: 'Suche nach Tippgruppe',
  rank: 'Rang',
  userName: 'Spieler',
  userNameShort: 'Spieler',
  teamName: 'Gruppen',
  total: 'Total',
  groupTotal: 'Total Ø',
  gained: 'Heute',
  groupGained: 'Heute Ø',
  more: 'Mehr anzeigen',
}
