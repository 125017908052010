import { Suspense, lazy, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { checkBlickAuth, checkBackendAuth } from 'src/api/auth/actions'
import { getDidReturnFromLogin, getIsAuthResolved, getLoginMethod } from 'src/api/auth/selectors'

import { ErrorBoundaryProvider } from 'src/components/error/ErrorBoundaryProvider'
import { DrawerProvider } from 'src/drawer'
import { Error500 } from 'src/components/error/Error500'
import { useUrlParams } from 'src/utils/hooks/useUrlParams'
import { getTenant } from 'src/utils/tenant'
import { Tenants } from 'src/enums/tenants'
import { trackAppInitialization, trackUserAuthStateUpdated, trackUserFinishedLogin } from 'src/tracking/gtm/actions'
import styled from 'styled-components/macro'
import { isFromPaths } from 'src/utils/router/isFromPaths'
import { WIDGET_PATHS } from 'src/router/constants'
import { useLocation } from 'react-router-dom'
import { getOnetSession } from 'src/utils/getOnetSession'
import { config } from 'src/config'

const LibertateaLayout = lazy(() => import('src/layout/LibertateaLayout'))
const BlickLayout = lazy(() => import('src/layout/BlickLayout'))
const OnetLayout = lazy(() => import('src/layout/OnetLayout'))

export const AppLayout = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  useUrlParams()

  const isAuthResolved = useSelector(getIsAuthResolved)
  const tenant = getTenant()
  const didReturnFromLogin = useSelector(getDidReturnFromLogin)
  const loginMethod = useSelector(getLoginMethod)

  const [isWidgetPath, setIsWidgetPath] = useState<boolean>()

  useEffect(() => {
    if (isAuthResolved) {
      trackUserAuthStateUpdated()
      if (didReturnFromLogin) {
        trackUserFinishedLogin(loginMethod)
      }
    }
  }, [isAuthResolved, didReturnFromLogin, loginMethod])

  useEffect(() => {
    // Initially
    if (tenant === Tenants.BLICK) {
      dispatch(checkBlickAuth())
    } else if (tenant === Tenants.ONET) {
      if (config.isLocalhost) {
        dispatch(checkBackendAuth())
      } else {
        getOnetSession.then((res) => {
          dispatch(checkBackendAuth())
        })
      }
    } else {
      dispatch(checkBackendAuth())
    }
  }, [dispatch, tenant])

  useEffect(() => trackAppInitialization(), [])

  useEffect(() => {
    const widgetPath = isFromPaths(WIDGET_PATHS, location.pathname)
    setIsWidgetPath(widgetPath)
  }, [location])

  const getLayout = () => {
    switch (tenant) {
      case Tenants.LIBERTATEA:
        return <LibertateaLayout isWidgetPath={isWidgetPath} />
      case Tenants.ONET:
        return <OnetLayout isWidgetPath={isWidgetPath} />
      default:
        return <BlickLayout isWidgetPath={isWidgetPath} />
    }
  }

  return (
    <ErrorBoundaryProvider fallbackComponent={<Error500 />}>
      <DrawerProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Wrapper>{getLayout()}</Wrapper>
        </Suspense>
      </DrawerProvider>
    </ErrorBoundaryProvider>
  )
}
const Wrapper = styled.div<{ isWidgetPath?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${({ isWidgetPath }) => (isWidgetPath ? 'auto' : '100vh')};
  ${({ theme }) => theme.media.isMobile} {
    overflow-y: initial;
  }
`
