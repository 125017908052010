const texts = {
  primary: '#000000',
  secondary: '#545454',
  inverted: '#fff',
  grey: '#8d8f97',
  lightGrey: '#8e8e90',
}

export const colors = {
  texts,
  feedback: {
    positive: '#00d523',
    neutral: texts.primary,
    negative: '#ff0024',
  },
  secondary: {
    focus: '#2460C5',
  },
  feedbackAlt: {
    positive: '#62a01a',
    neutral: '#999999',
    negative: '#e20000',
    warning: '#ffce00',
    warningBg: '#FFF7E3',
  },
  prizes: ['#F9C442', '#BEC2CB', '#CC8E34'],
  blicktv: {
    grey05: '#f9fbff',
    grey30: '#d2e0f2',
    grey55: '#46505A',
    grey90: '#080808',
  },
  blick: {
    brand: '#e20000',
    brandBg: '#c00500',
    brand5: '#fff3f2',
    brand10: '#FED1CD',
    brand20: '#FDADA8',
    brand55: '#e20000',
    brand50: '#68B83A',
    brand60: '#c00500',
    brand70: '#9e0900',
    brand100: '#170400',
    green: '#9fe72c',
    greenBg: '#88dc24',
    greenAlt: '#68b83a',
    red: '#f00',
    redBg: '#4d0707',
    redAlt: '#c20000',
    sport: '#46d079',
    sport5: '#EBF5F0',
    sport10: '#CCF3D9',
    sport40: '#7BCF65',
    sport55: '#62a01a',
    sport60: '#3b8910',
    sport65: '#1D710B',
    sportBg: '#163327',
    people: '#e26ac6',
    peopleBg: '#3b1432',
    life: '#5db4ff',
    lifeBg: '#0a193b',
    sponsor: '#ffb120',
    sponsorBg: '#4a3610',
    blueGrey: '#8395aa',
    blueGreyLight: '#B2C1D4',
    blueGreyAlt: '#616C77',
    blueGreyDark: '#8796A6',
    blueLight1: '#E7F0FE',
    grey10: '#f8f8f8',
    grey15: '#f3f3f3',
    grey20: '#eeeeee',
    grey30: '#e0e0e0',
    grey50: '#c8c8c8',
    grey55: '#46505a',
    grey60: '#A9A9A9',
    grey70: '#8e8e90',
    grey75: '#767676',
    grey80: '#545454',
    grey90: '#3d3d3d',
    grey100: '#1f1f1f',
  },
  social: {
    socialFacebook: '#3c5a99',
    socialInstagram: '#d93175',
    socialTwitter: '#00aced',
    socialWhatsapp: '#48cb57',
    socialEmail: '#e20000',
    socialCopy: '#919191',
  },
  shades: {
    transparent: 'transparent',
    // named greyscale
    greyDark: '#2e363d',
    grey: '#545454',
    greyMedium: '#919191',
    greyLight: '#929292',
    greyVeryLight: '#f8f8f8',
    // greyscale
    white: '#fff',
    shade0: '#f1f1f1',
    shade1: '#eee',
    shade2: '#ddd',
    shade3: '#ccc',
    shade4: '#bbb',
    shade5: '#aaa',
    shade6: '#999',
    shade6b: '#9c9c9c', // former shade16
    shade7: '#888',
    shade8: '#777',
    shade9: '#666',
    shade10: '#555',
    shade11: '#444',
    shade12: '#333',
    shade13: '#222',
    shade14: '#111',
    shade15: '#1f1f1f',
    black: '#000',
    red: '#C10202',
    green: '#447807',
  },
  food: {
    food60: '#F59C00',
  },
}
