import { defaultsDeep } from 'lodash-es'

import { TTranslations } from 'src/translations/types'
import { translations as defaultTranslations } from 'src/translations/de/default'

import { eishockeywmRulesTranslations } from './rules'

export const translations: TTranslations = defaultsDeep({}, defaultTranslations)

translations.rules = eishockeywmRulesTranslations
