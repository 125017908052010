export enum ActionTypes {
  ASSIGN_WIDGET_QUIZ = '@QUIZ/ASSIGN_WIDGET_QUIZ',
  ASSIGN_WIDGET_QUIZ_SUCCESS = '@QUIZ/ASSIGN_WIDGET_QUIZ_SUCCESS',
  ASSIGN_WIDGET_QUIZ_FAILURE = '@QUIZ/ASSIGN_WIDGET_QUIZ_FAILURE',

  GET_WIDGET_STATUS = '@QUIZ/GET_WIDGET_STATUS',
  GET_WIDGET_STATUS_SUCCESS = '@QUIZ/GET_WIDGET_STATUS_SUCCESS',
  GET_WIDGET_STATUS_FAILURE = '@QUIZ/GET_WIDGET_STATUS_FAILURE',

  GET_NEXT_WIDGET_QUESTION = '@QUIZ/GET_NEXT_WIDGET_QUESTION',
  GET_NEXT_WIDGET_QUESTION_SUCCESS = '@QUIZ/GET_NEXT_WIDGET_QUESTION_SUCCESS',
  GET_NEXT_WIDGET_QUESTION_FAILURE = '@QUIZ/GET_NEXT_WIDGET_QUESTION_FAILURE',

  ANSWER_WIDGET_QUESTION = '@QUIZ/ANSWER_WIDGET_QUESTION',
  ANSWER_WIDGET_QUESTION_SUCCESS = '@QUIZ/ANSWER_WIDGET_QUESTION_SUCCESS',
  ANSWER_WIDGET_QUESTION_FAILURE = '@QUIZ/ANSWER_WIDGET_QUESTION_FAILURE',

  STORE_WIDGET_ANSWERS = '@QUIZ/STORE_WIDGET_ANSWERS',
  STORE_WIDGET_ANSWERS_SUCCESS = '@QUIZ/STORE_WIDGET_ANSWERS_SUCCESS',
  STORE_WIDGET_ANSWERS_FAILURE = '@QUIZ/STORE_WIDGET_ANSWERS_FAILURE',
  RESET_STORE_WIDGET_ANSWERS = `@QUIZ/RESET_STORE_WIDGET_ANSWERS`,

  SET_SELECTED_WIDGET_ANSWER = '@QUIZ/SET_SELECTED_WIDGET_ANSWER',

  SET_LAST_ANSWERED_QUESTION = '@QUIZ/SET_LAST_ANSWERED_QUESTION',
}
