import { InternalLink } from 'src/components/links/InternalLink'
import { TPrizesTranslations } from 'src/translations/de/default/prizes'

export const prizesTranslations: TPrizesTranslations = {
  title: 'Premii',
  description: (link?: string) => (
    <>
      În cadrul concursului “Câștigă cu Întrebarea Zilei” ai șansa de a câștiga unul dintre{' '}
      {link && (
        <InternalLink to={link} smooth>
          zecile de premii puse în joc
        </InternalLink>
      )}
      .
    </>
  ),
}
