import { colors } from 'src/theme/colors'
import { fonts } from 'src/theme/fonts'

export type TCommonStyle = typeof defaultCommon

export const defaultCommon = {
  background: colors.blick.grey10,
  font: fonts.sourceSansPro,
  color: colors.blick.grey100,
  borderRadius: '10px',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.4)',
  scrollMarginTop: '110px',
  scrollMarginTopMobile: '76px',
}
