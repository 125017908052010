export const localStorageSetItem = (key: string, value: string) => {
  if (localStorage) {
    localStorage.setItem(key, value)
  }
}

export const localStorageGetItem = (key: string): string | null => {
  if (localStorage) {
    return localStorage.getItem(key)
  }
  return null
}

export const localStorageGetParsedItem = (key: string): string | null => {
  try {
    const item = localStorage.getItem(key)
    const parsedItem = item ? JSON.parse(item) : null
    return parsedItem
  } catch (e) {
    const error = new Error(`Failed to get and parse ${key} from local storage`)
    console.error(error)
    console.error(e)
    return null
  }
}

export const localStorageRemoveItem = (key: string) => {
  if (localStorage) {
    localStorage.removeItem(key)
  }
}
