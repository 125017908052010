import { ExternalLink } from 'src/components/links/ExternalLink'

export type TRulesTranslations = typeof rulesTranslations

export const rulesTranslations = {
  title: 'Das sind die Spielregeln',
  description: (
    <>
      {' '}
      Hier findest du die{' '}
      <ExternalLink
        href="https://www.blick.ch/gewinnen/das-muessen-sie-wissen-teilnahmebedingungen-id7732110.html"
        target="_blank"
        key="link-1"
      >
        Teilnahmebedingungen
      </ExternalLink>
      . Viel Spass und Erfolg!
    </>
  ),
}
