import { TEndOfSeasonModalStyle } from 'src/theme/themes/default/components/endOfSeasonModal'
import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { bluefrostColors } from 'src/theme/themes/bluefrost/colors'
import { colors } from 'src/theme/colors'
import { StatisticksBlockStyles } from 'src/enums/summary'

const endOfSeasonModal: TEndOfSeasonModalStyle = defaultsDeep({}, defaultTheme.components.EndOfSeasonModal)

endOfSeasonModal.backgroundLinear = `linear-gradient(135deg, ${bluefrostColors.blueLight1} 45%, ${bluefrostColors.blueLight2} 45%)`
endOfSeasonModal.backgroundLinearSubscribe = `linear-gradient(135deg, ${bluefrostColors.blueLight1} 65%, ${bluefrostColors.blueLight2} 35%)`
endOfSeasonModal.backgroundColor = bluefrostColors.blueLight2
endOfSeasonModal.statisticsSubtitleColor = colors.shades.white
endOfSeasonModal.statisticsBlockBgColor = colors.shades.white
endOfSeasonModal.statisticsBlockColor = colors.blick.grey100
endOfSeasonModal.messageAnimationWidth = '124px'
endOfSeasonModal.messageAnimationWidthMobile = '124px'
endOfSeasonModal.feedbackStarColor = colors.blick.grey100
endOfSeasonModal.statisticksBlockStyle = StatisticksBlockStyles.Round

export const blueFrostEndOfSeasonModal = endOfSeasonModal
