import { defaultsDeep } from 'lodash-es'

import { quizWidgetTranslations, TQuizWidgetTranslations } from 'src/translations/de/default/quizwidget'

export const frauenwmQuizWidgetTranslations: TQuizWidgetTranslations = defaultsDeep({}, quizWidgetTranslations)

frauenwmQuizWidgetTranslations.quizTitle = 'Tagesquiz lösen und gewinnen mit der «WM Trophy»'
frauenwmQuizWidgetTranslations.resultBottomTextWithBets = (availableBets?: number) => (
  <>
    Du hast noch <b>{availableBets ? availableBets : 0} ungetippte Spiele</b>. Jetzt tippen und noch mehr Punkte
    sammeln.
  </>
)
frauenwmQuizWidgetTranslations.resultButtonUserWithBets = 'Spiele warten auf Tipps'
frauenwmQuizWidgetTranslations.resultButtonUser = 'Zur Blick WM Trophy'
frauenwmQuizWidgetTranslations.resultBottomText = 'Schaue dir deine bisherigen Spieltipps und die Rangliste an.'
