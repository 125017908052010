export enum Path {
  Home = '/',
  Product = '/:productName',
  ProductLeaderBoard = '/:productName/leaderboard',
  ProductLeaderBoardGroup = '/:productName/leaderboard-group',
  ProductBet = '/:productName/bet/:raceId',
  LoginRedirect = ':productName/login_redirect',
  QuizWidget = '/:productName/quizwidget',
  ScorebetWidget = '/:productName/scorebetwidget',
  MatchbetWidget = '/:productName/matchbetwidget',
  EndOfSeasonSummary = '/:productName/summary/:username',
  EndOfSeasonGroupSummary = '/:productName/summary/team/:groupId',
  EndOfRoundSummary = '/:productName/summary/round/:roundId/user/:userId',
  EndOfRoundGroupSummary = '/:productName/summary/round/:roundId/team/:groupId',
  // test routes
  QuizWidgetTest = '/:productName/test-the-quizwidget',
  ScorebetWidgetTest = '/:productName/test-the-scorebetwidget',
  MatchbetWidgetTest = '/:productName/test-the-matchbetwidget',
}
