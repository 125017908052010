import { theme as defaultTheme } from 'src/theme/themes/default'
import { defaultsDeep } from 'lodash-es'
import { greenfieldColors } from 'src/theme/themes/greenfield/colors'
import { fonts } from 'src/theme/fonts'
import { TLeaderboardStyle } from 'src/theme/themes/default/components/leaderboard'

const leaderboard: TLeaderboardStyle = defaultsDeep({}, defaultTheme.components.Leaderboard)

leaderboard.currentUserRow.background = greenfieldColors.green55
leaderboard.currentUserRow.color = '#fff'
leaderboard.hasRewardRow.background = defaultTheme.colors.shades.white
leaderboard.sectionTitleBorderColor = greenfieldColors.intenseGreen
leaderboard.sectionTitleFontWeight = 900
leaderboard.goToLeaderBoardButton.background = 'none'
leaderboard.goToLeaderBoardButton.hover.background = defaultTheme.colors.blick.grey10
leaderboard.goToLeaderBoardButton.textTransform = 'uppercase'
leaderboard.goToLeaderBoardButton.textDecoration = 'none'
leaderboard.goToLeaderBoardButton.borderRadius = '25px'
leaderboard.goToLeaderBoardButton.padding = '10px 12px'
leaderboard.goToLeaderBoardButton.fontFamily = fonts.goodOffcPro

export const greenfieldLeaderboard = leaderboard
